import * as React from "react";
import styled from "styled-components";

interface IProps {
  image: string;
  width?: string;
}

const Logo = styled.img`
  display: inline-block;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const LogoWrapper: React.SFC<IProps> = (props: IProps) => {
  return <Logo src={props.image} width={props.width || "auto"} />;
};

export default LogoWrapper;
