import * as React from "react";
import { Label } from "reactstrap";

// Themes
import { Colors } from "../../Themes";

interface IProps {
  value: string;
  for?: string
}

const RequiredField: React.SFC<IProps> = (props: IProps) => {
  return (
    <Label for={props.for ? props.for : ''}>
      {props.value}
      <span style={{ color:Colors.error }}> *</span>
    </Label>
  );
};

export default RequiredField;
