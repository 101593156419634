import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withToastManager } from "react-toast-notifications";

/** Presentation/UI */
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import PageLoader from "../../Components/PageLoader";
import {
  TableHeader,
  TableHeaderContainer
} from "../../Components/Styled/ListViewElements";
import { Collapse, Card, CardHeader, CardBody } from "reactstrap";

/** Custom Types */
import { ToastNotificationType } from "../../CustomTypes";
import { sortByCreatedAt } from "../../Utils/Helpers";
import { TableConsts } from "../../Utils/Consts";
import { listFaqTypes } from "../../graphql/queries";

type Props = {
  toastManager: ToastNotificationType;
  location: { [key: string]: any }; // object with any amount and types of params
};

type State = {
  importModal: boolean;
  selected: any;
  containerType: string;
  addModal: boolean;
  editModal: boolean;
  searchData: string;
  collapse: { card: string; state: boolean };
  collapseCategory: { category: string; state: boolean };
};

class Help extends React.Component<Props> {
  state: State = {
    importModal: false,
    selected: null,
    containerType: this.props.location.pathname,
    addModal: false,
    editModal: false,
    searchData: "",
    collapse: { card: "", state: false },
    collapseCategory: { category: "", state: false }
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance ? appearance : "success",
      autoDismiss: true
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      addModal: false,
      editModal: false
    });
  };

  /** Open modal */
  openAddModal = (): void => {
    this.setState({ addModal: true });
  };

  openEditModal = (data: any): void => {
    this.setState({ editModal: true, selected: data });
  };

  // Toggle to view selected question's answer
  viewQuestion = (question: string) => {
    return this.setState({ collapse: { card: question, state: false } });
  };
  // Toggle to view selected question's answer
  viewCategory = (category: string) => {
    return this.setState({ collapseCategory: { category, state: false } });
  };

  categoryToggle = (categoryId: string): boolean => {
    const { collapseCategory } = this.state;
    if (collapseCategory.category === categoryId) {
      return true;
    } else {
      return false;
    }
  };

  answerToggle = (cardId: string): boolean => {
    const { collapse } = this.state;
    if (collapse.card === cardId) {
      return true;
    } else {
      return false;
    }
  };

  // List collapsible FAQ questions
  listFaqs = (questions: any) => {
    const faqCards = questions.map((item: any) => {
      return (
        <Card key={item.id}>
          <CardHeader onClick={() => this.viewQuestion(item.id)}>
            <h5>{item.question}</h5>
          </CardHeader>
          <Collapse isOpen={this.answerToggle(item.id)}>
            <CardBody>{item.answer}</CardBody>
          </Collapse>
        </Card>
      );
    });
    return faqCards;
  };

  // List collapsible FAQ categories
  listCategories = (categories: any) => {
    const faqCategories = categories.map((item: any) => {
      return (
        <Card key={item.id}>
          <CardHeader onClick={() => this.viewCategory(item.id)}>
            <h5>{item.title}</h5>
          </CardHeader>
          <Collapse isOpen={this.categoryToggle(item.id)}>
            <CardBody>{this.listFaqs(item.faqs.items)}</CardBody>
          </Collapse>
        </Card>
      );
    });
    return faqCategories;
  };

  render() {
    const { searchData } = this.state;
    return (
      <BackendWrapper>
        <TableHeaderContainer>
          <TableHeader>
            <span>FAQs</span>
          </TableHeader>
        </TableHeaderContainer>
        <Query
          query={gql(listFaqTypes)}
          variables={{ limit: TableConsts.limit }}
        >
          {({ loading, error, data }: any) => {
            if (loading) {
              return <PageLoader />;
            }
            if (error) {
              return <div>There was a problem loading your data</div>;
            }

            if (!data || !data.listFaqTypes) {
              return <div>There are no faq at the moment</div>;
            }

            let filteredData = data.listFaqTypes.items;
            filteredData = filteredData.filter((row: any) => {
              return row.title.toLowerCase().includes(searchData.toLowerCase());
            });

            return this.listCategories(sortByCreatedAt(filteredData));
          }}
        </Query>
        <Helmet title="Help" />
      </BackendWrapper>
    );
  }
}

export default withToastManager(Help);
