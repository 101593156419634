import styled from "styled-components";

type IProps = {
  flex?: string;
  width?: string;
  justify?: string;
  align?: string;
  marginRight?: string;
  marginLeft?: string;
  padding?: string;
  paddingTop?: string;
  paddingBottom?: string;
  flexWrap?: string;
};

const Row = styled.div`
  display: flex;
  flex: ${(props: IProps) => (props.flex ? props.flex : "")}
  width: ${(props: IProps) => (props.width ? props.width : "100%")};
  margin-right: ${(props: IProps) =>
    props.marginRight ? props.marginRight + "px" : "0"};
  margin-left: ${(props: IProps) =>
    props.marginLeft ? props.marginLeft + "px" : "0"};
  padding-bottom: ${(props: IProps) =>
    props.padding ? props.padding + "px" : "0"};
  padding-top: ${(props: IProps) =>
    props.paddingTop ? props.paddingTop + "px" : "0"};
  flex-wrap: ${(props: IProps) => (props.flexWrap ? props.flexWrap : "wrap")};
  `;

export default Row;
