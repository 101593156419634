import * as React from "react";
import {
  MdInsertDriveFile,
  MdAttachMoney,
  MdQuestionAnswer
} from "react-icons/md";

/** Custom types */
import { NavItemType } from "../CustomTypes";

export const User: Array<NavItemType> = [
  {
    to: "/consumer/reports",
    role: "USER",
    title: "Reports",
    icon: <MdInsertDriveFile size="1.3em" />
  },
  {
    to: "/consumer/purchased",
    role: "USER",
    title: "Purchased",
    icon: <MdAttachMoney size="1.3em" />
  },
  {
    to: "/consumer/about-roots",
    role: "USER",
    title: "About Roots",
    icon: <MdQuestionAnswer size="1.3em" />
  }
];
