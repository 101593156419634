import * as React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap";
// GraphQL
import { updateUser } from "../../graphql/mutations";
import { UpdateUserMutation, UpdateUserMutationVariables } from "../../API";

// Presentation/UI
import ErrorMessage from "../../Components/Styled/ErrorMessage";

type State = {
  name: string;
  surname: string;
  idNumber: string;
  jobTitle: string;
  contactNumber: string;
  loading: boolean;
  error: string | null;
};

type Props = {
  userId: string;
  updateView(view: string): void;
};

class UserDetailsForm extends React.Component<Props, State> {
  state: State = {
    name: "",
    surname: "",
    idNumber: "",
    jobTitle: "",
    contactNumber: "",
    loading: false,
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  // Handle change in input fields
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    switch (name) {
      case "name":
        this.setState({ name: value });
        break;
      case "surname":
        this.setState({ surname: value });
        break;
      case "idNumber":
        this.setState({ idNumber: value });
        break;
      case "jobTitle":
        this.setState({ jobTitle: value });
        break;
      case "contactNumber":
        this.setState({ contactNumber: value });
        break;
      default:
        break;
    }
  };

  // Validation
  validateForm = (): boolean => {
    const { name, surname, idNumber, jobTitle, contactNumber } = this.state;
    // Check for undefined or empty input fields
    if (!name || !surname || !idNumber || !jobTitle || !contactNumber) {
      this.setError("Please complete the form.");
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { name, surname, idNumber, jobTitle, contactNumber } = this.state;

    const { updateView, userId } = this.props;

    return (
      <Mutation<UpdateUserMutation, UpdateUserMutationVariables>
        mutation={gql(updateUser)}
      >
        {updateUserMutation => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateForm()) {
                updateUserMutation({
                  variables: {
                    input: {
                      id: userId,
                      name,
                      surname,
                      idNumber,
                      jobTitle,
                      contactNumber,
                      archived: false
                    }
                  }
                })
                  .then((updateUserRes: any) => {
                    // change to success view
                    updateView("success");
                  })
                  .catch((updateUserErr: any) => {
                    this.setError(updateUserErr.message);
                  });
              }
            }}
          >
            <Row>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    placeholder="First Name"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <Label for="name">Surname</Label>
                  <Input
                    type="text"
                    name="surname"
                    id="surname"
                    value={surname}
                    placeholder="Last name"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <Label for="idNumber">ID Number</Label>
                  <Input
                    type="number"
                    name="idNumber"
                    id="idNumber"
                    value={idNumber}
                    placeholder="ID Number"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <Label for="jobTitle">Job Title</Label>
                  <Input
                    type="text"
                    name="jobTitle"
                    id="jobTitle"
                    value={jobTitle}
                    placeholder="Job Title"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <Label for="contactNumber">Contact Number</Label>
                  <Input
                    type="number"
                    name="contactNumber"
                    id="contactNumber"
                    value={contactNumber}
                    placeholder="Contact Number"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <br />
            <Button>
              <span>Next</span>
            </Button>
            {this.state.error && (
              <ErrorMessage errorMessage={this.state.error} />
            )}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default UserDetailsForm;
