import * as React from "react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { Row, Col, Form, Label, FormGroup, Input } from "reactstrap";

/** GraphQL */
import { createFaqType, createFaq } from "../../graphql/mutations";

import {
  CreateFaqTypeMutationVariables,
  CreateFaqTypeMutation,
  CreateFaqMutation,
  CreateFaqMutationVariables
} from "../../API";

/** Presentation/UI */
import Loader from "../../Components/Loader";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import StyledButton from "../../Components/Styled/Button";

/** Custom Types */
import { Error } from "../../CustomTypes";

/** Themes */
import { Colors } from "../../Themes";
import { TableConsts } from "../../Utils/Consts";
import { listFaqTypes, listFaqs } from "../../graphql/queries";
import Select from "react-select";
import PageLoader from "../../Components/PageLoader";

type Props = {
  data?: any;
  containerType: string;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  loading: boolean;
  error: Error;
  name: string;
  selectedId: string;
  question: string;
  answer: string;
  category: { value: string; label: string };
};

class AddFaq extends React.Component<Props, State> {
  state: State = {
    loading: false,
    error: null,
    name: this.props.data && this.props.data.name ? this.props.data.name : "",
    selectedId: this.props.data && this.props.data.id ? this.props.data.id : "",
    category: { value: "", label: "" },
    question: "",
    answer: ""
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  // Create a new FAQ category
  // after react scripts changes
  // eslint-disable-next-line
  createCategory = async (createFaqTypeMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { name } = this.state;

    this.setState({ loading: true });
    // create the category in dynamodb
    if (name !== "") {
      createFaqTypeMutation({
        variables: {
          input: {
            title: name
          }
        },
        refetchQueries: [
          {
            query: gql(listFaqTypes),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Added category, ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  // Create new Question for FAQ
  // after react scripts changes
  // eslint-disable-next-line
  createFaq = async (createFaqMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { question, answer, category } = this.state;

    this.setState({ loading: true });
    if (question || answer || category.value !== "") {
      createFaqMutation({
        variables: {
          input: {
            question,
            answer,
            faqCategoryId: category.value
          }
        },
        refetchQueries: [
          {
            query: gql(listFaqs),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification("Question Added");
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Fields cannot be blank");
    }
  };

  // Category Form Fields
  categoryFormFields = () => {
    const { error, loading, name, selectedId } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="name">Category Name</Label>
              <Input
                type="text"
                name="name"
                value={name}
                id="name"
                placeholder="Name"
                onChange={e => this.setState({ name: e.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <StyledButton
                type="submit"
                label={
                  !loading ? selectedId !== "" ? "Save" : "Add" : <Loader />
                }
                color={Colors.coal}
                background={Colors.background}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        {error && <ErrorMessage errorMessage={error} />}
      </React.Fragment>
    );
  };

  // FAQ Question fields
  faqFormFields = () => {
    const { question, answer } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Query query={gql(listFaqTypes)}>
                {({ loading, data }: any) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (!loading && data) {
                    const optionsToUse =
                      data && data.listFaqTypes && data.listFaqTypes.items
                        ? data.listFaqTypes.items.map(
                          (item: { id: string; title: string }) => {
                            return { label: item.title, value: item.id };
                          }
                        )
                        : [];
                    return (
                      <React.Fragment>
                        <FormGroup>
                          <Label>Category</Label>
                          <Select
                            options={optionsToUse}
                            onChange={(e: any) => {
                              this.setState({ category: e });
                            }}
                            value={this.state.category}
                            placeholder=""
                            className="select-styling"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Question</Label>
                          <Input
                            type="text"
                            name="question"
                            value={question}
                            id="question"
                            placeholder="Question"
                            onChange={e =>
                              this.setState({ question: e.target.value })
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Answer</Label>
                          <Input
                            type="textarea"
                            name="answer"
                            value={answer}
                            id="answer"
                            placeholder="Answer"
                            onChange={e =>
                              this.setState({ answer: e.target.value })
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <StyledButton
                            type="submit"
                            label={loading ? <Loader /> : " Save"}
                            color={Colors.coal}
                            background={Colors.background}
                          />
                        </FormGroup>
                      </React.Fragment>
                    );
                  } else {
                    return <p>There has been a problem loading your cart</p>;
                  }
                }}
              </Query>
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  render() {
    switch (this.props.containerType) {
      case "Category":
        return (
          <Mutation<CreateFaqTypeMutation, CreateFaqTypeMutationVariables>
            mutation={gql(createFaqType)}
          >
            {createFaqTypeMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  this.createCategory(createFaqTypeMutation);
                }}
              >
                {this.categoryFormFields()}
              </Form>
            )}
          </Mutation>
        );
      case "FAQ":
        return (
          <Mutation<CreateFaqMutation, CreateFaqMutationVariables>
            mutation={gql(createFaq)}
          >
            {createFaqMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  this.createFaq(createFaqMutation);
                }}
              >
                {this.faqFormFields()}
              </Form>
            )}
          </Mutation>
        );
      default:
        return <div>There was an error loading your data.</div>;
    }
  }
}

export default AddFaq;
