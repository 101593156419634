import * as React from "react";
import { Helmet } from "react-helmet";

// Presentation/UI
import styled from "styled-components";
import Table from "../../Components/Table";
import { Col, Row } from "reactstrap";
import Card from "../../Components/Styled/Card";
import { Colors } from "../../Themes";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { dashboardCount } from "../../graphql/custom-queries";
import PageLoader from "../../Components/PageLoader";

import StackedBarChart from "../../Components/Charts/StackedBarChart";
import SimpleLineChart from "../../Components/Charts/SimpleLineChart";

import { purchaseData, newClientsData, TableConsts } from "../../Utils/Consts";
import { RouteComponentProps } from "react-router";

type Props = RouteComponentProps;

class Dashboard extends React.Component<Props> {
  showCharts = (name: string, list: any[]) => {
    return (
      <Col md={6}>
        <Card>
          <Row>
            <Col>
              <h6>{name}</h6>
            </Col>
          </Row>
          <Row style={{ height: "400px" }}>
            <Col>
              {name === "Purchases" ? (
                <StackedBarChart data={list} />
              ) : (
                  <SimpleLineChart data={list} />
                )}
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };
  showCountCard = (name: string, count: number) => {
    return (
      <Col md={4}>
        <Card color={Colors.default}>
          <h5 className="text-white">{name}</h5>
          <h3 className="text-white">{count}</h3>
        </Card>
      </Col>
    );
  };

  render() {
    return (
      <BackendWrapper>
        <Query query={gql(dashboardCount)}>
          {({ loading, error, data }: any) => {
            if (loading) {
              return <PageLoader />;
            }
            if (data) {
              const purchasesCount = JSON.parse(JSON.stringify(purchaseData));
              // after react scripts changes
              // eslint-disable-next-line
              data.listTransactions.items.map((transaction: any) => {
                purchasesCount[new Date(transaction.createdAt).getMonth()]
                  .Purchases++;
              });

              const clientsCount = JSON.parse(JSON.stringify(newClientsData));
              // after react scripts changes
              // eslint-disable-next-line
              data.listCompanys.items.map((company: any) => {
                clientsCount[new Date(company.createdAt).getMonth()].Clients++;
              });

              return (
                <React.Fragment>
                  <Row>
                    {this.showCountCard(
                      "Clients",
                      data.listCompanys.items.length
                    )}
                    {this.showCountCard(
                      "Documents",
                      data.listReports.items.length
                    )}
                    {this.showCountCard(
                      "Purchased Documents",
                      data.listTransactions.items.length
                    )}
                  </Row>
                  <Row style={{ paddingTop: "20px" }}>
                    <Col md={6}>
                      <Card>
                        <Row>
                          <Col>
                            <h6>Purchases</h6>
                          </Col>
                        </Row>
                        <Row style={{ height: "400px" }}>
                          <Col>
                            <StackedBarChart data={purchasesCount} />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <Row>
                          <Col>
                            <h6>New Clients</h6>
                          </Col>
                        </Row>
                        <Row style={{ height: "400px" }}>
                          <SimpleLineChart data={clientsCount} />
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "20px" }}>
                    <Col md={6}>
                      <h6>Top 10 Purchased Reports</h6>
                    </Col>
                    <Col md={6}>
                      <p className="text-right">View all reports</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <Table
                          data={data.listReports.items}
                          getTdProps={(
                            state: any,
                            rowInfo: { [key: string]: any }
                          ) => {
                            return {
                              onClick: () => {
                                if (rowInfo) {
                                  this.props.history.push({
                                    pathname: `/admin-reports/preview`,
                                    state: rowInfo.original
                                  });
                                }
                              }
                            };
                          }}
                          columns={[
                            {
                              Header: "Report Name",
                              accessor: "name"
                            },
                            {
                              id: "purchases",
                              Header: "Purchases",
                              accessor: (item: { [key: string]: any }) => {
                                return item.companys.items.length;
                              }
                            },
                            {
                              id: "price",
                              Header: "Price",
                              accessor: (item: { [key: string]: any }) => {
                                return "R " + item.price;
                              }
                            }
                          ]}
                          defaultSortBy={[
                            {
                              id: "purchases",
                              desc: true
                            }
                          ]}
                          defaultPageSize={TableConsts.defaultRows}
                          showPaginationBottom={
                            data.listTransactions.items.length >
                            TableConsts.defaultRows
                          }
                        />
                      </Card>
                    </Col>
                  </Row>
                </React.Fragment>
              );
            } else {
              return (
                <Row>
                  <p>There has been an error {error}</p>
                </Row>
              );
            }
          }}
        </Query>
        <Helmet title="Dashboard" />
      </BackendWrapper>
    );
  }
}

const BackendWrapper = styled.div`
  display: flex;
  flex: 1;
  background: ${Colors.dashboardGrey};
  min-height: 100vh;
  padding: 20px;
  flex-direction: column;
`;

export default Dashboard;
