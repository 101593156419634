import * as React from "react";
import * as validator from "validator";
import * as moment from "moment";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { MdCopyright } from "react-icons/md";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

// Presentation/UI
import FullWidthContainer from "../../Components/Layouts/FullWidthContainer";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import Button from "../../Components/Styled/Button";
import Loader from "../../Components/Loader";
import GlobalModalContainer from "../../Components/Modal";
import TermsAndConditions from "../../Components/TermsAndConditions";
import Span from "../../Components/Styled/Span";
import Link from "../../Components/Styled/Link";
import { Auth } from "aws-amplify";

// Local components
import ForgotPassword from "./ForgotPassword";
import SetNewPassword from "./SetNewPassword";

// Themes
import { Colors } from "../../Themes";

/** Utils */
import { AUTH_USER_TOKEN_KEY } from "../../Utils/LocalStorage";
import { COGNITO_CHALLENGES, ADMIN_ROLES } from "../../Utils/Consts";
import { getUserRole } from "../../Utils/Helpers";

type PropsType = RouteComponentProps & {
  history: History;
};

type IState = {
  email: string;
  password: string;
  session: string;
  loading: boolean;
  modal: boolean;
  newPasswordModal: boolean;
  termsModal: boolean;
  error: string | null;
};

class LoginForm extends React.Component<PropsType, IState> {
  state: IState = {
    email: "",
    password: "",
    session: "",
    loading: false,
    modal: false,
    newPasswordModal: false,
    termsModal: false,
    error: null
  };

  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;
    if (name === "email") {
      this.setState({
        email: value.toLowerCase()
      });
    } else if (name === "password") {
      this.setState({
        password: value
      });
    }
  };

  // Handle sign in
  signIn = () => {
    const { email, password } = this.state;
    // clears everything in local storage before logging the user in, to ensure no previous user data is there still.
    window.localStorage.clear();
    Auth.signIn(email, password)
      .then(user => {
        const { history } = this.props;
        // Authenticated user
        if (user.signInUserSession) {
          // Authorised user token
          window.localStorage.setItem(
            AUTH_USER_TOKEN_KEY,
            user.signInUserSession.accessToken.jwtToken
          );
          ADMIN_ROLES.includes(getUserRole())
            ? history.push("/dashboard")
            : history.push("/consumer/reports");
        }
        // New user with temporary password
        if (user.challengeName === COGNITO_CHALLENGES.newPasswordRequired) {
          this.openNewPasswordModal(user.Session);
        }
      })
      .catch(err => {
        this.setError(err.message);
        this.setState({ loading: false });
      });
  };

  validateForm = (): boolean => {
    const { email, password } = this.state;
    // Check for undefined or empty input fields
    if (!email || !password) {
      this.setError("Please enter a valid email and password.");
      return false;
    }

    // Validate email
    if (!validator.isEmail(email)) {
      this.setError("Please enter a valid email address.");
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  // Close modal
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  // Open modal
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  /** Close new password modal */
  closeNewPasswordModal = (): void => {
    this.setState({
      newPasswordModal: false,
      loading: false,
      email: "",
      password: "",
      session: ""
    });
  };

  /** Open modal */
  openNewPasswordModal = (session: string): void => {
    this.setState({
      session,
      newPasswordModal: true
    });
  };

  /** Open modal with terms and conditions */
  openTermsAndConditionsModal = (): void => {
    this.setState({
      termsModal: true
    });
  };

  /** Close modal with terms and conditions */
  closeTermsAndConditionsModal = (): void => {
    this.setState({
      termsModal: false
    });
  };

  render() {
    const {
      email,
      password,
      session,
      loading,
      error,
      modal,
      newPasswordModal,
      termsModal
    } = this.state;
    return (
      <FullWidthContainer>
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title="Reset Password"
          modalDisplay={
            <ForgotPassword
              nav={() => this.props.history.push("/dashboard")}
              closeModal={this.closeModal}
            />
          }
          modal={modal}
        />
        <GlobalModalContainer
          toggleModal={this.closeNewPasswordModal}
          title="New Password Required"
          modalDisplay={
            <SetNewPassword
              closeModal={this.closeNewPasswordModal}
              username={email}
              session={session}
              challengeName={COGNITO_CHALLENGES.newPasswordRequired}
            />
          }
          modal={newPasswordModal}
        />
        <TermsAndConditions
          modal={termsModal}
          closeModal={this.closeTermsAndConditionsModal}
        />
        <Form
          onSubmit={e => {
            e.preventDefault();
            if (this.validateForm()) {
              this.setState({ loading: true });
              this.signIn();
            }
          }}
        >
          <FormGroup>
            <Label for="userEmail">Email Address</Label>
            <Input
              type="email"
              name="email"
              value={email}
              id="userEmail"
              placeholder="john@mail.com"
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="userPassword">Password</Label>
            <Input
              type="password"
              name="password"
              value={password}
              id="userPassword"
              placeholder="Password"
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup className="right-align">
            <Span
              onClick={this.openModal}
              text="Forgot Password?"
              color={Colors.primary}
              pointer={true}
            />
          </FormGroup>
          <br />
          <br />
          <Button
            type="submit"
            disabled={loading}
            label={!loading ? "Sign In" : <Loader color={Colors.whiteSmoke} />}
            background={Colors.primary}
          />
          <br />
          {error && <ErrorMessage errorMessage={error} />}
          <br />
          <br />
          <FullWidthContainer align="center">
            <Span text="New To Roots?" style={{ marginRight: "10px" }} />
            {"  "}
            <Link to="/sign-up" label="Sign Up" color={Colors.primary} />
            <br />
            <br />
            <br />
            <Span
              onClick={this.openTermsAndConditionsModal}
              text="Read Terms &amp; Conditions"
              color={Colors.primary}
              pointer={true}
            />
            <br />
            <br />
            <Span text="ROOTS" />{" "}
            <MdCopyright
              size="1em"
              style={{ position: "relative", top: "-2px" }}
            />{" "}
            {/*
            // @ts-ignore after react scripts changes.*/}
            {moment().format("YYYY")}
          </FullWidthContainer>
        </Form>
      </FullWidthContainer>
    );
  }
}

export default withRouter(LoginForm);
