import axios from "axios";
import {
  TEST_HOST,
  RECURRING_INITIAL,
  AUTHORIZATION,
  ENTITY_ID_RECURRING
} from "./Constants";

// Save a billing card to Peach DB
export async function saveNewCard(
  cardType: string,
  cardNumber: string,
  nameOnCard: string,
  cardExpiryMonth: string,
  cardExpiryYear: string,
  cardCVV: string
): Promise<object> {
  try {
    return axios({
      method: "post",
      headers: {
        Authorization: AUTHORIZATION
      },
      url: "/v1/payments",
      baseURL: TEST_HOST,
      data:
        "entityId=" +
        ENTITY_ID_RECURRING +
        "&" +
        "paymentBrand=" +
        cardType +
        "&" +
        "card.number=" +
        cardNumber +
        "&" +
        "card.holder=" +
        nameOnCard +
        "&" +
        "card.expiryMonth=" +
        cardExpiryMonth +
        "&" +
        "card.expiryYear=" +
        cardExpiryYear +
        "&" +
        "card.cvv=" +
        cardCVV +
        "&" +
        "recurringType=" +
        RECURRING_INITIAL +
        "&" +
        "createRegistration=true" +
        "&" +
        "paymentType=PA" +
        "&" +
        "currency=ZAR" +
        "&" +
        `amount=${Number(1.0).toFixed(2)}`
    });
  } catch (e) {
    return { error: e.message };
  }
}
