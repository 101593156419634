// Base urls for the web app based on the current environment
export const DEV_BASE_URL = "https://dev.roots.sovtech.org";

export const UAT_BASE_URL = "https://uat.roots.sovtech.org";

export const PROD_BASE_URL = "https://prod.roots.sovtech.org";

// recurring payment type
export const RECURRING_REPEATED = "REPEATED";

// first of many recurring payments
export const RECURRING_INITIAL = "INITIAL";

// if the user is redirected from the roots portal for payment, they should return to the below address once payment successful
export const SHOPPER_RESULT_URL = `${DEV_BASE_URL}/payment/success`;

// All test transactions should be performed under the test link
export const TEST_HOST = "https://test.oppwa.com";

// Once the app is live in the production server, transactions should be performed with the link below
export const LIVE_HOST = "https://oppwa.com";

// Authentication for any transaction with Peach Payments
export const AUTH_ONE = "8ac7a4c76a7c4c1d016a7d53db4f0164";

// 3D Secure transaction key, this should be used for all payments
export const ENTITY_ID_3DS = "8ac7a4c76a7c4c1d016a7d53db4f0164";

// Use this for testing only
export const ENTITY_ID_RECURRING = "8ac7a4c76a7c4c1d016a7d53da120160";

export const AUTHORIZATION = "Bearer OGFjN2E0Yzc2YTdjNGMxZDAxNmE3ZDUzZDY1ZDAxNWV8WkVqOTZTem5wYg==";
