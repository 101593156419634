import * as React from "react";
import { Button } from "reactstrap";

interface IProps {
  background?: string;
  disabled?: boolean;
  color?: string;
  label: string | React.ReactNode;
  width?: string;
  margin?: string;
  className?: string;
  type: "button" | "submit" | "reset";
  onClick?: () => void;
}

const ButtonWrapper: React.SFC<IProps> = (props: IProps) => {
  return (
    <Button
      disabled={props.disabled}
      type={props.type}
      className={props.className}
      style={{
        color: props.color,
        background: props.background,
        width: props.width,
        margin: props.margin
      }}
      onClick={props.onClick}
    >
      {props.label}
    </Button>
  );
};

export default ButtonWrapper;
