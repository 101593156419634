import * as React from "react";
import styled from "styled-components";

// Presentation/UI
import Loader from "../Loader";

// Themes
import { Colors } from "../../Themes";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: ${(props: { height?: string }) =>
    props.height ? `${props.height}` : "auto"};
  background: ${Colors.background};
`;

const PageLoader: React.FC<{ height?: string }> = props => {
  return (
    <Wrapper height={props.height}>
      <Loader size={40} color={Colors.primary} />
    </Wrapper>
  );
};

export default PageLoader;
