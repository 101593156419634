import * as React from "react";
import * as validator from "validator";
import styled from "styled-components";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import Select from "react-select";
import { Row, Col, Form, Label, FormGroup, Input } from "reactstrap";
import AppSyncConfig from "../../aws-exports";

/** GraphQL */
import {
  createUser,
  updateUser,
  createUserCompany
} from "../../graphql/mutations";

import {
  CreateUserMutation,
  CreateUserMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserRole,
  UpdateUserInput,
  CreateUserCompanyMutation,
  CreateUserCompanyMutationVariables
} from "../../API";

/** Presentation/UI */
import Loader from "../../Components/Loader";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import StyledButton from "../../Components/Styled/Button";

/** Custom Types */
import { Error } from "../../CustomTypes";

/** Themes */
import { Colors } from "../../Themes";

// Utils
import { HTTP_METHODS } from "../../Utils/Consts";
import {
  COGNITO_CREATE_USER,
  COGNITO_EDIT_USER
} from "../../Utils/LambdaEndpoints";

/** API */
import { apiRequest } from "../../Utils/API";
import { formatString, formatDropdown } from "../../Utils/Helpers";
import { listUsers } from "../../graphql/queries";

const ToggleButtonsContainer = styled.div`
  & .leftToggleBtn {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  & .rightToggleBtn {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`;

type SelectRoleType = { value: UserRole; label: string };

type Props = {
  userData?: UpdateUserInput | null;
  containerType: string;
  companyId?: string | null;
  archivedFilter: boolean;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  username: string;
  userRole: UserRole | null;
  inviteUser: boolean;
  loading: boolean;
  error: Error;
  name: string;
  surname: string;
  userId: string;
  idNumber: string;
  contactNumber: string;
  archived: boolean;
};

class AddAdminUser extends React.Component<Props, State> {
  state: State = {
    username:
      this.props.userData && this.props.userData.emailAddress
        ? this.props.userData.emailAddress
        : "",
    userRole:
      this.props.userData && this.props.userData.role
        ? this.props.userData.role
        : null,
    inviteUser: true,
    loading: false,
    error: null,
    name:
      this.props.userData && this.props.userData.name
        ? this.props.userData.name
        : "",
    surname:
      this.props.userData && this.props.userData.surname
        ? this.props.userData.surname
        : "",
    userId:
      this.props.userData && this.props.userData.id
        ? this.props.userData.id
        : "",
    archived:
      this.props.userData && this.props.userData.archived
        ? this.props.userData.archived
        : false,
    idNumber:
      this.props.userData && this.props.userData.idNumber
        ? this.props.userData.idNumber
        : "",
    contactNumber:
      this.props.userData && this.props.userData.contactNumber
        ? this.props.userData.contactNumber
        : ""
  };

  /** Select user role
   * @param userRole - user role based on created company roles
   */
  selectRoleType = (userRole: SelectRoleType): void => {
    this.setState({ userRole: userRole.value });
  };

  /** Company role options */
  listRoleOptions = (): any => {
    let roles = [UserRole.SUPER_USER, UserRole.USER];
    if (this.props.containerType === "/admin/team") {
      roles = [UserRole.ROOTS_SUPER_ADMIN, UserRole.ROOTS_ADMIN];
    }
    if (roles && roles.length) {
      return roles.map((role: UserRole) => {
        return formatDropdown(role);
      });
    }
    return [];
  };

  /** Validation */
  validateForm = (): boolean => {
    const { username, userRole } = this.state;

    if (!userRole) {
      this.setError("Please select a company role");
      return false;
    }

    // Validate email
    if (!validator.isEmail(username)) {
      this.setError("Please enter a valid email address.");
      return false;
    }

    return true;
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Create a new user */
  createNewUser = async (
    // after react scripts changes
    // eslint-disable-next-line
    createUserMutation: ({ }) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    createUserCompanyMutation?: ({ }) => Promise<any>
  ) => {
    const { closeModal, notification } = this.props;
    const {
      username,
      userRole,
      inviteUser,
      name,
      surname,
      idNumber,
      contactNumber
    } = this.state;

    this.setState({ loading: true });

    const bodyParams = {
      groupName: userRole,
      userPoolId: AppSyncConfig.aws_user_pools_id,
      email: username,
      inviteUser
    };

    // create user in cognito pool
    const user = await apiRequest(
      COGNITO_CREATE_USER,
      HTTP_METHODS.POST,
      bodyParams
    ).catch(e => {
      this.setState({ loading: false });
      this.setError(e.message);
    });
    // create the user in dynamodb
    if (user && user.Attributes && user.Attributes.length) {
      const id = user.Attributes[0].Value;

      createUserMutation({
        variables: {
          input: {
            id,
            emailAddress: username,
            archived: false,
            role: userRole,
            name,
            surname,
            idNumber,
            contactNumber
          }
        },
        refetchQueries: [
          {
            query: gql(listUsers),
            variables: {
              filter: {
                or: [
                  { role: { eq: UserRole.ROOTS_SUPER_ADMIN } },
                  { role: { eq: UserRole.ROOTS_ADMIN } }
                ]
              }
            }
          }
        ]
      })
        .then(() => {
          if (createUserCompanyMutation !== undefined) {
            createUserCompanyMutation({
              variables: {
                input: {
                  userCompanyCompanyId: this.props.companyId,
                  userCompanyUserId: id
                }
              }
            })
              .then(s => {
                this.setState({ loading: false });
                closeModal();
                notification(
                  `Added ${username} as a ${formatString(userRole)}`
                );
              })
              .catch(err => {
                this.setState({ loading: false });
                this.setError(err.message);
              });
          } else {
            this.setState({ loading: false });
            closeModal();
            notification(`Added ${username} as a ${formatString(userRole)}`);
          }
        })
        .catch(err => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError(user ? user : "There was an error adding the new user.");
    }
  };

  /** Edit a user */
    // after react scripts changes
    // eslint-disable-next-line
  editUser = async (updateUserMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const {
      username,
      userRole,
      name,
      surname,
      userId,
      archived,
      idNumber,
      contactNumber
    } = this.state;

    if (name === "") {
      this.setState({ error: "Name cannot be blank" });
      return;
    }
    if (surname === "") {
      this.setState({ error: "Surname cannot be blank" });
      return;
    }
    if (idNumber === "") {
      this.setState({ error: "Id number cannot be blank" });
      return;
    }
    if (contactNumber === "") {
      this.setState({ error: "Contact number cannot be blank" });
      return;
    }

    this.setState({ loading: true });

    const bodyParams = {
      userPoolId: AppSyncConfig.aws_user_pools_id,
      email: username
    };

    // create user in cognito pool
    await apiRequest(COGNITO_EDIT_USER, HTTP_METHODS.POST, bodyParams).catch(
      e => {
        this.setState({ loading: false });
        this.setError(e.message);
        return;
      }
    );
    // update the user in dynamodb
    updateUserMutation({
      variables: {
        input: {
          id: userId,
          emailAddress: username,
          archived,
          role: userRole,
          name,
          surname,
          idNumber,
          contactNumber
        }
      },
      refetchQueries: [
        {
          query: gql(listUsers),
          variables: {
            filter: {
              or: [
                { role: { eq: UserRole.ROOTS_SUPER_ADMIN } },
                { role: { eq: UserRole.ROOTS_ADMIN } }
              ],
              archived: { eq: this.props.archivedFilter }
            }
          }
        }
      ]
    })
      .then(() => {
        this.setState({ loading: false });
        closeModal();
        notification(`Added ${username} as a ${formatString(userRole)}`);
      })
      .catch(err => {
        this.setState({ loading: false });
        this.setError(err.message);
      });
  };

  /**
   * Toggle whether or not to invite a user to the platform via email
   */
  toggleInviteUser = (inviteUser: boolean) => {
    this.setState({ inviteUser });
  };
  toggleArchiveUser = () => {
    this.setState({ archived: !this.state.archived });
  };

  returnFormFields = () => {
    const {
      username,
      userRole,
      error,
      loading,
      name,
      surname,
      userId,
      archived,
      idNumber,
      contactNumber
    } = this.state;
    const re = /^[0-9\b]+$/;
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                value={name}
                id="name"
                placeholder="Name"
                onChange={e => this.setState({ name: e.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="surname">Surname</Label>
              <Input
                type="text"
                name="surname"
                value={surname}
                id="surname"
                placeholder="surname"
                onChange={e => this.setState({ surname: e.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="username">Email Address</Label>
              <Input
                type="email"
                name="username"
                value={username}
                id="username"
                placeholder="Email"
                onChange={e => this.setState({ username: e.target.value.toLowerCase() })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="contactNumber">Contact Number</Label>
              <Input
                maxLength={10}
                minLength={10}
                type="text"
                name="contactNumber"
                value={contactNumber}
                id="contactNumber"
                placeholder="Contact number"
                onChange={e => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    this.setState({ contactNumber: e.target.value });
                  }
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="idNumber">ID Number</Label>
              <Input
                maxLength={13}
                minLength={13}
                type="text"
                name="idNumber"
                value={idNumber}
                id="idNumber"
                placeholder="ID number"
                onChange={e => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    this.setState({ idNumber: e.target.value });
                  }
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="userRole">User Role</Label>
              <Select
                // @ts-ignore after react scripts changes
                onChange={this.selectRoleType}
                options={this.listRoleOptions()}
                // @ts-ignore after react scripts changes
                value={formatDropdown(userRole)}
                isSearchable={true}
                className="select-styling"
              />
            </FormGroup>
          </Col>
        </Row>
        {userId !== "" ? (
          <Row>
            <Col xs={6} md={6} lg={6}>
              <FormGroup>
                <Label>{archived ? "Unarchive" : "Archive"} user?</Label>
                <ToggleButtonsContainer>
                  <StyledButton
                    type="button"
                    label="Yes"
                    width="100px"
                    onClick={() => this.toggleArchiveUser()}
                    color={archived ? Colors.snow : Colors.snow}
                    background={archived ? Colors.primary : Colors.coralPink}
                  />
                </ToggleButtonsContainer>
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        <br />
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <StyledButton
                type="submit"
                label={!loading ? userId !== "" ? "Save" : "Add" : <Loader />}
                color={Colors.coal}
                background={Colors.background}
              />
            </FormGroup>
          </Col>
        </Row>
        {error && <ErrorMessage errorMessage={error} />}
      </React.Fragment>
    );
  };

  render() {
    if (this.state.userId !== "") {
      return (
        <Mutation<UpdateUserMutation, UpdateUserMutationVariables>
          mutation={gql(updateUser)}
        >
          {updateUserMutation => (
            <Form
              onSubmit={e => {
                e.preventDefault();
                if (this.validateForm()) {
                  this.editUser(updateUserMutation);
                }
              }}
            >
              {this.returnFormFields()}
            </Form>
          )}
        </Mutation>
      );
    } else if (
      this.props.containerType &&
      this.props.containerType === "/consumer/team"
    ) {
      return (
        <Mutation<CreateUserMutation, CreateUserMutationVariables>
          mutation={gql(createUser)}
        >
          {createUserMutation => (
            <Mutation<
              CreateUserCompanyMutation,
              CreateUserCompanyMutationVariables
            >
              mutation={gql(createUserCompany)}
            >
              {createUserCompanyMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    if (this.validateForm()) {
                      this.createNewUser(
                        createUserMutation,
                        createUserCompanyMutation
                      );
                    }
                  }}
                >
                  {this.returnFormFields()}
                </Form>
              )}
            </Mutation>
          )}
        </Mutation>
      );
    } else {
      return (
        <Mutation<CreateUserMutation, CreateUserMutationVariables>
          mutation={gql(createUser)}
        >
          {createUserMutation => (
            <Form
              onSubmit={e => {
                e.preventDefault();
                if (this.validateForm()) {
                  this.createNewUser(createUserMutation);
                }
              }}
            >
              {this.returnFormFields()}
            </Form>
          )}
        </Mutation>
      );
    }
  }
}

export default AddAdminUser;
