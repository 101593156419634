import * as React from "react";
import { Form, Label, FormGroup, Input } from "reactstrap";

/** Presentation/UI */
import FullWidthContainer from "../../Components/Layouts/FullWidthContainer";
import LottieWrapper from "../../Components/Anim/LottieWrapper";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import Loader from "../../Components/Loader";
import Button from "../../Components/Styled/Button";

/** Custom Types */
import { ForgotPasswordViews, Error } from "../../CustomTypes";
import StyledButton from "../../Components/Styled/Button";
import Link from "../../Components/Styled/Link";

// Themes
import { Colors } from "../../Themes";

/** Utils */
import { COGNITO_CONFIRM_USER } from "../../Utils/LambdaEndpoints";
import { HTTP_METHODS } from "../../Utils/Consts";

import AppSyncConfig from "../../aws-exports";

/** API */
import { apiRequest } from "../../Utils/API";
import { Auth } from "aws-amplify";
import { AUTH_USER_TOKEN_KEY } from "../../Utils/LocalStorage";

type IProps = {
  username: string;
  session: string;
  challengeName: string;
  closeModal: () => void;
};

type NewPasswordFieldProps = {
  loading: boolean;
  password: string;
  error: Error;
  validateNewPasswordForm: () => boolean;
  confirmNewUser: () => void;
  setNewPassword(password: string): void;
};

type IState = {
  view: ForgotPasswordViews;
  password: string;
  loading: boolean;
  error: Error;
};

const NewPasswordField: React.FC<NewPasswordFieldProps> = props => {
  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        if (props.validateNewPasswordForm()) {
          props.confirmNewUser();
        }
      }}
    >
      <FormGroup>
        <Label for="userPassword">New Password</Label>
        <Input
          type="password"
          name="password"
          value={props.password}
          id="userPassword"
          placeholder="Password"
          onChange={e => props.setNewPassword(e.target.value)}
        />
      </FormGroup>
      <Button
        type="submit"
        label={props.loading ? <Loader /> : <span>Update Password</span>}
        background={Colors.primary}
      />
      {props.error && <ErrorMessage errorMessage={props.error} />}
    </Form>
  );
};

class SetNewPasswordForm extends React.Component<IProps, IState> {
  state: IState = {
    view: "newPassword",
    password: "",
    loading: false,
    error: null
  };

  validateNewPasswordForm = (): boolean => {
    const { password } = this.state;
    // Check for undefined or empty input fields
    if (!password) {
      this.setError("Please enter a valid new password");
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Set new state for password */
  setNewPassword = (password: string): void => {
    this.setState({ password });
  };

  // Handle setting of a new password
  confirmNewUser = async () => {
    const { password } = this.state;
    const { username, session, challengeName } = this.props;

    this.setState({ loading: true });

    const bodyParams = {
      username,
      password,
      challengeName,
      session,
      clientId: AppSyncConfig.aws_user_pools_web_client_id
    };

    const response = await apiRequest(
      COGNITO_CONFIRM_USER,
      HTTP_METHODS.POST,
      bodyParams
    ).catch(e => {
      this.setError(e.message);
    });

    if (response) {
      // Log the user in after successfully setting a new password
      Auth.signIn(this.props.username, this.state.password).then(user => {
        if (user.signInUserSession) {
          window.localStorage.setItem(
            AUTH_USER_TOKEN_KEY,
            user.signInUserSession.accessToken.jwtToken
          );
        }
      });
      this.setState({ loading: false, view: "success" });
    }
  };

  renderForm = (view: ForgotPasswordViews): React.ReactNode => {
    const { loading, error, password } = this.state;

    switch (view) {
      case "newPassword":
        return (
          <NewPasswordField
            loading={loading}
            error={error}
            password={password}
            setNewPassword={this.setNewPassword}
            confirmNewUser={this.confirmNewUser}
            validateNewPasswordForm={this.validateNewPasswordForm}
          />
        );
      case "success":
        return (
          <FullWidthContainer align="center">
            <LottieWrapper
              loop={false}
              width={140}
              height={110}
              anim={require("../../LottieFiles/success1.json")}
            />
            <br />
            Password reset successful
            <br />
            <Link
              to="/dashboard"
              label={
                <StyledButton
                  type="button"
                  label="Login"
                  width="120px"
                  color={Colors.snow}
                  background={Colors.default}
                />
              }
            />
          </FullWidthContainer>
        );
      default:
        return (
          <NewPasswordField
            loading={loading}
            error={error}
            password={password}
            setNewPassword={this.setNewPassword}
            confirmNewUser={this.confirmNewUser}
            validateNewPasswordForm={this.validateNewPasswordForm}
          />
        );
    }
  };

  render() {
    const { view } = this.state;
    return <FullWidthContainer>{this.renderForm(view)}</FullWidthContainer>;
  }
}

export default SetNewPasswordForm;
