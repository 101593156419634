import * as React from "react";
import { Input } from "reactstrap";

interface IProps {
  width?: string;
  margin?: string;
  type: any;
  placeholder?: string;
  name: string;
  id: string;
  value: string;
  height: string;
  onChange: any;
}

const InputWrapper: React.SFC<IProps> = (props: IProps) => {
  return (
    <Input
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      style={{
        width: props.width,
        height: props.height
      }}
    />
  );
};

export default InputWrapper;
