import * as React from "react";
import * as validator from "validator";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Row, Col, Form, Label, FormGroup, Input, Button } from "reactstrap";

/** GraphQL */
import { updateUser } from "../../graphql/mutations";

import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserInput
} from "../../API";

/** Presentation/UI */
import Loader from "../../Components/Loader";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import StyledButton from "../../Components/Styled/Button";

/** Customer Types */
import { Error, UpdatePersonalProfileViews } from "../../CustomTypes";

/** Themes */
import { Colors } from "../../Themes";

interface IProps {
  user: UpdateUserInput;
  closeModal(): void;
  updateView(view: UpdatePersonalProfileViews): void;
  notification(message: string): void;
}

type State = {
  id: string;
  name: string;
  surname: string;
  idNumber: string;
  emailAddress: string;
  contactNumber: string;
  jobTitle: string;
  loading: boolean;
  error: Error;
};

class EditUserForm extends React.Component<IProps, State> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      id: props.user.id,
      name: props.user.name || "",
      surname: props.user.surname || "",
      idNumber: props.user.idNumber || "",
      emailAddress: props.user.emailAddress || "",
      contactNumber: props.user.contactNumber || "",
      jobTitle: props.user.jobTitle || "",
      loading: false,
      error: null
    };
  }

  // Handle change
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;
    const re = /^[0-9\b]+$/;

    switch (name) {
      case "name":
        this.setState({ name: value });
        break;
      case "surname":
        this.setState({ surname: value });
        break;
      case "idNumber":
        if (value === "" || re.test(value)) {
          this.setState({ idNumber: value });
        }
        break;
      case "emailAddress":
        this.setState({ emailAddress: value.toLowerCase() });
        break;
      case "jobTitle":
        this.setState({ jobTitle: value });
        break;
      case "contactNumber":
        if (value === "" || re.test(value)) {
          this.setState({ contactNumber: value });
        }
        break;
      default:
        break;
    }
  };

  validateForm = (): boolean => {
    const { name, emailAddress } = this.state;
    // Check for undefined or empty input field
    if (!name) {
      this.setError("Please fill in the first name field");
      return false;
    }

    // Validate emailAddress
    if (!validator.isEmail(emailAddress)) {
      this.setError("Please enter a valid emailAddress address.");
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const {
      id,
      name,
      surname,
      idNumber,
      emailAddress,
      contactNumber,
      jobTitle
    } = this.state;
    const { notification, closeModal, updateView } = this.props;

    return (
      <Mutation<UpdateUserMutation, UpdateUserMutationVariables>
        mutation={gql(updateUser)}
      >
        {updateUserMutation => (
          <div>
            <Form
              onSubmit={e => {
                e.preventDefault();
                if (this.validateForm()) {
                  this.setState({ loading: true });

                  updateUserMutation({
                    variables: {
                      input: {
                        id,
                        name,
                        surname,
                        idNumber,
                        contactNumber,
                        jobTitle
                      }
                    }
                  })
                    .then(res => {
                      this.setState({ loading: false });
                      closeModal();
                      notification("Profile updated successfully");
                    })
                    .catch(err => {
                      this.setState({ loading: false });
                      this.setError(err.message);
                    });
                }
              }}
            >
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <Label for="name">First Name</Label>
                    <Input
                      type="text"
                      name="name"
                      value={name}
                      id="name"
                      placeholder="First name"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <Label for="surname">Last Name</Label>
                    <Input
                      type="text"
                      name="surname"
                      value={surname}
                      id="surname"
                      placeholder="Last name"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <FormGroup>
                    <Label for="idNumber">ID Number</Label>
                    <Input
                      maxLength={13}
                      minLength={13}
                      type="text"
                      name="idNumber"
                      value={idNumber}
                      id="idNumber"
                      placeholder="ID Number"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <FormGroup>
                    <Label for="useremailAddress">Email Address</Label>
                    <Input
                      type="email"
                      name="emailAddress"
                      value={emailAddress}
                      id="useremailAddress"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <FormGroup>
                    <Label>Job Title</Label>
                    <Input
                      type="text"
                      name="jobTitle"
                      value={jobTitle}
                      id="jobTitle"
                      placeholder="Job Title"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <Input
                      maxLength={10}
                      minLength={10}
                      type="text"
                      name="contactNumber"
                      value={contactNumber}
                      id="contactNumber"
                      placeholder="Contact number"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <StyledButton
                      label="Change Password"
                      type="button"
                      onClick={() => updateView("changePassword")}
                      background={Colors.background}
                      color={Colors.coal}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <Button background={Colors.background} color={Colors.coal}>
                      {!this.state.loading ? "Update" : <Loader />}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              {this.state.error && (
                <ErrorMessage errorMessage={this.state.error} />
              )}
            </Form>
          </div>
        )}
      </Mutation>
    );
  }
}

export default EditUserForm;
