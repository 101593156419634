import * as React from "react";
import { Nav } from "reactstrap";
import HamburgerIcon from "./HamburgerIcon";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
// Context API
import { AppContextConsumer } from "../ContextProvider";

// Styled elements and presentation components
import Header from "../Header";
import SideBarNavItem from "./SideBarNavItem";

// Custom types
import { NavItemType } from "../../CustomTypes";

// Utils
import { convertToSelector } from "../../Utils/Helpers";

import {
  PageWrap,
  Spacer,
  LogoCont,
  Logo,
  SideBarWrapper
} from "./StyledElements";

// Themes
import { Images } from "../../Themes";

// CSS styling
import "./index.css";

type PropsType = RouteComponentProps & {
  render: React.ReactNode;
  title: string;
  navItems: Array<NavItemType>;
  path: string;
  role: string;
  userId: string;
};

class Sidebar extends React.Component<PropsType> {
  // return sidebar nav items
  returnNavItems = () => {
    const { navItems, role } = this.props;

    const navItemsToDisplay = navItems.filter(navItem => navItem.role === role);

    return navItemsToDisplay.map((item, i) => {
      return (
        <SideBarNavItem
          key={item.to}
          to={item.to}
          navId={convertToSelector(item.title)}
          icon={item.icon}
          title={item.title}
          subMenu={item.subMenu}
        />
      );
    });
  };

  render() {
    const { render, userId } = this.props;

    return (
      <AppContextConsumer>
        {({ drawerOpen, toggleDrawer }) => {
          const sideBarOpen = drawerOpen;

          return (
            <PageWrap>
              <Spacer open={sideBarOpen} />
              <LogoCont open={sideBarOpen}>
                <HamburgerIcon onClick={toggleDrawer} open={sideBarOpen} />
                <Logo open={sideBarOpen} src={Images.rootsLogo} />
              </LogoCont>
              <SideBarWrapper className="custom-sidebar" open={sideBarOpen}>
                <Nav>{this.returnNavItems()}</Nav>
              </SideBarWrapper>

              <React.Fragment>
                <Header userId={userId} />
                <PageWrap style={{ paddingTop: 70, flex: 1 }}>
                  {render}
                </PageWrap>
              </React.Fragment>
            </PageWrap>
          );
        }}
      </AppContextConsumer>
    );
  }
}

export default withRouter(Sidebar);
