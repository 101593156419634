import * as React from "react";
import PageWrapper from "../Layouts/PageWrapper";
import LottieWrapper from "../Anim/LottieWrapper";

interface IProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<IProps> {
  render() {
    return (
      <PageWrapper>
        <LottieWrapper
          width={400}
          height={"auto"}
          loop={true}
          anim={require("../../LottieFiles/me_at_office.json")}
        />
        Oops, looks like something went wrong. Our team has been notified of
        this error and will have it resolved as soon as possible.
      </PageWrapper>
    );
  }
}

export default ErrorBoundary;
