import * as React from "react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { Row, Col, Form, Label } from "reactstrap";
import Select from "react-select";

/** GraphQL */
import {
  deleteReportCategory,
  deleteReportTag,
  createReportCategory,
  createReportTag
} from "../../graphql/mutations";

import {
  DeleteReportCategoryMutation,
  DeleteReportCategoryInput,
  DeleteReportTagMutation,
  DeleteReportTagInput,
  CreateReportCategoryMutation,
  CreateReportCategoryMutationVariables,
  CreateReportTagMutation,
  CreateReportTagMutationVariables
} from "../../API";

/** Presentation/UI */
import StyledButton from "../../Components/Styled/Button";

/** Custom Types */
import { Error, ToastNotificationType } from "../../CustomTypes";

import { Colors } from "../../Themes";
import Loader from "../../Components/Loader";
import Table from "../../Components/Table";
import { getReportCategoriesTags } from "../../graphql/custom-queries";
import FullWidthContainer from "../../Components/Layouts/FullWidthContainer";

import { withToastManager } from "react-toast-notifications";
import PageLoader from "../../Components/PageLoader";
import { listCategorys, listTags } from "../../graphql/queries";

type Props = {
  data: any;
  type: "CATEGORY" | "TAG";
  toastManager: ToastNotificationType;
  reportId: any;
  closeModal(successData: any): void;
};

type State = {
  loading: boolean;
  error: Error;
  changedData: any;
  data: any;
  type: string;
  reportId: string;
};

type SelectType = {
  value: string;
  label: string;
};

class EditCategoryTag extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      changedData: "",
      data: props.data,
      type: "CATEGORY",
      reportId: props.reportId ? props.reportId : ""
    };
  }

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  toastNotification = (message: string) => {
    this.props.toastManager.add(message, {
      appearance: "success",
      autoDismiss: true
    });
  };

  deleteCategory = async (
    rowInfo: any,
    // after react scripts changes
    // eslint-disable-next-line
    deleteReportCategoryMutation: ({ }) => Promise<any>
  ) => {
    const { label, value } = rowInfo;
    this.setState({ loading: true });
    deleteReportCategoryMutation({
      variables: {
        input: {
          id: value
        }
      },
      refetchQueries: [
        {
          query: gql(getReportCategoriesTags),
          variables: { id: this.state.reportId }
        }
      ]
    })
      .then((data: any) => {
        this.setState({ loading: false });
        this.toastNotification(`Deleted category ${label}.`);
      })
      .catch(err => {
        this.setState({ loading: false });
        this.setError(err.message);
      });
  };

  deleteTag = async (
    rowInfo: any,
    // after react scripts changes
    // eslint-disable-next-line
    deleteReportTagMutation: ({ }) => Promise<any>
  ) => {
    const { label, value } = rowInfo;
    this.setState({ loading: true });
    deleteReportTagMutation({
      variables: {
        input: {
          id: value
        }
      },
      refetchQueries: [
        {
          query: gql(getReportCategoriesTags),
          variables: { id: this.state.reportId }
        }
      ]
    })
      .then((data: any) => {
        this.setState({ loading: false });
        this.toastNotification(`Deleted tag ${label}.`);
      })
      .catch(err => {
        this.setState({ loading: false });
        this.setError(err.message);
      });
  };

  loopCategories = async (
    // after react scripts changes
    // eslint-disable-next-line
    createReportCategoryMutation: ({ }) => Promise<any>
  ) => {
    const { changedData } = this.state;
    this.setState({ loading: true });
    changedData.map(async (item: SelectType) => {
      return await createReportCategoryMutation({
        variables: {
          input: {
            reportCategoryCategoryId: item.value,
            reportCategoryReportId: this.state.reportId
          }
        },
        refetchQueries: [
          {
            query: gql(getReportCategoriesTags),
            variables: { id: this.state.reportId }
          }
        ]
      })
        .then(() => {
          this.toastNotification("Successfully added category");
          this.setState({ loading: false });
          Promise.resolve();
        })
        .catch((err: any) => {
          this.setError(err.message);
          this.setState({ loading: false });
          Promise.reject();
        });
    });
  };
  // after react scripts changes
  // eslint-disable-next-line
  loopTags = async (createReportTagMutation: ({ }) => Promise<any>) => {
    const { changedData } = this.state;
    this.setState({ loading: true });
    changedData.map(async (item: SelectType) => {
      return await createReportTagMutation({
        variables: {
          input: {
            reportTagTagId: item.value,
            reportTagReportId: this.state.reportId
          }
        },
        refetchQueries: [
          {
            query: gql(getReportCategoriesTags),
            variables: { id: this.state.reportId }
          }
        ]
      })
        .then(() => {
          this.toastNotification("Successfully added tag");
          this.setState({ loading: false });
          Promise.resolve();
        })
        .catch((err: any) => {
          this.setError(err.message);
          this.setState({ loading: false });
          Promise.reject();
        });
    });
  };

  handleAddChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ changedData: e });
  };

  renderAddCategory = (listData: any) => {
    return (
      <Query query={gql(listCategorys)} variables={{ limit: 1000 }}>
        {({ loading, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (!loading && data) {
            const categoriesList = data.listCategorys.items.map((cat: any) => ({
              value: cat.id,
              label: cat.name
            }));
            return (
              <Row style={{ flex: 1 }}>
                <Col sm={12} md={12} lg={12}>
                  <Label>Add category</Label>
                </Col>
                <Row style={{ flex: 1 }}>
                  <Col sm={true} style={{ flex: 4 }}>
                    <Select
                      options={categoriesList}
                      // @ts-ignore after react scripts changes.
                      onChange={this.handleAddChange}
                      value={this.state.changedData}
                      isSearchable={true}
                      isMulti={true}
                      placeholder=""
                      className="select-styling"
                    />
                  </Col>
                  <Mutation<
                    CreateReportCategoryMutation,
                    CreateReportCategoryMutationVariables
                  >
                    mutation={gql(createReportCategory)}
                  >
                    {createReportCategoryMutation => {
                      if (this.state.changedData === "") {
                        return (
                          <StyledButton
                            type="button"
                            label={
                              this.state.loading ? (
                                <Loader color={Colors.whiteSmoke} />
                              ) : (
                                  "DONE"
                                )
                            }
                            disabled={this.state.loading}
                            onClick={() => {
                              this.props.closeModal(listData);
                            }}
                            width="120px"
                            color={Colors.coal}
                            background={Colors.primary}
                          />
                        );
                      } else {
                        return (
                          <StyledButton
                            type="button"
                            label="Add"
                            onClick={() => {
                              this.loopCategories(
                                createReportCategoryMutation
                              ).then(() => {
                                this.setState({ changedData: "" });
                              });
                            }}
                            width="120px"
                            color={Colors.whiteSmoke}
                            background={Colors.error}
                          />
                        );
                      }
                    }}
                  </Mutation>
                </Row>
              </Row>
            );
          } else {
            return <div>There has been an error loading categories</div>;
          }
        }}
      </Query>
    );
  };

  renderAddTag = (listData: any) => {
    return (
      <Query query={gql(listTags)} variables={{ limit: 1000 }}>
        {({ loading, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (!loading && data) {
            const tagsList = data.listTags.items.map((tag: any) => ({
              value: tag.id,
              label: tag.name
            }));
            return (
              <Row style={{ flex: 1 }}>
                <Col sm={12} md={12} lg={12}>
                  <Label>Add tag</Label>
                </Col>
                <Row style={{ flex: 1 }}>
                  <Col sm={true} style={{ flex: 4 }}>
                    <Select
                      options={tagsList}
                      // @ts-ignore after react scripts changes.
                      onChange={this.handleAddChange}
                      value={this.state.changedData}
                      isSearchable={true}
                      isMulti={true}
                      placeholder=""
                      className="select-styling"
                    />
                  </Col>
                  <Mutation<
                    CreateReportTagMutation,
                    CreateReportTagMutationVariables
                  >
                    mutation={gql(createReportTag)}
                  >
                    {createReportTagMutation => {
                      if (this.state.changedData === "") {
                        return (
                          <StyledButton
                            type="button"
                            label={
                              this.state.loading ? (
                                <Loader color={Colors.whiteSmoke} />
                              ) : (
                                  "DONE"
                                )
                            }
                            disabled={this.state.loading}
                            onClick={() => {
                              this.props.closeModal(listData);
                            }}
                            width="120px"
                            color={Colors.coal}
                            background={Colors.primary}
                          />
                        );
                      } else {
                        return (
                          <StyledButton
                            type="button"
                            label="Add"
                            onClick={() => {
                              this.loopCategories(createReportTagMutation).then(
                                () => {
                                  this.setState({ changedData: "" });
                                }
                              );
                            }}
                            width="120px"
                            color={Colors.whiteSmoke}
                            background={Colors.error}
                          />
                        );
                      }
                    }}
                  </Mutation>
                </Row>
              </Row>
            );
          } else {
            return <div>There has been an error loading tags</div>;
          }
        }}
      </Query>
    );
  };

  render() {
    return (
      <Form>
        <Query
          query={gql(getReportCategoriesTags)}
          variables={{ id: this.state.reportId }}
        >
          {({ loading, error, data }: any) => {
            if (loading) {
              return (
                <FullWidthContainer align="center">
                  <Loader size={50} color={Colors.primary} />
                </FullWidthContainer>
              );
            }
            if (error) {
              return <div>There was a problem loading your user data</div>;
            }

            if (!data) {
              return <div>There was a problem loading your user data</div>;
            }

            let dataToUse: any = [];
            if (data !== undefined) {
              if (this.props.type === "CATEGORY") {
                dataToUse = data.getReport.categories.items.map((item: any) => {
                  return {
                    value: item.id,
                    label: item.category.name
                  };
                });
              } else {
                dataToUse = data.getReport.tags.items.map((item: any) => {
                  return {
                    value: item.id,
                    label: item.tag.name
                  };
                });
              }
            }

            return (
              <Mutation<DeleteReportCategoryMutation, DeleteReportCategoryInput>
                mutation={gql(deleteReportCategory)}
              >
                {deleteReportCategoryMutation => (
                  <Mutation<DeleteReportTagMutation, DeleteReportTagInput>
                    mutation={gql(deleteReportTag)}
                  >
                    {deleteReportTagMutation => (
                      <React.Fragment>
                        <Table
                          data={dataToUse}
                          columns={[
                            {
                              Header: "Name",
                              accessor: "label",
                              sortable: true
                            },
                            {
                              id: "deleteReport",
                              Header: "",
                              accessor: "",
                              Cell: (row: any) => {
                                return (
                                  <StyledButton
                                    type="button"
                                    label="Delete"
                                    onClick={() => {
                                      this.props.type === "CATEGORY"
                                        ? this.deleteCategory(
                                          row.original,
                                          deleteReportCategoryMutation
                                        )
                                        : this.deleteTag(
                                          row.original,
                                          deleteReportTagMutation
                                        );
                                    }}
                                    width="60px"
                                    color={Colors.whiteSmoke}
                                    background={Colors.error}
                                  />
                                );
                              },
                              sortable: true
                            }
                          ]}
                          defaultPageSize={5}
                          showPaginationBottom={(dataToUse.length || 1) > 4}
                        />
                        {this.props.type === "CATEGORY"
                          ? this.renderAddCategory(dataToUse)
                          : this.renderAddTag(dataToUse)}
                      </React.Fragment>
                    )}
                  </Mutation>
                )}
              </Mutation>
            );
          }}
        </Query>
      </Form>
    );
  }
}

export default withToastManager(EditCategoryTag);
