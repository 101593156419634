import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withToastManager } from "react-toast-notifications";

/** GraphQL */
import { listCompanys } from "../../graphql/queries";
/** Presentation/UI */
import Table from "../../Components/Table";
import StyledButton from "../../Components/Styled/Button";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import PageLoader from "../../Components/PageLoader";
import {
  TableHeader,
  TableHeaderContainer
} from "../../Components/Styled/ListViewElements";
import { MdSearch } from "react-icons/md";

/** Themes */
import { Colors } from "../../Themes";

/** Custom Types */
import { ToastNotificationType } from "../../CustomTypes";
import { CreateCompanyInput, UserRole } from "../../API";
import GlobalModalContainer from "../../Components/Modal";
import CompanyView from "./CompanyView";
import { TableConsts } from "../../Utils/Consts";
import { sortByCreatedAt } from "../../Utils/Helpers";

type Props = {
  toastManager: ToastNotificationType;
};

type State = {
  modal: boolean;
  importModal: boolean;
  searchFilter: boolean;
  archivedFilter: boolean;
  selected: any;
  companyData: CreateCompanyInput | null;
};

class Companies extends React.Component<Props> {
  state: State = {
    modal: false,
    importModal: false,
    searchFilter: false,
    archivedFilter: false,
    selected: null,
    companyData: null
  };

  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  toggleArchivedFilter = (): void => {
    this.setState({ archivedFilter: !this.state.archivedFilter });
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance ? appearance : "success",
      autoDismiss: true
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  /** Open modal */
  openModal = (modalTitle: string, companyData?: {} | null): void => {
    this.setState({
      modal: true,
      modalTitle,
      companyData
    });
  };
  render() {
    const { searchFilter, modal, archivedFilter, companyData } = this.state;
    return (
      <BackendWrapper>
        <Query
          query={gql(listCompanys)}
          variables={{ limit: TableConsts.limit }}
        >
          {({ loading, error, data }: any) => {
            if (loading) {
              return <PageLoader />;
            }
            if (error) {
              return <div>There was a problem loading your data</div>;
            }

            if (!data || !data.listCompanys) {
              return <div>There are no registered companies at the moment</div>;
            }

            return (
              <React.Fragment>
                <GlobalModalContainer
                  toggleModal={this.closeModal}
                  title="View Company Details"
                  modalDisplay={
                    <CompanyView
                      roles={[UserRole.ROOTS_SUPER_ADMIN, UserRole.ROOTS_ADMIN]}
                      notification={this.toastNotification}
                      closeModal={this.closeModal}
                      companyData={companyData}
                      {...this.props}
                    />
                  }
                  modal={modal}
                />
                <TableHeaderContainer>
                  <TableHeader>
                    <span>Manage Companies</span>
                  </TableHeader>
                  <StyledButton
                    type="button"
                    label={<MdSearch size="1.3em" color={Colors.secondary} />}
                    width="auto"
                    margin="0px 10px"
                    onClick={this.toggleSearchFilter}
                    color={searchFilter ? Colors.background : Colors.coal}
                    background={
                      searchFilter ? Colors.default : Colors.background
                    }
                  />
                  <StyledButton
                    type="button"
                    label={archivedFilter ? "Show Active" : "Show Archived"}
                    width="120px"
                    onClick={this.toggleArchivedFilter}
                    color={archivedFilter ? Colors.background : Colors.coal}
                    background={
                      archivedFilter ? Colors.default : Colors.background
                    }
                  />
                </TableHeaderContainer>
                <Table
                  data={sortByCreatedAt(data.listCompanys.items)}
                  getTdProps={(
                    state: any,
                    rowInfo: { [key: string]: string }
                  ) => {
                    return {
                      onClick: (e: any) => {
                        if (rowInfo) {
                          this.openModal(
                            "View Company details",
                            rowInfo.original
                          );
                        }
                      }
                    };
                  }}
                  columns={[
                    {
                      Header: "Company Name",
                      accessor: "name",
                      sortable: true,
                      filterable: searchFilter
                    },
                    {
                      Header: "Email Address",
                      accessor: "emailAddress",
                      sortable: true,
                      filterable: searchFilter
                    },
                    {
                      Header: "Number of Employees",
                      accessor: "numberOfEmployees",
                      sortable: true
                    },
                    {
                      Header: "Registration Date",
                      accessor: "idNumber",
                      sortable: true,
                      filterable: false
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      sortable: true,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={TableConsts.defaultRows}
                  showPaginationBottom={
                    data.listCompanys.items.length > TableConsts.defaultRows
                  }
                />
              </React.Fragment>
            );
          }}
        </Query>
        <Helmet title="Manage Companies" />
      </BackendWrapper>
    );
  }
}

export default withToastManager(Companies);
