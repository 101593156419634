const BASE_URL = "https://8pxoawa960.execute-api.eu-west-1.amazonaws.com/dev";

/** Stripe publishable key */
export const STRIPE_PUBLISHABLE_KEY = "pk_test_eAgCi5ajqtUG7a1A0KeTngUI";

/** Stripe AWS lambda function endpoints */
export const CREATE_CUSTOMER_AND_SUBSCRIPTION = `${BASE_URL}/create-customer`;

/** AWS Cognito Pool */

/** Create user and add them to a group */
export const COGNITO_CREATE_USER = `${BASE_URL}/create-user`;

/** Edit user */
export const COGNITO_EDIT_USER = `${BASE_URL}/edit-user`;

/** Confirm new created user */
export const COGNITO_CONFIRM_USER = `${BASE_URL}/confirm-new-user`;
