import * as React from "react";
import { Helmet } from "react-helmet";
import { Query } from "react-apollo";
import gql from "graphql-tag";

// GraphQL
import { getUser } from "../../graphql/queries";

import { GetUserQuery, GetUserQueryVariables } from "../../API";

// Presentation/UI
import FullWidthContainer from "../../Components/Layouts/FullWidthContainer";
import PageWrapper from "../../Components/Layouts/PageWrapper";
import SignUpWrapper from "../../Components/Layouts/SignUpWrapper";
import Logo from "../../Components/Layouts/LogoWrapper";
import Loader from "../../Components/Loader";

// Themes
import { Images, Colors } from "../../Themes";

// Amplify
import { Auth } from "aws-amplify";

// Local components
import UserDetailsForm from "./UserDetailsForm";
import Success from "./Success";

type StateType = {
  userId: string;
  view: "userDetails" | "success";
  loading: boolean;
};

class UserSetup extends React.Component<{}> {
  state: StateType = {
    userId: "",
    view: "userDetails",
    loading: false
  };

  componentDidMount() {
    this.setState({ loading: true });
    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(user => {
      const userId = user.attributes.sub;
      this.setState({ loading: false, userId });
    });
  }

  /** render a view based on stage
   * @view represents the current view, userDetails or companySetup or success page
   * @userId is the ID of the currently logged in user
   *
   */

  renderView = (view: string): React.ReactNode => {
    const { userId } = this.state;

    switch (view) {
      case "userDetails":
        return <UserDetailsForm userId={userId} updateView={this.updateView} />;
      case "success":
        return <Success />;
      default:
        return <UserDetailsForm userId={userId} updateView={this.updateView} />;
    }
  };

  // update the view
  updateView = (view: string): void => {
    this.setState({ view });
  };

  render() {
    const { loading, userId } = this.state;
    const { view } = this.state;

    if (loading) {
      return (
        <PageWrapper>
          <SignUpWrapper width="697px" height="auto">
            <FullWidthContainer align="center">
              <Loader size={150} color={Colors.darkGrey} />
            </FullWidthContainer>
          </SignUpWrapper>
          <Helmet title="User Details" />
        </PageWrapper>
      );
    }

    return (
      <Query<GetUserQuery, GetUserQueryVariables>
        query={gql(getUser)}
        variables={{ id: userId }}
      >
        {getUserQuery => {
          if (getUserQuery.loading) {
            return (
              <FullWidthContainer align="center">
                <Loader size={150} />
              </FullWidthContainer>
            );
          }
          if (getUserQuery.error) {
            return <div>There was a problem loading your user data</div>;
          }

          if (!getUserQuery.data) {
            return <div>There was a problem loading your user data</div>;
          }

          return (
            <PageWrapper>
              <SignUpWrapper width="850px" height="auto">
                <React.Fragment>
                  <FullWidthContainer align="center">
                    <Logo image={Images.rootsLogo} />
                  </FullWidthContainer>
                  {this.renderView(view)}
                </React.Fragment>
              </SignUpWrapper>
              <Helmet title="User Details" />
            </PageWrapper>
          );
        }}
      </Query>
    );
  }
}

export default UserSetup;
