import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withToastManager } from "react-toast-notifications";

/** Presentation/UI */
import Table from "../../Components/Table";
import StyledButton from "../../Components/Styled/Button";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import PageLoader from "../../Components/PageLoader";
import {
  TableHeader,
  TableHeaderContainer
} from "../../Components/Styled/ListViewElements";
import GlobalModalContainer from "../../Components/Modal";
import {
  Input,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "reactstrap";

/** Themes */
import { Colors } from "../../Themes";

/** Custom Types */
import { ToastNotificationType } from "../../CustomTypes";
import { formatTitle, sortByCreatedAt } from "../../Utils/Helpers";
import AddFaq from "./AddFaq";
import EditFaq from "./EditFaq";
import { TableConsts } from "../../Utils/Consts";
import { listFaqTypes, listFaqs } from "../../graphql/queries";

type Props = {
  toastManager: ToastNotificationType;
  location: { [key: string]: any }; // object with any amount and types of params
};

type State = {
  importModal: boolean;
  selected: any;
  containerType: string;
  addModal: boolean;
  editModal: boolean;
  searchData: string;
  collapse: { card: string; state: boolean };
};

class FaqManagement extends React.Component<Props> {
  state: State = {
    importModal: false,
    selected: null,
    containerType: this.props.location.pathname,
    addModal: false,
    editModal: false,
    searchData: "",
    collapse: { card: "", state: false }
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance ? appearance : "success",
      autoDismiss: true
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      addModal: false,
      editModal: false
    });
  };

  /** Open modal */
  openAddModal = (): void => {
    this.setState({ addModal: true });
  };

  openEditModal = (data: any): void => {
    this.setState({ editModal: true, selected: data });
  };

  // Query FAQ Questions
  faqManagement = () => {
    const { searchData } = this.state;
    return (
      <Query query={gql(listFaqs)} variables={{ limit: TableConsts.limit }}>
        {({ loading, error, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (error) {
            return <div>There was a problem loading your data</div>;
          }

          if (!data || !data.listFaqs) {
            return <div>There are no areas at the moment</div>;
          }

          let filteredData = data.listFaqs.items;
          filteredData = filteredData.filter((row: any) => {
            return row.question
              .toLowerCase()
              .includes(searchData.toLowerCase());
          });

          return this.listFaqs(sortByCreatedAt(filteredData));
        }}
      </Query>
    );
  };

  // Toggle to view selected question's answer
  viewQuestion = (question: string) => {
    return this.setState({ collapse: { card: question, state: false } });
  };

  answerToggle = (cardId: string): boolean => {
    const { collapse } = this.state;
    if (collapse.card === cardId) {
      return true;
    } else {
      return false;
    }
  };

  // List collapsible FAq questions
  listFaqs = (questions: any) => {
    const faqCards = questions.map((item: any) => {
      return (
        <Card key={item.id}>
          <CardHeader onClick={() => this.viewQuestion(item.id)}>
            <h5>
              {item.question} | {item.category.title}
            </h5>
          </CardHeader>
          <Collapse isOpen={this.answerToggle(item.id)}>
            <CardBody>{item.answer}</CardBody>
            <CardFooter>
              <StyledButton
                type="button"
                label="Edit"
                width="120px"
                margin="0px 10px"
                onClick={() => this.openEditModal(item)}
                color={Colors.coal}
                background={Colors.background}
              />
            </CardFooter>
          </Collapse>
        </Card>
      );
    });
    return faqCards;
  };

  // Query FAQ categories
  faqCategories = () => {
    const { searchData } = this.state;
    return (
      <Query query={gql(listFaqTypes)} variables={{ limit: TableConsts.limit }}>
        {({ loading, error, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (error) {
            return <div>There was a problem loading your data</div>;
          }

          if (!data || !data.listFaqTypes) {
            return <div>There are no Categories at the moment</div>;
          }

          let filteredData = data.listFaqTypes.items;
          filteredData = filteredData.filter((row: any) => {
            return row.title.toLowerCase().includes(searchData.toLowerCase());
          });

          return (
            <Table
              data={sortByCreatedAt(filteredData)}
              getTdProps={(state: any, rowInfo: { [key: string]: string }) => {
                return {
                  onClick: (e: any) => {
                    if (rowInfo) {
                      this.openEditModal(rowInfo.original);
                    }
                  }
                };
              }}
              columns={[
                {
                  Header: "Name",
                  accessor: "title",
                  sortable: true
                },
                {
                  id: "faqs",
                  Header: "FAQ's",
                  accessor: (faq: any) => {
                    return faq.faqs.items.length;
                  },
                  sortable: true
                }
              ]}
              defaultPageSize={TableConsts.defaultRows}
              showPaginationBottom={
                data.listFaqTypes.items.length > TableConsts.defaultRows
              }
            />
          );
        }}
      </Query>
    );
  };

  render() {
    const {
      containerType,
      selected,
      addModal,
      editModal,
      searchData
    } = this.state;
    return (
      <BackendWrapper>
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title={`Add ${
            containerType === "/faq-management" ? "FAQ" : "Category"
          }`}
          modalDisplay={
            <AddFaq
              notification={this.toastNotification}
              closeModal={this.closeModal}
              data={selected}
              containerType={
                containerType === "/faq-management" ? "FAQ" : "Category"
              }
            />
          }
          modal={addModal}
        />
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title={`Edit ${
            containerType === "/faq-management" ? "FAQ" : "Category"
          }`}
          modalDisplay={
            <EditFaq
              notification={this.toastNotification}
              closeModal={this.closeModal}
              data={selected}
              containerType={
                containerType === "/faq-management" ? "FAQ" : "Category"
              }
            />
          }
          modal={editModal}
        />
        <TableHeaderContainer>
          <TableHeader>
            <span>{formatTitle(containerType)}</span>
          </TableHeader>
          <StyledButton
            type="button"
            label={`Add ${
              containerType === "/faq-management" ? "FAQ" : "Category"
            }`}
            width="250px"
            margin="0px 10px"
            onClick={() => this.openAddModal()}
            color={Colors.coal}
            background={Colors.background}
          />
          <Input
            type="text"
            name="name"
            style={{ height: "40px", width: "300px" }}
            value={searchData}
            id="name"
            placeholder="Search"
            onChange={e => this.setState({ searchData: e.target.value })}
          />
        </TableHeaderContainer>
        {containerType === "/faq-management"
          ? this.faqManagement()
          : this.faqCategories()}
        <Helmet title={formatTitle(containerType) || undefined} />
      </BackendWrapper>
    );
  }
}

export default withToastManager(FaqManagement);
