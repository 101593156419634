/**
 * API Request
 * @param endPoint - api endpoint
 * @param httpVaraible - the http variable defining the type of request (POST/GET/PUT/PATCH)
 * @param bodyParams - object with properties being passed with the request
 */

export const apiRequest = async (
  endPoint: string,
  httpVaraible: string,
  bodyParams: object
): Promise<any> => {
  const response = await fetch(endPoint, {
    method: httpVaraible,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyParams)
  });
  const resData = await response.json();
  return resData;
};
