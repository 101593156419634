import * as React from "react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import Select from "react-select";
import { Row, Col, Button, Form, FormGroup, Input } from "reactstrap";
import { formatDropdown } from "../../Utils/Helpers";
// GraphQL
import {
  createCompany,
  createUserCompany,
  createCompanyInterest
} from "../../graphql/mutations";
import {
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
  Industry,
  CreateUserCompanyMutation,
  CreateUserCompanyMutationVariables,
  CreateCompanyInterestMutation,
  CreateCompanyInterestMutationVariables
} from "../../API";

// Presentation/UI
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import Loader from "../../Components/Loader";
import RequiredField from "../../Components/Styled/RequiredField";

// Utils
import { COMPANY_SIZE } from "../../Utils/Consts";
import { listProvinceTypes, listInterests } from "../../graphql/queries";
import PageLoader from "../../Components/PageLoader";

type SelectType = {
  value: string;
  label: string;
};

type SelectIndustryType = {
  value: Industry;
  label: string;
};

type State = {
  name: string;
  emailAddress: string;
  contactNumber: string;
  address: string;
  province: any;
  suburb: any;
  city: any;
  companySize: SelectType;
  companyIndustry: SelectIndustryType;
  loading: boolean;
  error: string | null;
  companyInterests: any;
};

type Props = {
  userId: string;
  updateView(view: string): void;
};

class CompanySetupForm extends React.Component<Props, State> {
  state: State = {
    name: "",
    emailAddress: "",
    contactNumber: "",
    address: "",
    province: "",
    city: "",
    suburb: "",
    companySize: { value: "", label: "" },
    companyIndustry: { value: Industry.INDUSTRY_ONE, label: "" },
    loading: false,
    error: null,
    companyInterests: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  // Select industry
  selectIndustry = (companyIndustry: SelectIndustryType): void => {
    this.setState({ companyIndustry });
  };

  // Select company size
  selectCompanySize = (companySize: SelectType): void => {
    this.setState({ companySize });
  };

  // Select company industry
  selectCompanyIndustry = (companyIndustry: SelectIndustryType): void => {
    this.setState({ companyIndustry });
  };

  handleProvinceChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ province: e });
  };

  handleCityChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ city: e });
  };

  handleSuburbChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ suburb: e });
  };

  listOfIndustries = (consts: object) => {
    return Object.keys(consts).map((item: string) => {
      return formatDropdown(item);
    });
  };

  handleInterestsChange = (interests: any): void => {
    this.setState({ companyInterests: interests });
  };

  // Handle change in input fields
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    const validateNumInput = /^[0-9\b]+$/;

    switch (name) {
      case "name":
        this.setState({ name: value });
        break;
      case "emailAddress":
        this.setState({ emailAddress: value.toLowerCase() });
        break;
      case "contactNumber":
        if (value === "" || validateNumInput.test(value)) {
          this.setState({ contactNumber: value });
        }
        break;
      case "address":
        this.setState({ address: value });
        break;
      default:
        break;
    }
  };

  // Validation
  validateForm = (): boolean => {
    const {
      name,
      emailAddress,
      contactNumber,
      address,
      province,
      city,
      suburb,
      companySize,
      companyIndustry,
      companyInterests
    } = this.state;
    // Check for undefined or empty input fields
    if (
      !name ||
      !emailAddress ||
      !contactNumber ||
      !address ||
      !province ||
      !city ||
      !suburb ||
      !companySize ||
      !companyIndustry ||
      !companyInterests
    ) {
      this.setError("Please complete the form.");
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const {
      name,
      emailAddress,
      contactNumber,
      address,
      province,
      city,
      suburb,
      companySize,
      companyIndustry,
      companyInterests
    } = this.state;

    const { updateView, userId } = this.props;

    return (
      <Mutation<CreateCompanyMutation, CreateCompanyMutationVariables>
        mutation={gql(createCompany)}
      >
        {(createCompanyMutation, response) => (
          <Mutation<
            CreateUserCompanyMutation,
            CreateUserCompanyMutationVariables
          >
            mutation={gql(createUserCompany)}
          >
            {createUserCompanyMutation => (
              <Mutation<
                CreateCompanyInterestMutation,
                CreateCompanyInterestMutationVariables
              >
                mutation={gql(createCompanyInterest)}
              >
                {createCompanyInterestMutation => (
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      if (this.validateForm()) {
                        createCompanyMutation({
                          variables: {
                            input: {
                              name,
                              emailAddress,
                              contactNumber,
                              address,
                              numberOfEmployees: companySize.value,
                              industry: companyIndustry.value,
                              companyAreaId: suburb.value
                            }
                          }
                        })
                          .then((res: any) => {
                            const { id } = res.data.createCompany;
                            // update user by adding them to newly created company
                            createUserCompanyMutation({
                              variables: {
                                input: {
                                  userCompanyCompanyId: id,
                                  userCompanyUserId: userId
                                }
                              }
                            })
                              .then((updateUserRes: any) => {
                                companyInterests.map(async (interest: any) => {
                                  await createCompanyInterestMutation({
                                    variables: {
                                      input: {
                                        companyInterestCompanyId: id,
                                        companyInterestInterestsId:
                                          interest.value
                                      }
                                    }
                                  }).catch(err => {
                                    this.setState({ loading: false });
                                    this.setError(err.message);
                                  });
                                  // Change view to success view
                                  updateView("success");
                                });
                              })
                              .catch((updateUserErr: any) => {
                                this.setError(updateUserErr.message);
                              });
                          })
                          .catch((err: any) => {
                            this.setError(err.message);
                          });
                      }
                    }}
                  >
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <FormGroup>
                          <RequiredField for="name" value="Company Name"/>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            placeholder="Company name"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <FormGroup>
                          <RequiredField for="emailAddress" value="Company Email"/>
                          <Input
                            type="email"
                            name="emailAddress"
                            id="emailAddress"
                            value={emailAddress}
                            placeholder="Company Email"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <FormGroup>
                          <RequiredField for="emailAddress" value="Company Phone"/>
                          <Input
                            maxLength={10}
                            minLength={10}
                            type="text"
                            name="contactNumber"
                            id="contactNumber"
                            value={contactNumber}
                            placeholder="Company Phone"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <FormGroup>
                          <RequiredField for="address" value="Company Address"/>
                          <Input
                            type="text"
                            name="address"
                            id="address"
                            value={address}
                            placeholder="Company address"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Query
                      query={gql(listProvinceTypes)}
                      variables={{ limit: 1000 }}
                    >
                      {({ loading, data }: any) => {
                        if (loading) {
                          return <PageLoader />;
                        }
                        if (!loading && data) {
                          const provinceList = data.listProvinceTypes.items.map(
                            (prov: any) => ({
                              ...prov,
                              value: prov.id,
                              label: prov.name
                            })
                          );
                          return (
                            <React.Fragment>
                              <Row>
                                <Col sm={6}>
                                  <RequiredField value="Province"/>
                                  <Select
                                    options={provinceList}
                                    // @ts-ignore after react scripts changes.
                                    onChange={this.handleProvinceChange}
                                    value={province}
                                    placeholder=""
                                    className="select-styling"
                                  />
                                </Col>
                                <Col sm={6}>
                                  <RequiredField for="City" value="City"/>
                                  <Select
                                    options={
                                      this.state.province === "" ||
                                        !this.state.province.id
                                        ? []
                                        : this.state.province.cities.items.map(
                                          (cit: any) => ({
                                            ...cit,
                                            value: cit.id,
                                            label: cit.name
                                          })
                                        )
                                    }
                                    // @ts-ignore after react scripts changes.
                                    onChange={this.handleCityChange}
                                    value={city}
                                    placeholder={
                                      this.state.province === ""
                                        ? "Please select a province first."
                                        : ""
                                    }
                                    className="select-styling"
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col sm={6}>
                                  <RequiredField value="Area"/>
                                  <Select
                                    options={
                                      this.state.city === "" ||
                                        !this.state.city.id
                                        ? []
                                        : this.state.city.areas.items.map(
                                          (area: any) => ({
                                            value: area.id,
                                            label: area.name
                                          })
                                        )
                                    }
                                    // @ts-ignore after react scripts changes.
                                    onChange={this.handleSuburbChange}
                                    value={suburb}
                                    placeholder={
                                      this.state.city === ""
                                        ? "Please select a city first."
                                        : ""
                                    }
                                    className="select-styling"
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <div>There has been an error loading provinces</div>
                          );
                        }
                      }}
                    </Query>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <FormGroup>
                          <RequiredField value="Company Size"/>
                          <Select
                            // @ts-ignore after react scripts changes.
                            onChange={this.selectCompanySize}
                            options={COMPANY_SIZE}
                            value={companySize}
                            isSearchable={true}
                            placeholder=""
                            className="select-styling"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <FormGroup>
                          <RequiredField value="Industry"/>
                          <Select
                            options={this.listOfIndustries(Industry)}
                            // @ts-ignore after react scripts changes.
                            onChange={this.selectCompanyIndustry}
                            value={companyIndustry}
                            isSearchable={true}
                            placeholder=""
                            className="select-styling"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <FormGroup>
                          <RequiredField value="Interests & Objectives"/>
                          <Query query={gql(listInterests)}>
                            {({ loading, data }: any) => {
                              if (loading) {
                                return (
                                  <Select
                                    placeholder="Loading..."
                                    className="select-styling"
                                  />
                                );
                              }
                              if (data) {
                                const interestsList = data.listInterests.items.map(
                                  (item: any) => ({
                                    value: item.id,
                                    label: item.name
                                  })
                                );
                                return (
                                  <Select
                                    isMulti={true}
                                    isSearchable={true}
                                    isClearable={true}
                                    options={interestsList}
                                    onChange={this.handleInterestsChange}
                                    value={companyInterests}
                                    className="select-styling"
                                  />
                                );
                              } else {
                                return (
                                  <div>
                                    There has been an error loading company
                                    interests
                                  </div>
                                );
                              }
                            }}
                          </Query>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <Button>
                      {!response.loading ? <span>Next</span> : <Loader />}
                    </Button>
                    {this.state.error && (
                      <ErrorMessage errorMessage={this.state.error} />
                    )}
                  </Form>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export default CompanySetupForm;
