import * as React from "react";
import { Link, NavLinkProps } from "react-router-dom";

type IProps = NavLinkProps & {
  label: string | React.ReactNode;
  color?: string;
  size?: string;
};

const LinkWrapper: React.SFC<IProps> = (props: IProps) => {
  return (
    <Link
      to={props.to}
      style={{
        color: props.color,
        fontSize: props.size,
        textDecoration: "none"
      }}
    >
      {props.label}
    </Link>
  );
};

export default LinkWrapper;
