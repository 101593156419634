import * as React from "react";
import * as Loadable from "react-loadable";

/** Parent nav items */
import * as ParentNavItems from "./Consts";

/** Presentation/UI */
import PageLoader from "../Components/PageLoader";

/** Containers */
import Dashboard from "../Containers/Dashboard";
import SignIn from "../Containers/Login";
import SignUp from "../Containers/SignUp";
import CompanySetup from "../Containers/CompanySetup";
import Team from "../Containers/Team";
import Companies from "../Containers/Companies";
import CompanyDetails from "../Containers/Consumer/CompanyDetails";
import UserSetup from "../Containers/UserSetup";
import Reports from "../Containers/Reports";
import AddReport from "../Containers/Reports/AddReport";
import Management from "../Containers/Management";
import ConsumerReports from "../Containers/ConsumerReports";
import Report from "../Containers/Report";
import Checkout from "../Containers/Checkout";
import PurchasedReports from "../Containers/PurchasedReports";
import AboutRoots from "../Containers/AboutRoots";
import Sales from "../Containers/Sales";
import VoucherManagement from "../Containers/VoucherManagement";
import LocationManagement from "../Containers/LocationManagement";
import FaqManagement from "../Containers/FaqManagement";
import AddSampleReport from "../Containers/AboutRoots/AddSampleReport";
import Help from "../Containers/Help";

// @ts-ignore after react scripts changes.
const AsyncDashboard = Loadable({
  loader: () => Promise.resolve(Dashboard),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncSignIn = Loadable({
  loader: () => Promise.resolve(SignIn),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncSignUp = Loadable({
  loader: () => Promise.resolve(SignUp),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncCompanySetup = Loadable({
  loader: () => Promise.resolve(CompanySetup),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncTeam = Loadable({
  loader: () => Promise.resolve(Team),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncCompanies = Loadable({
  loader: () => Promise.resolve(Companies),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncUserSetup = Loadable({
  loader: () => Promise.resolve(UserSetup),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncCompanyDetails = Loadable({
  loader: () => Promise.resolve(CompanyDetails),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncReportList = Loadable({
  loader: () => Promise.resolve(Reports),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncManagement = Loadable({
  loader: () => Promise.resolve(Management),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncAddReport = Loadable({
  loader: () => Promise.resolve(AddReport),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncConsumerReports = Loadable({
  loader: () => Promise.resolve(ConsumerReports),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncReport = Loadable({
  loader: () => Promise.resolve(Report),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncCheckout = Loadable({
  loader: () => Promise.resolve(Checkout),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncPurchasedReports = Loadable({
  loader: () => Promise.resolve(PurchasedReports),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncAboutRoots = Loadable({
  loader: () => Promise.resolve(AboutRoots),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncSales = Loadable({
  loader: () => Promise.resolve(Sales),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncVoucherManagement = Loadable({
  loader: () => Promise.resolve(VoucherManagement),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncLocationManagement = Loadable({
  loader: () => Promise.resolve(LocationManagement),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncFaqManagement = Loadable({
  loader: () => Promise.resolve(FaqManagement),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncAddSampleReport = Loadable({
  loader: () => Promise.resolve(AddSampleReport),
  loading: () => <PageLoader />
});

// @ts-ignore after react scripts changes.
const AsyncHelp = Loadable({
  loader: () => Promise.resolve(Help),
  loading: () => <PageLoader />
});

const routes = [
  {
    title: "Dashboard",
    path: "/dashboard",
    parent: ParentNavItems.DASHBOARD,
    component: AsyncDashboard,
    exact: true,
    private: true
  },
  {
    title: "Sign In",
    path: "/sign-in",
    component: AsyncSignIn,
    exact: true,
    private: false
  },
  {
    title: "Sign Up",
    path: "/sign-up",
    component: AsyncSignUp,
    exact: true,
    private: false
  },
  {
    title: "Company Setup",
    path: "/company-setup",
    component: AsyncCompanySetup,
    exact: true,
    private: false
  },
  {
    title: "Team",
    path: "/team",
    component: AsyncTeam,
    exact: true,
    private: true
  },
  {
    title: "Companies",
    path: "/admin/companies",
    component: AsyncCompanies,
    exact: true,
    private: true
  },
  {
    title: "User Setup",
    path: "/user-setup",
    component: AsyncUserSetup,
    exact: true,
    private: false
  },
  {
    title: "Sign In",
    path: "/",
    component: AsyncSignIn,
    exact: true,
    private: false
  },
  {
    title: "Team",
    path: "/admin/team",
    component: AsyncTeam,
    exact: true,
    private: true
  },
  {
    title: "Companies Team",
    path: "/companies/team",
    component: AsyncTeam,
    exact: true,
    private: true
  },
  {
    title: "Team",
    path: "/consumer/team",
    component: AsyncTeam,
    exact: true,
    private: true
  },
  {
    title: "Account",
    path: "/consumer/account",
    component: AsyncCompanyDetails,
    exact: true,
    private: true
  },
  {
    title: "Reports",
    path: "/admin-reports",
    component: AsyncReportList,
    exact: true,
    private: true
  },
  {
    title: "Add Report",
    path: "/admin-reports/add",
    component: AsyncAddReport,
    exact: true,
    private: true
  },
  {
    title: "Add Report",
    path: "/admin-reports/edit",
    component: AsyncAddReport,
    exact: true,
    private: true
  },
  {
    title: "Category",
    path: "/category-management",
    component: AsyncManagement,
    exact: true,
    private: true
  },
  {
    title: "Tags",
    path: "/tags-management",
    component: AsyncManagement,
    exact: true,
    private: true
  },
  {
    title: "Interest",
    path: "/interest-management",
    component: AsyncManagement,
    exact: true,
    private: true
  },
  {
    title: "Vouchers",
    path: "/voucher-management",
    component: AsyncManagement,
    exact: true,
    private: true
  },
  {
    title: "Consumer Reports",
    path: "/consumer/reports",
    component: AsyncConsumerReports,
    exact: true,
    private: true
  },
  {
    title: "Report",
    path: "/consumer/report/:id",
    component: AsyncReport,
    exact: true,
    private: true
  },
  {
    title: "Report",
    path: "/admin-reports/preview",
    component: AsyncReport,
    exact: true,
    private: true
  },
  {
    title: "Checkout",
    path: "/consumer/checkout/:id",
    component: AsyncCheckout,
    exact: true,
    private: true
  },
  {
    title: "Purchased Reports",
    path: "/consumer/purchased",
    component: AsyncPurchasedReports,
    exact: true,
    private: true
  },
  {
    title: "About Roots",
    path: "/consumer/about-roots",
    component: AsyncAboutRoots,
    exact: true,
    private: true
  },
  {
    title: "Sales",
    path: "/admin/sales",
    component: AsyncSales,
    exact: true,
    private: true
  },
  {
    title: "Voucher Management",
    path: "/admin/voucher-management",
    component: AsyncVoucherManagement,
    exact: true,
    private: true
  },
  {
    title: "Province Management",
    path: "/province-management",
    component: AsyncLocationManagement,
    exact: true,
    private: true
  },
  {
    title: "City Management",
    path: "/city-management",
    component: AsyncLocationManagement,
    exact: true,
    private: true
  },
  {
    title: "Area Management",
    path: "/area-management",
    component: AsyncLocationManagement,
    exact: true,
    private: true
  },
  {
    title: "FAQ Categories",
    path: "/faq-categories",
    component: AsyncFaqManagement,
    exact: true,
    private: true
  },
  {
    title: "FAQs",
    path: "/faq-management",
    component: AsyncFaqManagement,
    exact: true,
    private: true
  },
  {
    title: "Sample Report",
    path: "/sample-report",
    component: AsyncAddSampleReport,
    exact: true,
    private: true
  },
  {
    title: "Help",
    path: "/help",
    component: AsyncHelp,
    exact: true,
    private: true
  }
];

export default routes;
