import * as React from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Label, Input, Row, Col } from "reactstrap";

import {
  UserRole,
  CreateCompanyInput,
  ReportStatus,
  CreateReportMutation,
  CreateReportMutationVariables,
  CreateReportCategoryMutation,
  CreateReportCategoryMutationVariables,
  CreateReportTagMutation,
  CreateReportTagMutationVariables,
  UpdateReportMutation,
  UpdateReportMutationVariables,
  UpdateReportCategoryMutation,
  UpdateReportCategoryMutationVariables,
  UpdateReportTagMutation,
  UpdateReportTagMutationVariables
} from "../../API";

import StyledButton from "../../Components/Styled/Button";
import Card from "../../Components/Styled/Card";

import { Error } from "../../CustomTypes";
import { listReportsWithCategories } from "../../graphql/custom-queries";
import { generateRandomNumber } from "../../Utils/Helpers";
import styled from "styled-components";
import PageLoader from "../../Components/PageLoader";

import { ToastNotificationType } from "../../CustomTypes";

import { withToastManager } from "react-toast-notifications";
import Container from "reactstrap/lib/Container";
import { Colors } from "../../Themes";
import Select from "react-select";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import {
  createReport,
  createReportCategory,
  createReportTag,
  updateReport,
  updateReportTag,
  updateReportCategory
} from "../../graphql/mutations";
import {
  listCategorys,
  listTags,
  listProvinceTypes
} from "../../graphql/queries";
import { Route, RouteComponentProps } from "react-router";
import { TableConsts } from "../../Utils/Consts";
import Loader from "../../Components/Loader";
import Link from "../../Components/Styled/Link";

import EditCategoryTag from "./EditCategoryTag";
import GlobalModalContainer from "../../Components/Modal";
import { Storage } from "aws-amplify";

type SelectProvinceType = {
  value: string;
  label: string;
};

type SelectSuburbType = {
  value: string;
  label: string;
};

type SelectBoolean = {
  value: boolean;
  label: string;
};
type SelectType = {
  value: string;
  label: string;
};

type Props = RouteComponentProps & {
  roles?: UserRole[];
  companyData?: CreateCompanyInput | null;
  toastManager?: ToastNotificationType;
  location: { [key: string]: any }; // object with any amount and types of params
};

type State = {
  loading: boolean;
  error: Error;
  readOnly: boolean;
  modal: boolean;
  companyData: any;
  id: string;
  name: string;
  publishedDate: string;
  description: string;
  price: string;
  documentLocation: string;
  maxDownloads: string;
  downloadable: boolean;
  status: ReportStatus;
  province: any;
  suburb: any;
  city: any;
  paidOrFree: boolean;
  reportDetails: string;
  coverImageUrl: string;
  documentResourceUrl: string;
  tags: any; // CreateReportTagInput
  categories: any; // CreateReportCategoryInput
  editType: "CATEGORY" | "TAG";
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: flex-end;
  align-items: flex-end;
`;

class AddReport extends React.Component<Props, State> {
  state: State = {
    loading: false,
    error: null,
    readOnly: true,
    modal: false,
    companyData: {},
    id:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.id
        ? this.props.location.state.id
        : "",
    name:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.name
        ? this.props.location.state.name
        : "",
    publishedDate:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.publishedDate
        ? this.props.location.state.publishedDate
        : "",
    description:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.description
        ? this.props.location.state.description
        : "",
    price:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.price
        ? this.props.location.state.price
        : "",
    documentLocation: "test",
    maxDownloads: "", // TODO:  no longer needed
    downloadable: false, // TODO:  no longer needed
    status:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.status
        ? this.props.location.state.status
        : ReportStatus.DRAFT,
    province:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.area &&
        this.props.location.state.area.city &&
        this.props.location.state.area.city.province
        ? {
          value: this.props.location.state.area.city.province.id,
          label: this.props.location.state.area.city.province.name
        }
        : "",
    city:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.area &&
        this.props.location.state.area.city
        ? {
          value: this.props.location.state.area.city.id,
          label: this.props.location.state.area.city.name
        }
        : "",
    suburb:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.area
        ? {
          value: this.props.location.state.area.id,
          label: this.props.location.state.area.name
        }
        : "",
    paidOrFree: true,
    reportDetails:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.reportDetails
        ? this.props.location.state.reportDetails
        : "",
    coverImageUrl:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.coverImageUrl
        ? this.props.location.state.coverImageUrl
        : "",
    documentResourceUrl:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.documentResourceUrl
        ? this.props.location.state.documentResourceUrl
        : "",
    tags:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.tags &&
        this.props.location.state.tags.items
        ? this.props.location.state.tags.items.map((item: any) => {
          return {
            label: item.tag.name,
            value: item.tag.id
          };
        })
        : this.props.location &&
          this.props.location.state &&
          this.props.location.state.tags &&
          this.props.location.state.tags.length > 0
          ? this.props.location.state.tags
          : "",
    categories:
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.categories &&
        this.props.location.state.categories.items
        ? this.props.location.state.categories.items.map((item: any) => {
          return {
            label: item.category.name,
            value: item.category.id
          };
        })
        : this.props.location &&
          this.props.location.state &&
          this.props.location.state.categories &&
          this.props.location.state.categories.length > 0
          ? this.props.location.state.categories
          : "",
    editType: "CATEGORY"
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  // Select company province
  selectCompanyProvince = (province: SelectProvinceType): void => {
    this.setState({ province: province.value });
  };
  // Select company province
  selectSuburb = (suburb: SelectSuburbType): void => {
    this.setState({ suburb: suburb.value });
  };

  selectBoolean = (paifOrFree: SelectBoolean): void => {
    this.setState({ paidOrFree: paifOrFree.value });
  };

  openEdit = (data: object) => {
    this.setState({ modal: true, companyData: data });
  };

  /** Close modal */
  closeModal = (successData: any): void => {
    if (this.state.editType === "CATEGORY") {
      this.setState({
        modal: false,
        categories: successData
      });
    } else {
      this.setState({
        modal: false,
        tags: successData
      });
    }
  };

  handleCategoryChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ categories: e });
  };

  handleProvinceChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ province: e });
  };

  handleCityChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ city: e });
  };

  handleSuburbChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ suburb: e });
  };

  handleTagChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ tags: e });
  };

  // Handle change
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    switch (name) {
      case "name":
        this.setState({ name: value });
        break;
      case "publishedDate":
        this.setState({ publishedDate: value });
        break;
      case "description":
        this.setState({ description: value });
        break;
      case "reportDetails":
        this.setState({ reportDetails: value });
        break;
      case "city":
        this.setState({ city: value });
        break;
      case "price":
        this.setState({ price: value });
        break;
      case "documentResourceUrl":
        this.setState({ documentResourceUrl: value });
        break;
      case "coverImageUrl":
        this.setState({ coverImageUrl: value });
        break;
      default:
        break;
    }
  };

  /** Create a new tag */
  createReport = async (
    // after react scripts changes
    // eslint-disable-next-line
    createReportMutation: ({ }) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    createReportCategoryMutation: ({ }) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    createTagMutation: ({ }) => Promise<any>,
    status: ReportStatus,
    history: any
  ) => {
    const {
      name,
      publishedDate,
      description,
      price,
      documentLocation,
      downloadable,
      suburb,
      paidOrFree,
      reportDetails,
      coverImageUrl,
      documentResourceUrl
    } = this.state;

    // create the tag in dynamodb
    createReportMutation({
      variables: {
        input: {
          name,
          publishedDate,
          description,
          price,
          documentLocation,
          maxDownloads: "unlimited",
          downloadable,
          status,
          reportAreaId: suburb.value,
          paidOrFree,
          reportDetails,
          coverImageUrl,
          documentResourceUrl
        }
      },
      refetchQueries: [
        {
          query: gql(listReportsWithCategories),
          variables: { limit: TableConsts.limit }
        }
      ]
    })
      .then((response: any) => {
        /**
         * Loop through Categories selected and run the createReportCategory for each,
         * after that loop successfully completes, do the same with Tags and run the createReportTag mutation.
         */
        this.loopCategories(
          createReportCategoryMutation,
          createTagMutation,
          response.data.createReport.id,
          history
        );
      })
      .catch((err: any) => {
        this.setError(err.message);
      });
  };

  loopCategories = async (
    // after react scripts changes
    // eslint-disable-next-line
    createReportCategoryMutation: ({ }) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    createTagMutation: ({ }) => Promise<any>,
    reportId: string,
    history: any
  ) => {
    const { categories } = this.state;
    categories.map(async (item: SelectType) => {
      return await createReportCategoryMutation({
        variables: {
          input: {
            reportCategoryCategoryId: item.value,
            reportCategoryReportId: reportId
          }
        }
      })
        .then((response: any) => {
          this.loopTags(createTagMutation, reportId, history);
        })
        .catch((err: any) => {
          this.setError(err.message);
        });
    });
  };

  loopTags = async (
    // after react scripts changes
    // eslint-disable-next-line
    createTagMutation: ({ }) => Promise<any>,
    reportId: string,
    history: any
  ) => {
    const { tags } = this.state;
    await Promise.all(
      tags.map(async (item: SelectType) => {
        return await createTagMutation({
          variables: {
            input: {
              reportTagTagId: item.value,
              reportTagReportId: reportId
            }
          },
          refetchQueries: [
            {
              query: gql(listReportsWithCategories),
              variables: { limit: TableConsts.limit }
            }
          ]
        }).catch((err: any) => {
          this.setError(err.message);
        });
      })
    );
    // NAV TO LIST REPORT SCREEN.
    history.push("/admin-reports");
  };

  /** Create a new tag */
  editReport = async (
    // after react scripts changes
    // eslint-disable-next-line
    updateReportMutation: ({ }) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    updateReportCategoryMutation: ({ }) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    updateTagMutation: ({ }) => Promise<any>,
    status: ReportStatus,
    history: any
  ) => {
    const {
      id,
      name,
      publishedDate,
      description,
      price,
      documentLocation,
      downloadable,
      suburb,
      paidOrFree,
      reportDetails,
      coverImageUrl,
      documentResourceUrl
    } = this.state;

    // update the tag in dynamodb
    updateReportMutation({
      variables: {
        input: {
          id,
          name,
          publishedDate,
          description,
          price,
          documentLocation,
          maxDownloads: "unlimited",
          downloadable,
          status,
          reportAreaId: suburb.value,
          paidOrFree,
          reportDetails,
          coverImageUrl,
          documentResourceUrl
        }
      },
      refetchQueries: [
        {
          query: gql(listReportsWithCategories),
          variables: { limit: TableConsts.limit }
        }
      ]
    })
      .then((response: any) => {
        if (response.errors && response.errors[0]) {
          this.setError("One or more inputs are invalid.");
          return;
        }
        /**
         * Loop through Categories selected and run the createReportCategory for each,
         * after that loop successfully completes, do the same with Tags and run the createReportTag mutation.
         */
        this.editLoopCategories(
          updateReportCategoryMutation,
          updateTagMutation,
          response.data.updateReport.id,
          history
        );
      })
      .catch((err: any) => {
        this.setError(err.message);
      });
  };

  editLoopCategories = async (
    // after react scripts changes
    // eslint-disable-next-line
    updateReportCategoryMutation: ({ }) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    updateTagMutation: ({ }) => Promise<any>,
    reportId: string,
    history: any
  ) => {
    const { categories } = this.state;
    categories.map(async (item: SelectType) => {
      return await updateReportCategoryMutation({
        variables: {
          input: {
            reportCategoryCategoryId: item.value,
            reportCategoryReportId: reportId
          }
        }
      })
        .then((response: any) => {
          // NAV TO LIST REPORT SCREEN.
          history.push("/admin-reports");
        })
        .catch((err: any) => {
          this.setError(err.message);
        });
    });
  };

  /** Open modal */
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  returnFormFields = () => {
    const {
      id,
      name,
      publishedDate,
      description,
      price,
      province,
      suburb,
      city,
      paidOrFree,
      reportDetails,
      coverImageUrl,
      documentResourceUrl,
      tags,
      categories,
      error
    } = this.state;

    let fieldsDisabled = false;
    if (id !== "" && this.state.status === ReportStatus.PUBLISHED) {
      fieldsDisabled = true;
    }
    const paidOrFreeOptions = [
      {
        value: true,
        label: "Paid"
      },
      {
        value: false,
        label: "Free"
      }
    ];

    return (
      <React.Fragment>
        <Card width="100%">
          <Container>
            <Row>
              <HeaderContainer>
                <h2>Create Report</h2>
              </HeaderContainer>
            </Row>
            <Row>
              <Col sm={6}>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  value={name}
                  id="name"
                  placeholder="Name"
                  onChange={e => this.onChange(e)}
                  disabled={fieldsDisabled}
                />
              </Col>
              <Col sm={6}>
                <Label for="publishedDate">Publised Date</Label>
                <Input
                  type="date"
                  name="publishedDate"
                  value={publishedDate}
                  id="publishedDate"
                  placeholder="publised date"
                  onChange={e => this.onChange(e)}
                  disabled={fieldsDisabled}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Query query={gql(listCategorys)} variables={{ limit: 1000 }}>
                {({ loading, data }: any) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (!loading && data) {
                    const categoriesList = data.listCategorys.items.map(
                      (cat: any) => ({
                        value: cat.id,
                        label: cat.name
                      })
                    );
                    return (
                      <Row style={{ flex: 1 }}>
                        <Col sm={12} md={12} lg={12}>
                          <Label>Categories</Label>
                        </Col>
                        <Row style={{ flex: 1 }}>
                          <Col sm={true} style={{ flex: 4 }}>
                            <Select
                              options={categoriesList}
                              // @ts-ignore after react scripts changes.
                              onChange={this.handleCategoryChange}
                              value={categories}
                              isSearchable={true}
                              isMulti={true}
                              placeholder=""
                              className="select-styling"
                              isDisabled={
                                this.state.id !== "" || fieldsDisabled
                              }
                            />
                          </Col>
                          {this.state.id === "" ||
                            (this.state.id !== "" &&
                              this.state.status ===
                              ReportStatus.PUBLISHED) ? null : (
                              <StyledButton
                                type="button"
                                label="Edit Categories"
                                onClick={() => {
                                  this.setState({ editType: "CATEGORY" });
                                  this.openModal();
                                }}
                                width="120px"
                                color={Colors.coal}
                                background={Colors.background}
                              />
                            )}
                        </Row>
                      </Row>
                    );
                  } else {
                    return <div>There has been an error loading filters</div>;
                  }
                }}
              </Query>
            </Row>
            <br />
            <Row>
              <Query query={gql(listTags)} variables={{ limit: 1000 }}>
                {({ loading, data }: any) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (!loading && data) {
                    const tagsList = data.listTags.items.map((cat: any) => ({
                      value: cat.id,
                      label: cat.name
                    }));
                    return (
                      <Row style={{ flex: 1 }}>
                        <Col sm={12} md={12} lg={12}>
                          <Label>Tags</Label>
                        </Col>
                        <Row style={{ flex: 1 }}>
                          <Col sm={true} style={{ flex: 4 }}>
                            <Select
                              options={tagsList}
                              // @ts-ignore after react scripts changes.
                              onChange={this.handleTagChange}
                              value={tags}
                              isSearchable={true}
                              isMulti={true}
                              placeholder=""
                              className="select-styling"
                              isDisabled={
                                this.state.id !== "" || fieldsDisabled
                              }
                            />
                          </Col>
                          {this.state.id === "" ||
                            (this.state.id !== "" &&
                              this.state.status ===
                              ReportStatus.PUBLISHED) ? null : (
                              <StyledButton
                                type="button"
                                label="Edit Tags"
                                onClick={() => {
                                  this.setState({ editType: "TAG" });
                                  this.openModal();
                                }}
                                width="120px"
                                color={Colors.coal}
                                background={Colors.background}
                              />
                            )}
                        </Row>
                      </Row>
                    );
                  } else {
                    return <div>There has been an error loading filters</div>;
                  }
                }}
              </Query>
            </Row>
            <br />
            <Row>
              <Col sm={true}>
                <hr />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={true}>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  value={description}
                  id="description"
                  placeholder="description"
                  onChange={e => this.onChange(e)}
                  disabled={fieldsDisabled}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={true}>
                <Label for="reportDetails">Report Details</Label>
                <Input
                  type="textarea"
                  name="reportDetails"
                  value={reportDetails}
                  id="reportDetails"
                  placeholder="reportDetails"
                  onChange={e => this.onChange(e)}
                  disabled={fieldsDisabled}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={true}>
                <hr />
              </Col>
            </Row>
            <br />
            <Query query={gql(listProvinceTypes)} variables={{ limit: 1000 }}>
              {({ loading, data }: any) => {
                if (loading) {
                  return <PageLoader />;
                }
                if (!loading && data) {
                  const provinceList = data.listProvinceTypes.items.map(
                    (prov: any) => ({
                      ...prov,
                      value: prov.id,
                      label: prov.name
                    })
                  );
                  return (
                    <React.Fragment>
                      <Row>
                        <Col sm={6}>
                          <Label>Province</Label>
                          <Select
                            options={provinceList}
                            // @ts-ignore after react scripts changes.
                            onChange={this.handleProvinceChange}
                            value={province}
                            placeholder=""
                            className="select-styling"
                            isDisabled={fieldsDisabled}
                          />
                        </Col>
                        <Col sm={6}>
                          <Label for="city">City</Label>
                          <Select
                            options={
                              this.state.province === "" ||
                                !this.state.province.id
                                ? []
                                : this.state.province.cities.items.map(
                                  (cit: any) => ({
                                    ...cit,
                                    value: cit.id,
                                    label: cit.name
                                  })
                                )
                            }
                            // @ts-ignore after react scripts changes.
                            onChange={this.handleCityChange}
                            value={city}
                            placeholder={
                              this.state.province === ""
                                ? "Please select a province first."
                                : ""
                            }
                            className="select-styling"
                            isDisabled={fieldsDisabled}
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col sm={6}>
                          <Label>Area</Label>
                          <Select
                            options={
                              this.state.city === "" || !this.state.city.id
                                ? []
                                : this.state.city.areas.items.map(
                                  (area: any) => ({
                                    value: area.id,
                                    label: area.name
                                  })
                                )
                            }
                            // @ts-ignore after react scripts changes.
                            onChange={this.handleSuburbChange}
                            value={suburb}
                            placeholder={
                              this.state.city === ""
                                ? "Please select a city first."
                                : ""
                            }
                            className="select-styling"
                            isDisabled={fieldsDisabled}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                } else {
                  return <div>There has been an error loading provinces</div>;
                }
              }}
            </Query>
            <br />
            <Row>
              <Col sm={true}>
                <hr />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <Label>Paid or Free</Label>
                <Select
                  options={paidOrFreeOptions}
                  // @ts-ignore after react scripts changes.
                  onChange={this.selectBoolean}
                  value={
                    paidOrFree
                      ? { value: true, label: "Paid" }
                      : { value: false, label: "Free" }
                  }
                  placeholder=""
                  className="select-styling"
                  isDisabled={fieldsDisabled}
                />
              </Col>
              <Col sm={6}>
                <Label for="price">Price (incl. VAT)</Label>
                <Input
                  type="number"
                  name="price"
                  value={price}
                  id="price"
                  placeholder="price"
                  onChange={e => this.onChange(e)}
                  disabled={fieldsDisabled}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                {documentResourceUrl !== "" ? (
                  <React.Fragment>
                    <div color={Colors.secondary}>Document Uploaded</div>
                    <StyledButton
                      type="button"
                      label="Remove PDF"
                      onClick={() => {
                        Storage.remove(this.state.documentResourceUrl)
                          .then(result => {
                            this.setState({ documentResourceUrl: "" });
                          })
                          .catch(err => this.setError(err.message));
                      }}
                      width="120px"
                      color={Colors.coal}
                      background={Colors.background}
                    />
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      <Label for="documentResourceUrl">Upload document</Label>
                      <Input
                        type="file"
                        accept="application/pdf"
                        name="documentResourceUrl"
                        // value={documentResourceUrl}
                        id="documentResourceUrl"
                        placeholder="Name"
                        onChange={(e, ...args) => {
                          if (e && e.target && e.target.files) {
                            const file = e.target.files[0];
                            const fileKey = generateRandomNumber();
                            Storage.put(fileKey, file, {
                              contentType: "application/pdf"
                            })
                              .then((result: any) => {
                                this.setState({
                                  documentResourceUrl: fileKey.toString()
                                });
                              })
                              .catch((err: any) =>
                                this.setState(
                                  { error: "Error uploading PDF" },
                                  () => {
                                    setTimeout(() => {
                                      this.setState({ error: null });
                                    }, 3000);
                                  }
                                )
                              );
                          }
                        }}
                        disabled={fieldsDisabled}
                      />
                    </React.Fragment>
                  )}
              </Col>
              <Col sm={6}>
                {coverImageUrl !== "" ? (
                  <React.Fragment>
                    <div color={Colors.secondary}>Cover image uploaded</div>
                    <StyledButton
                      type="button"
                      label="Remove Image"
                      onClick={() => {
                        Storage.remove(this.state.coverImageUrl)
                          .then(result => {
                            this.setState({ coverImageUrl: "" });
                          })
                          .catch(err => this.setError(err.message));
                      }}
                      width="120px"
                      color={Colors.coal}
                      background={Colors.background}
                    />
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      <Label for="coverImageUrl">Upload cover image</Label>
                      <Input
                        type="file"
                        accept="image/*"
                        name="coverImageUrl"
                        id="coverImageUrl"
                        placeholder="Name"
                        onChange={(e, ...args) => {
                          if (e && e.target && e.target.files) {
                            const file = e.target.files[0];
                            const fileKey = generateRandomNumber();
                            Storage.put(`${fileKey}`, file, {
                              contentType: "image/png"
                            })
                              .then((result: any) => {
                                this.setState({
                                  coverImageUrl: fileKey.toString()
                                });
                              })
                              .catch((err: any) =>
                                this.setState(
                                  { error: "Error uploading cover image" },
                                  () => {
                                    setTimeout(() => {
                                      this.setState({ error: null });
                                    }, 3000);
                                  }
                                )
                              );
                          }
                        }}
                        disabled={fieldsDisabled}
                      />
                    </React.Fragment>
                  )}
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={true}>
                <hr />
              </Col>
            </Row>
            <br />
            {this.state.id === ""
              ? this.renderCreate()
              : this.state.id !== "" &&
                this.state.status === ReportStatus.PUBLISHED
                ? this.renderArchived()
                : this.renderEdit()}
          </Container>
          <br />
          {error && <ErrorMessage errorMessage={error} />}
        </Card>
      </React.Fragment>
    );
  };

  renderCreate = () => {
    return (
      <Mutation<CreateReportMutation, CreateReportMutationVariables>
        mutation={gql(createReport)}
      >
        {(createReportMutation, { loading: createReportLoading }) => (
          <Mutation<
            CreateReportCategoryMutation,
            CreateReportCategoryMutationVariables
          >
            mutation={gql(createReportCategory)}
          >
            {(
              createReportCategoryMutation,
              { loading: createCategoryLoading }
            ) => (
                <Mutation<
                  CreateReportTagMutation,
                  CreateReportTagMutationVariables
                >
                  mutation={gql(createReportTag)}
                >
                  {(createReportTagMutation, { loading: createTagLoading }) => (
                    <Route
                      render={({ history }) => (
                        <React.Fragment>
                          {createReportLoading ||
                            createCategoryLoading ||
                            createTagLoading ? (
                              <Loader color={Colors.primary} />
                            ) : (
                              <Row>
                                <Col xs="auto">
                                  <StyledButton
                                    type="button"
                                    label="Save as draft"
                                    onClick={() =>
                                      this.createReport(
                                        createReportMutation,
                                        createReportCategoryMutation,
                                        createReportTagMutation,
                                        ReportStatus.DRAFT,
                                        history
                                      )
                                    }
                                    width="120px"
                                    color={Colors.coal}
                                    background={Colors.background}
                                  />
                                </Col>
                                <Col xs="auto">
                                  <StyledButton
                                    type="button"
                                    label="Preview"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: "/admin-reports/preview",
                                        state: this.state
                                      });
                                    }}
                                    width="120px"
                                    color={Colors.coal}
                                    background={Colors.background}
                                  />
                                </Col>
                                <Col xs="auto">
                                  <StyledButton
                                    type="button"
                                    label="Publish"
                                    onClick={() =>
                                      this.createReport(
                                        createReportMutation,
                                        createReportCategoryMutation,
                                        createReportTagMutation,
                                        ReportStatus.PUBLISHED,
                                        history
                                      )
                                    }
                                    width="120px"
                                    color={Colors.coal}
                                    background={Colors.background}
                                  />
                                </Col>
                                <Col xs="auto">
                                  <Link
                                    to={{
                                      pathname: "/admin-reports"
                                    }}
                                    label={
                                      <StyledButton
                                        type="button"
                                        label="Cancel"
                                        width="120px"
                                        color={Colors.coal}
                                        background={Colors.background}
                                      />
                                    }
                                  />
                                </Col>
                              </Row>
                            )}
                        </React.Fragment>
                      )}
                    />
                  )}
                </Mutation>
              )}
          </Mutation>
        )}
      </Mutation>
    );
  };

  // after react scripts changes
  // eslint-disable-next-line
  archiveReport = (updateReportStatus: ({ }) => Promise<any>) => {
    updateReportStatus({
      variables: {
        input: {
          id: this.state.id,
          status: ReportStatus.ARCHIVED
        }
      },
      refetchQueries: [
        {
          query: gql(listReportsWithCategories),
          variables: { limit: TableConsts.limit }
        }
      ]
    })
      .then(() => {
        this.props.history.push("/admin-reports");
      })
      .catch((err: any) => {
        this.setState({ error: err.message }, () => {
          setTimeout(() => {
            this.setState({ error: null });
          }, 3000);
        });
      });
  };

  renderEdit = () => {
    return (
      <Mutation<UpdateReportMutation, UpdateReportMutationVariables>
        mutation={gql(updateReport)}
      >
        {(updateReportMutation, { loading: updateReportLoading }) => (
          <Mutation<
            UpdateReportCategoryMutation,
            UpdateReportCategoryMutationVariables
          >
            mutation={gql(updateReportCategory)}
          >
            {(
              updateReportCategoryMutation,
              { loading: updateCategoryLoading }
            ) => (
                <Mutation<
                  UpdateReportTagMutation,
                  UpdateReportTagMutationVariables
                >
                  mutation={gql(updateReportTag)}
                >
                  {(updateReportTagMutation, { loading: updateTagLoading }) => (
                    <Route
                      render={({ history }) => (
                        <React.Fragment>
                          {updateReportLoading ||
                            updateCategoryLoading ||
                            updateTagLoading ? (
                              <Loader color={Colors.primary} />
                            ) : (
                              <Row>
                                <React.Fragment>
                                  <Col sm={2}>
                                    <StyledButton
                                      type="button"
                                      label="Save as draft"
                                      onClick={() =>
                                        this.editReport(
                                          updateReportMutation,
                                          updateReportCategoryMutation,
                                          updateReportTagMutation,
                                          ReportStatus.DRAFT,
                                          history
                                        )
                                      }
                                      width="120px"
                                      color={Colors.coal}
                                      background={Colors.background}
                                    />
                                  </Col>
                                  <Col sm={2}>
                                    <StyledButton
                                      type="button"
                                      label="Preview"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname: "/admin-reports/preview",
                                          state: this.state
                                        });
                                      }}
                                      width="120px"
                                      color={Colors.coal}
                                      background={Colors.background}
                                    />
                                  </Col>
                                  <Col sm={2}>
                                    <StyledButton
                                      type="button"
                                      label="Publish"
                                      onClick={() =>
                                        this.editReport(
                                          updateReportMutation,
                                          updateReportCategoryMutation,
                                          updateReportTagMutation,
                                          ReportStatus.PUBLISHED,
                                          history
                                        )
                                      }
                                      width="120px"
                                      color={Colors.coal}
                                      background={Colors.background}
                                    />
                                  </Col>
                                </React.Fragment>
                                {this.state.id !== "" &&
                                  (this.state.documentResourceUrl === "" ||
                                    this.state.coverImageUrl === "") ? null : (
                                    <Col sm={2}>
                                      <Link
                                        to={{
                                          pathname: "/admin-reports"
                                        }}
                                        label={
                                          <StyledButton
                                            type="button"
                                            label="Cancel"
                                            width="120px"
                                            color={Colors.coal}
                                            background={Colors.background}
                                          />
                                        }
                                      />
                                    </Col>
                                  )}
                              </Row>
                            )}
                        </React.Fragment>
                      )}
                    />
                  )}
                </Mutation>
              )}
          </Mutation>
        )}
      </Mutation>
    );
  };

  renderArchived = () => {
    return (
      <Mutation<UpdateReportMutation, UpdateReportMutationVariables>
        mutation={gql(updateReport)}
      >
        {(updateReportMutation, { loading: updateReportLoading }) => (
          <Mutation<
            UpdateReportCategoryMutation,
            UpdateReportCategoryMutationVariables
          >
            mutation={gql(updateReportCategory)}
          >
            {(
              updateReportCategoryMutation,
              { loading: updateCategoryLoading }
            ) => (
                <Mutation<
                  UpdateReportTagMutation,
                  UpdateReportTagMutationVariables
                >
                  mutation={gql(updateReportTag)}
                >
                  {(updateReportTagMutation, { loading: updateTagLoading }) => (
                    <Route
                      render={({ history }) => (
                        <React.Fragment>
                          {updateReportLoading ||
                            updateCategoryLoading ||
                            updateTagLoading ? (
                              <Loader color={Colors.primary} />
                            ) : (
                              <Row>
                                <React.Fragment>
                                  <Col sm={2}>
                                    <StyledButton
                                      type="button"
                                      label="Archive"
                                      onClick={() =>
                                        this.archiveReport(updateReportMutation)
                                      }
                                      width="120px"
                                      color={Colors.coal}
                                      background={Colors.background}
                                    />
                                  </Col>
                                  <Col sm={2}>
                                    <StyledButton
                                      type="button"
                                      label="Preview"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname: "/admin-reports/preview",
                                          state: this.state
                                        });
                                      }}
                                      width="120px"
                                      color={Colors.coal}
                                      background={Colors.background}
                                    />
                                  </Col>
                                </React.Fragment>
                                <Col sm={2}>
                                  <Link
                                    to={{
                                      pathname: "/admin-reports"
                                    }}
                                    label={
                                      <StyledButton
                                        type="button"
                                        label="Cancel"
                                        width="120px"
                                        color={Colors.coal}
                                        background={Colors.background}
                                      />
                                    }
                                  />
                                </Col>
                              </Row>
                            )}
                        </React.Fragment>
                      )}
                    />
                  )}
                </Mutation>
              )}
          </Mutation>
        )}
      </Mutation>
    );
  };

  render() {
    const { editType } = this.state;
    return (
      <BackendWrapper>
        <GlobalModalContainer
          title={"Edit " + (editType === "CATEGORY" ? "categories" : "tags")}
          modalDisplay={
            <EditCategoryTag
              closeModal={(successData: any) => this.closeModal(successData)}
              data={
                editType === "CATEGORY"
                  ? this.state.categories
                  : this.state.tags
              }
              type={editType}
              reportId={this.state.id}
              {...this.props}
            />
          }
          modal={this.state.modal}
        />
        {this.returnFormFields()}
      </BackendWrapper>
    );
  }
}
export default withToastManager(AddReport);
