import * as React from "react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { Row, Col, Form, Label, FormGroup, Input } from "reactstrap";

/** GraphQL */
import {
  updateFaq,
  deleteFaq,
  updateFaqType,
  deleteFaqType
} from "../../graphql/mutations";

import {
  UpdateFaqMutation,
  UpdateFaqMutationVariables,
  DeleteFaqMutation,
  DeleteFaqMutationVariables,
  UpdateFaqTypeMutation,
  UpdateFaqTypeMutationVariables,
  DeleteFaqTypeMutation,
  DeleteFaqTypeMutationVariables
} from "../../API";

/** Presentation/UI */
import Loader from "../../Components/Loader";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import StyledButton from "../../Components/Styled/Button";

/** Custom Types */
import { Error } from "../../CustomTypes";

/** Themes */
import { Colors } from "../../Themes";
import { TableConsts } from "../../Utils/Consts";
import { listFaqs, listFaqTypes } from "../../graphql/queries";
import Select from "react-select";
import PageLoader from "../../Components/PageLoader";

type Props = {
  data?: any;
  containerType: string;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  loading: boolean;
  error: Error;
  name: string;
  selectedId: string;
  categoryFaqs: boolean;
  question: string;
  answer: string;
  category: { value: string; label: string };
};

class EditFaq extends React.Component<Props, State> {
  state: State = {
    loading: false,
    error: null,
    name: this.props.data && this.props.data.title ? this.props.data.title : "",
    selectedId: this.props.data && this.props.data.id ? this.props.data.id : "",
    categoryFaqs:
      this.props.data && this.props.data.faqs && this.props.data.faqs.items[0]
        ? true
        : false,
    question:
      this.props.data && this.props.data.question
        ? this.props.data.question
        : "",
    answer:
      this.props.data && this.props.data.answer ? this.props.data.answer : "",
    category:
      this.props.data && this.props.data.category && this.props.data.category.id
        ? {
          value: this.props.data.category.id,
          label: this.props.data.category.title
        }
        : { value: "", label: "" }
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  // Edit FAQ Category
  // after react scripts changes
  // eslint-disable-next-line
  editCategory = async (updateFaqTypeMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { selectedId, name } = this.state;

    this.setState({ loading: true });
    if (name !== "") {
      updateFaqTypeMutation({
        variables: {
          input: {
            id: selectedId,
            title: name
          }
        },
        refetchQueries: [
          {
            query: gql(listFaqTypes),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Updated category, ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Title cannot be blank");
    }
  };

  // Edit FAQ question
  // after react scripts changes
  // eslint-disable-next-line
  editFaq = async (updateFaqMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { selectedId, question, answer, category } = this.state;

    this.setState({ loading: true });
    if (question || answer || category.value !== "") {
      updateFaqMutation({
        variables: {
          input: {
            id: selectedId,
            question,
            answer,
            faqCategoryId: category.value
          }
        },
        refetchQueries: [
          {
            query: gql(listFaqs),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification("Updated FAQ");
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("All fields are mandatory");
    }
  };

  // Delete FAQ or FAQ Category
  returnDelete = () => {
    const { loading, categoryFaqs } = this.state;
    switch (this.props.containerType) {
      case "Category":
        return (
          <Mutation<DeleteFaqTypeMutation, DeleteFaqTypeMutationVariables>
            mutation={gql(deleteFaqType)}
          >
            {deleteFaqTypeMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  deleteFaqTypeMutation({
                    variables: {
                      input: {
                        id: this.state.selectedId
                      }
                    },
                    refetchQueries: [
                      {
                        query: gql(listFaqTypes),
                        variables: {
                          limit: TableConsts.limit
                        }
                      }
                    ]
                  })
                    .then(() => {
                      this.setState({ loading: false });
                      this.props.closeModal();
                      this.props.notification(
                        `Deleted category ${this.state.name}`
                      );
                    })
                    .catch((err: any) => {
                      this.setState({ loading: false });
                      this.setError(err.message);
                    });
                }}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <StyledButton
                        type="submit"
                        label={!loading ? "Delete" : <Loader />}
                        color={Colors.snow}
                        background={Colors.error}
                        disabled={categoryFaqs}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}
          </Mutation>
        );
      case "FAQ":
        return (
          <Mutation<DeleteFaqMutation, DeleteFaqMutationVariables>
            mutation={gql(deleteFaq)}
          >
            {deleteFaqMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  deleteFaqMutation({
                    variables: {
                      input: {
                        id: this.state.selectedId
                      }
                    },
                    refetchQueries: [
                      {
                        query: gql(listFaqs),
                        variables: {
                          limit: TableConsts.limit
                        }
                      }
                    ]
                  })
                    .then(() => {
                      this.setState({ loading: false });
                      this.props.closeModal();
                      this.props.notification("Deleted FAQ");
                    })
                    .catch((err: any) => {
                      this.setState({ loading: false });
                      this.setError(err.message);
                    });
                }}
              >
                <FormGroup>
                  <StyledButton
                    type="submit"
                    label={!loading ? "Delete" : <Loader />}
                    color={Colors.snow}
                    background={Colors.error}
                  />
                </FormGroup>
              </Form>
            )}
          </Mutation>
        );
      default:
        return null;
    }
  };

  // Category Form Fields
  categoryFormFields = () => {
    const { error, loading, name, selectedId } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="name">Category</Label>
              <Input
                type="text"
                name="name"
                value={name}
                id="name"
                placeholder="Category"
                onChange={e => this.setState({ name: e.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <StyledButton
                type="submit"
                label={
                  !loading ? selectedId !== "" ? "Save" : "Add" : <Loader />
                }
                color={Colors.coal}
                background={Colors.background}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        {selectedId !== "" ? this.returnDelete() : null}
        {error && <ErrorMessage errorMessage={error} />}
      </React.Fragment>
    );
  };

  // Category Question Fields
  faqFormFields = () => {
    const { question, answer, selectedId, error } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Query query={gql(listFaqTypes)}>
                {({ loading, data }: any) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (!loading && data) {
                    const optionsToUse =
                      data && data.listFaqTypes && data.listFaqTypes.items
                        ? data.listFaqTypes.items.map((item: any) => {
                          return { value: item.id, label: item.title };
                        })
                        : [];
                    return (
                      <React.Fragment>
                        <FormGroup>
                          <Select
                            options={optionsToUse}
                            onChange={(e: any) => {
                              this.setState({ category: e });
                            }}
                            value={this.state.category}
                            placeholder=""
                            className="select-styling"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Question</Label>
                          <Input
                            type="text"
                            name="question"
                            value={question}
                            id="question"
                            placeholder="Question"
                            onChange={e =>
                              this.setState({ question: e.target.value })
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Answer</Label>
                          <Input
                            type="textarea"
                            name="answer"
                            value={answer}
                            id="answer"
                            placeholder="Answer"
                            onChange={e =>
                              this.setState({ answer: e.target.value })
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <StyledButton
                            type="submit"
                            label={loading ? <Loader /> : " Save"}
                            color={Colors.coal}
                            background={Colors.background}
                          />
                        </FormGroup>
                        {selectedId !== "" ? this.returnDelete() : null}
                        {error && <ErrorMessage errorMessage={error} />}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <p>There has been a problem loading your Categories</p>
                    );
                  }
                }}
              </Query>
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  render() {
    switch (this.props.containerType) {
      case "Category":
        return (
          <Mutation<UpdateFaqTypeMutation, UpdateFaqTypeMutationVariables>
            mutation={gql(updateFaqType)}
          >
            {updateFaqTypeMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  this.editCategory(updateFaqTypeMutation);
                }}
              >
                {this.categoryFormFields()}
              </Form>
            )}
          </Mutation>
        );
      case "FAQ":
        return (
          <Mutation<UpdateFaqMutation, UpdateFaqMutationVariables>
            mutation={gql(updateFaq)}
          >
            {updateFaqMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  this.editFaq(updateFaqMutation);
                }}
              >
                {this.faqFormFields()}
              </Form>
            )}
          </Mutation>
        );
      default:
        return <div>There was an error loading your data.</div>;
    }
  }
}

export default EditFaq;
