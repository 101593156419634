import axios from "axios";
import { TEST_HOST, AUTHORIZATION, ENTITY_ID_RECURRING } from "./Constants";

// Delete card from peach payments db
export async function deletePeachCard(cardId: string): Promise<object> {
  try {
    return axios({
      method: "delete",
      headers: {
        Authorization: AUTHORIZATION
      },
      url: `/v1/registrations/${cardId}?entityId=${ENTITY_ID_RECURRING}`,
      baseURL: TEST_HOST
    });
  } catch (e) {
    return { error: e.message };
  }
}
