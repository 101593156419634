import * as React from "react";
import { CardImg } from "reactstrap";
import { returnImageFromStorage } from "../../Utils/Helpers";
import colors from "../../Themes/Colors";
import StyledButton from "../../Components/Styled/Button";

type Props = {
  resourceKey: string;
  downloadPdf?: boolean;
};

type State = {
  resourceUrl: string;
};

class ResourceWrapper extends React.Component<Props, State> {
  state = {
    resourceUrl: ""
  };

  async componentDidMount() {
    const response = await returnImageFromStorage(this.props.resourceKey);
    const resourceUrl = response || "";
    this.setState({ resourceUrl });
  }

  render() {
    if (this.props.downloadPdf) {
      return this.state.resourceUrl !== "" ? (
        <StyledButton
          type="button"
          onClick={() => {
            window.open(this.state.resourceUrl);
          }}
          label="Download"
          width="100px"
          color={colors.snow}
          background={colors.default}
          margin="5px"
        />
      ) : null;
    }
    return this.state.resourceUrl !== "" ? (
      <CardImg
        variant="top"
        src={this.state.resourceUrl}
        alt="No image preview available."
        width="100%"
        height="250px"
      />
    ) : null;
  }
}

export default ResourceWrapper;
