// tslint:disable
// this is an auto generated file. This will be overwritten

export const getTask = `query GetTask($id: ID!) {
  getTask(id: $id) {
    id
    title
    description
    status
    assignedTo {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const listTasks = `query ListTasks(
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      status
      assignedTo {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
    nextToken
  }
}
`;
export const getCompany = `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    createdAt
    name
    emailAddress
    contactNumber
    address
    industry
    physicalAddress
    numberOfEmployees
    archived
    interests {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        interests {
          id
          name
          createdAt
          company {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    area {
      id
      name
      createdAt
      city {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      companies {
        items {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
    }
    teamLead {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    teamMembers {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        user {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
    reports {
      items {
        id
        createdAt
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    transactions {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    cards {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        card {
          id
          type
          number
          nameOnCard
          expiryMonth
          expiryYear
          peachPaymentCardId
          company {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const listCompanys = `query ListCompanys(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    dateOfBirth
    createdAt
    name
    surname
    emailAddress
    contactNumber
    idNumber
    physicalAddress
    archived
    verified
    role
    status
    jobTitle
    company {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        user {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
    companyTeamLead {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getReport = `query GetReport($id: ID!) {
  getReport(id: $id) {
    id
    createdAt
    name
    description
    publishedDate
    price
    documentLocation
    maxDownloads
    downloadable
    status
    paidOrFree
    reportDetails
    coverImageUrl
    documentResourceUrl
    area {
      id
      name
      createdAt
      city {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      companies {
        items {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
    }
    tags {
      items {
        id
        tag {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    categories {
      items {
        id
        category {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    region {
      items {
        id
        region {
          id
          name
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    companys {
      items {
        id
        createdAt
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    transactions {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const listReports = `query ListReports(
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
    nextToken
  }
}
`;
export const getRegion = `query GetRegion($id: ID!) {
  getRegion(id: $id) {
    id
    name
    reports {
      items {
        id
        region {
          id
          name
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const listRegions = `query ListRegions(
  $filter: ModelRegionFilterInput
  $limit: Int
  $nextToken: String
) {
  listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      reports {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getTag = `query GetTag($id: ID!) {
  getTag(id: $id) {
    id
    name
    createdAt
    reports {
      items {
        id
        tag {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const listTags = `query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
  listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      reports {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getCategory = `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    name
    createdAt
    reports {
      items {
        id
        category {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const listCategorys = `query ListCategorys(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      reports {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getVoucher = `query GetVoucher($id: ID!) {
  getVoucher(id: $id) {
    id
    code
    discountPercentage
    numberOfUses
    expiryDate
    archived
    createdAt
    transactions {
      items {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const listVouchers = `query ListVouchers(
  $filter: ModelVoucherFilterInput
  $limit: Int
  $nextToken: String
) {
  listVouchers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      discountPercentage
      numberOfUses
      expiryDate
      archived
      createdAt
      transactions {
        items {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getFaq = `query GetFaq($id: ID!) {
  getFaq(id: $id) {
    id
    question
    answer
    category {
      id
      title
      faqs {
        items {
          id
          question
          answer
          category {
            id
            title
            faqs {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const listFaqs = `query ListFaqs($filter: ModelFaqFilterInput, $limit: Int, $nextToken: String) {
  listFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      question
      answer
      category {
        id
        title
        faqs {
          items {
            id
            question
            answer
            category {
              id
              title
            }
          }
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getFaqType = `query GetFaqType($id: ID!) {
  getFaqType(id: $id) {
    id
    title
    faqs {
      items {
        id
        question
        answer
        category {
          id
          title
          faqs {
            items {
              id
              question
              answer
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const listFaqTypes = `query ListFaqTypes(
  $filter: ModelFaqTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listFaqTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      faqs {
        items {
          id
          question
          answer
          category {
            id
            title
            faqs {
              nextToken
            }
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getInterest = `query GetInterest($id: ID!) {
  getInterest(id: $id) {
    id
    name
    createdAt
    company {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        interests {
          id
          name
          createdAt
          company {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const listInterests = `query ListInterests(
  $filter: ModelInterestFilterInput
  $limit: Int
  $nextToken: String
) {
  listInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getTransaction = `query GetTransaction($id: ID!) {
  getTransaction(id: $id) {
    id
    createdAt
    cost
    voucher {
      id
      transaction {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      voucher {
        id
        code
        discountPercentage
        numberOfUses
        expiryDate
        archived
        createdAt
        transactions {
          items {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          nextToken
        }
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const listTransactions = `query ListTransactions(
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getCard = `query GetCard($id: ID!) {
  getCard(id: $id) {
    id
    type
    number
    nameOnCard
    expiryMonth
    expiryYear
    peachPaymentCardId
    company {
      id
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
      card {
        id
        type
        number
        nameOnCard
        expiryMonth
        expiryYear
        peachPaymentCardId
        company {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
      }
    }
  }
}
`;
export const listCards = `query ListCards(
  $filter: ModelCardFilterInput
  $limit: Int
  $nextToken: String
) {
  listCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      number
      nameOnCard
      expiryMonth
      expiryYear
      peachPaymentCardId
      company {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        card {
          id
          type
          number
          nameOnCard
          expiryMonth
          expiryYear
          peachPaymentCardId
          company {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
        }
      }
    }
    nextToken
  }
}
`;
export const getProvinceType = `query GetProvinceType($id: ID!) {
  getProvinceType(id: $id) {
    id
    name
    createdAt
    cities {
      items {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const listProvinceTypes = `query ListProvinceTypes(
  $filter: ModelProvinceTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listProvinceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      cities {
        items {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getCityType = `query GetCityType($id: ID!) {
  getCityType(id: $id) {
    id
    name
    province {
      id
      name
      createdAt
      cities {
        items {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
    createdAt
    areas {
      items {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const listCityTypes = `query ListCityTypes(
  $filter: ModelCityTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listCityTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      province {
        id
        name
        createdAt
        cities {
          items {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          nextToken
        }
      }
      createdAt
      areas {
        items {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getAreaType = `query GetAreaType($id: ID!) {
  getAreaType(id: $id) {
    id
    name
    createdAt
    city {
      id
      name
      province {
        id
        name
        createdAt
        cities {
          items {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          nextToken
        }
      }
      createdAt
      areas {
        items {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        nextToken
      }
    }
    companies {
      items {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
      nextToken
    }
    reports {
      items {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const listAreaTypes = `query ListAreaTypes(
  $filter: ModelAreaTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listAreaTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      city {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      companies {
        items {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getSampleReport = `query GetSampleReport($id: ID!) {
  getSampleReport(id: $id) {
    id
    name
    createdAt
    rootsDescription
    sampleReportDescription
    coverImageUrl
    documentResourceUrl
  }
}
`;
export const listSampleReports = `query ListSampleReports(
  $filter: ModelSampleReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listSampleReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      rootsDescription
      sampleReportDescription
      coverImageUrl
      documentResourceUrl
    }
    nextToken
  }
}
`;
export const getTransactionVoucher = `query GetTransactionVoucher($id: ID!) {
  getTransactionVoucher(id: $id) {
    id
    transaction {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    voucher {
      id
      code
      discountPercentage
      numberOfUses
      expiryDate
      archived
      createdAt
      transactions {
        items {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const listTransactionVouchers = `query ListTransactionVouchers(
  $filter: ModelTransactionVoucherFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactionVouchers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      transaction {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      voucher {
        id
        code
        discountPercentage
        numberOfUses
        expiryDate
        archived
        createdAt
        transactions {
          items {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getCompanyCard = `query GetCompanyCard($id: ID!) {
  getCompanyCard(id: $id) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    card {
      id
      type
      number
      nameOnCard
      expiryMonth
      expiryYear
      peachPaymentCardId
      company {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        card {
          id
          type
          number
          nameOnCard
          expiryMonth
          expiryYear
          peachPaymentCardId
          company {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
        }
      }
    }
  }
}
`;
export const listCompanyCards = `query ListCompanyCards(
  $filter: ModelCompanyCardFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
      card {
        id
        type
        number
        nameOnCard
        expiryMonth
        expiryYear
        peachPaymentCardId
        company {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
      }
    }
    nextToken
  }
}
`;
export const getCompanyInterest = `query GetCompanyInterest($id: ID!) {
  getCompanyInterest(id: $id) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    interests {
      id
      name
      createdAt
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const listCompanyInterests = `query ListCompanyInterests(
  $filter: ModelCompanyInterestFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
      interests {
        id
        name
        createdAt
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getUserCompany = `query GetUserCompany($id: ID!) {
  getUserCompany(id: $id) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    user {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const listUserCompanys = `query ListUserCompanys(
  $filter: ModelUserCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
      user {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
    nextToken
  }
}
`;
export const getCompanyReport = `query GetCompanyReport($id: ID!) {
  getCompanyReport(id: $id) {
    id
    createdAt
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const listCompanyReports = `query ListCompanyReports(
  $filter: ModelCompanyReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getReportTag = `query GetReportTag($id: ID!) {
  getReportTag(id: $id) {
    id
    tag {
      id
      name
      createdAt
      reports {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
export const listReportTags = `query ListReportTags(
  $filter: ModelReportTagFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag {
        id
        name
        createdAt
        reports {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
    }
    nextToken
  }
}
`;
export const getReportCategory = `query GetReportCategory($id: ID!) {
  getReportCategory(id: $id) {
    id
    category {
      id
      name
      createdAt
      reports {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
export const listReportCategorys = `query ListReportCategorys(
  $filter: ModelReportCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      category {
        id
        name
        createdAt
        reports {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
    }
    nextToken
  }
}
`;
export const getReportRegion = `query GetReportRegion($id: ID!) {
  getReportRegion(id: $id) {
    id
    region {
      id
      name
      reports {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
export const listReportRegions = `query ListReportRegions(
  $filter: ModelReportRegionFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      region {
        id
        name
        reports {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
    }
    nextToken
  }
}
`;
