import { RootsSuperAdmin } from "./RootsSuperAdminRoutes";

/** Custom types */
import { NavItemType } from "../CustomTypes";
import { RootsAdmin } from "./RootsAdminRoutes";
import { ConsumerAdmin } from "./ConsumerAdminRoutes";
import { SuperUser } from "./SuperUserRoutes";
import { User } from "./UserRoutes";

export const NAV_ITEMS: Array<NavItemType> = [
  ...RootsSuperAdmin,
  ...RootsAdmin,
  ...ConsumerAdmin,
  ...SuperUser,
  ...User
];
