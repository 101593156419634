import { ServiceType } from "../CustomTypes";
import { ServiceIcons } from "../Themes";
import { UserRole, UserStatus } from "../API";

export const COMPANY_SIZE = [
  {
    value: "1 - 5",
    label: "1 - 5"
  },
  {
    value: "15 - 30",
    label: "15 - 30"
  },
  {
    value: "30 - 50",
    label: "30 - 50"
  },
  {
    value: "50+",
    label: "50+"
  }
];

export const PRODUCTION_INDUSTRY = [
  {
    value: "Industry 1",
    label: "Industry 1"
  },
  {
    value: "Industry 2",
    label: "Industry 2"
  },
  {
    value: "Industry 3",
    label: "Industry 3"
  },
  {
    value: "Industry 4",
    label: "Industry 4"
  }
];

/** User Roles - used for dropdown selection */
export const USER_PERMISSIONS = [
  {
    value: UserRole.ROOTS_SUPER_ADMIN,
    label: "Roots Super Admin"
  },
  {
    value: UserRole.ROOTS_ADMIN,
    label: "Roots Admin"
  },
  {
    value: UserRole.CONSUMER_ADMIN,
    label: "Consumer Admin"
  },
  {
    value: UserRole.SUPER_USER,
    label: "Super User"
  },
  {
    value: UserRole.USER,
    label: "User"
  }
];

/** User permission roles */
const USER_PERMISSION_ROLES = {
  rootsSuperAdmin: "ROOTS_SUPER_ADMIN",
  rootsAdmin: "ROOTS_ADMIN",
  consumerAdmin: "CONSUMER_ADMIN",
  superUser: "SUPER_USER",
  user: "USER"
};

/** Cognito User Groups */
export const ADMIN_GROUP = "Admin";
export const USER_GROUP = "User";
export const SUPER_USER_GROUP = "SuperUser";

/** Cognito challenge requests */
export const COGNITO_CHALLENGES = {
  newPasswordRequired: "NEW_PASSWORD_REQUIRED"
};

export const USER_ROLES = Object.freeze(USER_PERMISSION_ROLES);

/** User status options for dropdown select */
export const USER_STATUS_OPTIONS = [
  {
    value: UserStatus.ACTIVE,
    label: "Active"
  },
  {
    value: UserStatus.INACTIVE,
    label: "Inactive"
  }
];

/** Space statuses */
const SPACE_STATUSES = {
  active: "ACTIVE",
  inactive: "INACTIVE"
};

export const SPACE_STATUS = Object.freeze(SPACE_STATUSES);

/** Acceptable file formats */
const FILE_UPLOAD_FORMATS = {
  csv: "text/csv"
};

export const VALID_FILE_FORMATS = Object.freeze(FILE_UPLOAD_FORMATS);

/** Default service */
export const defaultService: ServiceType = {
  id: "",
  name: "",
  icon: ServiceIcons.serviceIcon1
};

/** Http Methods */
const HTTP_METHOD_OPTIONS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE"
};

// Temporary chart data
export const newClientsData = [
  { name: "Jan", Clients: 0 },
  { name: "Feb", Clients: 0 },
  { name: "Mar", Clients: 0 },
  { name: "Apr", Clients: 0 },
  { name: "May", Clients: 0 },
  { name: "June", Clients: 0 },
  { name: "July", Clients: 0 },
  { name: "Aug", Clients: 0 },
  { name: "Sept", Clients: 0 },
  { name: "Oct", Clients: 0 },
  { name: "Nov", Clients: 0 },
  { name: "Dec", Clients: 0 }
];

export const purchaseData = [
  { name: "Jan", Purchases: 0 },
  { name: "Feb", Purchases: 0 },
  { name: "Mar", Purchases: 0 },
  { name: "Apr", Purchases: 0 },
  { name: "May", Purchases: 0 },
  { name: "June", Purchases: 0 },
  { name: "July", Purchases: 0 },
  { name: "Aug", Purchases: 0 },
  { name: "Sept", Purchases: 0 },
  { name: "Oct", Purchases: 0 },
  { name: "Nov", Purchases: 0 },
  { name: "Dec", Purchases: 0 }
];

export const ADMIN_PERMISSION_ROLES = [USER_ROLES.rootsSuperAdmin, USER_ROLES.rootsAdmin];
export const ADMIN_ROLES = Object.freeze(ADMIN_PERMISSION_ROLES);

export const HTTP_METHODS = Object.freeze(HTTP_METHOD_OPTIONS);

export const TableConsts = { limit: 500, defaultRows: 10 };
