import * as React from "react";
import {
  MdDashboard,
  MdPeople,
  MdInsertDriveFile,
  MdAttachMoney,
  MdContentCopy,
  MdLocationOn,
  MdQuestionAnswer
} from "react-icons/md";

/** Custom types */
import { NavItemType } from "../CustomTypes";

export const RootsAdmin: Array<NavItemType> = [
  {
    to: "/dashboard",
    role: "ROOTS_ADMIN",
    title: "Dashboard",
    icon: <MdDashboard size="1.3em" />
  },
  {
    to: "#",
    role: "ROOTS_ADMIN",
    title: "Manage",
    icon: <MdPeople size="1.3em" />,
    subMenu: [
      {
        to: "/team",
        title: "Users"
      },
      {
        to: "/admin/companies",
        title: "Companies"
      }
    ]
  },
  {
    to: "/admin-reports",
    role: "ROOTS_ADMIN",
    title: "Reports Management",
    icon: <MdInsertDriveFile size="1.3em" />
  },
  {
    to: "/admin/sales",
    role: "ROOTS_ADMIN",
    title: "Sales",
    icon: <MdAttachMoney size="1.3em" />
  },
  {
    to: "#",
    role: "ROOTS_ADMIN",
    title: "Content Management",
    icon: <MdPeople size="1.3em" />,
    subMenu: [
      {
        to: "/category-management",
        title: "Category Management"
      },
      {
        to: "/interest-management",
        title: "Interest Management"
      },
      {
        to: "/tags-management",
        title: "Tags Management"
      },
      {
        to: "/sample-report",
        title: "Sample report"
      }
    ]
  },
  {
    to: "/admin/voucher-management",
    role: "ROOTS_ADMIN",
    title: "Voucher Management",
    icon: <MdContentCopy size="1.3em" />
  },
  {
    to: "#",
    role: "ROOTS_ADMIN",
    title: "Location Management",
    icon: <MdLocationOn size="1.3em" />,
    subMenu: [
      {
        to: "/province-management",
        title: "Province Management"
      },
      {
        to: "/city-management",
        title: "City Management"
      },
      {
        to: "/area-management",
        title: "Area Management"
      }
    ]
  },
  {
    to: "#",
    role: "ROOTS_ADMIN",
    title: "FAQ Management",
    icon: <MdQuestionAnswer size="1.3em" />,
    subMenu: [
      {
        to: "/faq-management",
        title: "FAQ's Management"
      },
      {
        to: "/faq-categories",
        title: "FAQ Categories"
      }
    ]
  }
];
