import * as React from "react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import Select from "react-select";
import { Row, Col, Form, Label, FormGroup, Input } from "reactstrap";

/** GraphQL */
import {
  updateCompany,
  createCompanyInterest,
  deleteCompanyInterest
} from "../../graphql/mutations";
import { listInterests } from "../../graphql/queries";
import { listInterestsWithCompanys } from "../../graphql/custom-queries";

import {
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  CreateCompanyInterestMutation,
  CreateCompanyInterestMutationVariables,
  DeleteCompanyInterestMutation,
  DeleteCompanyInterestMutationVariables,
  UserRole,
  Industry
} from "../../API";

/** Presentation/UI */
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import StyledButton from "../../Components/Styled/Button";
import Link from "../../Components/Styled/Link";

/** Custom Types */
import { Error } from "../../CustomTypes";

import { Colors } from "../../Themes";
import styled from "styled-components";
import { TableHeaderContainer } from "../../Components/Styled/ListViewElements";
import Loader from "../../Components/Loader";
import { formatDropdown, getUserRole } from "../../Utils/Helpers";
import { listProvinceTypes } from "../../graphql/queries";
import PageLoader from "../../Components/PageLoader";

type selectIndustryType = {
  value: Industry;
  label: string;
};

type Props = {
  roles?: UserRole[];
  companyData?: any;
  editCompany?: boolean;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  loading: boolean;
  error: Error;
  readOnly: boolean;
  archived: boolean;
  id: string;
  name: string;
  emailAddress: string;
  contactNumber: string;
  address: string;
  industry?: Industry | null | undefined;
  numberOfEmployees?: string;
  editCompany: boolean;
  province: any;
  suburb: any;
  city: any;
  companyAreaId: string;
  companyInterests: any;
  selectedInterests: any;
};

const ToggleButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

class CompanyView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { companyData } = props;
    this.state = {
      loading: false,
      error: null,
      readOnly: this.props.editCompany ? false : true,
      name: companyData && companyData.name ? companyData.name : "",
      id: companyData && companyData.id ? companyData.id : "",
      emailAddress:
        companyData && companyData.emailAddress ? companyData.emailAddress : "",
      contactNumber:
        companyData && companyData.contactNumber
          ? companyData.contactNumber
          : "",
      address: companyData && companyData.address ? companyData.address : "",
      province: "",
      city: "",
      suburb: "",
      companyAreaId:
        companyData && companyData.companyAreaId
          ? companyData.companyAreaId
          : "",
      numberOfEmployees:
        companyData && companyData.numberOfEmployees
          ? companyData.numberOfEmployees
          : "",
      industry:
        companyData && companyData.industry ? companyData.industry : null,
      companyInterests:
        companyData && companyData.interests ? companyData.interests : null,
      selectedInterests:
        companyData && companyData.interests
          ? this.formartCompanyInterests(companyData.interests)
          : null,
      archived: false,
      editCompany: this.props.editCompany || false
    };
  }

  /** Validation */
  validateForm = (): boolean => {
    return true;
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  formartCompanyInterests = (companyInterests: any) => {
    return companyInterests.items
      ? companyInterests.items.map((item: any) => ({
          value: item.interests.id,
          label: item.interests.name
        }))
      : null;
  };

  handleProvinceChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ province: e });
  };

  handleCityChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ city: e });
  };

  handleSuburbChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ suburb: e });
  };

  /** Edit a company */
  editCompany = async (
    // after react scripts changes
    // eslint-disable-next-line
    updateCompanyMutation: ({}) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    createReportCategoryMutation: ({}) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    deleteCompanyInterestMutation: ({}) => Promise<any>
  ) => {
    const { closeModal, notification } = this.props;
    const {
      name,
      id,
      emailAddress,
      contactNumber,
      address,
      suburb,
      numberOfEmployees,
      industry,
      companyInterests,
      selectedInterests
    } = this.state;
    this.setState({ loading: true });
    // update the company in dynamodb
    updateCompanyMutation({
      variables: {
        input: {
          id,
          name,
          emailAddress,
          contactNumber,
          address,
          numberOfEmployees,
          industry,
          companyAreaId: suburb.value
        }
      }
    })
      .then(() => {
        const removedInterests = companyInterests.items.filter(
          (item: any) =>
            !selectedInterests
              .map((interest: any) => interest.value)
              .includes(item.interests.id)
        );
        removedInterests.map(async (removedInterest: any) => {
          await deleteCompanyInterestMutation({
            variables: {
              input: {
                id: removedInterest.id
              }
            }
          });
        });
        const newCompanyInterests = selectedInterests.filter(
          (item: any) =>
            !this.state.companyInterests.items
              .map(
                (interest: any) => interest.interests && interest.interests.id
              )
              .includes(item.value)
        );
        newCompanyInterests.map(async (interest: any) => {
          return await createReportCategoryMutation({
            variables: {
              input: {
                companyInterestCompanyId: id,
                companyInterestInterestsId: interest.value
              }
            },
            refetchQueries: [
              {
                query: gql(listInterestsWithCompanys),
                filter: { company: id }
              }
            ]
          }).catch(err => {
            this.setState({ loading: false });
            this.setError(err.message);
          });
        });
        this.setState({ loading: false });
        closeModal();
        notification(`Updated ${name} details`);
      })
      .catch(err => {
        this.setState({ loading: false });
        this.setError(err.message);
      });
  };

  toggleEdit = () => {
    this.setState({ readOnly: !this.state.readOnly });
  };

  toggleArchived = () => {
    this.setState({ archived: !this.state.archived });
  };

  selectInterests = (interests: any): void => {
    this.setState({ selectedInterests: interests });
  };

  // format constants into dropdowns for provinces
  listOfProvinces = (consts: object) => {
    return Object.keys(consts).map((item: string) => {
      return formatDropdown(item);
    });
  };

  // select company industry
  // after react scripts changes.
  // @ts-ignore 
  selectIndustry = (industry: selectIndustryType): void => {
    this.setState({ industry: industry.value });
  };

  // format constants into dropdowns for insdustries
  listOfIndustries = (consts: object) => {
    return Object.keys(consts).map((item: string) => {
      return formatDropdown(item);
    });
  };

  returnFormFields = () => {
    const {
      id,
      name,
      emailAddress,
      contactNumber,
      address,
      province,
      city,
      suburb,
      numberOfEmployees,
      industry,
      error,
      readOnly,
      archived,
      selectedInterests
    } = this.state;

    return (
      <React.Fragment>
        <ToggleButtonsContainer>
          <FormGroup>
            {readOnly ? (
              <Row>
                <Col xs={4} md={4} lg={4}>
                  <StyledButton
                    type="button"
                    label="Edit"
                    width="100px"
                    onClick={() => this.toggleEdit()}
                    color={Colors.coal}
                    background={Colors.background}
                  />
                </Col>
                {!this.state.editCompany ? (
                  <Col xs={4} md={4} lg={4}>
                    <Link
                      to={{
                        pathname: "/companies/team",
                        // @ts-ignore
                        params: {
                          containerTitle: name + " Team members",
                          companyData: this.props.companyData
                        }
                      }}
                      label={
                        <StyledButton
                          type="button"
                          label="View Team"
                          width="100px"
                          color={Colors.coal}
                          background={Colors.background}
                        />
                      }
                    />
                  </Col>
                ) : null}
              </Row>
            ) : (
              <FormGroup>
                <Row>
                  <Col xs={4} md={4} lg={4}>
                    <StyledButton
                      type="submit"
                      label={!this.state.loading ? "Save" : <Loader />}
                      width="100px"
                      color={Colors.coal}
                      background={Colors.background}
                    />
                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <StyledButton
                      type="button"
                      label="Cancel"
                      width="100px"
                      onClick={() =>
                        this.state.editCompany
                          ? this.props.closeModal()
                          : this.toggleEdit()
                      }
                      color={Colors.coal}
                      background={Colors.background}
                    />
                  </Col>
                  {!this.state.editCompany ? (
                    <Col xs={4} md={4} lg={4}>
                      <Link
                        to={{
                          pathname: "/companies/team",
                          // @ts-ignore
                          params: {
                            containerTitle: name + " Team members",
                            companyData: this.props.companyData
                          }
                        }}
                        label={
                          <StyledButton
                            type="button"
                            label="View Team"
                            width="100px"
                            color={Colors.coal}
                            background={Colors.background}
                          />
                        }
                      />
                    </Col>
                  ) : null}
                </Row>
              </FormGroup>
            )}
          </FormGroup>
        </ToggleButtonsContainer>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                value={name}
                id="name"
                placeholder="Name"
                onChange={e => this.setState({ name: e.target.value })}
                disabled={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="emailAddress">Email Address</Label>
              <Input
                type="email"
                name="emailAddress"
                value={emailAddress}
                id="emailAddress"
                placeholder="Email"
                onChange={e => this.setState({ emailAddress: e.target.value.toLowerCase() })}
                disabled={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="contactNumber">Contact Number</Label>
              <Input
                type="tel"
                name="contactNumber"
                value={contactNumber}
                id="contactNumber"
                placeholder="contactNumber"
                onChange={e => this.setState({ contactNumber: e.target.value })}
                disabled={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="numberOfEmployees">Number of Employees</Label>
              <Input
                type="tel"
                name="numberOfEmployees"
                value={numberOfEmployees}
                id="numberOfEmployees"
                placeholder="numberOfEmployees"
                onChange={e =>
                  this.setState({ numberOfEmployees: e.target.value })
                }
                disabled={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label>Industry</Label>
              <Select
                options={this.listOfIndustries(Industry)}
                // @ts-ignore after react scripts changes.
                onChange={this.selectIndustry}
                value={formatDropdown(industry)}
                placeholder=""
                className="select-styling"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="address">Address</Label>
              <Input
                type="text"
                name="address"
                value={address}
                id="address"
                placeholder="Address"
                onChange={e => this.setState({ address: e.target.value })}
                disabled={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label>Interests & Objectives</Label>
              <Query query={gql(listInterests)}>
                {({ loading, data }: any) => {
                  if (loading) {
                    return (
                      <Select
                        placeholder="Loading..."
                        className="select-styling"
                      />
                    );
                  }
                  if (data) {
                    const interestsList = data.listInterests.items.map(
                      (item: any) => ({ value: item.id, label: item.name })
                    );
                    return (
                      <Select
                        isMulti={true}
                        isSearchable={true}
                        isClearable={true}
                        options={interestsList}
                        onChange={this.selectInterests}
                        value={selectedInterests}
                        className="select-styling"
                      />
                    );
                  } else {
                    return <Label>{error}</Label>;
                  }
                }}
              </Query>
            </FormGroup>
          </Col>
        </Row>
        <Query query={gql(listProvinceTypes)} variables={{ limit: 1000 }}>
          {({ loading, data }: any) => {
            if (loading) {
              return <PageLoader />;
            }
            if (!loading && data) {
              const provinceList = data.listProvinceTypes.items.map(
                (prov: any) => ({ ...prov, value: prov.id, label: prov.name })
              );
              return (
                <React.Fragment>
                  <Row>
                    <Col sm={6}>
                      <Label>Province</Label>
                      <Select
                        options={provinceList}
                        // @ts-ignore after react scripts changes.
                        onChange={this.handleProvinceChange}
                        value={province}
                        placeholder=""
                        className="select-styling"
                        isDisabled={readOnly}
                      />
                    </Col>
                    <Col sm={6}>
                      <Label for="city">City</Label>
                      <Select
                        options={
                          this.state.province === "" || !this.state.province.id
                            ? []
                            : this.state.province.cities.items.map(
                                (cit: any) => ({
                                  ...cit,
                                  value: cit.id,
                                  label: cit.name
                                })
                              )
                        }
                        // @ts-ignore after react scripts changes.
                        onChange={this.handleCityChange}
                        value={city}
                        placeholder={
                          this.state.province === ""
                            ? "Please select a province first."
                            : ""
                        }
                        className="select-styling"
                        isDisabled={readOnly}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={6}>
                      <Label>Area</Label>
                      <Select
                        options={
                          this.state.city === "" || !this.state.city.id
                            ? []
                            : this.state.city.areas.items.map((area: any) => ({
                                value: area.id,
                                label: area.name
                              }))
                        }
                        // @ts-ignore after react scripts changes.
                        onChange={this.handleSuburbChange}
                        value={suburb}
                        placeholder={
                          this.state.city === ""
                            ? "Please select a city first."
                            : ""
                        }
                        className="select-styling"
                        isDisabled={readOnly}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              );
            } else {
              return <div>There has been an error loading provinces</div>;
            }
          }}
        </Query>
        <Row>
          <Col xs={6} md={6} lg={6}>
            <ToggleButtonsContainer>
              {id !== "" && getUserRole() !== "CONSUMER_ADMIN" ? (
                <React.Fragment>
                  <Label>{archived ? "Unarchive" : "Archive"} company?</Label>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      <FormGroup>
                        <TableHeaderContainer>
                          <StyledButton
                            type="button"
                            label="Yes"
                            width="100px"
                            onClick={() => this.toggleArchived()}
                            color={archived ? Colors.snow : Colors.coal}
                            background={
                              archived ? Colors.coralPink : Colors.background
                            }
                          />
                        </TableHeaderContainer>
                      </FormGroup>
                    </Col>
                  </Row>
                </React.Fragment>
              ) : null}
            </ToggleButtonsContainer>
          </Col>
        </Row>
        <br />
        {error && <ErrorMessage errorMessage={error} />}
      </React.Fragment>
    );
  };

  render() {
    return (
      <Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>
        mutation={gql(updateCompany)}
      >
        {updateCompanyMutation => (
          <Mutation<
            CreateCompanyInterestMutation,
            CreateCompanyInterestMutationVariables
          >
            mutation={gql(createCompanyInterest)}
          >
            {createCompanyInterestMutation => (
              <Mutation<
                DeleteCompanyInterestMutation,
                DeleteCompanyInterestMutationVariables
              >
                mutation={gql(deleteCompanyInterest)}
              >
                {deleteCompanyInterestMutation => (
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      if (this.validateForm()) {
                        this.editCompany(
                          updateCompanyMutation,
                          createCompanyInterestMutation,
                          deleteCompanyInterestMutation
                        );
                      }
                    }}
                  >
                    {this.returnFormFields()}
                  </Form>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export default CompanyView;
