const colors = {
  snow: "white",
  coal: "#464E53",
  whiteSmoke: "#f7f7f8",
  lightGray: "#D3D3D3",
  grey: "#4f565d",
  darkGrey: "#232c34",
  transparent: "rgba(0,0,0,0)",
  transparentWhite: "rgba(255,255,255,0.1)",
  error: "rgba(239, 65, 35, 0.8)",
  overlayBlack: "rgba(0,0,0,0.4)",
  semiBlack: "rgba(0,0,0,0.5)",
  primary: "#22d1b4",
  secondary: "#00927A",
  background: "#FFFFFF",
  coralPink: "#ff6a70",
  default: "#5bbeab",
  dashboardGrey: "#f2f5fa"
};

export default colors;
