import * as React from "react";
import styled from "styled-components";

// Themes
import { Colors } from "../../Themes";

// Custom types
import { Error } from "../../CustomTypes";

type ErrorMessageProps = {
  errorMessage: Error;
};

const ErrorMessage = styled.p`
  text-align: center;
  margin-top: 10px;
  color: ${Colors.error};
`;

const ErrorMessageContainer: React.SFC<ErrorMessageProps> = (
  props: ErrorMessageProps
) => {
  return <ErrorMessage>{props.errorMessage}</ErrorMessage>;
};

export default ErrorMessageContainer;
