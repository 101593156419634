import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withToastManager } from "react-toast-notifications";

/** GraphQL */
import { listVouchers } from "../../graphql/queries";
/** Presentation/UI */
import Table from "../../Components/Table";
import StyledButton from "../../Components/Styled/Button";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import PageLoader from "../../Components/PageLoader";
import {
  TableHeader,
  TableHeaderContainer
} from "../../Components/Styled/ListViewElements";
import GlobalModalContainer from "../../Components/Modal";
import Row from "../../Components/Styled/Row";

/** Themes */
import { Colors } from "../../Themes";
import { MdSearch } from "react-icons/md";

/** Custom Types */
import { ToastNotificationType } from "../../CustomTypes";
import { TableConsts } from "../../Utils/Consts";
import { sortByCreatedAt } from "../../Utils/Helpers";
import AddVoucher from "./AddVoucher";
import EditVoucher from "./EditVoucher";

type Props = {
  toastManager: ToastNotificationType;
  location: { [key: string]: any }; // object with any amount and types of params
};

type State = {
  createModal: boolean;
  editModal: boolean;
  modalData: {};
  searchFilter: boolean;
};

class VoucherManagement extends React.Component<Props> {
  state: State = {
    createModal: false,
    editModal: false,
    modalData: {},
    searchFilter: false
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance ? appearance : "success",
      autoDismiss: true
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      createModal: false,
      editModal: false
    });
  };
  /** Open modal */
  openModal = (data?: {}): void => {
    if (data) {
      this.setState({ editModal: true, modalData: data });
    } else {
      this.setState({ createModal: true });
    }
  };

  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  render() {
    const { searchFilter } = this.state;
    return (
      <BackendWrapper>
        <Query
          query={gql(listVouchers)}
          variables={{
            limit: TableConsts.limit
          }}
        >
          {({ loading, error, data }: any) => {
            if (loading) {
              return <PageLoader />;
            }

            if (error) {
              return <div>There was a problem loading your data</div>;
            }

            if (!data || !data.listVouchers) {
              return <Row>There are no Vouchers at the moment</Row>;
            }
            return (
              <React.Fragment>
                <GlobalModalContainer
                  toggleModal={this.closeModal}
                  title="Generate Voucher"
                  modalDisplay={
                    <AddVoucher
                      notification={this.toastNotification}
                      closeModal={this.closeModal}
                    />
                  }
                  modal={this.state.createModal}
                />
                <GlobalModalContainer
                  toggleModal={this.closeModal}
                  title="Edit Voucher"
                  modalDisplay={
                    <EditVoucher
                      notification={this.toastNotification}
                      closeModal={this.closeModal}
                      voucherData={this.state.modalData}
                    />
                  }
                  modal={this.state.editModal}
                />
                <TableHeaderContainer>
                  <TableHeader>
                    <span>Voucher Management</span>
                  </TableHeader>
                  <StyledButton
                    type="button"
                    label="Generate Voucher"
                    width="250px"
                    margin="0px 10px"
                    onClick={() => this.openModal()}
                    color={Colors.coal}
                    background={Colors.background}
                  />
                  <StyledButton
                    type="button"
                    label={<MdSearch size="1.3em" color={Colors.secondary} />}
                    width="auto"
                    margin="0px 10px"
                    onClick={this.toggleSearchFilter}
                    color={searchFilter ? Colors.background : Colors.coal}
                    background={
                      searchFilter ? Colors.default : Colors.background
                    }
                  />
                </TableHeaderContainer>
                <Table
                  data={sortByCreatedAt(data.listVouchers.items)}
                  getTdProps={(
                    state: any,
                    rowInfo: { [key: string]: string }
                  ) => {
                    return {
                      onClick: () => {
                        if (rowInfo) {
                          this.openModal(rowInfo.original);
                        }
                      }
                    };
                  }}
                  columns={[
                    {
                      Header: "Voucher Code",
                      accessor: "code",
                      sortable: true,
                      filterable: searchFilter
                    },
                    {
                      Header: "Discount Percentage",
                      accessor: "discountPercentage",
                      sortable: true
                    },
                    {
                      Header: "Max of uses",
                      accessor: "numberOfUses",
                      sortable: true
                    },
                    {
                      Header: "Expiry Date",
                      accessor: "expiryDate",
                      sortable: true
                    },
                    {
                      id: "status",
                      Header: "Status",
                      accessor: (item: { [key: string]: any }) => {
                        return item.archived ? "Archived" : "Active";
                      },
                      sortable: true
                    }
                  ]}
                  defaultPageSize={TableConsts.defaultRows}
                  showPaginationBottom={
                    data.listVouchers.items.length > TableConsts.defaultRows
                  }
                />
              </React.Fragment>
            );
          }}
        </Query>
        <Helmet title="Voucher Management" />
      </BackendWrapper>
    );
  }
}

export default withToastManager(VoucherManagement);
