import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withToastManager } from "react-toast-notifications";

/** GraphQL */
import {
  listReports,
  listCategorys,
  listProvinceTypes
} from "../../graphql/queries";
import {
  getCategoryReports,
  listFeaturedReports
} from "../../graphql/custom-queries";

import { ReportStatus } from "../../API";

/** Presentation/UI */
import StyledButton from "../../Components/Styled/Button";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import PageLoader from "../../Components/PageLoader";
import Link from "../../Components/Styled/Link";
import Row from "../../Components/Styled/Row";
import Card from "../../Components/Styled/Card";

/** Themes */
import { Colors } from "../../Themes";

/** Custom Types */
import { TableConsts } from "../../Utils/Consts";
import { CardTitle, CardBody, CardText, Col, Label, Input } from "reactstrap";
import Select from "react-select";
import ImageWrapper from "./ResourceWrapper";

type SelectType = {
  value: string;
  label: string;
};

// type SelectProvinceType = {
//   value: Province;
//   label: string;
// };

// type SelectSuburbType = {
//   value: Suburb;
//   label: string;
// };

type State = {
  keywordSearch: string;
  category: SelectType;
  filterData: boolean;
  categoryFilter: boolean;
  province: any;
  suburb: any;
  city: any;
};

class ConsumerReports extends React.Component<State> {
  state: State = {
    keywordSearch: "",
    province: "",
    city: "",
    suburb: "",
    category: { value: "", label: "" },
    filterData: false,
    categoryFilter: false
  };

  // Select Category
  selectCategory = (category: SelectType): void => {
    this.setState({ category });
  };

  handleProvinceChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ province: e });
  };

  handleCityChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ city: e });
  };

  handleSuburbChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ suburb: e });
  };

  // Toggle between the normal filter & category filter
  toggleFilter = () => {
    const { categoryFilter } = this.state;

    if (categoryFilter) {
      return this.showCategoryFilter();
    } else {
      return this.showFilter();
    }
  };

  // Clear the fields in the filter box
  clearSearchFilters = () => {
    return this.setState({
      keywordSearch: "",
      province: "",
      city: "",
      suburb: ""
    });
  };

  // Show filter at the top of the page & categories from server
  showFilter = () => {
    const { keywordSearch, province, city, suburb } = this.state;
    return (
      <Row>
        <Col sm={12} md={12} lg={12}>
          <Card>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <p>Filters</p>
              </Col>
            </Row>
            <Query query={gql(listProvinceTypes)} variables={{ limit: 1000 }}>
              {({ loading, data }: any) => {
                if (loading) {
                  return <PageLoader />;
                }
                if (!loading && data) {
                  const provinceList = data.listProvinceTypes.items.map(
                    (prov: any) => ({
                      ...prov,
                      value: prov.id,
                      label: prov.name
                    })
                  );
                  return (
                    <React.Fragment>
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <Label>Keyword Search</Label>
                          <Input
                            type="text"
                            name="keywordSearch"
                            value={keywordSearch}
                            id="keywordSearch"
                            placeholder="Keyword Search"
                            onChange={e =>
                              this.setState({ keywordSearch: e.target.value })
                            }
                          />
                          <br />
                        </Col>
                        <Col sm={6}>
                          <Label>Province</Label>
                          <Select
                            options={provinceList}
                            // @ts-ignore after react scripts changes.
                            onChange={this.handleProvinceChange}
                            value={province}
                            placeholder=""
                            className="select-styling"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Label for="city">City</Label>
                          <Select
                            options={
                              this.state.province === "" ||
                                !this.state.province.id
                                ? []
                                : this.state.province.cities.items.map(
                                  (cit: any) => ({
                                    ...cit,
                                    value: cit.id,
                                    label: cit.name
                                  })
                                )
                            }
                            // @ts-ignore after react scripts changes.
                            onChange={this.handleCityChange}
                            value={city}
                            placeholder={
                              this.state.province === ""
                                ? "Please select a province first."
                                : ""
                            }
                            className="select-styling"
                          />
                        </Col>
                        <Col sm={6}>
                          <Label>Area</Label>
                          <Select
                            options={
                              this.state.city === "" || !this.state.city.id
                                ? []
                                : this.state.city.areas.items.map(
                                  (area: any) => ({
                                    value: area.id,
                                    label: area.name
                                  })
                                )
                            }
                            // @ts-ignore after react scripts changes.
                            onChange={this.handleSuburbChange}
                            value={suburb}
                            placeholder={
                              this.state.city === ""
                                ? "Please select a city first."
                                : ""
                            }
                            className="select-styling"
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                } else {
                  return <div>There has been an error loading provinces</div>;
                }
              }}
            </Query>
            <br />
            <Row>
              <Col sm={12} md={12} lg={12}>
                <StyledButton
                  onClick={() =>
                    this.setState({ categoryFilter: true, filterData: false })
                  }
                  type="button"
                  label="Filter by Category"
                  width="200px"
                  color={Colors.snow}
                  background={Colors.default}
                  margin="5px"
                />
                <StyledButton
                  onClick={() => this.clearSearchFilters()}
                  type="button"
                  label="Clear Filters"
                  width="120px"
                  color={Colors.coal}
                  background={Colors.background}
                  margin="5px"
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  };

  // Switch filters so users can filter report by categories only
  showCategoryFilter = () => {
    const { category } = this.state;
    return (
      <Query query={gql(listCategorys)}>
        {({ loading, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (!loading && data) {
            const categories = data.listCategorys.items.map((cat: any) => ({
              value: cat.id,
              label: cat.name
            }));
            return (
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <Card>
                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <p>Filters</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <Label>Category</Label>
                        <Select
                          options={categories}
                          // @ts-ignore after react scripts changes.
                          onChange={this.selectCategory}
                          value={category}
                          placeholder=""
                          className="select-styling"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <StyledButton
                          onClick={() => this.setState({ filterData: true })}
                          type="button"
                          label="Filter by Category"
                          width="200px"
                          color={Colors.snow}
                          background={Colors.default}
                          margin="5px"
                        />
                        <StyledButton
                          onClick={() =>
                            this.setState({
                              categoryFilter: false,
                              filterData: false
                            })
                          }
                          type="button"
                          label="Clear Filters"
                          width="120px"
                          color={Colors.coal}
                          background={Colors.background}
                          margin="5px"
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            );
          } else {
            return <div>There has been an error loading filters</div>;
          }
        }}
      </Query>
    );
  };

  // Query unfiltered reports from the server & pass data to reportCards
  allReports = () => {
    return (
      <Query
        query={gql(listReports)}
        variables={{
          limit: TableConsts.limit,
          filter: { status: { eq: ReportStatus.PUBLISHED } }
        }}
      >
        {({ loading, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (data && data.listReports.items) {
            return this.reportCards(data.listReports);
          } else {
            return <p>There has been an error loading reports</p>;
          }
        }}
      </Query>
    );
  };

  // Query filtered reports from the server & pass data to reportCards
  filteredReports = () => {
    const { category } = this.state;
    return (
      <Query
        query={gql(getCategoryReports)}
        variables={{
          id: category.value
        }}
      >
        {({ loading, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (data) {
            return this.categoryReportCards(data.getCategory.reports);
          } else {
            return <p>There are no reports matching your query</p>;
          }
        }}
      </Query>
    );
  };

  // Show report cards from query data
  reportCards = (data: any) => {
    let filteredData = data.items;
    const { keywordSearch, province, city, suburb } = this.state;
    if (keywordSearch !== "") {
      if (province && city && suburb) {
        // after react scripts changes
        // eslint-disable-next-line
        filteredData = data.items.filter((report: any) => {
          if (report.area) {
            return (report.name
              .toLowerCase()
              .includes(keywordSearch.toLowerCase()),
              report.area.city.province.name
                .toLowerCase()
                .includes(province.name.toLowerCase()),
              report.area.city.name
                .toLowerCase()
                .includes(city.name.toLowerCase()),
              report.area.name.toLowerCase().includes(suburb.label.toLowerCase())
            )
          }
        });
      }
      if (province && city) {
        // after react scripts changes
        // eslint-disable-next-line
        filteredData = data.items.filter((report: any) => {
          if (report.area) {
            return (
              report.name
                .toLowerCase()
                .includes(keywordSearch.toLowerCase()),
              report.area.city.province.name
                .toLowerCase()
                .includes(province.name.toLowerCase()),
              report.area.city.name
                .toLowerCase()
                .includes(city.name.toLowerCase())
            );
          }
        });
      }
      if (province) {
        // after react scripts changes
        // eslint-disable-next-line
        filteredData = data.items.filter((report: any) => {
          if (report.area) {
            return (
              report.name
                .toLowerCase()
                .includes(keywordSearch.toLowerCase()),
              report.area.city.province.name
                .toLowerCase()
                .includes(province.name.toLowerCase())
            );
          }
        });
      }
      if (keywordSearch) {
        // after react scripts changes
        // eslint-disable-next-line
        filteredData = data.items.filter((report: any) => {
          if (report) {
            return (
              report.name
                .toLowerCase()
                .includes(keywordSearch.toLowerCase())
            );
          }
        });
      }
    } else {
      if (province && city && suburb) {
        // after react scripts changes
        // eslint-disable-next-line
        filteredData = data.items.filter((report: any) => {
          if (report.area) {
            return (
              report.area.city.province.name
                .toLowerCase()
                .includes(province.name.toLowerCase()),
              report.area.city.name
                .toLowerCase()
                .includes(city.name.toLowerCase()),
              report.area.name.toLowerCase().includes(suburb.label.toLowerCase())
            )
          }
        });
      }
      if (province && city) {
        // after react scripts changes
        // eslint-disable-next-line
        filteredData = data.items.filter((report: any) => {
          if (report.area) {
            return (
              report.area.city.province.name
                .toLowerCase()
                .includes(province.name.toLowerCase()),
              report.area.city.name
                .toLowerCase()
                .includes(city.name.toLowerCase())
            );
          }
        });
      }
      if (province) {
        // after react scripts changes
        // eslint-disable-next-line
        filteredData = data.items.filter((report: any) => {
          if (report.area) {
            return (
              report.area.city.province.name
                .toLowerCase()
                .includes(province.name.toLowerCase())
            );
          }
        });
      }
    }


    const reports = filteredData.map((report: any) => (
      <Col style={{ paddingTop: "10px" }} sm={12} md={4} lg={4} key={report.id}>
        <Card>
          <ImageWrapper resourceKey={report.coverImageUrl} />
          <CardBody>
            <CardText
              style={{
                height: "35px",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {report.name}
            </CardText>
            <CardTitle>R {report.price}</CardTitle>
            <CardText
              style={{
                height: "110px",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {report.description}...
            </CardText>
          </CardBody>
          <Row paddingBottom={"10"}>
            <Col>
              <Link
                to={{
                  pathname: `/consumer/report/${report.id}`
                }}
                label={
                  <StyledButton
                    type="button"
                    label="View"
                    width="70px"
                    color={Colors.snow}
                    background={Colors.default}
                    margin="5px"
                  />
                }
              />
              <Link
                to={{
                  pathname: `/consumer/checkout/${report.id}`
                }}
                label={
                  <StyledButton
                    type="button"
                    label="Buy"
                    width="70px"
                    color={Colors.snow}
                    background={Colors.default}
                    margin="5px"
                  />
                }
              />
            </Col>
          </Row>
        </Card>
      </Col>
    ));
    return (
      <Row paddingTop={"20"}>
        <Col sm={12} md={12} lg={12}>
          All Reports
        </Col>
        {reports}
      </Row>
    );
  };

  featuredReports = () => {
    return (
      <Query
        query={gql(listFeaturedReports)}
        variables={{
          limit: 3,
          filter: { status: { eq: "PUBLISHED" } }
        }}
      >
        {({ loading, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (data && data.listReports.items) {
            return this.listFeaturedReports(data.listReports);
          } else {
            return;
          }
        }}
      </Query>
    );
  };

  listFeaturedReports = (reports: any) => {
    const fReports = reports.items.map((report: any) => {
      if (report.companys.items.length.toString() > 3) {
        return (
          <Col
            style={{ paddingTop: "10px" }}
            sm={12}
            md={4}
            lg={4}
            key={report.id}
          >
            <Card>
              <ImageWrapper resourceKey={report.coverImageUrl} />
              <CardBody>
                <CardText
                  style={{
                    height: "35px",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  {report.name}
                </CardText>
                <CardTitle>R {report.price}</CardTitle>
                <CardText
                  style={{
                    height: "110px",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  {report.description}...
                </CardText>
              </CardBody>
              <Row paddingBottom={"10"}>
                <Col>
                  <Link
                    to={{
                      pathname: `/consumer/report/${report.id}`
                    }}
                    label={
                      <StyledButton
                        type="button"
                        label="View"
                        width="70px"
                        color={Colors.snow}
                        background={Colors.default}
                        margin="5px"
                      />
                    }
                  />
                  <Link
                    to={{
                      pathname: `/consumer/checkout/${report.id}`
                    }}
                    label={
                      <StyledButton
                        type="button"
                        label="Buy"
                        width="70px"
                        color={Colors.snow}
                        background={Colors.default}
                        margin="5px"
                      />
                    }
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        );
      } else {
        // after react scripts changes
        // eslint-disable-next-line
        return;
      }
    });
    return (
      <Row paddingTop={"20"}>
        <Col sm={12} md={12} lg={12}>
          Featured Reports
        </Col>
        {fReports}
      </Row>
    );
  };

  // Show report cards filtered through categories
  categoryReportCards = (data: any) => {
    const reports = data.items.map((report: any) => {
      if (report.report && report.report.status === ReportStatus.PUBLISHED) {
        return (
          <Col
            style={{ paddingTop: "10px" }}
            sm={12}
            md={4}
            lg={4}
            key={report.report.id}
          >
            <Card>
              <ImageWrapper resourceKey={report.report.coverImageUrl} />
              <CardBody>
                <CardText>{report.report.name}</CardText>
                <CardTitle>R {report.report.price}</CardTitle>
                <CardText>{report.report.description}</CardText>
              </CardBody>
              <Row paddingBottom={"10"}>
                <Col>
                  <Link
                    to={{
                      pathname: `/consumer/report/${report.report.id}`
                    }}
                    label={
                      <StyledButton
                        type="button"
                        label="View"
                        width="70px"
                        color={Colors.snow}
                        background={Colors.default}
                        margin="5px"
                      />
                    }
                  />
                  <Link
                    to={{
                      pathname: `/consumer/checkout/${report.report.id}`
                    }}
                    label={
                      <StyledButton
                        type="button"
                        label="Buy"
                        width="70px"
                        color={Colors.snow}
                        background={Colors.default}
                        margin="5px"
                      />
                    }
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        );
      } else {
        // after react scripts changes
        // eslint-disable-next-line
        return;
      }
    });
    return (
      <Row paddingTop={"20"}>
        <Col sm={12} md={12} lg={12}>
          Filtered Reports
        </Col>
        {reports}
      </Row>
    );
  };

  render() {
    const { filterData } = this.state;
    if (filterData) {
      return (
        <BackendWrapper>
          {this.toggleFilter()}
          {this.filteredReports()}
          <Helmet title="Reports" />
        </BackendWrapper>
      );
    } else {
      return (
        <BackendWrapper>
          {this.toggleFilter()}
          {this.featuredReports()}
          {this.allReports()}
          <Helmet title="Reports" />
        </BackendWrapper>
      );
    }
  }
}
export default withToastManager(ConsumerReports);
