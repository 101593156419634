import * as React from "react";
import styled from "styled-components";

interface IProps {
  children: React.ReactNode;
  align?: "right" | "center" | "left";
}

const Container = styled.div`
  width: 100%;
  text-align: ${(props: { align?: string }) =>
    props.align ? `${props.align}` : "auto"};
`;

const FullWidthContainer: React.SFC<IProps> = (props: IProps) => {
  return <Container align={props.align}>{props.children}</Container>;
};

export default FullWidthContainer;
