import * as React from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withToastManager } from "react-toast-notifications";

/** Presentation/UI */
import Table from "../../Components/Table";
import StyledButton from "../../Components/Styled/Button";
import Link from "../../Components/Styled/Link";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import PageLoader from "../../Components/PageLoader";
import {
  TableHeader,
  TableHeaderContainer
} from "../../Components/Styled/ListViewElements";
import { MdSearch } from "react-icons/md";
import Logo from "../../Components/Layouts/LogoWrapper";

/** Themes */
import { Colors, Images } from "../../Themes";

/** Custom Types */
import { ToastNotificationType } from "../../CustomTypes";
import { TableConsts } from "../../Utils/Consts";
import { RouteComponentProps } from "react-router";
import GlobalModalContainer from "../../Components/Modal";
import { Label } from "reactstrap";
import {
  ReportStatus,
  UpdateReportMutation,
  UpdateReportMutationVariables
} from "../../API";
import { updateReport } from "../../graphql/mutations";
import { sortByCreatedAt } from "../../Utils/Helpers";
import { listReports } from "../../graphql/queries";

type Props = RouteComponentProps & {
  toastManager: ToastNotificationType;
};

type State = {
  importModal: boolean;
  searchFilter: boolean;
  selected: any;
  modal: boolean;
  archiveId: string;
};

class Reports extends React.Component<Props> {
  state: State = {
    importModal: false,
    searchFilter: false,
    selected: null,
    modal: false,
    archiveId: ""
  };

  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance ? appearance : "success",
      autoDismiss: true
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      modal: false,
      archivedId: ""
    });
  };

  // after react scripts changes
  // eslint-disable-next-line
  archiveReport = (updateReportStatus: ({ }) => Promise<any>) => {
    updateReportStatus({
      variables: {
        input: {
          id: this.state.archiveId,
          status: ReportStatus.ARCHIVED
        }
      },
      refetchQueries: [
        {
          query: gql(listReports),
          variables: { limit: TableConsts.limit }
        }
      ]
    })
      .then(() => {
        this.closeModal();
      })
      .catch((err: any) => {
        this.setState({ error: err.message }, () => {
          setTimeout(() => {
            this.setState({ error: null });
          }, 3000);
        });
      });
  };

  render() {
    const { searchFilter } = this.state;
    return (
      <BackendWrapper>
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title={"Archive report "}
          modalDisplay={
            <React.Fragment>
              <Label style={{ paddingRight: "20px" }}>
                Are you sure you want to archive this report?
              </Label>
              <Mutation<UpdateReportMutation, UpdateReportMutationVariables>
                mutation={gql(updateReport)}
              >
                {updateReportMutation => (
                  <StyledButton
                    type="button"
                    label="Archive"
                    onClick={() => {
                      this.archiveReport(updateReportMutation);
                    }}
                    width="120px"
                    color={Colors.coal}
                    background={Colors.background}
                  />
                )}
              </Mutation>
            </React.Fragment>
          }
          modal={this.state.modal}
        />
        <Query
          query={gql(listReports)}
          variables={{ limit: TableConsts.limit }}
        >
          {({ loading, error, data }: any) => {
            if (loading) {
              return <PageLoader />;
            }
            if (error) {
              return <div>There was a problem loading your data</div>;
            }

            if (!data || !data.listReports) {
              return <div>There are no Reports at the moment</div>;
            }

            return (
              <React.Fragment>
                <TableHeaderContainer>
                  <TableHeader>
                    <span>Manage Reports</span>
                  </TableHeader>
                  <Link
                    to={{
                      pathname: "/admin-reports/add",
                      // @ts-ignore
                      params: {
                        notification: this.toastNotification
                      }
                    }}
                    label={
                      <StyledButton
                        type="button"
                        label="Create Report"
                        width="120px"
                        color={Colors.coal}
                        background={Colors.background}
                      />
                    }
                  />
                  <StyledButton
                    type="button"
                    label={<MdSearch size="1.3em" color={Colors.secondary} />}
                    width="auto"
                    margin="0px 10px"
                    onClick={this.toggleSearchFilter}
                    color={searchFilter ? Colors.background : Colors.coal}
                    background={
                      searchFilter ? Colors.default : Colors.background
                    }
                  />
                </TableHeaderContainer>
                <Table
                  data={sortByCreatedAt(data.listReports.items)}
                  getTdProps={(state: any, rowInfo: any) => {
                    return {
                      onClick: (e: any) => {
                        if (rowInfo) {
                          if (
                            rowInfo.original.status === "DRAFT" ||
                            rowInfo.original.status === "PUBLISHED"
                          ) {
                            this.props.history.push({
                              pathname: "/admin-reports/edit",
                              state: rowInfo.original
                            });
                          }
                        }
                      }
                    };
                  }}
                  columns={[
                    {
                      id: "logo",
                      Header: "",
                      accessor: (item: { [key: string]: any }) => {
                        return <Logo image={Images.pdfIcon} width="20px" />;
                      }
                    },
                    {
                      Header: "Report Name",
                      accessor: "name",
                      sortable: true,
                      filterable: searchFilter
                    },
                    {
                      id: "numberOfCategories",
                      Header: "Number of categories",
                      accessor: (item: { [key: string]: any }) => {
                        return item.categories.items.length > 0
                          ? item.categories.items.length
                          : "-";
                      },
                      sortable: true
                    },
                    {
                      id: "price",
                      Header: "Price",
                      accessor: (item: { [key: string]: any }) => {
                        return item.price ? "R" + item.price : "-";
                      },
                      sortable: true
                    },
                    {
                      Header: "Published Date",
                      accessor: "publishedDate",
                      sortable: false,
                      filterable: false
                    },
                    {
                      id: "purchases",
                      Header: "Purchases",
                      accessor: (item: { [key: string]: any }) => {
                        return item.companys.items
                          ? item.companys.items.length
                          : 0;
                      },
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      sortable: true,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={TableConsts.defaultRows}
                  showPaginationBottom={
                    data.listReports.items.length > TableConsts.defaultRows
                  }
                />
              </React.Fragment>
            );
          }}
        </Query>
        <Helmet title="Manage Reports" />
      </BackendWrapper>
    );
  }
}

export default withToastManager(Reports);
