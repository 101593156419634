import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withToastManager } from "react-toast-notifications";

/** Presentation/UI */
import Table from "../../Components/Table";
import StyledButton from "../../Components/Styled/Button";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import PageLoader from "../../Components/PageLoader";
import {
  TableHeader,
  TableHeaderContainer
} from "../../Components/Styled/ListViewElements";
import GlobalModalContainer from "../../Components/Modal";
import { Input } from "reactstrap";

/** Themes */
import { Colors } from "../../Themes";

/** Custom Types */
import { ToastNotificationType } from "../../CustomTypes";
import { formatTitle, sortByCreatedAt } from "../../Utils/Helpers";
import AddEdit from "./AddEdit";
import { TableConsts } from "../../Utils/Consts";
import {
  listProvinceTypes,
  listCityTypes,
  listAreaTypes
} from "../../graphql/queries";

type Props = {
  toastManager: ToastNotificationType;
  location: { [key: string]: any }; // object with any amount and types of params
};

type State = {
  importModal: boolean;
  selected: any;
  containerType: string;
  modal: boolean;
  searchData: string;
};

class LocationManagement extends React.Component<Props> {
  state: State = {
    importModal: false,
    selected: null,
    containerType: this.props.location.pathname,
    modal: false,
    searchData: ""
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance ? appearance : "success",
      autoDismiss: true
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };
  /** Open modal */
  openModal = (data?: any): void => {
    this.setState({
      modal: true,
      selected: data
    });
  };

  areaManagement = () => {
    const { searchData } = this.state;
    return (
      <Query
        query={gql(listAreaTypes)}
        variables={{ limit: TableConsts.limit }}
      >
        {({ loading, error, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (error) {
            return <div>There was a problem loading your data</div>;
          }

          if (!data || !data.listAreaTypes) {
            return <div>There are no areas at the moment</div>;
          }

          let filteredData = data.listAreaTypes.items;
          filteredData = filteredData.filter((row: any) => {
            return row.name.toLowerCase().includes(searchData.toLowerCase());
          });

          return (
            <Table
              data={sortByCreatedAt(filteredData)}
              getTdProps={(state: any, rowInfo: { [key: string]: string }) => {
                return {
                  onClick: (e: any) => {
                    if (rowInfo) {
                      this.openModal(rowInfo.original);
                    }
                  }
                };
              }}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                  sortable: true
                },
                {
                  id: "city",
                  Header: "City",
                  accessor: (area: any) => {
                    return area.city.name;
                  },
                  sortable: true
                },
                {
                  id: "province",
                  Header: "Province",
                  accessor: (city: any) => {
                    return city.city.province.name;
                  },
                  sortable: true
                }
              ]}
              defaultPageSize={TableConsts.defaultRows}
              showPaginationBottom={
                data.listAreaTypes.items.length > TableConsts.defaultRows
              }
            />
          );
        }}
      </Query>
    );
  };

  cityManagement = () => {
    const { searchData } = this.state;
    return (
      <Query
        query={gql(listCityTypes)}
        variables={{ limit: TableConsts.limit }}
      >
        {({ loading, error, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (error) {
            return <div>There was a problem loading your data</div>;
          }

          if (!data || !data.listCityTypes) {
            return <div>There are no cities at the moment</div>;
          }

          let filteredData = data.listCityTypes.items;
          filteredData = filteredData.filter((row: any) => {
            return row.name.toLowerCase().includes(searchData.toLowerCase());
          });

          return (
            <Table
              data={sortByCreatedAt(filteredData)}
              getTdProps={(state: any, rowInfo: { [key: string]: string }) => {
                return {
                  onClick: (e: any) => {
                    if (rowInfo) {
                      this.openModal(rowInfo.original);
                    }
                  }
                };
              }}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                  sortable: true
                },
                {
                  id: "province",
                  Header: "Province",
                  accessor: (city: any) => {
                    return city.province.name;
                  },
                  sortable: true
                },
                {
                  id: "numberOfAreas",
                  Header: "Number of Areas",
                  accessor: (city: any) => {
                    return city.areas.items.length;
                  },
                  sortable: true
                }
              ]}
              defaultPageSize={TableConsts.defaultRows}
              showPaginationBottom={
                data.listCityTypes.items.length > TableConsts.defaultRows
              }
            />
          );
        }}
      </Query>
    );
  };

  provinceManagement = () => {
    const { searchData } = this.state;
    return (
      <Query
        query={gql(listProvinceTypes)}
        variables={{ limit: TableConsts.limit }}
      >
        {({ loading, error, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (error) {
            return <div>There was a problem loading your data</div>;
          }

          if (!data || !data.listProvinceTypes) {
            return <div>There are no Interests at the moment</div>;
          }

          let filteredData = data.listProvinceTypes.items;
          filteredData = filteredData.filter((row: any) => {
            return row.name.toLowerCase().includes(searchData.toLowerCase());
          });

          return (
            <Table
              data={sortByCreatedAt(filteredData)}
              getTdProps={(state: any, rowInfo: { [key: string]: string }) => {
                return {
                  onClick: (e: any) => {
                    if (rowInfo) {
                      this.openModal(rowInfo.original);
                    }
                  }
                };
              }}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                  sortable: true
                },
                {
                  id: "numberOfCities",
                  Header: "Number of cities",
                  accessor: (province: any) => {
                    return province.cities.items.length;
                  },
                  sortable: true
                }
              ]}
              defaultPageSize={TableConsts.defaultRows}
              showPaginationBottom={
                data.listProvinceTypes.items.length > TableConsts.defaultRows
              }
            />
          );
        }}
      </Query>
    );
  };

  render() {
    const { containerType, selected, modal, searchData } = this.state;
    return (
      <BackendWrapper>
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title={`Add ${
            containerType === "/province-management"
              ? "province"
              : containerType === "/city-management"
              ? "city"
              : "area"
          }`}
          modalDisplay={
            <AddEdit
              notification={this.toastNotification}
              closeModal={this.closeModal}
              data={selected}
              containerType={
                containerType === "/province-management"
                  ? "province"
                  : containerType === "/city-management"
                  ? "city"
                  : "area"
              }
            />
          }
          modal={modal}
        />
        <TableHeaderContainer>
          <TableHeader>
            <span>{formatTitle(containerType)}</span>
          </TableHeader>
          <StyledButton
            type="button"
            label={`Add ${
              containerType === "/province-management"
                ? "province"
                : containerType === "/city-management"
                ? "city"
                : "area"
            }`}
            width="250px"
            margin="0px 10px"
            onClick={() => this.openModal()}
            color={Colors.coal}
            background={Colors.background}
          />
          <Input
            type="text"
            name="name"
            style={{ height: "40px", width: "300px" }}
            value={searchData}
            id="name"
            placeholder="Search"
            onChange={e => this.setState({ searchData: e.target.value })}
          />
        </TableHeaderContainer>
        {containerType === "/province-management"
          ? this.provinceManagement()
          : containerType === "/city-management"
          ? this.cityManagement()
          : this.areaManagement()}
        <Helmet title={formatTitle(containerType) || undefined} />
      </BackendWrapper>
    );
  }
}

export default withToastManager(LocationManagement);
