import * as React from "react";
import {
  MdPeople,
  MdInsertDriveFile,
  MdAttachMoney,
  MdFavorite,
  MdQuestionAnswer
} from "react-icons/md";

/** Custom types */
import { NavItemType } from "../CustomTypes";

export const ConsumerAdmin: Array<NavItemType> = [
  {
    to: "/consumer/team",
    role: "CONSUMER_ADMIN",
    title: "Team",
    icon: <MdPeople size="1.3em" />
  },
  {
    to: "/consumer/reports",
    role: "CONSUMER_ADMIN",
    title: "Reports",
    icon: <MdInsertDriveFile size="1.3em" />
  },
  {
    to: "/consumer/purchased",
    role: "CONSUMER_ADMIN",
    title: "Purchased",
    icon: <MdAttachMoney size="1.3em" />
  },
  {
    to: "/consumer/account",
    role: "CONSUMER_ADMIN",
    title: "Account",
    icon: <MdFavorite size="1.3em" />
  },
  {
    to: "/consumer/about-roots",
    role: "CONSUMER_ADMIN",
    title: "About Roots",
    icon: <MdQuestionAnswer size="1.3em" />
  }
];
