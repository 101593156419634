import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { UserRole, CreateCompanyInput } from "../../API";

import { Label, Input } from "reactstrap";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import StyledButton from "../../Components/Styled/Button";
import Link from "../../Components/Styled/Link";
import { Col, Row } from "reactstrap";

/** Custom Types */
import { Error } from "../../CustomTypes";

import { getUsersCompany } from "../../graphql/custom-queries";
import { Colors } from "../../Themes";
import { getUserId, formatString } from "../../Utils/Helpers";
import styled from "styled-components";
import PageLoader from "../../Components/PageLoader";
import CompanyView from "../Companies/CompanyView";

import GlobalModalContainer from "../../Components/Modal";

import { ToastNotificationType } from "../../CustomTypes";

import { withToastManager } from "react-toast-notifications";
import Card from "../../Components/Styled/Card";
import { MdDeleteForever } from "react-icons/md";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import Select from "react-select";
import AddCardModal from "./AddCardModal";
import DeleteCardModal from "./DeleteCardModal";

type Props = {
  roles?: UserRole[];
  modalData?: CreateCompanyInput | null;
  toastManager: ToastNotificationType;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  loading: boolean;
  error: Error;
  readOnly: boolean;
  editCompanyModal: boolean;
  addCardModal: boolean;
  deleteCardModal: boolean;
  modalData: any;
  companyInterests: any;
  id: string;
};

const ToggleButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1%;
  padding-bottom: 1%;
  align-items: center;
`;

const BankCardList = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2% 2% 0 2%;
  margin-bottom: 2px;
  justify-content: space-between;
  background-color: #e9ecef;
  font-size: 14px;
  margin-left: -15px;
`;

class CompanyDetails extends React.Component<Props, State> {
  state: State = {
    loading: false,
    error: null,
    readOnly: true,
    editCompanyModal: false,
    addCardModal: false,
    deleteCardModal: false,
    modalData: {},
    companyInterests: null,
    id: ""
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  // Open edit company modal with company details
  editCompanyModal = (data: {}, interests: []) => {
    this.setState({
      editCompanyModal: true,
      modalData: data,
      companyInterests: interests
    });
  };

  // Open delete card modal with id of the card
  deleteCardModal = (data: string) => {
    this.setState({ deleteCardModal: true, modalData: data });
  };

  // Open add card modal with id of the card
  addCardModal = (data: string) => {
    this.setState({ addCardModal: true, modalData: data });
  };
  updateCompantInterest = (data: any) => {
    this.setState({ companyInterests: data });
  };
  /** Close modal */
  closeModal = (): void => {
    this.setState({
      editCompanyModal: false,
      deleteCardModal: false,
      addCardModal: false
    });
  };

  formartCompanyInterests = (companyInterests: any) => {
    return companyInterests.items
      ? companyInterests.items.map((item: any) =>
          item.interests
            ? { value: item.interests.id, label: item.interests.name }
            : null
        )
      : null;
  };

  returnFormFields = (companyData: any) => {
    const {
      id,
      name,
      emailAddress,
      address,
      contactNumber,
      numberOfEmployees,
      industry,
      interests
    } = companyData;
    const { error, readOnly, companyInterests } = this.state;
    const companyProvince = companyData.area
      ? {
          value: companyData.area.city.province.id,
          label: companyData.area.city.province.name
        }
      : "";
    return (
      <BackendWrapper>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <h3>Company Account Details</h3>
          </Col>
          <Col sm={12} md={12} lg={12}>
            <ToggleButtonsContainer>
              <Link
                to={{
                  pathname: "/consumer/team",
                  // @ts-ignore
                  params: {
                    containerTitle: name + " Team members",
                    companyData: { companyData }
                  }
                }}
                label={
                  <StyledButton
                    type="button"
                    label="View Team"
                    width="100px"
                    color={Colors.snow}
                    background={Colors.default}
                  />
                }
              />
              <StyledButton
                type="button"
                label="Edit"
                width="100px"
                onClick={() =>
                  this.editCompanyModal(companyData, companyInterests)
                }
                color={Colors.coal}
                background={Colors.background}
              />
            </ToggleButtonsContainer>
          </Col>
          <Col sm={12} md={12} lg={12}>
            <Card>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    id="name"
                    placeholder="Name"
                    disabled={readOnly}
                  />
                  <br />
                  <Label for="emailAddress">Email Address</Label>
                  <Input
                    type="email"
                    name="emailAddress"
                    value={emailAddress}
                    id="emailAddress"
                    placeholder="Email Address"
                    disabled={readOnly}
                  />
                  <br />
                  <Label for="contactNumber">Contact Number</Label>
                  <Input
                    type="number"
                    name="contactNumber"
                    value={contactNumber}
                    id="contactNumber"
                    placeholder="Contact Number"
                    disabled={readOnly}
                  />
                  <br />
                  <Label for="industry">Industry</Label>
                  <Input
                    type="text"
                    name="industry"
                    value={formatString(industry) || ""}
                    id="industry"
                    placeholder="Industry"
                    disabled={readOnly}
                  />
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <Label for="address">Address</Label>
                  <Input
                    type="text"
                    name="address"
                    value={address}
                    id="address"
                    placeholder="ID number"
                    disabled={readOnly}
                  />
                  <br />
                  <Label for="province">Province</Label>
                  <Select
                    value={companyProvince}
                    placeholder=""
                    className="select-styling"
                    isDisabled={readOnly}
                  />
                  <br />
                  <Label for="numberOfEmployees">Number of Employees</Label>
                  <Input
                    type="text"
                    name="numberOfEmployees"
                    value={numberOfEmployees}
                    id="numberOfEmployees"
                    placeholder="Number of Employees"
                    disabled={readOnly}
                  />
                  <br />
                  <Label for="interests">Interests & Objectives</Label>
                  <Select
                    isMulti={true}
                    value={this.formartCompanyInterests(interests)}
                    className="select-styling"
                    placeholder="No interests selected"
                    isDisabled={readOnly}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm={12} md={12} lg={12}>
            <Card marginTop={"20px"}>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <h4>Cards</h4>
                </Col>
                <Col sm={12} md={12} lg={12}>
                  {this.ListCards(companyData.cards)}
                </Col>
                <Col sm={12} md={12} lg={12}>
                  <StyledButton
                    type="button"
                    label="Add Card"
                    width="100px"
                    onClick={() => this.addCardModal(id)}
                    color={Colors.snow}
                    background={Colors.default}
                    margin="5% 0 0 0"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          {error && <ErrorMessage errorMessage={error} />}
        </Row>
      </BackendWrapper>
    );
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance ? appearance : "success",
      autoDismiss: true
    });
  };

  // show list of cards linked to company
  ListCards = (cards: any) => {
    const cardList = cards.items.map((data: any) => (
      <Row key={data.card.id}>
        <Col sm={10} md={10} lg={10}>
          <BankCardList>
            <p>{data.card.nameOnCard}</p>
            <p>
              {data.card.expiryMonth}/{data.card.expiryYear}
            </p>
            <p>************{data.card.number}</p>
          </BankCardList>
        </Col>
        <Col sm={2} md={2} lg={2}>
          <MdDeleteForever
            color={"red"}
            size="3em"
            style={{ cursor: "pointer", marginTop: "5%" }}
            onClick={() => this.deleteCardModal(data)}
          />
        </Col>
      </Row>
    ));
    return cardList;
  };

  render() {
    return (
      <React.Fragment>
        {/* TODO: remove this limit after testing, companies should not have more than 5 active cards */}
        <Query
          query={gql(getUsersCompany)}
          variables={{ id: getUserId(), limit: 30 }}
        >
          {({ loading, data }: any) => {
            if (loading) {
              return <PageLoader />;
            }

            if (
              !data ||
              !data.getUser ||
              !data.getUser.company.items ||
              !data.getUser.company.items[0].company
            ) {
              return <div>Cannot retreive company data</div>;
            }
            return this.returnFormFields(data.getUser.company.items[0].company);
          }}
        </Query>

        {/* Edit Company Modal */}
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title="Edit Company"
          modalDisplay={
            <CompanyView
              roles={[UserRole.ROOTS_SUPER_ADMIN, UserRole.ROOTS_ADMIN]}
              notification={this.toastNotification}
              closeModal={this.closeModal}
              companyData={this.state.modalData}
              editCompany={true}
              {...this.props}
            />
          }
          modal={this.state.editCompanyModal}
        />

        {/* Add Card Modal */}
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title="Add Card"
          modalDisplay={
            <AddCardModal
              notification={this.toastNotification}
              closeModal={this.closeModal}
              companyId={this.state.modalData}
              {...this.props}
            />
          }
          modal={this.state.addCardModal}
        />

        {/* Delete Card Modal */}
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title="Delete Card"
          modalDisplay={
            <DeleteCardModal
              notification={this.toastNotification}
              closeModal={this.closeModal}
              cardData={this.state.modalData}
              {...this.props}
            />
          }
          modal={this.state.deleteCardModal}
        />
      </React.Fragment>
    );
  }
}

export default withToastManager(CompanyDetails);
