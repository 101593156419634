import * as React from "react";
import { RouteComponentProps } from "react-router";
import {
  Row,
  Col,
  CardBody,
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Label,
  Form
} from "reactstrap";
import { Query, Mutation, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";
import { getReport, getCompanyReports } from "../../graphql/custom-queries";
import {
  createTransaction,
  createCompanyReport,
  createTransactionVoucher
} from "../../graphql/mutations";
import {
  CreateTransactionMutation,
  CreateTransactionMutationVariables,
  CreateCompanyReportMutation,
  CreateCompanyReportMutationVariables,
  CreateTransactionVoucherMutation,
  CreateTransactionVoucherMutationVariables
} from "../../API";
import { getUserId } from "../../Utils/Helpers";
import { makePayment } from "../../PeachPayments/MakePayment";

/** Presentation/UI */
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import { Error } from "../../CustomTypes";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import { MdKeyboardArrowLeft } from "react-icons/md";
import StyledButton from "../../Components/Styled/Button";
import Link from "../../Components/Styled/Link";
import colors from "../../Themes/Colors";
import styled from "styled-components";
import Card from "../../Components/Styled/Card";
import LargeInput from "../../Components/Styled/Input";

import PageLoader from "../../Components/PageLoader";
import Loader from "../../Components/Loader";

import GlobalModalContainer from "../../Components/Modal";
import { listVouchers } from "../../graphql/queries";

interface IPathParams {
  id: string;
}

type Props = RouteComponentProps<IPathParams> & {
  computedMatch: {
    params: {
      id: string;
    };
  };
  reportData: {};
  closeModal(): void;
};

type State = {
  processing: boolean;
  error: Error;
  voucherCode: string;
  newPrice: number;
  companyId: string;
  cardData: any;
  reportData: any;
  voucherData: any;
  modal: boolean;
  cvv: string;
};

const Line = styled.label`
  padding: 1px;
  background: ${colors.coal};
  display: block;
  margin: 20px 0px;
`;

class Checkout extends React.Component<Props, State> {
  state: State = {
    processing: false,
    error: null,
    voucherCode: "",
    newPrice: 0,
    companyId: "",
    cardData: {},
    reportData: {},
    voucherData: {},
    cvv: "",
    modal: false
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 7000);
      }
    );
  };

  // Open checkout modal with passed checkout data
  openModal = (report: any) => {
    const { voucherData } = this.state;
    // Check if user has added a voucher
    if (voucherData.id) {
      // calculate new checkout price based on voucher discount
      this.setState({
        newPrice:
          report.price - (voucherData.discountPercentage / 100) * report.price
      });
    } else {
      this.setState({ newPrice: report.price });
    }
    this.setState({ modal: true, reportData: report });
  };

  // Close modal
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  // Handle change in input fields
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    switch (name) {
      case "voucherCode":
        this.setState({ voucherCode: value });
        break;
      case "cvv":
        this.setState({ cvv: value });
        break;
      default:
        break;
    }
  };
  // show list of cards for company
  listCards = (cards: any) => {
    const cardList = cards.items.map((data: any) => (
      <Row key={data.card.id}>
        <Label>
          <Input
            type="radio"
            name="bankCard"
            onChange={() => this.setState({ cardData: data.card })}
          />
          Card ending ******{data.card.number}
        </Label>
      </Row>
    ));
    return cardList;
  };

  /* This function is called when customer is ready to checkout
   * it processes the transaction with selected card through peach payments
   * @param createTransactionMutation: create a transactioj
   * @param createCompanyReportMutation: link purchased report with company
   * */
  makePeachPurchase = (
    createTransactionMutation: any,
    createCompanyReportMutation: any,
    createTransactionVoucherMutation: any
  ) => {
    this.setState({ processing: true });
    const { cardData, newPrice } = this.state;
    // Check the cost of the transaction, if is less than R1 do not go through Peach
    if (newPrice > 0) {
      return makePayment(cardData.peachPaymentCardId, newPrice)
        .then((res: any) => {
          this.savePurchaseToDb(
            createTransactionMutation,
            createCompanyReportMutation,
            createTransactionVoucherMutation
          );
        })
        .catch(() => {
          this.setState({ processing: false });
          this.setError("There has been an error, please try again");
          return false;
        });
    } else {
      return this.savePurchaseToDb(
        createTransactionMutation,
        createCompanyReportMutation,
        createTransactionVoucherMutation
      );
    }
  };

  /* This function is called after the transaction has proccessed successfully on peach payment
   * it creates stores the transaction details locally for users to view
   * @param createTransactionMutation: create a transactioj
   * @param createCompanyReportMutation: link purchased report with company
   * */
  savePurchaseToDb = async (
    // after react scripts changes
    // eslint-disable-next-line
    createTransactionMutation: ({ }) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    createCompanyReportMutation: ({ }) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    createTransactionVoucherMutation: ({ }) => Promise<any>
  ) => {
    return createTransactionMutation({
      variables: {
        input: {
          transactionReportId: this.state.reportData.id,
          transactionCompanyId: this.state.companyId,
          transactionVoucherId: this.state.voucherData.id,
          cost: this.state.newPrice
        }
      }
    })
      .then((transRes: any) => {
        createTransactionVoucherMutation({
          variables: {
            input: {
              transactionVoucherTransactionId:
                transRes.data.createTransaction.id,
              transactionVoucherVoucherId: this.state.voucherData.id
            }
          }
        })
          .then(() => {
            createCompanyReportMutation({
              variables: {
                input: {
                  companyReportCompanyId: this.state.companyId,
                  companyReportReportId: this.state.reportData.id
                }
              }
            })
              .then(() => {
                this.setState({ processing: false });
                this.closeModal();
              })
              .catch((companyReportErr: any) => {
                this.setState({ processing: false });
                this.setError(companyReportErr.message);
              });
          })
          .catch((transResErr: any) => {
            this.setState({ processing: false });
            this.setError(transResErr.message);
          });
      })
      .catch((transErr: any) => {
        this.setState({ processing: false });
        this.setError(transErr.message);
      });
  };

  // retrieved datails of the voucher entered by user
  retrieveVoucher = () => {
    const { voucherCode, processing } = this.state;
    return (
      <ApolloConsumer>
        {client => (
          <StyledButton
            type="button"
            disabled={voucherCode ? false : true}
            label={processing ? <Loader /> : "Apply Voucher"}
            color={colors.snow}
            background={colors.default}
            width="120px"
            onClick={async () => {
              this.setState({ processing: true });
              const { data } = await client.query({
                query: gql(listVouchers),
                fetchPolicy: "network-only",
                variables: {
                  filter: { code: { eq: voucherCode } }
                }
              });
              if (data.listVouchers.items[0] && data.listVouchers.items[0].id) {
                this.checkVoucherExpiryDate(data.listVouchers.items[0]);
              } else {
                this.setState({ processing: false, voucherData: "" });
                this.setError("Your voucher code is invalid");
              }
            }}
          />
        )}
      </ApolloConsumer>
    );
  };

  /* Check the expiry date of the voucher to see if it has not expired
   * Also check whether the voucher usage limit has not been reached
   * @param data : voucher data from the query above
   */
  checkVoucherExpiryDate = (data: any) => {
    if (data.transactions.items.length.toString() === data.numberOfUses) {
      this.setState({ processing: false, voucherData: "" });
      this.setError("This voucher has already been used");
    } else {
      const today = new Date().getTime();
      const expiryDate = new Date(data.expiryDate).getTime();
      if (today < expiryDate) {
        this.setState({ processing: false, voucherData: data });
      } else {
        this.setState({ processing: false, voucherData: "" });
        this.setError("This voucher has expired");
      }
    }
  };

  // If user has a 100% discount voucher hide cvv field
  showCVVField = () => {
    const { cvv, newPrice } = this.state;
    if (newPrice > 0) {
      return (
        <React.Fragment>
          <Label>CVV</Label>
          <Input
            type="text"
            name="cvv"
            id="cvv"
            value={cvv}
            maxLength={3}
            placeholder="Enter your cvv"
            autoComplete="off"
            onChange={this.handleChange}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  // Disable buy button if the price is greater than zero & there is no CVV present
  disableBuyButton = () => {
    const { cvv, newPrice } = this.state;
    if (newPrice === 0) {
      return false;
    }

    if (newPrice !== 0 && !cvv) {
      return true;
    } else {
      return false;
    }
  };

  // Disable checkout button if user has not selected a card
  disableCheckoutButton = () => {
    const { cardData, voucherData } = this.state;
    // if user has not selected a card but has a 100% discount voucher enable checkout
    if (!cardData.id && voucherData.discountPercentage === "100") {
      return false;
    }

    if (cardData.id) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const {
      voucherCode,
      newPrice,
      processing,
      voucherData,
      error
    } = this.state;
    let { reportData } = this.state;
    return (
      <React.Fragment>
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title="Confirm Report Purchase"
          modalDisplay={
            <Mutation<
              CreateTransactionMutation,
              CreateTransactionMutationVariables
            >
              mutation={gql(createTransaction)}
            >
              {createTransactionMutation => (
                <Mutation<
                  CreateTransactionVoucherMutation,
                  CreateTransactionVoucherMutationVariables
                >
                  mutation={gql(createTransactionVoucher)}
                >
                  {createTransactionVoucherMutation => (
                    <Mutation<
                      CreateCompanyReportMutation,
                      CreateCompanyReportMutationVariables
                    >
                      mutation={gql(createCompanyReport)}
                    >
                      {createCompanyReportMutation => (
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            this.makePeachPurchase(
                              createTransactionMutation,
                              createCompanyReportMutation,
                              createTransactionVoucherMutation
                            );
                          }}
                        >
                          <Row>
                            <Col sm={12} md={12} lg={12}>
                              <h3>Buying {reportData.name}</h3>
                              <Line />
                              <h5>Summary</h5>
                              <h6>Tax : R 0.00</h6>
                              <h6>
                                {voucherData.id
                                  ? `Voucher : ${
                                  voucherData.discountPercentage
                                  } % Discount`
                                  : ""}{" "}
                              </h6>
                              <Line />
                              <h5>Order Total(incl. Vat): R {newPrice}</h5>
                              {this.showCVVField()}
                              <StyledButton
                                type="submit"
                                label={processing ? <Loader /> : "Buy"}
                                width="100%"
                                color={colors.snow}
                                background={colors.default}
                                margin="5px"
                                disabled={this.disableBuyButton()}
                              />
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Mutation>
                  )}
                </Mutation>
              )}
            </Mutation>
          }
          modal={this.state.modal}
        />
        <BackendWrapper>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <Link
                to={{
                  pathname: "/consumer/reports"
                }}
                color={colors.coal}
                label={
                  <p style={{ fontSize: "17px" }}>
                    <MdKeyboardArrowLeft size="2em" /> Back
                  </p>
                }
              />
            </Col>
            <Col sm={12} md={12} lg={12}>
              <h4>Checkout</h4>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <Card>
                <CardBody>
                  <Query
                    query={gql(getReport)}
                    variables={{
                      limit: 1,
                      id: this.props.computedMatch.params.id
                    }}
                  >
                    {({ loading, data }: any) => {
                      if (loading) {
                        return <PageLoader />;
                      }
                      if (!loading && data) {
                        reportData = data.getReport;
                        return (
                          <Col sm={12} md={12} lg={12}>
                            <Table striped={true}>
                              <thead>
                                <tr>
                                  <th>ITEM</th>
                                  <th>MAX DOWNLOADS</th>
                                  <th>COST</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row">{reportData.name}</th>
                                  <td>{reportData.maxDownloads}</td>
                                  <td>R {reportData.price}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        );
                      } else {
                        return (
                          <p>There has been a problem loading your cart</p>
                        );
                      }
                    }}
                  </Query>
                  <Col sm={12} md={6} lg={6}>
                    <InputGroup>
                      <LargeInput
                        type="text"
                        name="voucherCode"
                        id="voucherCode"
                        value={voucherCode}
                        placeholder="Enter your code"
                        height={"40px"}
                        onChange={this.handleChange}
                      />
                      <InputGroupAddon addonType="append">
                        {this.retrieveVoucher()}
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  {error && <ErrorMessage errorMessage={error} />}
                  <Line />
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <h3>Payment Method</h3>
                      <FormGroup>
                        <Col sm={12} md={12} lg={12}>
                          <Query
                            query={gql(getCompanyReports)}
                            variables={{ id: getUserId() }}
                          >
                            {({ loading, data }: any) => {
                              if (loading) {
                                return <Loader />;
                              }
                              if (
                                data &&
                                data.getUser.company.items[0].company.cards
                                  .items
                              ) {
                                this.setState({ companyId: data.getUser.company.items[0].company.id })
                                return this.listCards(
                                  data.getUser.company.items[0].company.cards
                                );
                              } else {
                                return (
                                  <Col sm={12} md={12} lg={12}>
                                    <p>There are no cards</p>
                                    <Link
                                      to={{
                                        pathname: "/consumer/account"
                                      }}
                                      label={
                                        <StyledButton
                                          type="button"
                                          label="Add New Card"
                                          width="120px"
                                          color={colors.snow}
                                          background={colors.default}
                                          margin="5px"
                                        />
                                      }
                                    />
                                  </Col>
                                );
                              }
                            }}
                          </Query>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                      <h3>Summary</h3>
                      <h6>Tax : R 0.00</h6>
                      <h6>
                        {voucherData.id
                          ? `Voucher : ${
                          voucherData.discountPercentage
                          } % Discount`
                          : ""}{" "}
                      </h6>
                      <Line />
                      <StyledButton
                        type="button"
                        label="CHECKOUT"
                        width="100%"
                        color={colors.snow}
                        background={colors.default}
                        margin="5px"
                        onClick={() => this.openModal(reportData)}
                        disabled={this.disableCheckoutButton()}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </BackendWrapper>
      </React.Fragment>
    );
  }
}
export default Checkout;
