import * as React from "react";
import * as ReactDOM from "react-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

type Props = {
  title: string;
  modalDisplay: any;
  modal: boolean;
  toggleModal?(): void;
};

const GlobalModalContainer: React.FC<Props> = props => {
  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggleModal}>
        <ModalHeader toggle={props.toggleModal}>{props.title}</ModalHeader>
        <ModalBody>{props.modalDisplay}</ModalBody>
      </Modal>
    </div>
  );
};

function GlobalModal(props: Props) {
  return ReactDOM.createPortal(
    <GlobalModalContainer {...props} />,
    document.getElementById("modal") as HTMLElement
  );
}

export default GlobalModal;
