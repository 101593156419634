import * as React from "react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";

import { Label, Input, Row, Col } from "reactstrap";
import { withToastManager } from "react-toast-notifications";

import {
  CreateSampleReportMutation,
  CreateSampleReportMutationVariables,
  UpdateSampleReportMutation,
  UpdateSampleReportMutationVariables
} from "../../API";

import StyledButton from "../../Components/Styled/Button";
import Card from "../../Components/Styled/Card";
import LottieWrapper from "../../Components/Anim/LottieWrapper";

import { Error, ToastNotificationType } from "../../CustomTypes";
import { generateRandomNumber } from "../../Utils/Helpers";
import styled from "styled-components";
import Container from "reactstrap/lib/Container";
import { Colors } from "../../Themes";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import { createSampleReport, updateSampleReport } from "../../graphql/mutations";
import { RouteComponentProps } from "react-router";
import { Storage } from "aws-amplify";
import { listSampleReports } from "../../graphql/queries";
import PageLoader from "../../Components/PageLoader";

type Props = RouteComponentProps & {
  location: { [key: string]: any }; // object with any amount and types of params
  toastManager: ToastNotificationType;
};

type State = {
  error: Error;
  reportCreated: boolean;
  id: string;
  name: string;
  createdAt: string;
  rootsDescription: string;
  sampleReportDescription: string;
  coverImageUrl: string;
  documentResourceUrl: string;
  reportData: any;
  editReport: boolean;
  removedCoverImg: boolean;
  removedPdf: boolean;
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: flex-end;
  align-items: flex-end;
`;

class AddSampleReport extends React.Component<Props, State> {
  state: State = {
    error: null,
    reportCreated: false,
    id: "",
    name: "",
    createdAt: "",
    rootsDescription: "",
    sampleReportDescription: "",
    coverImageUrl: "",
    documentResourceUrl: "",
    reportData: {},
    editReport: false,
    removedCoverImg: false,
    removedPdf: false
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance ? appearance : "success",
      autoDismiss: true
    });
  };

  // Handle change
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    switch (name) {
      case "name":
        this.setState({ name: value });
        break;
      case "rootsDescription":
        this.setState({ rootsDescription: value });
        break;
      case "sampleReportDescription":
        this.setState({ sampleReportDescription: value });
        break;
      default:
        break;
    }
  };

  sampleReportData = () => {
    return <Query query={gql(listSampleReports)} variables={{ limit: 1 }}>
      {({ loading, data }: any) => {
        if (loading) {
          return <PageLoader />;
        }
        if (data && data.listSampleReports.items[0]) {
          this.setState({reportData: data.listSampleReports.items[0]})
          return this.updateSampleReport(data.listSampleReports.items[0])
        } else {
          return this.newSampleReport();
        }
      }}
    </Query>
  }

  newSampleReport = () => {
    const {
      error,
      reportCreated,
      name,
      rootsDescription,
      sampleReportDescription,
      coverImageUrl,
      documentResourceUrl
    } = this.state;
    return <BackendWrapper>
      <Card width="100%">
        <Container>
          <Row>
            <HeaderContainer>
              <h2>Create Sample Report</h2>
            </HeaderContainer>
          </Row>
          <Row>
            <Col sm={true}>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                value={name}
                id="name"
                placeholder="Name"
                onChange={e => this.onChange(e)}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={true}>
              <Label for="rootsDescription">Roots Description</Label>
              <Input
                type="textarea"
                name="rootsDescription"
                value={rootsDescription}
                id="rootsDescription"
                placeholder=""
                onChange={e => this.onChange(e)}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={true}>
              <Label for="sampleReportDescription">
                Sample Report Description
            </Label>
              <Input
                type="textarea"
                name="sampleReportDescription"
                value={sampleReportDescription}
                id="sampleReportDescription"
                placeholder=""
                onChange={e => this.onChange(e)}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={true}>
              <hr />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={6}>
              {documentResourceUrl !== "" ? (
                <div color={Colors.secondary}>Document Uploaded</div>
              ) : (
                  <React.Fragment>
                    <Label for="documentResourceUrl">Upload document</Label>
                    <Input
                      type="file"
                      name="documentResourceUrl"
                      // value={documentResourceUrl}
                      id="documentResourceUrl"
                      placeholder="Name"
                      onChange={(e, ...args) => {
                        if (e && e.target && e.target.files) {
                          const file = e.target.files[0];
                          const fileKey = generateRandomNumber();
                          Storage.put(fileKey, file, {
                            contentType: "application/pdf"
                          })
                            .then((result: any) => {
                              this.setState({
                                documentResourceUrl: fileKey.toString()
                              });
                            })
                            .catch((err: any) =>
                              this.setState(
                                { error: "Error uploading PDF" },
                                () => {
                                  setTimeout(() => {
                                    this.setState({ error: null });
                                  }, 3000);
                                }
                              )
                            );
                        }
                      }}
                    />
                  </React.Fragment>
                )}
            </Col>
            <Col sm={6}>
              {coverImageUrl !== "" ? (
                <div color={Colors.secondary}>Cover image uploaded</div>
              ) : (
                  <React.Fragment>
                    <Label for="coverImageUrl">Upload cover image</Label>
                    <Input
                      type="file"
                      name="coverImageUrl"
                      id="coverImageUrl"
                      placeholder="Name"
                      onChange={(e, ...args) => {
                        if (e && e.target && e.target.files) {
                          const file = e.target.files[0];
                          const fileKey = generateRandomNumber();
                          Storage.put(`${fileKey}`, file, {
                            contentType: "image/png"
                          })
                            .then((result: any) => {
                              this.setState({
                                coverImageUrl: fileKey.toString()
                              });
                            })
                            .catch((err: any) =>
                              this.setState(
                                { error: "Error uploading cover image" },
                                () => {
                                  setTimeout(() => {
                                    this.setState({ error: null });
                                  }, 3000);
                                }
                              )
                            );
                        }
                      }}
                    />
                  </React.Fragment>
                )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={true}>
              <hr />
            </Col>
          </Row>
          <br />
          {!reportCreated ? (
            <Mutation<
              CreateSampleReportMutation,
              CreateSampleReportMutationVariables
            >
              mutation={gql(createSampleReport)}
            >
              {(
                createSampleReportMutation,
                { loading: createReportLoading }
              ) => (
                  <StyledButton
                    type="button"
                    label="Create"
                    onClick={() => {
                      createSampleReportMutation({
                        variables: {
                          input: {
                            name,
                            rootsDescription,
                            sampleReportDescription,
                            coverImageUrl,
                            documentResourceUrl
                          }
                        }
                      })
                        .then((response: any) => {
                          this.setState({ reportCreated: true });
                          this.toastNotification(
                            "Successfully created sample report."
                          );
                        })
                        .catch((err: any) => {
                          this.setError(err.message);
                        });
                    }}
                    width="120px"
                    color={Colors.coal}
                    background={Colors.background}
                  />
                )}
            </Mutation>
          ) : (
              <LottieWrapper
                loop={false}
                width={70}
                height={55}
                anim={require("../../LottieFiles/success1.json")}
              />
            )}
        </Container>
        <br />
        {error && <ErrorMessage errorMessage={error} />}
      </Card>
    </BackendWrapper>;
  }

  toggleEditMode = () => {
    const { reportData } = this.state;

    this.setState({
      name: reportData.name,
      rootsDescription: reportData.rootsDescription,
      sampleReportDescription: reportData.sampleReportDescription
    })
    return this.setState({ editReport: true })
  }

  updateSampleReport = (data: any) => {
    const {
      error,
      name,
      rootsDescription,
      sampleReportDescription,
      coverImageUrl,
      documentResourceUrl,
      editReport,
      removedCoverImg,
      removedPdf
    } = this.state;
    return <BackendWrapper>
      <Card width="100%">
        <Container>
          <Row>
            <HeaderContainer>
              <h2>{editReport ? "Edit" : "Published"} Sample Report</h2>
            </HeaderContainer>
          </Row>
          <Row>
            <Col sm={true}>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                value={editReport ? name : data.name}
                id="name"
                placeholder="Name"
                onChange={e => this.onChange(e)}
                disabled={editReport ? false : true}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={true}>
              <Label for="rootsDescription">Roots Description</Label>
              <Input
                type="textarea"
                name="rootsDescription"
                value={editReport ? rootsDescription : data.rootsDescription}
                id="rootsDescription"
                placeholder=""
                onChange={e => this.onChange(e)}
                disabled={editReport ? false : true}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={true}>
              <Label for="sampleReportDescription">
                Sample Report Description
            </Label>
              <Input
                type="textarea"
                name="sampleReportDescription"
                value={editReport ? sampleReportDescription : data.sampleReportDescription}
                id="sampleReportDescription"
                placeholder=""
                onChange={e => this.onChange(e)}
                disabled={editReport ? false : true}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={true}>
              <hr />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={6}>
              {data.documentResourceUrl !== "" && !removedPdf ? (
                <React.Fragment>
                  <div color={Colors.secondary}>Document Uploaded</div>
                  {editReport ?
                    <StyledButton
                      type="button"
                      label="Remove PDF"
                      onClick={() => {
                        Storage.remove(data.documentResourceUrl)
                          .then(result => {
                            this.setState({ removedPdf: true });
                          })
                          .catch(err => this.setError(err.message));
                      }}
                      width="120px"
                      color={Colors.coal}
                      background={Colors.background}
                    /> : null}
                </React.Fragment>
              ) : (
                  <React.Fragment>
                    <Label for="documentResourceUrl">Upload document</Label>
                    <Input
                      type="file"
                      name="documentResourceUrl"
                      value={documentResourceUrl}
                      id="documentResourceUrl"
                      placeholder="Name"
                      onChange={(e, ...args) => {
                        if (e && e.target && e.target.files) {
                          const file = e.target.files[0];
                          const fileKey = generateRandomNumber();
                          Storage.put(fileKey, file, {
                            contentType: "application/pdf"
                          })
                            .then((result: any) => {
                              this.setState({
                                removedPdf: false,
                                documentResourceUrl: fileKey.toString(),
                              });
                            })
                            .catch((err: any) =>
                              this.setState(
                                { error: "Error uploading PDF" },
                                () => {
                                  setTimeout(() => {
                                    this.setState({ error: null });
                                  }, 3000);
                                }
                              )
                            );
                        }
                      }}
                    />
                  </React.Fragment>
                )}
            </Col>
            <Col sm={6}>
              {data.coverImageUrl !== "" && !removedCoverImg ? (
                <React.Fragment>
                  <div color={Colors.secondary}>Cover image uploaded</div>
                  {editReport ?
                    <StyledButton
                      type="button"
                      label="Remove Image"
                      onClick={() => {
                        Storage.remove(data.coverImageUrl)
                          .then(result => {
                            this.setState({ removedCoverImg: true });
                          })
                          .catch(err => this.setError(err.message));
                      }}
                      width="120px"
                      color={Colors.coal}
                      background={Colors.background}
                    />
                    : null}
                </React.Fragment>
              ) : (
                  <React.Fragment>
                    <Label for="coverImageUrl">Upload cover image</Label>
                    <Input
                      type="file"
                      name="coverImageUrl"
                      id="coverImageUrl"
                      placeholder="Name"
                      onChange={(e, ...args) => {
                        if (e && e.target && e.target.files) {
                          const file = e.target.files[0];
                          const fileKey = generateRandomNumber();
                          Storage.put(`${fileKey}`, file, {
                            contentType: "image/png"
                          })
                            .then((result: any) => {
                              this.setState({
                                removedCoverImg: false,
                                coverImageUrl: fileKey.toString()
                              });
                            })
                            .catch((err: any) =>
                              this.setState(
                                { error: "Error uploading cover image" },
                                () => {
                                  setTimeout(() => {
                                    this.setState({ error: null });
                                  }, 3000);
                                }
                              )
                            );
                        }
                      }}
                    />
                  </React.Fragment>
                )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={true}>
              <hr />
            </Col>
          </Row>
          <br />
          {!editReport ? <StyledButton
            type="button"
            label="Edit"
            onClick={() => { this.toggleEditMode() }}
            width="120px"
            color={Colors.coal}
            background={Colors.background}
          />
            : null}
          {editReport ? (
            <Mutation<
              UpdateSampleReportMutation,
              UpdateSampleReportMutationVariables
            >
              mutation={gql(updateSampleReport)}
            >
              {(
                updateSampleReportMutation,
                { loading: createReportLoading }
              ) => (
                  <StyledButton
                    type="button"
                    label="Update"
                    onClick={() => {
                      updateSampleReportMutation({
                        variables: {
                          input: {
                            id: data.id,
                            name,
                            rootsDescription,
                            sampleReportDescription,
                            coverImageUrl,
                            documentResourceUrl
                          }
                        }
                      })
                        .then((response: any) => {
                          this.setState({ editReport: false });
                          this.toastNotification(
                            "Successfully updated sample report."
                          );
                        })
                        .catch((err: any) => {
                          this.setError(err.message);
                        });
                    }}
                    width="120px"
                    color={Colors.coal}
                    background={Colors.background}
                    disabled={removedPdf || removedCoverImg ? true : false }
                  />
                )}
            </Mutation>
          ) : null}
        </Container>
        <br />
        {error && <ErrorMessage errorMessage={error} />}
      </Card>
    </BackendWrapper>
  }

  render() {
    return this.sampleReportData();
  }
}

export default withToastManager(AddSampleReport);
