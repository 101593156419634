import * as React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { Colors } from "../../Themes";

const StackedBarChart = (props: any) => {
  return (
    <ResponsiveContainer>
      <BarChart
        data={props.data}
        margin={{ top: 0, right: 0, left: -35, bottom: 0 }}
      >
        <CartesianGrid
          strokeDasharray="solid"
          vertical={false}
          stroke="#d6dbe6"
        />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="Purchases"
          stackId="a"
          fill={Colors.default}
          barSize={40}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;
