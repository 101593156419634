import styled from "styled-components";

type IProps = {
  color?: string;
  flex?: string;
  width?: string;
  height?: string;
  flexDirection?: string;
  padding?: string;
  margin?: string;
  shadow?: string;
  marginTop?: string;
};

const Card = styled.div`
  display: flex;
  flex: ${(props: IProps) => (props.flex ? props.flex : "")};
  background-color: ${(props: IProps) => (props.color ? props.color : "#fff")};
  width: ${(props: IProps) => props.width || "initial"};
  height: ${(props: IProps) => (props.height ? props.height : "auto")};
  flex-direction: ${(props: IProps) =>
    props.flexDirection ? props.flexDirection : "column"};
  padding: ${(props: IProps) => (props.padding ? props.padding : "20px")};
  margin: ${(props: IProps) => (props.margin ? props.margin : "inherit")};
  margin-top: ${(props: IProps) =>
    props.marginTop ? props.marginTop : "inherit"};
  border-radius: 2px;
  box-shadow: ${(props: IProps) =>
    props.shadow
      ? "5px 10px 20px -5px #d48960;"
      : "rgba(0, 0, 0, 0.1) 0px 0px 8px 3px"};
`;

export default Card;
