import * as React from "react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { Row, Col, Form, Label, FormGroup, Input } from "reactstrap";

/** GraphQL */
import {
  createProvinceType,
  updateProvinceType,
  deleteProvinceType,
  createCityType,
  updateCityType,
  deleteCityType,
  createAreaType,
  updateAreaType,
  deleteAreaType
} from "../../graphql/mutations";

import {
  CreateProvinceTypeMutation,
  CreateProvinceTypeMutationVariables,
  UpdateProvinceTypeMutation,
  UpdateProvinceTypeMutationVariables,
  DeleteProvinceTypeMutation,
  DeleteProvinceTypeMutationVariables,
  CreateCityTypeMutation,
  CreateCityTypeMutationVariables,
  UpdateCityTypeMutation,
  UpdateCityTypeMutationVariables,
  DeleteCityTypeMutation,
  DeleteCityTypeMutationVariables,
  CreateAreaTypeMutation,
  CreateAreaTypeMutationVariables,
  UpdateAreaTypeMutation,
  UpdateAreaTypeMutationVariables,
  DeleteAreaTypeMutation,
  DeleteAreaTypeMutationVariables
} from "../../API";

/** Presentation/UI */
import Loader from "../../Components/Loader";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import StyledButton from "../../Components/Styled/Button";

/** Custom Types */
import { Error } from "../../CustomTypes";

/** Themes */
import { Colors } from "../../Themes";
import { TableConsts } from "../../Utils/Consts";
import {
  listProvinceTypes,
  listCityTypes,
  listAreaTypes,
  getProvinceType
} from "../../graphql/queries";
import Select from "react-select";
import PageLoader from "../../Components/PageLoader";

type Props = {
  data?: any;
  containerType: string;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  loading: boolean;
  error: Error;
  name: string;
  selectedId: string;
  city: { value: string; label: string };
  province: { value: string; label: string };
};

class AddEditLocation extends React.Component<Props, State> {
  state: State = {
    loading: false,
    error: null,
    name: this.props.data && this.props.data.name ? this.props.data.name : "",
    selectedId: this.props.data && this.props.data.id ? this.props.data.id : "",
    city: { value: "", label: "" },
    province: { value: "", label: "" }
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  // after react scripts changes
  // eslint-disable-next-line
  createProvince = async (createProvinceTypeMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { name } = this.state;

    this.setState({ loading: true });
    // create the province in dynamodb
    if (name !== "") {
      createProvinceTypeMutation({
        variables: {
          input: {
            name
          }
        },
        refetchQueries: [
          {
            query: gql(listProvinceTypes),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Added province, ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  // after react scripts changes
  // eslint-disable-next-line
  editProvince = async (updateProvinceTypeMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { selectedId, name } = this.state;

    this.setState({ loading: true });
    if (name !== "") {
      updateProvinceTypeMutation({
        variables: {
          input: {
            id: selectedId,
            name
          }
        },
        refetchQueries: [
          {
            query: gql(listProvinceTypes),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Updated province, ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  // after react scripts changes
  // eslint-disable-next-line
  createCity = async (createCityTypeMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { name, province } = this.state;

    this.setState({ loading: true });
    if (name !== "") {
      createCityTypeMutation({
        variables: {
          input: {
            name,
            cityTypeProvinceId: province ? province.value : ""
          }
        },
        refetchQueries: [
          {
            query: gql(listCityTypes),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Added city, ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  // after react scripts changes
  // eslint-disable-next-line
  editCity = async (updateCityTypeMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { selectedId, name } = this.state;

    this.setState({ loading: true });
    if (name !== "") {
      updateCityTypeMutation({
        variables: {
          input: {
            id: selectedId,
            name
          }
        },
        refetchQueries: [
          {
            query: gql(listCityTypes),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Updated city, ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  // after react scripts changes
  // eslint-disable-next-line
  createArea = async (createAreaTypeMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { name, city } = this.state;

    this.setState({ loading: true });
    if (name !== "") {
      createAreaTypeMutation({
        variables: {
          input: {
            name,
            areaTypeCityId: city ? city.value : ""
          }
        },
        refetchQueries: [
          {
            query: gql(listAreaTypes),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Added area, ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  // after react scripts changes
  // eslint-disable-next-line
  editArea = async (updateAreaTypeMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { selectedId, name, city } = this.state;

    this.setState({ loading: true });
    if (name !== "") {
      updateAreaTypeMutation({
        variables: {
          input: {
            id: selectedId,
            name,
            areaTypeCityId: city ? city.value : ""
          }
        },
        refetchQueries: [
          {
            query: gql(listAreaTypes),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Updated area, ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  returnDelete = () => {
    const { loading } = this.state;
    switch (this.props.containerType) {
      case "province":
        if (
          this.props.data &&
          this.props.data.cities &&
          this.props.data.cities.items.length > 0
        ) {
          return null;
        }
        return (
          <Mutation<
            DeleteProvinceTypeMutation,
            DeleteProvinceTypeMutationVariables
          >
            mutation={gql(deleteProvinceType)}
          >
            {deleteProvinceTypeMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  deleteProvinceTypeMutation({
                    variables: {
                      input: {
                        id: this.state.selectedId
                      }
                    },
                    refetchQueries: [
                      {
                        query: gql(listProvinceTypes),
                        variables: {
                          limit: TableConsts.limit
                        }
                      }
                    ]
                  })
                    .then(() => {
                      this.setState({ loading: false });
                      this.props.closeModal();
                      this.props.notification(
                        `Deleted province ${this.state.name}`
                      );
                    })
                    .catch((err: any) => {
                      this.setState({ loading: false });
                      this.setError(err.message);
                    });
                }}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <StyledButton
                        type="submit"
                        label={!loading ? "Delete" : <Loader />}
                        color={Colors.coal}
                        background={Colors.error}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}
          </Mutation>
        );
      case "city":
        if (
          this.props.data &&
          this.props.data.areas &&
          this.props.data.areas.items.length > 0
        ) {
          return null;
        }
        return (
          <Mutation<DeleteCityTypeMutation, DeleteCityTypeMutationVariables>
            mutation={gql(deleteCityType)}
          >
            {deleteCityTypeMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  deleteCityTypeMutation({
                    variables: {
                      input: {
                        id: this.state.selectedId
                      }
                    },
                    refetchQueries: [
                      {
                        query: gql(listCityTypes),
                        variables: {
                          limit: TableConsts.limit
                        }
                      }
                    ]
                  })
                    .then(() => {
                      this.setState({ loading: false });
                      this.props.closeModal();
                      this.props.notification(
                        `Deleted city ${this.state.name}`
                      );
                    })
                    .catch((err: any) => {
                      this.setState({ loading: false });
                      this.setError(err.message);
                    });
                }}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <StyledButton
                        type="submit"
                        label={!loading ? "Delete" : <Loader />}
                        color={Colors.coal}
                        background={Colors.error}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}
          </Mutation>
        );
      case "area":
        return (
          <Mutation<DeleteAreaTypeMutation, DeleteAreaTypeMutationVariables>
            mutation={gql(deleteAreaType)}
          >
            {deleteAreaTypeMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  deleteAreaTypeMutation({
                    variables: {
                      input: {
                        id: this.state.selectedId
                      }
                    },
                    refetchQueries: [
                      {
                        query: gql(listAreaTypes),
                        variables: {
                          limit: TableConsts.limit
                        }
                      }
                    ]
                  })
                    .then(() => {
                      this.setState({ loading: false });
                      this.props.closeModal();
                      this.props.notification(
                        `Deleted area ${this.state.name}`
                      );
                    })
                    .catch((err: any) => {
                      this.setState({ loading: false });
                      this.setError(err.message);
                    });
                }}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <StyledButton
                        type="submit"
                        label={!loading ? "Delete" : <Loader />}
                        color={Colors.coal}
                        background={Colors.error}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}
          </Mutation>
        );
      default:
        return null;
    }
  };

  returnFormFields = () => {
    const { error, loading, name, selectedId } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                value={name}
                id="name"
                placeholder="Name"
                onChange={e => this.setState({ name: e.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <StyledButton
                type="submit"
                label={
                  !loading ? selectedId !== "" ? "Save" : "Add" : <Loader />
                }
                color={Colors.coal}
                background={Colors.background}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        {selectedId !== "" ? this.returnDelete() : null}
        {error && <ErrorMessage errorMessage={error} />}
      </React.Fragment>
    );
  };

  returnCityFields = () => {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Query query={gql(listProvinceTypes)}>
                {({ loading, data }: any) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (!loading && data) {
                    const optionsToUse =
                      data &&
                        data.listProvinceTypes &&
                        data.listProvinceTypes.items
                        ? data.listProvinceTypes.items.map(
                          (item: {
                            id: string;
                            name: string;
                            cities: any;
                          }) => {
                            return { label: item.name, value: item.id };
                          }
                        )
                        : [];
                    return (
                      <React.Fragment>
                        <Label>Province</Label>
                        <Select
                          options={optionsToUse}
                          onChange={(e: any) => {
                            this.setState({ province: e });
                          }}
                          value={this.state.province}
                          placeholder=""
                          className="select-styling"
                        />
                        {this.state.province.value !== "" &&
                          this.props.containerType === "area" ? (
                            <Query
                              query={gql(getProvinceType)}
                              variables={{ id: this.state.province.value }}
                            >
                              {({ loading: cityLoading, data: cityData }: any) => {
                                if (cityLoading) {
                                  return <PageLoader />;
                                }
                                if (!cityLoading && cityData) {
                                  const cityList =
                                    cityData &&
                                      cityData.getProvinceType &&
                                      cityData.getProvinceType.cities &&
                                      cityData.getProvinceType.cities.items
                                      ? cityData.getProvinceType.cities.items.map(
                                        (item: {
                                          id: string;
                                          name: string;
                                          cities: any;
                                        }) => {
                                          return {
                                            label: item.name,
                                            value: item.id
                                          };
                                        }
                                      )
                                      : [];
                                  return (
                                    <React.Fragment>
                                      <Label>City</Label>
                                      <Select
                                        options={cityList}
                                        onChange={(e: any) => {
                                          this.setState({ city: e });
                                        }}
                                        value={this.state.city}
                                        placeholder=""
                                        className="select-styling"
                                      />
                                    </React.Fragment>
                                  );
                                } else {
                                  return (
                                    <p>
                                      There has been a problem loading your cart
                                  </p>
                                  );
                                }
                              }}
                            </Query>
                          ) : null}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <p>There has been a problem loading your locations</p>
                    );
                  }
                }}
              </Query>
            </FormGroup>
          </Col>
        </Row>
        {this.returnFormFields()}
      </React.Fragment>
    );
  };

  render() {
    switch (this.props.containerType) {
      case "province":
        if (this.state.selectedId !== "") {
          return (
            <Mutation<
              UpdateProvinceTypeMutation,
              UpdateProvinceTypeMutationVariables
            >
              mutation={gql(updateProvinceType)}
            >
              {updateProvinceTypeMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.editProvince(updateProvinceTypeMutation);
                  }}
                >
                  {this.returnFormFields()}
                </Form>
              )}
            </Mutation>
          );
        } else {
          return (
            <Mutation<
              CreateProvinceTypeMutation,
              CreateProvinceTypeMutationVariables
            >
              mutation={gql(createProvinceType)}
            >
              {createProvinceTypeMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.createProvince(createProvinceTypeMutation);
                  }}
                >
                  {this.returnFormFields()}
                </Form>
              )}
            </Mutation>
          );
        }
      case "city":
        if (this.state.selectedId !== "") {
          return (
            <Mutation<UpdateCityTypeMutation, UpdateCityTypeMutationVariables>
              mutation={gql(updateCityType)}
            >
              {updateCityTypeMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.editCity(updateCityTypeMutation);
                  }}
                >
                  {this.returnCityFields()}
                </Form>
              )}
            </Mutation>
          );
        } else {
          return (
            <Mutation<CreateCityTypeMutation, CreateCityTypeMutationVariables>
              mutation={gql(createCityType)}
            >
              {createCityTypeMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.createCity(createCityTypeMutation);
                  }}
                >
                  {this.returnCityFields()}
                </Form>
              )}
            </Mutation>
          );
        }
      case "area":
        if (this.state.selectedId !== "") {
          return (
            <Mutation<UpdateAreaTypeMutation, UpdateAreaTypeMutationVariables>
              mutation={gql(updateAreaType)}
            >
              {updateAreaTypeMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.editArea(updateAreaTypeMutation);
                  }}
                >
                  {this.returnCityFields()}
                </Form>
              )}
            </Mutation>
          );
        } else {
          return (
            <Mutation<CreateAreaTypeMutation, CreateAreaTypeMutationVariables>
              mutation={gql(createAreaType)}
            >
              {createAreaTypeMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.createArea(createAreaTypeMutation);
                  }}
                >
                  {this.returnCityFields()}
                </Form>
              )}
            </Mutation>
          );
        }
      default:
        return <div>There was an error loading your data.</div>;
    }
  }
}

export default AddEditLocation;
