/* tslint:disable */
//  This file was automatically generated and should not be edited.

export enum UserRole {
  ROOTS_SUPER_ADMIN = "ROOTS_SUPER_ADMIN",
  ROOTS_ADMIN = "ROOTS_ADMIN",
  CONSUMER_ADMIN = "CONSUMER_ADMIN",
  SUPER_USER = "SUPER_USER",
  USER = "USER",
}


export enum UserStatus {
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
}


export enum Industry {
  INDUSTRY_ONE = "INDUSTRY_ONE",
  INDUSTRY_TWO = "INDUSTRY_TWO",
  INDUSTRY_THREE = "INDUSTRY_THREE",
}


export type ModelCompanyFilterInput = {
  id?: ModelIDFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  name?: ModelStringFilterInput | null,
  emailAddress?: ModelStringFilterInput | null,
  contactNumber?: ModelStringFilterInput | null,
  address?: ModelStringFilterInput | null,
  industry?: ModelIndustryFilterInput | null,
  physicalAddress?: ModelStringFilterInput | null,
  numberOfEmployees?: ModelStringFilterInput | null,
  archived?: ModelBooleanFilterInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
};

export type ModelIDFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelStringFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIndustryFilterInput = {
  eq?: Industry | null,
  ne?: Industry | null,
};

export type ModelBooleanFilterInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelReportFilterInput = {
  id?: ModelIDFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  name?: ModelStringFilterInput | null,
  description?: ModelStringFilterInput | null,
  publishedDate?: ModelStringFilterInput | null,
  price?: ModelStringFilterInput | null,
  documentLocation?: ModelStringFilterInput | null,
  maxDownloads?: ModelStringFilterInput | null,
  downloadable?: ModelBooleanFilterInput | null,
  status?: ModelReportStatusFilterInput | null,
  paidOrFree?: ModelBooleanFilterInput | null,
  reportDetails?: ModelStringFilterInput | null,
  coverImageUrl?: ModelStringFilterInput | null,
  documentResourceUrl?: ModelStringFilterInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
};

export type ModelReportStatusFilterInput = {
  eq?: ReportStatus | null,
  ne?: ReportStatus | null,
};

export enum ReportStatus {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
  ARCHIVED = "ARCHIVED",
}


export type ModelTransactionFilterInput = {
  id?: ModelIDFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  cost?: ModelStringFilterInput | null,
  and?: Array< ModelTransactionFilterInput | null > | null,
  or?: Array< ModelTransactionFilterInput | null > | null,
  not?: ModelTransactionFilterInput | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelTagFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  and?: Array< ModelTagFilterInput | null > | null,
  or?: Array< ModelTagFilterInput | null > | null,
  not?: ModelTagFilterInput | null,
};

export type ModelInterestFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  and?: Array< ModelInterestFilterInput | null > | null,
  or?: Array< ModelInterestFilterInput | null > | null,
  not?: ModelInterestFilterInput | null,
};

export type ModelCompanyCardFilterInput = {
  id?: ModelIDFilterInput | null,
  and?: Array< ModelCompanyCardFilterInput | null > | null,
  or?: Array< ModelCompanyCardFilterInput | null > | null,
  not?: ModelCompanyCardFilterInput | null,
};

export enum CardType {
  VISA = "VISA",
  MASTER_CARD = "MASTER_CARD",
  AMERICAN_EXPRESS = "AMERICAN_EXPRESS",
}


export type CreateTaskInput = {
  id?: string | null,
  title: string,
  description?: string | null,
  status?: string | null,
};

export type UpdateTaskInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  status?: string | null,
};

export type DeleteTaskInput = {
  id?: string | null,
};

export type CreateCompanyInput = {
  id?: string | null,
  createdAt?: string | null,
  name?: string | null,
  emailAddress?: string | null,
  contactNumber?: string | null,
  address?: string | null,
  industry?: Industry | null,
  physicalAddress?: string | null,
  numberOfEmployees?: string | null,
  archived?: boolean | null,
  companyAreaId?: string | null,
  companyTeamLeadId?: string | null,
  companyTransactionsId?: string | null,
};

export type UpdateCompanyInput = {
  id: string,
  createdAt?: string | null,
  name?: string | null,
  emailAddress?: string | null,
  contactNumber?: string | null,
  address?: string | null,
  industry?: Industry | null,
  physicalAddress?: string | null,
  numberOfEmployees?: string | null,
  archived?: boolean | null,
  companyAreaId?: string | null,
  companyTeamLeadId?: string | null,
  companyTransactionsId?: string | null,
};

export type DeleteCompanyInput = {
  id?: string | null,
};

export type CreateUserInput = {
  id?: string | null,
  dateOfBirth?: string | null,
  createdAt?: string | null,
  name?: string | null,
  surname?: string | null,
  emailAddress?: string | null,
  contactNumber?: string | null,
  idNumber?: string | null,
  physicalAddress?: string | null,
  archived?: boolean | null,
  verified?: boolean | null,
  role?: UserRole | null,
  status?: UserStatus | null,
  jobTitle?: string | null,
  userCompanyTeamLeadId?: string | null,
};

export type UpdateUserInput = {
  id: string,
  dateOfBirth?: string | null,
  createdAt?: string | null,
  name?: string | null,
  surname?: string | null,
  emailAddress?: string | null,
  contactNumber?: string | null,
  idNumber?: string | null,
  physicalAddress?: string | null,
  archived?: boolean | null,
  verified?: boolean | null,
  role?: UserRole | null,
  status?: UserStatus | null,
  jobTitle?: string | null,
  userCompanyTeamLeadId?: string | null,
};

export type DeleteUserInput = {
  id?: string | null,
};

export type CreateReportInput = {
  id?: string | null,
  createdAt?: string | null,
  name?: string | null,
  description?: string | null,
  publishedDate?: string | null,
  price?: string | null,
  documentLocation?: string | null,
  maxDownloads?: string | null,
  downloadable?: boolean | null,
  status?: ReportStatus | null,
  paidOrFree?: boolean | null,
  reportDetails?: string | null,
  coverImageUrl?: string | null,
  documentResourceUrl?: string | null,
  reportAreaId?: string | null,
  reportTransactionsId?: string | null,
};

export type UpdateReportInput = {
  id: string,
  createdAt?: string | null,
  name?: string | null,
  description?: string | null,
  publishedDate?: string | null,
  price?: string | null,
  documentLocation?: string | null,
  maxDownloads?: string | null,
  downloadable?: boolean | null,
  status?: ReportStatus | null,
  paidOrFree?: boolean | null,
  reportDetails?: string | null,
  coverImageUrl?: string | null,
  documentResourceUrl?: string | null,
  reportAreaId?: string | null,
  reportTransactionsId?: string | null,
};

export type DeleteReportInput = {
  id?: string | null,
};

export type CreateRegionInput = {
  id?: string | null,
  name?: string | null,
};

export type UpdateRegionInput = {
  id: string,
  name?: string | null,
};

export type DeleteRegionInput = {
  id?: string | null,
};

export type CreateTagInput = {
  id?: string | null,
  name?: string | null,
  createdAt?: string | null,
};

export type UpdateTagInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
};

export type DeleteTagInput = {
  id?: string | null,
};

export type CreateCategoryInput = {
  id?: string | null,
  name?: string | null,
  createdAt?: string | null,
};

export type UpdateCategoryInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
};

export type DeleteCategoryInput = {
  id?: string | null,
};

export type CreateVoucherInput = {
  id?: string | null,
  code?: string | null,
  discountPercentage?: string | null,
  numberOfUses?: string | null,
  expiryDate?: string | null,
  archived?: boolean | null,
  createdAt?: string | null,
};

export type UpdateVoucherInput = {
  id: string,
  code?: string | null,
  discountPercentage?: string | null,
  numberOfUses?: string | null,
  expiryDate?: string | null,
  archived?: boolean | null,
  createdAt?: string | null,
};

export type DeleteVoucherInput = {
  id?: string | null,
};

export type CreateFaqInput = {
  id?: string | null,
  question?: string | null,
  answer?: string | null,
  faqCategoryId?: string | null,
};

export type UpdateFaqInput = {
  id: string,
  question?: string | null,
  answer?: string | null,
  faqCategoryId?: string | null,
};

export type DeleteFaqInput = {
  id?: string | null,
};

export type CreateFaqTypeInput = {
  id?: string | null,
  title?: string | null,
};

export type UpdateFaqTypeInput = {
  id: string,
  title?: string | null,
};

export type DeleteFaqTypeInput = {
  id?: string | null,
};

export type CreateInterestInput = {
  id?: string | null,
  name?: string | null,
  createdAt?: string | null,
};

export type UpdateInterestInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
};

export type DeleteInterestInput = {
  id?: string | null,
};

export type CreateTransactionInput = {
  id?: string | null,
  createdAt?: string | null,
  cost?: string | null,
  transactionVoucherId?: string | null,
  transactionReportId: string,
  transactionCompanyId: string,
};

export type UpdateTransactionInput = {
  id: string,
  createdAt?: string | null,
  cost?: string | null,
  transactionVoucherId?: string | null,
  transactionReportId?: string | null,
  transactionCompanyId?: string | null,
};

export type DeleteTransactionInput = {
  id?: string | null,
};

export type CreateCardInput = {
  id?: string | null,
  type?: CardType | null,
  number?: string | null,
  nameOnCard?: string | null,
  expiryMonth?: string | null,
  expiryYear?: string | null,
  peachPaymentCardId?: string | null,
  cardCompanyId?: string | null,
};

export type UpdateCardInput = {
  id: string,
  type?: CardType | null,
  number?: string | null,
  nameOnCard?: string | null,
  expiryMonth?: string | null,
  expiryYear?: string | null,
  peachPaymentCardId?: string | null,
  cardCompanyId?: string | null,
};

export type DeleteCardInput = {
  id?: string | null,
};

export type CreateProvinceTypeInput = {
  id?: string | null,
  name?: string | null,
  createdAt?: string | null,
};

export type UpdateProvinceTypeInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
};

export type DeleteProvinceTypeInput = {
  id?: string | null,
};

export type CreateCityTypeInput = {
  id?: string | null,
  name?: string | null,
  createdAt?: string | null,
  cityTypeProvinceId?: string | null,
};

export type UpdateCityTypeInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
  cityTypeProvinceId?: string | null,
};

export type DeleteCityTypeInput = {
  id?: string | null,
};

export type CreateAreaTypeInput = {
  id?: string | null,
  name?: string | null,
  createdAt?: string | null,
  areaTypeCityId?: string | null,
};

export type UpdateAreaTypeInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
  areaTypeCityId?: string | null,
};

export type DeleteAreaTypeInput = {
  id?: string | null,
};

export type CreateSampleReportInput = {
  id?: string | null,
  name?: string | null,
  createdAt?: string | null,
  rootsDescription?: string | null,
  sampleReportDescription?: string | null,
  coverImageUrl?: string | null,
  documentResourceUrl?: string | null,
};

export type UpdateSampleReportInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
  rootsDescription?: string | null,
  sampleReportDescription?: string | null,
  coverImageUrl?: string | null,
  documentResourceUrl?: string | null,
};

export type DeleteSampleReportInput = {
  id?: string | null,
};

export type CreateTransactionVoucherInput = {
  id?: string | null,
  transactionVoucherTransactionId?: string | null,
  transactionVoucherVoucherId?: string | null,
};

export type UpdateTransactionVoucherInput = {
  id: string,
  transactionVoucherTransactionId?: string | null,
  transactionVoucherVoucherId?: string | null,
};

export type DeleteTransactionVoucherInput = {
  id?: string | null,
};

export type CreateCompanyCardInput = {
  id?: string | null,
  companyCardCompanyId?: string | null,
  companyCardCardId?: string | null,
};

export type UpdateCompanyCardInput = {
  id: string,
  companyCardCompanyId?: string | null,
  companyCardCardId?: string | null,
};

export type DeleteCompanyCardInput = {
  id?: string | null,
};

export type CreateCompanyInterestInput = {
  id?: string | null,
  companyInterestCompanyId?: string | null,
  companyInterestInterestsId?: string | null,
};

export type UpdateCompanyInterestInput = {
  id: string,
  companyInterestCompanyId?: string | null,
  companyInterestInterestsId?: string | null,
};

export type DeleteCompanyInterestInput = {
  id?: string | null,
};

export type CreateUserCompanyInput = {
  id?: string | null,
  userCompanyCompanyId?: string | null,
  userCompanyUserId?: string | null,
};

export type UpdateUserCompanyInput = {
  id: string,
  userCompanyCompanyId?: string | null,
  userCompanyUserId?: string | null,
};

export type DeleteUserCompanyInput = {
  id?: string | null,
};

export type CreateCompanyReportInput = {
  id?: string | null,
  createdAt?: string | null,
  companyReportReportId?: string | null,
  companyReportCompanyId?: string | null,
};

export type UpdateCompanyReportInput = {
  id: string,
  createdAt?: string | null,
  companyReportReportId?: string | null,
  companyReportCompanyId?: string | null,
};

export type DeleteCompanyReportInput = {
  id?: string | null,
};

export type CreateReportTagInput = {
  id?: string | null,
  reportTagTagId?: string | null,
  reportTagReportId?: string | null,
};

export type UpdateReportTagInput = {
  id: string,
  reportTagTagId?: string | null,
  reportTagReportId?: string | null,
};

export type DeleteReportTagInput = {
  id?: string | null,
};

export type CreateReportCategoryInput = {
  id?: string | null,
  reportCategoryCategoryId?: string | null,
  reportCategoryReportId?: string | null,
};

export type UpdateReportCategoryInput = {
  id: string,
  reportCategoryCategoryId?: string | null,
  reportCategoryReportId?: string | null,
};

export type DeleteReportCategoryInput = {
  id?: string | null,
};

export type CreateReportRegionInput = {
  id?: string | null,
  reportRegionRegionId?: string | null,
  reportRegionReportId?: string | null,
};

export type UpdateReportRegionInput = {
  id: string,
  reportRegionRegionId?: string | null,
  reportRegionReportId?: string | null,
};

export type DeleteReportRegionInput = {
  id?: string | null,
};

export type ModelTaskFilterInput = {
  id?: ModelIDFilterInput | null,
  title?: ModelStringFilterInput | null,
  description?: ModelStringFilterInput | null,
  status?: ModelStringFilterInput | null,
  and?: Array< ModelTaskFilterInput | null > | null,
  or?: Array< ModelTaskFilterInput | null > | null,
  not?: ModelTaskFilterInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDFilterInput | null,
  dateOfBirth?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  name?: ModelStringFilterInput | null,
  surname?: ModelStringFilterInput | null,
  emailAddress?: ModelStringFilterInput | null,
  contactNumber?: ModelStringFilterInput | null,
  idNumber?: ModelStringFilterInput | null,
  physicalAddress?: ModelStringFilterInput | null,
  archived?: ModelBooleanFilterInput | null,
  verified?: ModelBooleanFilterInput | null,
  role?: ModelUserRoleFilterInput | null,
  status?: ModelUserStatusFilterInput | null,
  jobTitle?: ModelStringFilterInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserRoleFilterInput = {
  eq?: UserRole | null,
  ne?: UserRole | null,
};

export type ModelUserStatusFilterInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type ModelRegionFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  and?: Array< ModelRegionFilterInput | null > | null,
  or?: Array< ModelRegionFilterInput | null > | null,
  not?: ModelRegionFilterInput | null,
};

export type ModelVoucherFilterInput = {
  id?: ModelIDFilterInput | null,
  code?: ModelStringFilterInput | null,
  discountPercentage?: ModelStringFilterInput | null,
  numberOfUses?: ModelStringFilterInput | null,
  expiryDate?: ModelStringFilterInput | null,
  archived?: ModelBooleanFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  and?: Array< ModelVoucherFilterInput | null > | null,
  or?: Array< ModelVoucherFilterInput | null > | null,
  not?: ModelVoucherFilterInput | null,
};

export type ModelFaqFilterInput = {
  id?: ModelIDFilterInput | null,
  question?: ModelStringFilterInput | null,
  answer?: ModelStringFilterInput | null,
  and?: Array< ModelFaqFilterInput | null > | null,
  or?: Array< ModelFaqFilterInput | null > | null,
  not?: ModelFaqFilterInput | null,
};

export type ModelFaqTypeFilterInput = {
  id?: ModelIDFilterInput | null,
  title?: ModelStringFilterInput | null,
  and?: Array< ModelFaqTypeFilterInput | null > | null,
  or?: Array< ModelFaqTypeFilterInput | null > | null,
  not?: ModelFaqTypeFilterInput | null,
};

export type ModelCardFilterInput = {
  id?: ModelIDFilterInput | null,
  type?: ModelCardTypeFilterInput | null,
  number?: ModelStringFilterInput | null,
  nameOnCard?: ModelStringFilterInput | null,
  expiryMonth?: ModelStringFilterInput | null,
  expiryYear?: ModelStringFilterInput | null,
  peachPaymentCardId?: ModelStringFilterInput | null,
  and?: Array< ModelCardFilterInput | null > | null,
  or?: Array< ModelCardFilterInput | null > | null,
  not?: ModelCardFilterInput | null,
};

export type ModelCardTypeFilterInput = {
  eq?: CardType | null,
  ne?: CardType | null,
};

export type ModelProvinceTypeFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  and?: Array< ModelProvinceTypeFilterInput | null > | null,
  or?: Array< ModelProvinceTypeFilterInput | null > | null,
  not?: ModelProvinceTypeFilterInput | null,
};

export type ModelCityTypeFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  and?: Array< ModelCityTypeFilterInput | null > | null,
  or?: Array< ModelCityTypeFilterInput | null > | null,
  not?: ModelCityTypeFilterInput | null,
};

export type ModelAreaTypeFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  and?: Array< ModelAreaTypeFilterInput | null > | null,
  or?: Array< ModelAreaTypeFilterInput | null > | null,
  not?: ModelAreaTypeFilterInput | null,
};

export type ModelSampleReportFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  rootsDescription?: ModelStringFilterInput | null,
  sampleReportDescription?: ModelStringFilterInput | null,
  coverImageUrl?: ModelStringFilterInput | null,
  documentResourceUrl?: ModelStringFilterInput | null,
  and?: Array< ModelSampleReportFilterInput | null > | null,
  or?: Array< ModelSampleReportFilterInput | null > | null,
  not?: ModelSampleReportFilterInput | null,
};

export type ModelTransactionVoucherFilterInput = {
  id?: ModelIDFilterInput | null,
  and?: Array< ModelTransactionVoucherFilterInput | null > | null,
  or?: Array< ModelTransactionVoucherFilterInput | null > | null,
  not?: ModelTransactionVoucherFilterInput | null,
};

export type ModelCompanyInterestFilterInput = {
  id?: ModelIDFilterInput | null,
  and?: Array< ModelCompanyInterestFilterInput | null > | null,
  or?: Array< ModelCompanyInterestFilterInput | null > | null,
  not?: ModelCompanyInterestFilterInput | null,
};

export type ModelUserCompanyFilterInput = {
  id?: ModelIDFilterInput | null,
  and?: Array< ModelUserCompanyFilterInput | null > | null,
  or?: Array< ModelUserCompanyFilterInput | null > | null,
  not?: ModelUserCompanyFilterInput | null,
};

export type ModelCompanyReportFilterInput = {
  id?: ModelIDFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  and?: Array< ModelCompanyReportFilterInput | null > | null,
  or?: Array< ModelCompanyReportFilterInput | null > | null,
  not?: ModelCompanyReportFilterInput | null,
};

export type ModelReportTagFilterInput = {
  id?: ModelIDFilterInput | null,
  and?: Array< ModelReportTagFilterInput | null > | null,
  or?: Array< ModelReportTagFilterInput | null > | null,
  not?: ModelReportTagFilterInput | null,
};

export type ModelReportCategoryFilterInput = {
  id?: ModelIDFilterInput | null,
  and?: Array< ModelReportCategoryFilterInput | null > | null,
  or?: Array< ModelReportCategoryFilterInput | null > | null,
  not?: ModelReportCategoryFilterInput | null,
};

export type ModelReportRegionFilterInput = {
  id?: ModelIDFilterInput | null,
  and?: Array< ModelReportRegionFilterInput | null > | null,
  or?: Array< ModelReportRegionFilterInput | null > | null,
  not?: ModelReportRegionFilterInput | null,
};

export type GetCompaniesUsersQueryVariables = {
  id: string,
};

export type GetCompaniesUsersQuery = {
  getCompany:  {
    __typename: "Company",
    id: string,
    name: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    address: string | null,
    physicalAddress: string | null,
    numberOfEmployees: string | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    teamMembers:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type GetUsersCompanyQueryVariables = {
  id: string,
  limit?: number | null,
};

export type GetUsersCompanyQuery = {
  getUser:  {
    __typename: "User",
    id: string,
    dateOfBirth: string | null,
    name: string | null,
    surname: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    idNumber: string | null,
    physicalAddress: string | null,
    archived: boolean | null,
    verified: boolean | null,
    role: UserRole | null,
    status: UserStatus | null,
    jobTitle: string | null,
    company:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          industry: Industry | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              province:  {
                __typename: "ProvinceType",
                id: string,
                name: string | null,
                createdAt: string | null,
              } | null,
              createdAt: string | null,
              areas:  {
                __typename: "ModelAreaTypeConnection",
                nextToken: string | null,
              } | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
              user:  {
                __typename: "User",
                id: string,
                dateOfBirth: string | null,
                name: string | null,
                surname: string | null,
                emailAddress: string | null,
                contactNumber: string | null,
                idNumber: string | null,
                physicalAddress: string | null,
                archived: boolean | null,
                verified: boolean | null,
                role: UserRole | null,
                status: UserStatus | null,
                jobTitle: string | null,
              } | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
              card:  {
                __typename: "Card",
                id: string,
                expiryMonth: string | null,
                expiryYear: string | null,
                nameOnCard: string | null,
                number: string | null,
                peachPaymentCardId: string | null,
              } | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CountQueryVariables = {
  companyFilter?: ModelCompanyFilterInput | null,
  reportFilter?: ModelReportFilterInput | null,
  transFilter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CountQuery = {
  listCompanys:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      createdAt: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
  listReports:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      price: string | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
  listTransactions:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCategorysListQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCategorysListQuery = {
  listCategorys:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string | null,
      reports:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetTagsListQueryVariables = {
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTagsListQuery = {
  listTags:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetInterestsListQueryVariables = {
  filter?: ModelInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetInterestsListQuery = {
  listInterests:  {
    __typename: "ModelInterestConnection",
    items:  Array< {
      __typename: "Interest",
      id: string,
      name: string | null,
      createdAt: string | null,
      company:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetReportListQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetReportListQuery = {
  listReports:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCompanyCardsQueryVariables = {
  filter?: ModelCompanyCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCompanyCardsQuery = {
  listCompanyCards:  {
    __typename: "ModelCompanyCardConnection",
    items:  Array< {
      __typename: "CompanyCard",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
      } | null,
      card:  {
        __typename: "Card",
        id: string,
        nameOnCard: string | null,
        number: string | null,
        type: CardType | null,
        expiryMonth: string | null,
        expiryYear: string | null,
        peachPaymentCardId: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetUserCompanyReportsQueryVariables = {
  id: string,
};

export type GetUserCompanyReportsQuery = {
  getUser:  {
    __typename: "User",
    id: string,
    company:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
              card:  {
                __typename: "Card",
                id: string,
                number: string | null,
                peachPaymentCardId: string | null,
              } | null,
            } | null > | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
              report:  {
                __typename: "Report",
                id: string,
                name: string | null,
                price: string | null,
              } | null,
            } | null > | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type GetCompanyReportsQueryVariables = {
  id: string,
  limit?: number | null,
};

export type GetCompanyReportsQuery = {
  getUser:  {
    __typename: "User",
    id: string,
    company:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        company:  {
          __typename: "Company",
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              createdAt: string | null,
              report:  {
                __typename: "Report",
                id: string,
                name: string | null,
                price: string | null,
              } | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type GetSingleReportQueryVariables = {
  id: string,
};

export type GetSingleReportQuery = {
  getReport:  {
    __typename: "Report",
    id: string,
    name: string | null,
    price: string | null,
    maxDownloads: string | null,
    companys:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type GetSingleReportCTQueryVariables = {
  id: string,
};

export type GetSingleReportCTQuery = {
  getReport:  {
    __typename: "Report",
    id: string,
    categories:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    tags:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type getSalesQueryVariables = {
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type getSalesQuery = {
  listTransactions:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      company:  {
        __typename: "Company",
        id: string,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        numberOfEmployees: string | null,
        industry: Industry | null,
        address: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      },
      report:  {
        __typename: "Report",
        id: string,
        name: string | null,
        price: string | null,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetReportTransQueryVariables = {
  id: string,
};

export type GetReportTransQuery = {
  getReport:  {
    __typename: "Report",
    id: string,
    createdAt: string | null,
    name: string | null,
    description: string | null,
    publishedDate: string | null,
    price: string | null,
    documentLocation: string | null,
    maxDownloads: string | null,
    downloadable: boolean | null,
    status: ReportStatus | null,
    paidOrFree: boolean | null,
    reportDetails: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    tags:  {
      __typename: "ModelReportTagConnection",
      nextToken: string | null,
    } | null,
    categories:  {
      __typename: "ModelReportCategoryConnection",
      nextToken: string | null,
    } | null,
    region:  {
      __typename: "ModelReportRegionConnection",
      nextToken: string | null,
    } | null,
    companys:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
        } | null,
      } | null > | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
    } | null,
  } | null,
};

export type getOneCategoryQueryVariables = {
  id: string,
};

export type getOneCategoryQuery = {
  getCategory:  {
    __typename: "Category",
    id: string,
    reports:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        report:  {
          __typename: "Report",
          id: string,
          name: string | null,
          price: string | null,
          description: string | null,
          coverImageUrl: string | null,
          status: ReportStatus | null,
        } | null,
      } | null > | null,
    } | null,
  } | null,
};

export type getFeaturedQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
};

export type getFeaturedQuery = {
  listReports:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      name: string | null,
      price: string | null,
      description: string | null,
      coverImageUrl: string | null,
      status: ReportStatus | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type CreateTaskMutationVariables = {
  input: CreateTaskInput,
};

export type CreateTaskMutation = {
  createTask:  {
    __typename: "Task",
    id: string,
    title: string,
    description: string | null,
    status: string | null,
    assignedTo:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateTaskMutationVariables = {
  input: UpdateTaskInput,
};

export type UpdateTaskMutation = {
  updateTask:  {
    __typename: "Task",
    id: string,
    title: string,
    description: string | null,
    status: string | null,
    assignedTo:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteTaskMutationVariables = {
  input: DeleteTaskInput,
};

export type DeleteTaskMutation = {
  deleteTask:  {
    __typename: "Task",
    id: string,
    title: string,
    description: string | null,
    status: string | null,
    assignedTo:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
};

export type CreateCompanyMutation = {
  createCompany:  {
    __typename: "Company",
    id: string,
    createdAt: string | null,
    name: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    address: string | null,
    industry: Industry | null,
    physicalAddress: string | null,
    numberOfEmployees: string | null,
    archived: boolean | null,
    interests:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    teamLead:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    teamMembers:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    cards:  {
      __typename: "ModelCompanyCardConnection",
      items:  Array< {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
};

export type UpdateCompanyMutation = {
  updateCompany:  {
    __typename: "Company",
    id: string,
    createdAt: string | null,
    name: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    address: string | null,
    industry: Industry | null,
    physicalAddress: string | null,
    numberOfEmployees: string | null,
    archived: boolean | null,
    interests:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    teamLead:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    teamMembers:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    cards:  {
      __typename: "ModelCompanyCardConnection",
      items:  Array< {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
};

export type DeleteCompanyMutation = {
  deleteCompany:  {
    __typename: "Company",
    id: string,
    createdAt: string | null,
    name: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    address: string | null,
    industry: Industry | null,
    physicalAddress: string | null,
    numberOfEmployees: string | null,
    archived: boolean | null,
    interests:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    teamLead:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    teamMembers:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    cards:  {
      __typename: "ModelCompanyCardConnection",
      items:  Array< {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
};

export type CreateUserMutation = {
  createUser:  {
    __typename: "User",
    id: string,
    dateOfBirth: string | null,
    createdAt: string | null,
    name: string | null,
    surname: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    idNumber: string | null,
    physicalAddress: string | null,
    archived: boolean | null,
    verified: boolean | null,
    role: UserRole | null,
    status: UserStatus | null,
    jobTitle: string | null,
    company:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companyTeamLead:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
};

export type UpdateUserMutation = {
  updateUser:  {
    __typename: "User",
    id: string,
    dateOfBirth: string | null,
    createdAt: string | null,
    name: string | null,
    surname: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    idNumber: string | null,
    physicalAddress: string | null,
    archived: boolean | null,
    verified: boolean | null,
    role: UserRole | null,
    status: UserStatus | null,
    jobTitle: string | null,
    company:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companyTeamLead:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
};

export type DeleteUserMutation = {
  deleteUser:  {
    __typename: "User",
    id: string,
    dateOfBirth: string | null,
    createdAt: string | null,
    name: string | null,
    surname: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    idNumber: string | null,
    physicalAddress: string | null,
    archived: boolean | null,
    verified: boolean | null,
    role: UserRole | null,
    status: UserStatus | null,
    jobTitle: string | null,
    company:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companyTeamLead:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateReportMutationVariables = {
  input: CreateReportInput,
};

export type CreateReportMutation = {
  createReport:  {
    __typename: "Report",
    id: string,
    createdAt: string | null,
    name: string | null,
    description: string | null,
    publishedDate: string | null,
    price: string | null,
    documentLocation: string | null,
    maxDownloads: string | null,
    downloadable: boolean | null,
    status: ReportStatus | null,
    paidOrFree: boolean | null,
    reportDetails: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    tags:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    categories:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    region:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companys:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
  } | null,
};

export type UpdateReportMutationVariables = {
  input: UpdateReportInput,
};

export type UpdateReportMutation = {
  updateReport:  {
    __typename: "Report",
    id: string,
    createdAt: string | null,
    name: string | null,
    description: string | null,
    publishedDate: string | null,
    price: string | null,
    documentLocation: string | null,
    maxDownloads: string | null,
    downloadable: boolean | null,
    status: ReportStatus | null,
    paidOrFree: boolean | null,
    reportDetails: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    tags:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    categories:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    region:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companys:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
  } | null,
};

export type DeleteReportMutationVariables = {
  input: DeleteReportInput,
};

export type DeleteReportMutation = {
  deleteReport:  {
    __typename: "Report",
    id: string,
    createdAt: string | null,
    name: string | null,
    description: string | null,
    publishedDate: string | null,
    price: string | null,
    documentLocation: string | null,
    maxDownloads: string | null,
    downloadable: boolean | null,
    status: ReportStatus | null,
    paidOrFree: boolean | null,
    reportDetails: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    tags:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    categories:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    region:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companys:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
  } | null,
};

export type CreateRegionMutationVariables = {
  input: CreateRegionInput,
};

export type CreateRegionMutation = {
  createRegion:  {
    __typename: "Region",
    id: string,
    name: string | null,
    reports:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateRegionMutationVariables = {
  input: UpdateRegionInput,
};

export type UpdateRegionMutation = {
  updateRegion:  {
    __typename: "Region",
    id: string,
    name: string | null,
    reports:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteRegionMutationVariables = {
  input: DeleteRegionInput,
};

export type DeleteRegionMutation = {
  deleteRegion:  {
    __typename: "Region",
    id: string,
    name: string | null,
    reports:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateTagMutationVariables = {
  input: CreateTagInput,
};

export type CreateTagMutation = {
  createTag:  {
    __typename: "Tag",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateTagMutationVariables = {
  input: UpdateTagInput,
};

export type UpdateTagMutation = {
  updateTag:  {
    __typename: "Tag",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteTagMutationVariables = {
  input: DeleteTagInput,
};

export type DeleteTagMutation = {
  deleteTag:  {
    __typename: "Tag",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
};

export type CreateCategoryMutation = {
  createCategory:  {
    __typename: "Category",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
};

export type UpdateCategoryMutation = {
  updateCategory:  {
    __typename: "Category",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
};

export type DeleteCategoryMutation = {
  deleteCategory:  {
    __typename: "Category",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateVoucherMutationVariables = {
  input: CreateVoucherInput,
};

export type CreateVoucherMutation = {
  createVoucher:  {
    __typename: "Voucher",
    id: string,
    code: string | null,
    discountPercentage: string | null,
    numberOfUses: string | null,
    expiryDate: string | null,
    archived: boolean | null,
    createdAt: string | null,
    transactions:  {
      __typename: "ModelTransactionVoucherConnection",
      items:  Array< {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateVoucherMutationVariables = {
  input: UpdateVoucherInput,
};

export type UpdateVoucherMutation = {
  updateVoucher:  {
    __typename: "Voucher",
    id: string,
    code: string | null,
    discountPercentage: string | null,
    numberOfUses: string | null,
    expiryDate: string | null,
    archived: boolean | null,
    createdAt: string | null,
    transactions:  {
      __typename: "ModelTransactionVoucherConnection",
      items:  Array< {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteVoucherMutationVariables = {
  input: DeleteVoucherInput,
};

export type DeleteVoucherMutation = {
  deleteVoucher:  {
    __typename: "Voucher",
    id: string,
    code: string | null,
    discountPercentage: string | null,
    numberOfUses: string | null,
    expiryDate: string | null,
    archived: boolean | null,
    createdAt: string | null,
    transactions:  {
      __typename: "ModelTransactionVoucherConnection",
      items:  Array< {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateFaqMutationVariables = {
  input: CreateFaqInput,
};

export type CreateFaqMutation = {
  createFaq:  {
    __typename: "Faq",
    id: string,
    question: string | null,
    answer: string | null,
    category:  {
      __typename: "FaqType",
      id: string,
      title: string | null,
      faqs:  {
        __typename: "ModelFaqConnection",
        items:  Array< {
          __typename: "Faq",
          id: string,
          question: string | null,
          answer: string | null,
          category:  {
            __typename: "FaqType",
            id: string,
            title: string | null,
            faqs:  {
              __typename: "ModelFaqConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateFaqMutationVariables = {
  input: UpdateFaqInput,
};

export type UpdateFaqMutation = {
  updateFaq:  {
    __typename: "Faq",
    id: string,
    question: string | null,
    answer: string | null,
    category:  {
      __typename: "FaqType",
      id: string,
      title: string | null,
      faqs:  {
        __typename: "ModelFaqConnection",
        items:  Array< {
          __typename: "Faq",
          id: string,
          question: string | null,
          answer: string | null,
          category:  {
            __typename: "FaqType",
            id: string,
            title: string | null,
            faqs:  {
              __typename: "ModelFaqConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteFaqMutationVariables = {
  input: DeleteFaqInput,
};

export type DeleteFaqMutation = {
  deleteFaq:  {
    __typename: "Faq",
    id: string,
    question: string | null,
    answer: string | null,
    category:  {
      __typename: "FaqType",
      id: string,
      title: string | null,
      faqs:  {
        __typename: "ModelFaqConnection",
        items:  Array< {
          __typename: "Faq",
          id: string,
          question: string | null,
          answer: string | null,
          category:  {
            __typename: "FaqType",
            id: string,
            title: string | null,
            faqs:  {
              __typename: "ModelFaqConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateFaqTypeMutationVariables = {
  input: CreateFaqTypeInput,
};

export type CreateFaqTypeMutation = {
  createFaqType:  {
    __typename: "FaqType",
    id: string,
    title: string | null,
    faqs:  {
      __typename: "ModelFaqConnection",
      items:  Array< {
        __typename: "Faq",
        id: string,
        question: string | null,
        answer: string | null,
        category:  {
          __typename: "FaqType",
          id: string,
          title: string | null,
          faqs:  {
            __typename: "ModelFaqConnection",
            items:  Array< {
              __typename: "Faq",
              id: string,
              question: string | null,
              answer: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateFaqTypeMutationVariables = {
  input: UpdateFaqTypeInput,
};

export type UpdateFaqTypeMutation = {
  updateFaqType:  {
    __typename: "FaqType",
    id: string,
    title: string | null,
    faqs:  {
      __typename: "ModelFaqConnection",
      items:  Array< {
        __typename: "Faq",
        id: string,
        question: string | null,
        answer: string | null,
        category:  {
          __typename: "FaqType",
          id: string,
          title: string | null,
          faqs:  {
            __typename: "ModelFaqConnection",
            items:  Array< {
              __typename: "Faq",
              id: string,
              question: string | null,
              answer: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteFaqTypeMutationVariables = {
  input: DeleteFaqTypeInput,
};

export type DeleteFaqTypeMutation = {
  deleteFaqType:  {
    __typename: "FaqType",
    id: string,
    title: string | null,
    faqs:  {
      __typename: "ModelFaqConnection",
      items:  Array< {
        __typename: "Faq",
        id: string,
        question: string | null,
        answer: string | null,
        category:  {
          __typename: "FaqType",
          id: string,
          title: string | null,
          faqs:  {
            __typename: "ModelFaqConnection",
            items:  Array< {
              __typename: "Faq",
              id: string,
              question: string | null,
              answer: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateInterestMutationVariables = {
  input: CreateInterestInput,
};

export type CreateInterestMutation = {
  createInterest:  {
    __typename: "Interest",
    id: string,
    name: string | null,
    createdAt: string | null,
    company:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateInterestMutationVariables = {
  input: UpdateInterestInput,
};

export type UpdateInterestMutation = {
  updateInterest:  {
    __typename: "Interest",
    id: string,
    name: string | null,
    createdAt: string | null,
    company:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteInterestMutationVariables = {
  input: DeleteInterestInput,
};

export type DeleteInterestMutation = {
  deleteInterest:  {
    __typename: "Interest",
    id: string,
    name: string | null,
    createdAt: string | null,
    company:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateTransactionMutationVariables = {
  input: CreateTransactionInput,
};

export type CreateTransactionMutation = {
  createTransaction:  {
    __typename: "Transaction",
    id: string,
    createdAt: string | null,
    cost: string | null,
    voucher:  {
      __typename: "TransactionVoucher",
      id: string,
      transaction:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      voucher:  {
        __typename: "Voucher",
        id: string,
        code: string | null,
        discountPercentage: string | null,
        numberOfUses: string | null,
        expiryDate: string | null,
        archived: boolean | null,
        createdAt: string | null,
        transactions:  {
          __typename: "ModelTransactionVoucherConnection",
          items:  Array< {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    },
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UpdateTransactionMutationVariables = {
  input: UpdateTransactionInput,
};

export type UpdateTransactionMutation = {
  updateTransaction:  {
    __typename: "Transaction",
    id: string,
    createdAt: string | null,
    cost: string | null,
    voucher:  {
      __typename: "TransactionVoucher",
      id: string,
      transaction:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      voucher:  {
        __typename: "Voucher",
        id: string,
        code: string | null,
        discountPercentage: string | null,
        numberOfUses: string | null,
        expiryDate: string | null,
        archived: boolean | null,
        createdAt: string | null,
        transactions:  {
          __typename: "ModelTransactionVoucherConnection",
          items:  Array< {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    },
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type DeleteTransactionMutationVariables = {
  input: DeleteTransactionInput,
};

export type DeleteTransactionMutation = {
  deleteTransaction:  {
    __typename: "Transaction",
    id: string,
    createdAt: string | null,
    cost: string | null,
    voucher:  {
      __typename: "TransactionVoucher",
      id: string,
      transaction:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      voucher:  {
        __typename: "Voucher",
        id: string,
        code: string | null,
        discountPercentage: string | null,
        numberOfUses: string | null,
        expiryDate: string | null,
        archived: boolean | null,
        createdAt: string | null,
        transactions:  {
          __typename: "ModelTransactionVoucherConnection",
          items:  Array< {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    },
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type CreateCardMutationVariables = {
  input: CreateCardInput,
};

export type CreateCardMutation = {
  createCard:  {
    __typename: "Card",
    id: string,
    type: CardType | null,
    number: string | null,
    nameOnCard: string | null,
    expiryMonth: string | null,
    expiryYear: string | null,
    peachPaymentCardId: string | null,
    company:  {
      __typename: "CompanyCard",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      card:  {
        __typename: "Card",
        id: string,
        type: CardType | null,
        number: string | null,
        nameOnCard: string | null,
        expiryMonth: string | null,
        expiryYear: string | null,
        peachPaymentCardId: string | null,
        company:  {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateCardMutationVariables = {
  input: UpdateCardInput,
};

export type UpdateCardMutation = {
  updateCard:  {
    __typename: "Card",
    id: string,
    type: CardType | null,
    number: string | null,
    nameOnCard: string | null,
    expiryMonth: string | null,
    expiryYear: string | null,
    peachPaymentCardId: string | null,
    company:  {
      __typename: "CompanyCard",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      card:  {
        __typename: "Card",
        id: string,
        type: CardType | null,
        number: string | null,
        nameOnCard: string | null,
        expiryMonth: string | null,
        expiryYear: string | null,
        peachPaymentCardId: string | null,
        company:  {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteCardMutationVariables = {
  input: DeleteCardInput,
};

export type DeleteCardMutation = {
  deleteCard:  {
    __typename: "Card",
    id: string,
    type: CardType | null,
    number: string | null,
    nameOnCard: string | null,
    expiryMonth: string | null,
    expiryYear: string | null,
    peachPaymentCardId: string | null,
    company:  {
      __typename: "CompanyCard",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      card:  {
        __typename: "Card",
        id: string,
        type: CardType | null,
        number: string | null,
        nameOnCard: string | null,
        expiryMonth: string | null,
        expiryYear: string | null,
        peachPaymentCardId: string | null,
        company:  {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateProvinceTypeMutationVariables = {
  input: CreateProvinceTypeInput,
};

export type CreateProvinceTypeMutation = {
  createProvinceType:  {
    __typename: "ProvinceType",
    id: string,
    name: string | null,
    createdAt: string | null,
    cities:  {
      __typename: "ModelCityTypeConnection",
      items:  Array< {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateProvinceTypeMutationVariables = {
  input: UpdateProvinceTypeInput,
};

export type UpdateProvinceTypeMutation = {
  updateProvinceType:  {
    __typename: "ProvinceType",
    id: string,
    name: string | null,
    createdAt: string | null,
    cities:  {
      __typename: "ModelCityTypeConnection",
      items:  Array< {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteProvinceTypeMutationVariables = {
  input: DeleteProvinceTypeInput,
};

export type DeleteProvinceTypeMutation = {
  deleteProvinceType:  {
    __typename: "ProvinceType",
    id: string,
    name: string | null,
    createdAt: string | null,
    cities:  {
      __typename: "ModelCityTypeConnection",
      items:  Array< {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateCityTypeMutationVariables = {
  input: CreateCityTypeInput,
};

export type CreateCityTypeMutation = {
  createCityType:  {
    __typename: "CityType",
    id: string,
    name: string | null,
    province:  {
      __typename: "ProvinceType",
      id: string,
      name: string | null,
      createdAt: string | null,
      cities:  {
        __typename: "ModelCityTypeConnection",
        items:  Array< {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    createdAt: string | null,
    areas:  {
      __typename: "ModelAreaTypeConnection",
      items:  Array< {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateCityTypeMutationVariables = {
  input: UpdateCityTypeInput,
};

export type UpdateCityTypeMutation = {
  updateCityType:  {
    __typename: "CityType",
    id: string,
    name: string | null,
    province:  {
      __typename: "ProvinceType",
      id: string,
      name: string | null,
      createdAt: string | null,
      cities:  {
        __typename: "ModelCityTypeConnection",
        items:  Array< {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    createdAt: string | null,
    areas:  {
      __typename: "ModelAreaTypeConnection",
      items:  Array< {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteCityTypeMutationVariables = {
  input: DeleteCityTypeInput,
};

export type DeleteCityTypeMutation = {
  deleteCityType:  {
    __typename: "CityType",
    id: string,
    name: string | null,
    province:  {
      __typename: "ProvinceType",
      id: string,
      name: string | null,
      createdAt: string | null,
      cities:  {
        __typename: "ModelCityTypeConnection",
        items:  Array< {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    createdAt: string | null,
    areas:  {
      __typename: "ModelAreaTypeConnection",
      items:  Array< {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateAreaTypeMutationVariables = {
  input: CreateAreaTypeInput,
};

export type CreateAreaTypeMutation = {
  createAreaType:  {
    __typename: "AreaType",
    id: string,
    name: string | null,
    createdAt: string | null,
    city:  {
      __typename: "CityType",
      id: string,
      name: string | null,
      province:  {
        __typename: "ProvinceType",
        id: string,
        name: string | null,
        createdAt: string | null,
        cities:  {
          __typename: "ModelCityTypeConnection",
          items:  Array< {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      createdAt: string | null,
      areas:  {
        __typename: "ModelAreaTypeConnection",
        items:  Array< {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    companies:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateAreaTypeMutationVariables = {
  input: UpdateAreaTypeInput,
};

export type UpdateAreaTypeMutation = {
  updateAreaType:  {
    __typename: "AreaType",
    id: string,
    name: string | null,
    createdAt: string | null,
    city:  {
      __typename: "CityType",
      id: string,
      name: string | null,
      province:  {
        __typename: "ProvinceType",
        id: string,
        name: string | null,
        createdAt: string | null,
        cities:  {
          __typename: "ModelCityTypeConnection",
          items:  Array< {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      createdAt: string | null,
      areas:  {
        __typename: "ModelAreaTypeConnection",
        items:  Array< {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    companies:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteAreaTypeMutationVariables = {
  input: DeleteAreaTypeInput,
};

export type DeleteAreaTypeMutation = {
  deleteAreaType:  {
    __typename: "AreaType",
    id: string,
    name: string | null,
    createdAt: string | null,
    city:  {
      __typename: "CityType",
      id: string,
      name: string | null,
      province:  {
        __typename: "ProvinceType",
        id: string,
        name: string | null,
        createdAt: string | null,
        cities:  {
          __typename: "ModelCityTypeConnection",
          items:  Array< {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      createdAt: string | null,
      areas:  {
        __typename: "ModelAreaTypeConnection",
        items:  Array< {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    companies:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateSampleReportMutationVariables = {
  input: CreateSampleReportInput,
};

export type CreateSampleReportMutation = {
  createSampleReport:  {
    __typename: "SampleReport",
    id: string,
    name: string | null,
    createdAt: string | null,
    rootsDescription: string | null,
    sampleReportDescription: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
  } | null,
};

export type UpdateSampleReportMutationVariables = {
  input: UpdateSampleReportInput,
};

export type UpdateSampleReportMutation = {
  updateSampleReport:  {
    __typename: "SampleReport",
    id: string,
    name: string | null,
    createdAt: string | null,
    rootsDescription: string | null,
    sampleReportDescription: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
  } | null,
};

export type DeleteSampleReportMutationVariables = {
  input: DeleteSampleReportInput,
};

export type DeleteSampleReportMutation = {
  deleteSampleReport:  {
    __typename: "SampleReport",
    id: string,
    name: string | null,
    createdAt: string | null,
    rootsDescription: string | null,
    sampleReportDescription: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
  } | null,
};

export type CreateTransactionVoucherMutationVariables = {
  input: CreateTransactionVoucherInput,
};

export type CreateTransactionVoucherMutation = {
  createTransactionVoucher:  {
    __typename: "TransactionVoucher",
    id: string,
    transaction:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    voucher:  {
      __typename: "Voucher",
      id: string,
      code: string | null,
      discountPercentage: string | null,
      numberOfUses: string | null,
      expiryDate: string | null,
      archived: boolean | null,
      createdAt: string | null,
      transactions:  {
        __typename: "ModelTransactionVoucherConnection",
        items:  Array< {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateTransactionVoucherMutationVariables = {
  input: UpdateTransactionVoucherInput,
};

export type UpdateTransactionVoucherMutation = {
  updateTransactionVoucher:  {
    __typename: "TransactionVoucher",
    id: string,
    transaction:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    voucher:  {
      __typename: "Voucher",
      id: string,
      code: string | null,
      discountPercentage: string | null,
      numberOfUses: string | null,
      expiryDate: string | null,
      archived: boolean | null,
      createdAt: string | null,
      transactions:  {
        __typename: "ModelTransactionVoucherConnection",
        items:  Array< {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteTransactionVoucherMutationVariables = {
  input: DeleteTransactionVoucherInput,
};

export type DeleteTransactionVoucherMutation = {
  deleteTransactionVoucher:  {
    __typename: "TransactionVoucher",
    id: string,
    transaction:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    voucher:  {
      __typename: "Voucher",
      id: string,
      code: string | null,
      discountPercentage: string | null,
      numberOfUses: string | null,
      expiryDate: string | null,
      archived: boolean | null,
      createdAt: string | null,
      transactions:  {
        __typename: "ModelTransactionVoucherConnection",
        items:  Array< {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateCompanyCardMutationVariables = {
  input: CreateCompanyCardInput,
};

export type CreateCompanyCardMutation = {
  createCompanyCard:  {
    __typename: "CompanyCard",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    card:  {
      __typename: "Card",
      id: string,
      type: CardType | null,
      number: string | null,
      nameOnCard: string | null,
      expiryMonth: string | null,
      expiryYear: string | null,
      peachPaymentCardId: string | null,
      company:  {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateCompanyCardMutationVariables = {
  input: UpdateCompanyCardInput,
};

export type UpdateCompanyCardMutation = {
  updateCompanyCard:  {
    __typename: "CompanyCard",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    card:  {
      __typename: "Card",
      id: string,
      type: CardType | null,
      number: string | null,
      nameOnCard: string | null,
      expiryMonth: string | null,
      expiryYear: string | null,
      peachPaymentCardId: string | null,
      company:  {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteCompanyCardMutationVariables = {
  input: DeleteCompanyCardInput,
};

export type DeleteCompanyCardMutation = {
  deleteCompanyCard:  {
    __typename: "CompanyCard",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    card:  {
      __typename: "Card",
      id: string,
      type: CardType | null,
      number: string | null,
      nameOnCard: string | null,
      expiryMonth: string | null,
      expiryYear: string | null,
      peachPaymentCardId: string | null,
      company:  {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateCompanyInterestMutationVariables = {
  input: CreateCompanyInterestInput,
};

export type CreateCompanyInterestMutation = {
  createCompanyInterest:  {
    __typename: "CompanyInterest",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    interests:  {
      __typename: "Interest",
      id: string,
      name: string | null,
      createdAt: string | null,
      company:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateCompanyInterestMutationVariables = {
  input: UpdateCompanyInterestInput,
};

export type UpdateCompanyInterestMutation = {
  updateCompanyInterest:  {
    __typename: "CompanyInterest",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    interests:  {
      __typename: "Interest",
      id: string,
      name: string | null,
      createdAt: string | null,
      company:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteCompanyInterestMutationVariables = {
  input: DeleteCompanyInterestInput,
};

export type DeleteCompanyInterestMutation = {
  deleteCompanyInterest:  {
    __typename: "CompanyInterest",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    interests:  {
      __typename: "Interest",
      id: string,
      name: string | null,
      createdAt: string | null,
      company:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateUserCompanyMutationVariables = {
  input: CreateUserCompanyInput,
};

export type CreateUserCompanyMutation = {
  createUserCompany:  {
    __typename: "UserCompany",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateUserCompanyMutationVariables = {
  input: UpdateUserCompanyInput,
};

export type UpdateUserCompanyMutation = {
  updateUserCompany:  {
    __typename: "UserCompany",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteUserCompanyMutationVariables = {
  input: DeleteUserCompanyInput,
};

export type DeleteUserCompanyMutation = {
  deleteUserCompany:  {
    __typename: "UserCompany",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateCompanyReportMutationVariables = {
  input: CreateCompanyReportInput,
};

export type CreateCompanyReportMutation = {
  createCompanyReport:  {
    __typename: "CompanyReport",
    id: string,
    createdAt: string | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateCompanyReportMutationVariables = {
  input: UpdateCompanyReportInput,
};

export type UpdateCompanyReportMutation = {
  updateCompanyReport:  {
    __typename: "CompanyReport",
    id: string,
    createdAt: string | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteCompanyReportMutationVariables = {
  input: DeleteCompanyReportInput,
};

export type DeleteCompanyReportMutation = {
  deleteCompanyReport:  {
    __typename: "CompanyReport",
    id: string,
    createdAt: string | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateReportTagMutationVariables = {
  input: CreateReportTagInput,
};

export type CreateReportTagMutation = {
  createReportTag:  {
    __typename: "ReportTag",
    id: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type UpdateReportTagMutationVariables = {
  input: UpdateReportTagInput,
};

export type UpdateReportTagMutation = {
  updateReportTag:  {
    __typename: "ReportTag",
    id: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type DeleteReportTagMutationVariables = {
  input: DeleteReportTagInput,
};

export type DeleteReportTagMutation = {
  deleteReportTag:  {
    __typename: "ReportTag",
    id: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type CreateReportCategoryMutationVariables = {
  input: CreateReportCategoryInput,
};

export type CreateReportCategoryMutation = {
  createReportCategory:  {
    __typename: "ReportCategory",
    id: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type UpdateReportCategoryMutationVariables = {
  input: UpdateReportCategoryInput,
};

export type UpdateReportCategoryMutation = {
  updateReportCategory:  {
    __typename: "ReportCategory",
    id: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type DeleteReportCategoryMutationVariables = {
  input: DeleteReportCategoryInput,
};

export type DeleteReportCategoryMutation = {
  deleteReportCategory:  {
    __typename: "ReportCategory",
    id: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type CreateReportRegionMutationVariables = {
  input: CreateReportRegionInput,
};

export type CreateReportRegionMutation = {
  createReportRegion:  {
    __typename: "ReportRegion",
    id: string,
    region:  {
      __typename: "Region",
      id: string,
      name: string | null,
      reports:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type UpdateReportRegionMutationVariables = {
  input: UpdateReportRegionInput,
};

export type UpdateReportRegionMutation = {
  updateReportRegion:  {
    __typename: "ReportRegion",
    id: string,
    region:  {
      __typename: "Region",
      id: string,
      name: string | null,
      reports:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type DeleteReportRegionMutationVariables = {
  input: DeleteReportRegionInput,
};

export type DeleteReportRegionMutation = {
  deleteReportRegion:  {
    __typename: "ReportRegion",
    id: string,
    region:  {
      __typename: "Region",
      id: string,
      name: string | null,
      reports:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type GetTaskQueryVariables = {
  id: string,
};

export type GetTaskQuery = {
  getTask:  {
    __typename: "Task",
    id: string,
    title: string,
    description: string | null,
    status: string | null,
    assignedTo:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListTasksQueryVariables = {
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTasksQuery = {
  listTasks:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      title: string,
      description: string | null,
      status: string | null,
      assignedTo:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  id: string,
};

export type GetCompanyQuery = {
  getCompany:  {
    __typename: "Company",
    id: string,
    createdAt: string | null,
    name: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    address: string | null,
    industry: Industry | null,
    physicalAddress: string | null,
    numberOfEmployees: string | null,
    archived: boolean | null,
    interests:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    teamLead:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    teamMembers:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    cards:  {
      __typename: "ModelCompanyCardConnection",
      items:  Array< {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListCompanysQueryVariables = {
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompanysQuery = {
  listCompanys:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser:  {
    __typename: "User",
    id: string,
    dateOfBirth: string | null,
    createdAt: string | null,
    name: string | null,
    surname: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    idNumber: string | null,
    physicalAddress: string | null,
    archived: boolean | null,
    verified: boolean | null,
    role: UserRole | null,
    status: UserStatus | null,
    jobTitle: string | null,
    company:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companyTeamLead:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetReportQueryVariables = {
  id: string,
};

export type GetReportQuery = {
  getReport:  {
    __typename: "Report",
    id: string,
    createdAt: string | null,
    name: string | null,
    description: string | null,
    publishedDate: string | null,
    price: string | null,
    documentLocation: string | null,
    maxDownloads: string | null,
    downloadable: boolean | null,
    status: ReportStatus | null,
    paidOrFree: boolean | null,
    reportDetails: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    tags:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    categories:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    region:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companys:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
  } | null,
};

export type ListReportsQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsQuery = {
  listReports:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetRegionQueryVariables = {
  id: string,
};

export type GetRegionQuery = {
  getRegion:  {
    __typename: "Region",
    id: string,
    name: string | null,
    reports:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListRegionsQueryVariables = {
  filter?: ModelRegionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegionsQuery = {
  listRegions:  {
    __typename: "ModelRegionConnection",
    items:  Array< {
      __typename: "Region",
      id: string,
      name: string | null,
      reports:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetTagQueryVariables = {
  id: string,
};

export type GetTagQuery = {
  getTag:  {
    __typename: "Tag",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListTagsQueryVariables = {
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTagsQuery = {
  listTags:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory:  {
    __typename: "Category",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListCategorysQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategorysQuery = {
  listCategorys:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetVoucherQueryVariables = {
  id: string,
};

export type GetVoucherQuery = {
  getVoucher:  {
    __typename: "Voucher",
    id: string,
    code: string | null,
    discountPercentage: string | null,
    numberOfUses: string | null,
    expiryDate: string | null,
    archived: boolean | null,
    createdAt: string | null,
    transactions:  {
      __typename: "ModelTransactionVoucherConnection",
      items:  Array< {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListVouchersQueryVariables = {
  filter?: ModelVoucherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVouchersQuery = {
  listVouchers:  {
    __typename: "ModelVoucherConnection",
    items:  Array< {
      __typename: "Voucher",
      id: string,
      code: string | null,
      discountPercentage: string | null,
      numberOfUses: string | null,
      expiryDate: string | null,
      archived: boolean | null,
      createdAt: string | null,
      transactions:  {
        __typename: "ModelTransactionVoucherConnection",
        items:  Array< {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetFaqQueryVariables = {
  id: string,
};

export type GetFaqQuery = {
  getFaq:  {
    __typename: "Faq",
    id: string,
    question: string | null,
    answer: string | null,
    category:  {
      __typename: "FaqType",
      id: string,
      title: string | null,
      faqs:  {
        __typename: "ModelFaqConnection",
        items:  Array< {
          __typename: "Faq",
          id: string,
          question: string | null,
          answer: string | null,
          category:  {
            __typename: "FaqType",
            id: string,
            title: string | null,
            faqs:  {
              __typename: "ModelFaqConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListFaqsQueryVariables = {
  filter?: ModelFaqFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFaqsQuery = {
  listFaqs:  {
    __typename: "ModelFaqConnection",
    items:  Array< {
      __typename: "Faq",
      id: string,
      question: string | null,
      answer: string | null,
      category:  {
        __typename: "FaqType",
        id: string,
        title: string | null,
        faqs:  {
          __typename: "ModelFaqConnection",
          items:  Array< {
            __typename: "Faq",
            id: string,
            question: string | null,
            answer: string | null,
            category:  {
              __typename: "FaqType",
              id: string,
              title: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetFaqTypeQueryVariables = {
  id: string,
};

export type GetFaqTypeQuery = {
  getFaqType:  {
    __typename: "FaqType",
    id: string,
    title: string | null,
    faqs:  {
      __typename: "ModelFaqConnection",
      items:  Array< {
        __typename: "Faq",
        id: string,
        question: string | null,
        answer: string | null,
        category:  {
          __typename: "FaqType",
          id: string,
          title: string | null,
          faqs:  {
            __typename: "ModelFaqConnection",
            items:  Array< {
              __typename: "Faq",
              id: string,
              question: string | null,
              answer: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListFaqTypesQueryVariables = {
  filter?: ModelFaqTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFaqTypesQuery = {
  listFaqTypes:  {
    __typename: "ModelFaqTypeConnection",
    items:  Array< {
      __typename: "FaqType",
      id: string,
      title: string | null,
      faqs:  {
        __typename: "ModelFaqConnection",
        items:  Array< {
          __typename: "Faq",
          id: string,
          question: string | null,
          answer: string | null,
          category:  {
            __typename: "FaqType",
            id: string,
            title: string | null,
            faqs:  {
              __typename: "ModelFaqConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetInterestQueryVariables = {
  id: string,
};

export type GetInterestQuery = {
  getInterest:  {
    __typename: "Interest",
    id: string,
    name: string | null,
    createdAt: string | null,
    company:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListInterestsQueryVariables = {
  filter?: ModelInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInterestsQuery = {
  listInterests:  {
    __typename: "ModelInterestConnection",
    items:  Array< {
      __typename: "Interest",
      id: string,
      name: string | null,
      createdAt: string | null,
      company:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetTransactionQueryVariables = {
  id: string,
};

export type GetTransactionQuery = {
  getTransaction:  {
    __typename: "Transaction",
    id: string,
    createdAt: string | null,
    cost: string | null,
    voucher:  {
      __typename: "TransactionVoucher",
      id: string,
      transaction:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      voucher:  {
        __typename: "Voucher",
        id: string,
        code: string | null,
        discountPercentage: string | null,
        numberOfUses: string | null,
        expiryDate: string | null,
        archived: boolean | null,
        createdAt: string | null,
        transactions:  {
          __typename: "ModelTransactionVoucherConnection",
          items:  Array< {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    },
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type ListTransactionsQueryVariables = {
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionsQuery = {
  listTransactions:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCardQueryVariables = {
  id: string,
};

export type GetCardQuery = {
  getCard:  {
    __typename: "Card",
    id: string,
    type: CardType | null,
    number: string | null,
    nameOnCard: string | null,
    expiryMonth: string | null,
    expiryYear: string | null,
    peachPaymentCardId: string | null,
    company:  {
      __typename: "CompanyCard",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      card:  {
        __typename: "Card",
        id: string,
        type: CardType | null,
        number: string | null,
        nameOnCard: string | null,
        expiryMonth: string | null,
        expiryYear: string | null,
        peachPaymentCardId: string | null,
        company:  {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListCardsQueryVariables = {
  filter?: ModelCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCardsQuery = {
  listCards:  {
    __typename: "ModelCardConnection",
    items:  Array< {
      __typename: "Card",
      id: string,
      type: CardType | null,
      number: string | null,
      nameOnCard: string | null,
      expiryMonth: string | null,
      expiryYear: string | null,
      peachPaymentCardId: string | null,
      company:  {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetProvinceTypeQueryVariables = {
  id: string,
};

export type GetProvinceTypeQuery = {
  getProvinceType:  {
    __typename: "ProvinceType",
    id: string,
    name: string | null,
    createdAt: string | null,
    cities:  {
      __typename: "ModelCityTypeConnection",
      items:  Array< {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListProvinceTypesQueryVariables = {
  filter?: ModelProvinceTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProvinceTypesQuery = {
  listProvinceTypes:  {
    __typename: "ModelProvinceTypeConnection",
    items:  Array< {
      __typename: "ProvinceType",
      id: string,
      name: string | null,
      createdAt: string | null,
      cities:  {
        __typename: "ModelCityTypeConnection",
        items:  Array< {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCityTypeQueryVariables = {
  id: string,
};

export type GetCityTypeQuery = {
  getCityType:  {
    __typename: "CityType",
    id: string,
    name: string | null,
    province:  {
      __typename: "ProvinceType",
      id: string,
      name: string | null,
      createdAt: string | null,
      cities:  {
        __typename: "ModelCityTypeConnection",
        items:  Array< {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    createdAt: string | null,
    areas:  {
      __typename: "ModelAreaTypeConnection",
      items:  Array< {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListCityTypesQueryVariables = {
  filter?: ModelCityTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCityTypesQuery = {
  listCityTypes:  {
    __typename: "ModelCityTypeConnection",
    items:  Array< {
      __typename: "CityType",
      id: string,
      name: string | null,
      province:  {
        __typename: "ProvinceType",
        id: string,
        name: string | null,
        createdAt: string | null,
        cities:  {
          __typename: "ModelCityTypeConnection",
          items:  Array< {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      createdAt: string | null,
      areas:  {
        __typename: "ModelAreaTypeConnection",
        items:  Array< {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetAreaTypeQueryVariables = {
  id: string,
};

export type GetAreaTypeQuery = {
  getAreaType:  {
    __typename: "AreaType",
    id: string,
    name: string | null,
    createdAt: string | null,
    city:  {
      __typename: "CityType",
      id: string,
      name: string | null,
      province:  {
        __typename: "ProvinceType",
        id: string,
        name: string | null,
        createdAt: string | null,
        cities:  {
          __typename: "ModelCityTypeConnection",
          items:  Array< {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      createdAt: string | null,
      areas:  {
        __typename: "ModelAreaTypeConnection",
        items:  Array< {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    companies:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListAreaTypesQueryVariables = {
  filter?: ModelAreaTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAreaTypesQuery = {
  listAreaTypes:  {
    __typename: "ModelAreaTypeConnection",
    items:  Array< {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetSampleReportQueryVariables = {
  id: string,
};

export type GetSampleReportQuery = {
  getSampleReport:  {
    __typename: "SampleReport",
    id: string,
    name: string | null,
    createdAt: string | null,
    rootsDescription: string | null,
    sampleReportDescription: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
  } | null,
};

export type ListSampleReportsQueryVariables = {
  filter?: ModelSampleReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSampleReportsQuery = {
  listSampleReports:  {
    __typename: "ModelSampleReportConnection",
    items:  Array< {
      __typename: "SampleReport",
      id: string,
      name: string | null,
      createdAt: string | null,
      rootsDescription: string | null,
      sampleReportDescription: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetTransactionVoucherQueryVariables = {
  id: string,
};

export type GetTransactionVoucherQuery = {
  getTransactionVoucher:  {
    __typename: "TransactionVoucher",
    id: string,
    transaction:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    voucher:  {
      __typename: "Voucher",
      id: string,
      code: string | null,
      discountPercentage: string | null,
      numberOfUses: string | null,
      expiryDate: string | null,
      archived: boolean | null,
      createdAt: string | null,
      transactions:  {
        __typename: "ModelTransactionVoucherConnection",
        items:  Array< {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListTransactionVouchersQueryVariables = {
  filter?: ModelTransactionVoucherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionVouchersQuery = {
  listTransactionVouchers:  {
    __typename: "ModelTransactionVoucherConnection",
    items:  Array< {
      __typename: "TransactionVoucher",
      id: string,
      transaction:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      voucher:  {
        __typename: "Voucher",
        id: string,
        code: string | null,
        discountPercentage: string | null,
        numberOfUses: string | null,
        expiryDate: string | null,
        archived: boolean | null,
        createdAt: string | null,
        transactions:  {
          __typename: "ModelTransactionVoucherConnection",
          items:  Array< {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCompanyCardQueryVariables = {
  id: string,
};

export type GetCompanyCardQuery = {
  getCompanyCard:  {
    __typename: "CompanyCard",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    card:  {
      __typename: "Card",
      id: string,
      type: CardType | null,
      number: string | null,
      nameOnCard: string | null,
      expiryMonth: string | null,
      expiryYear: string | null,
      peachPaymentCardId: string | null,
      company:  {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListCompanyCardsQueryVariables = {
  filter?: ModelCompanyCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompanyCardsQuery = {
  listCompanyCards:  {
    __typename: "ModelCompanyCardConnection",
    items:  Array< {
      __typename: "CompanyCard",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      card:  {
        __typename: "Card",
        id: string,
        type: CardType | null,
        number: string | null,
        nameOnCard: string | null,
        expiryMonth: string | null,
        expiryYear: string | null,
        peachPaymentCardId: string | null,
        company:  {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCompanyInterestQueryVariables = {
  id: string,
};

export type GetCompanyInterestQuery = {
  getCompanyInterest:  {
    __typename: "CompanyInterest",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    interests:  {
      __typename: "Interest",
      id: string,
      name: string | null,
      createdAt: string | null,
      company:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListCompanyInterestsQueryVariables = {
  filter?: ModelCompanyInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompanyInterestsQuery = {
  listCompanyInterests:  {
    __typename: "ModelCompanyInterestConnection",
    items:  Array< {
      __typename: "CompanyInterest",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      interests:  {
        __typename: "Interest",
        id: string,
        name: string | null,
        createdAt: string | null,
        company:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetUserCompanyQueryVariables = {
  id: string,
};

export type GetUserCompanyQuery = {
  getUserCompany:  {
    __typename: "UserCompany",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListUserCompanysQueryVariables = {
  filter?: ModelUserCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserCompanysQuery = {
  listUserCompanys:  {
    __typename: "ModelUserCompanyConnection",
    items:  Array< {
      __typename: "UserCompany",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCompanyReportQueryVariables = {
  id: string,
};

export type GetCompanyReportQuery = {
  getCompanyReport:  {
    __typename: "CompanyReport",
    id: string,
    createdAt: string | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListCompanyReportsQueryVariables = {
  filter?: ModelCompanyReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompanyReportsQuery = {
  listCompanyReports:  {
    __typename: "ModelCompanyReportConnection",
    items:  Array< {
      __typename: "CompanyReport",
      id: string,
      createdAt: string | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      } | null,
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetReportTagQueryVariables = {
  id: string,
};

export type GetReportTagQuery = {
  getReportTag:  {
    __typename: "ReportTag",
    id: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type ListReportTagsQueryVariables = {
  filter?: ModelReportTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportTagsQuery = {
  listReportTags:  {
    __typename: "ModelReportTagConnection",
    items:  Array< {
      __typename: "ReportTag",
      id: string,
      tag:  {
        __typename: "Tag",
        id: string,
        name: string | null,
        createdAt: string | null,
        reports:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetReportCategoryQueryVariables = {
  id: string,
};

export type GetReportCategoryQuery = {
  getReportCategory:  {
    __typename: "ReportCategory",
    id: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type ListReportCategorysQueryVariables = {
  filter?: ModelReportCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportCategorysQuery = {
  listReportCategorys:  {
    __typename: "ModelReportCategoryConnection",
    items:  Array< {
      __typename: "ReportCategory",
      id: string,
      category:  {
        __typename: "Category",
        id: string,
        name: string | null,
        createdAt: string | null,
        reports:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetReportRegionQueryVariables = {
  id: string,
};

export type GetReportRegionQuery = {
  getReportRegion:  {
    __typename: "ReportRegion",
    id: string,
    region:  {
      __typename: "Region",
      id: string,
      name: string | null,
      reports:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type ListReportRegionsQueryVariables = {
  filter?: ModelReportRegionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportRegionsQuery = {
  listReportRegions:  {
    __typename: "ModelReportRegionConnection",
    items:  Array< {
      __typename: "ReportRegion",
      id: string,
      region:  {
        __typename: "Region",
        id: string,
        name: string | null,
        reports:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type OnCreateTaskSubscription = {
  onCreateTask:  {
    __typename: "Task",
    id: string,
    title: string,
    description: string | null,
    status: string | null,
    assignedTo:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateTaskSubscription = {
  onUpdateTask:  {
    __typename: "Task",
    id: string,
    title: string,
    description: string | null,
    status: string | null,
    assignedTo:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteTaskSubscription = {
  onDeleteTask:  {
    __typename: "Task",
    id: string,
    title: string,
    description: string | null,
    status: string | null,
    assignedTo:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateCompanySubscription = {
  onCreateCompany:  {
    __typename: "Company",
    id: string,
    createdAt: string | null,
    name: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    address: string | null,
    industry: Industry | null,
    physicalAddress: string | null,
    numberOfEmployees: string | null,
    archived: boolean | null,
    interests:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    teamLead:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    teamMembers:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    cards:  {
      __typename: "ModelCompanyCardConnection",
      items:  Array< {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany:  {
    __typename: "Company",
    id: string,
    createdAt: string | null,
    name: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    address: string | null,
    industry: Industry | null,
    physicalAddress: string | null,
    numberOfEmployees: string | null,
    archived: boolean | null,
    interests:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    teamLead:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    teamMembers:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    cards:  {
      __typename: "ModelCompanyCardConnection",
      items:  Array< {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany:  {
    __typename: "Company",
    id: string,
    createdAt: string | null,
    name: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    address: string | null,
    industry: Industry | null,
    physicalAddress: string | null,
    numberOfEmployees: string | null,
    archived: boolean | null,
    interests:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    teamLead:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    teamMembers:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    cards:  {
      __typename: "ModelCompanyCardConnection",
      items:  Array< {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser:  {
    __typename: "User",
    id: string,
    dateOfBirth: string | null,
    createdAt: string | null,
    name: string | null,
    surname: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    idNumber: string | null,
    physicalAddress: string | null,
    archived: boolean | null,
    verified: boolean | null,
    role: UserRole | null,
    status: UserStatus | null,
    jobTitle: string | null,
    company:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companyTeamLead:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser:  {
    __typename: "User",
    id: string,
    dateOfBirth: string | null,
    createdAt: string | null,
    name: string | null,
    surname: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    idNumber: string | null,
    physicalAddress: string | null,
    archived: boolean | null,
    verified: boolean | null,
    role: UserRole | null,
    status: UserStatus | null,
    jobTitle: string | null,
    company:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companyTeamLead:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser:  {
    __typename: "User",
    id: string,
    dateOfBirth: string | null,
    createdAt: string | null,
    name: string | null,
    surname: string | null,
    emailAddress: string | null,
    contactNumber: string | null,
    idNumber: string | null,
    physicalAddress: string | null,
    archived: boolean | null,
    verified: boolean | null,
    role: UserRole | null,
    status: UserStatus | null,
    jobTitle: string | null,
    company:  {
      __typename: "ModelUserCompanyConnection",
      items:  Array< {
        __typename: "UserCompany",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companyTeamLead:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateReportSubscription = {
  onCreateReport:  {
    __typename: "Report",
    id: string,
    createdAt: string | null,
    name: string | null,
    description: string | null,
    publishedDate: string | null,
    price: string | null,
    documentLocation: string | null,
    maxDownloads: string | null,
    downloadable: boolean | null,
    status: ReportStatus | null,
    paidOrFree: boolean | null,
    reportDetails: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    tags:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    categories:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    region:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companys:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
  } | null,
};

export type OnUpdateReportSubscription = {
  onUpdateReport:  {
    __typename: "Report",
    id: string,
    createdAt: string | null,
    name: string | null,
    description: string | null,
    publishedDate: string | null,
    price: string | null,
    documentLocation: string | null,
    maxDownloads: string | null,
    downloadable: boolean | null,
    status: ReportStatus | null,
    paidOrFree: boolean | null,
    reportDetails: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    tags:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    categories:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    region:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companys:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
  } | null,
};

export type OnDeleteReportSubscription = {
  onDeleteReport:  {
    __typename: "Report",
    id: string,
    createdAt: string | null,
    name: string | null,
    description: string | null,
    publishedDate: string | null,
    price: string | null,
    documentLocation: string | null,
    maxDownloads: string | null,
    downloadable: boolean | null,
    status: ReportStatus | null,
    paidOrFree: boolean | null,
    reportDetails: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
    area:  {
      __typename: "AreaType",
      id: string,
      name: string | null,
      createdAt: string | null,
      city:  {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      companies:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    tags:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    categories:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    region:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    companys:  {
      __typename: "ModelCompanyReportConnection",
      items:  Array< {
        __typename: "CompanyReport",
        id: string,
        createdAt: string | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    transactions:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
  } | null,
};

export type OnCreateRegionSubscription = {
  onCreateRegion:  {
    __typename: "Region",
    id: string,
    name: string | null,
    reports:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnUpdateRegionSubscription = {
  onUpdateRegion:  {
    __typename: "Region",
    id: string,
    name: string | null,
    reports:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnDeleteRegionSubscription = {
  onDeleteRegion:  {
    __typename: "Region",
    id: string,
    name: string | null,
    reports:  {
      __typename: "ModelReportRegionConnection",
      items:  Array< {
        __typename: "ReportRegion",
        id: string,
        region:  {
          __typename: "Region",
          id: string,
          name: string | null,
          reports:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnCreateTagSubscription = {
  onCreateTag:  {
    __typename: "Tag",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnUpdateTagSubscription = {
  onUpdateTag:  {
    __typename: "Tag",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnDeleteTagSubscription = {
  onDeleteTag:  {
    __typename: "Tag",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportTagConnection",
      items:  Array< {
        __typename: "ReportTag",
        id: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory:  {
    __typename: "Category",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory:  {
    __typename: "Category",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory:  {
    __typename: "Category",
    id: string,
    name: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportCategoryConnection",
      items:  Array< {
        __typename: "ReportCategory",
        id: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string | null,
          createdAt: string | null,
          reports:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnCreateVoucherSubscription = {
  onCreateVoucher:  {
    __typename: "Voucher",
    id: string,
    code: string | null,
    discountPercentage: string | null,
    numberOfUses: string | null,
    expiryDate: string | null,
    archived: boolean | null,
    createdAt: string | null,
    transactions:  {
      __typename: "ModelTransactionVoucherConnection",
      items:  Array< {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnUpdateVoucherSubscription = {
  onUpdateVoucher:  {
    __typename: "Voucher",
    id: string,
    code: string | null,
    discountPercentage: string | null,
    numberOfUses: string | null,
    expiryDate: string | null,
    archived: boolean | null,
    createdAt: string | null,
    transactions:  {
      __typename: "ModelTransactionVoucherConnection",
      items:  Array< {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnDeleteVoucherSubscription = {
  onDeleteVoucher:  {
    __typename: "Voucher",
    id: string,
    code: string | null,
    discountPercentage: string | null,
    numberOfUses: string | null,
    expiryDate: string | null,
    archived: boolean | null,
    createdAt: string | null,
    transactions:  {
      __typename: "ModelTransactionVoucherConnection",
      items:  Array< {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnCreateFaqSubscription = {
  onCreateFaq:  {
    __typename: "Faq",
    id: string,
    question: string | null,
    answer: string | null,
    category:  {
      __typename: "FaqType",
      id: string,
      title: string | null,
      faqs:  {
        __typename: "ModelFaqConnection",
        items:  Array< {
          __typename: "Faq",
          id: string,
          question: string | null,
          answer: string | null,
          category:  {
            __typename: "FaqType",
            id: string,
            title: string | null,
            faqs:  {
              __typename: "ModelFaqConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateFaqSubscription = {
  onUpdateFaq:  {
    __typename: "Faq",
    id: string,
    question: string | null,
    answer: string | null,
    category:  {
      __typename: "FaqType",
      id: string,
      title: string | null,
      faqs:  {
        __typename: "ModelFaqConnection",
        items:  Array< {
          __typename: "Faq",
          id: string,
          question: string | null,
          answer: string | null,
          category:  {
            __typename: "FaqType",
            id: string,
            title: string | null,
            faqs:  {
              __typename: "ModelFaqConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteFaqSubscription = {
  onDeleteFaq:  {
    __typename: "Faq",
    id: string,
    question: string | null,
    answer: string | null,
    category:  {
      __typename: "FaqType",
      id: string,
      title: string | null,
      faqs:  {
        __typename: "ModelFaqConnection",
        items:  Array< {
          __typename: "Faq",
          id: string,
          question: string | null,
          answer: string | null,
          category:  {
            __typename: "FaqType",
            id: string,
            title: string | null,
            faqs:  {
              __typename: "ModelFaqConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateFaqTypeSubscription = {
  onCreateFaqType:  {
    __typename: "FaqType",
    id: string,
    title: string | null,
    faqs:  {
      __typename: "ModelFaqConnection",
      items:  Array< {
        __typename: "Faq",
        id: string,
        question: string | null,
        answer: string | null,
        category:  {
          __typename: "FaqType",
          id: string,
          title: string | null,
          faqs:  {
            __typename: "ModelFaqConnection",
            items:  Array< {
              __typename: "Faq",
              id: string,
              question: string | null,
              answer: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnUpdateFaqTypeSubscription = {
  onUpdateFaqType:  {
    __typename: "FaqType",
    id: string,
    title: string | null,
    faqs:  {
      __typename: "ModelFaqConnection",
      items:  Array< {
        __typename: "Faq",
        id: string,
        question: string | null,
        answer: string | null,
        category:  {
          __typename: "FaqType",
          id: string,
          title: string | null,
          faqs:  {
            __typename: "ModelFaqConnection",
            items:  Array< {
              __typename: "Faq",
              id: string,
              question: string | null,
              answer: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnDeleteFaqTypeSubscription = {
  onDeleteFaqType:  {
    __typename: "FaqType",
    id: string,
    title: string | null,
    faqs:  {
      __typename: "ModelFaqConnection",
      items:  Array< {
        __typename: "Faq",
        id: string,
        question: string | null,
        answer: string | null,
        category:  {
          __typename: "FaqType",
          id: string,
          title: string | null,
          faqs:  {
            __typename: "ModelFaqConnection",
            items:  Array< {
              __typename: "Faq",
              id: string,
              question: string | null,
              answer: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnCreateInterestSubscription = {
  onCreateInterest:  {
    __typename: "Interest",
    id: string,
    name: string | null,
    createdAt: string | null,
    company:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnUpdateInterestSubscription = {
  onUpdateInterest:  {
    __typename: "Interest",
    id: string,
    name: string | null,
    createdAt: string | null,
    company:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnDeleteInterestSubscription = {
  onDeleteInterest:  {
    __typename: "Interest",
    id: string,
    name: string | null,
    createdAt: string | null,
    company:  {
      __typename: "ModelCompanyInterestConnection",
      items:  Array< {
        __typename: "CompanyInterest",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        interests:  {
          __typename: "Interest",
          id: string,
          name: string | null,
          createdAt: string | null,
          company:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnCreateTransactionSubscription = {
  onCreateTransaction:  {
    __typename: "Transaction",
    id: string,
    createdAt: string | null,
    cost: string | null,
    voucher:  {
      __typename: "TransactionVoucher",
      id: string,
      transaction:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      voucher:  {
        __typename: "Voucher",
        id: string,
        code: string | null,
        discountPercentage: string | null,
        numberOfUses: string | null,
        expiryDate: string | null,
        archived: boolean | null,
        createdAt: string | null,
        transactions:  {
          __typename: "ModelTransactionVoucherConnection",
          items:  Array< {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    },
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateTransactionSubscription = {
  onUpdateTransaction:  {
    __typename: "Transaction",
    id: string,
    createdAt: string | null,
    cost: string | null,
    voucher:  {
      __typename: "TransactionVoucher",
      id: string,
      transaction:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      voucher:  {
        __typename: "Voucher",
        id: string,
        code: string | null,
        discountPercentage: string | null,
        numberOfUses: string | null,
        expiryDate: string | null,
        archived: boolean | null,
        createdAt: string | null,
        transactions:  {
          __typename: "ModelTransactionVoucherConnection",
          items:  Array< {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    },
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteTransactionSubscription = {
  onDeleteTransaction:  {
    __typename: "Transaction",
    id: string,
    createdAt: string | null,
    cost: string | null,
    voucher:  {
      __typename: "TransactionVoucher",
      id: string,
      transaction:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      voucher:  {
        __typename: "Voucher",
        id: string,
        code: string | null,
        discountPercentage: string | null,
        numberOfUses: string | null,
        expiryDate: string | null,
        archived: boolean | null,
        createdAt: string | null,
        transactions:  {
          __typename: "ModelTransactionVoucherConnection",
          items:  Array< {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    },
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateCardSubscription = {
  onCreateCard:  {
    __typename: "Card",
    id: string,
    type: CardType | null,
    number: string | null,
    nameOnCard: string | null,
    expiryMonth: string | null,
    expiryYear: string | null,
    peachPaymentCardId: string | null,
    company:  {
      __typename: "CompanyCard",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      card:  {
        __typename: "Card",
        id: string,
        type: CardType | null,
        number: string | null,
        nameOnCard: string | null,
        expiryMonth: string | null,
        expiryYear: string | null,
        peachPaymentCardId: string | null,
        company:  {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateCardSubscription = {
  onUpdateCard:  {
    __typename: "Card",
    id: string,
    type: CardType | null,
    number: string | null,
    nameOnCard: string | null,
    expiryMonth: string | null,
    expiryYear: string | null,
    peachPaymentCardId: string | null,
    company:  {
      __typename: "CompanyCard",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      card:  {
        __typename: "Card",
        id: string,
        type: CardType | null,
        number: string | null,
        nameOnCard: string | null,
        expiryMonth: string | null,
        expiryYear: string | null,
        peachPaymentCardId: string | null,
        company:  {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteCardSubscription = {
  onDeleteCard:  {
    __typename: "Card",
    id: string,
    type: CardType | null,
    number: string | null,
    nameOnCard: string | null,
    expiryMonth: string | null,
    expiryYear: string | null,
    peachPaymentCardId: string | null,
    company:  {
      __typename: "CompanyCard",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      card:  {
        __typename: "Card",
        id: string,
        type: CardType | null,
        number: string | null,
        nameOnCard: string | null,
        expiryMonth: string | null,
        expiryYear: string | null,
        peachPaymentCardId: string | null,
        company:  {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateProvinceTypeSubscription = {
  onCreateProvinceType:  {
    __typename: "ProvinceType",
    id: string,
    name: string | null,
    createdAt: string | null,
    cities:  {
      __typename: "ModelCityTypeConnection",
      items:  Array< {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnUpdateProvinceTypeSubscription = {
  onUpdateProvinceType:  {
    __typename: "ProvinceType",
    id: string,
    name: string | null,
    createdAt: string | null,
    cities:  {
      __typename: "ModelCityTypeConnection",
      items:  Array< {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnDeleteProvinceTypeSubscription = {
  onDeleteProvinceType:  {
    __typename: "ProvinceType",
    id: string,
    name: string | null,
    createdAt: string | null,
    cities:  {
      __typename: "ModelCityTypeConnection",
      items:  Array< {
        __typename: "CityType",
        id: string,
        name: string | null,
        province:  {
          __typename: "ProvinceType",
          id: string,
          name: string | null,
          createdAt: string | null,
          cities:  {
            __typename: "ModelCityTypeConnection",
            items:  Array< {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        createdAt: string | null,
        areas:  {
          __typename: "ModelAreaTypeConnection",
          items:  Array< {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnCreateCityTypeSubscription = {
  onCreateCityType:  {
    __typename: "CityType",
    id: string,
    name: string | null,
    province:  {
      __typename: "ProvinceType",
      id: string,
      name: string | null,
      createdAt: string | null,
      cities:  {
        __typename: "ModelCityTypeConnection",
        items:  Array< {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    createdAt: string | null,
    areas:  {
      __typename: "ModelAreaTypeConnection",
      items:  Array< {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnUpdateCityTypeSubscription = {
  onUpdateCityType:  {
    __typename: "CityType",
    id: string,
    name: string | null,
    province:  {
      __typename: "ProvinceType",
      id: string,
      name: string | null,
      createdAt: string | null,
      cities:  {
        __typename: "ModelCityTypeConnection",
        items:  Array< {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    createdAt: string | null,
    areas:  {
      __typename: "ModelAreaTypeConnection",
      items:  Array< {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnDeleteCityTypeSubscription = {
  onDeleteCityType:  {
    __typename: "CityType",
    id: string,
    name: string | null,
    province:  {
      __typename: "ProvinceType",
      id: string,
      name: string | null,
      createdAt: string | null,
      cities:  {
        __typename: "ModelCityTypeConnection",
        items:  Array< {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    createdAt: string | null,
    areas:  {
      __typename: "ModelAreaTypeConnection",
      items:  Array< {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnCreateAreaTypeSubscription = {
  onCreateAreaType:  {
    __typename: "AreaType",
    id: string,
    name: string | null,
    createdAt: string | null,
    city:  {
      __typename: "CityType",
      id: string,
      name: string | null,
      province:  {
        __typename: "ProvinceType",
        id: string,
        name: string | null,
        createdAt: string | null,
        cities:  {
          __typename: "ModelCityTypeConnection",
          items:  Array< {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      createdAt: string | null,
      areas:  {
        __typename: "ModelAreaTypeConnection",
        items:  Array< {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    companies:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnUpdateAreaTypeSubscription = {
  onUpdateAreaType:  {
    __typename: "AreaType",
    id: string,
    name: string | null,
    createdAt: string | null,
    city:  {
      __typename: "CityType",
      id: string,
      name: string | null,
      province:  {
        __typename: "ProvinceType",
        id: string,
        name: string | null,
        createdAt: string | null,
        cities:  {
          __typename: "ModelCityTypeConnection",
          items:  Array< {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      createdAt: string | null,
      areas:  {
        __typename: "ModelAreaTypeConnection",
        items:  Array< {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    companies:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnDeleteAreaTypeSubscription = {
  onDeleteAreaType:  {
    __typename: "AreaType",
    id: string,
    name: string | null,
    createdAt: string | null,
    city:  {
      __typename: "CityType",
      id: string,
      name: string | null,
      province:  {
        __typename: "ProvinceType",
        id: string,
        name: string | null,
        createdAt: string | null,
        cities:  {
          __typename: "ModelCityTypeConnection",
          items:  Array< {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      createdAt: string | null,
      areas:  {
        __typename: "ModelAreaTypeConnection",
        items:  Array< {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    companies:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    reports:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnCreateSampleReportSubscription = {
  onCreateSampleReport:  {
    __typename: "SampleReport",
    id: string,
    name: string | null,
    createdAt: string | null,
    rootsDescription: string | null,
    sampleReportDescription: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
  } | null,
};

export type OnUpdateSampleReportSubscription = {
  onUpdateSampleReport:  {
    __typename: "SampleReport",
    id: string,
    name: string | null,
    createdAt: string | null,
    rootsDescription: string | null,
    sampleReportDescription: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
  } | null,
};

export type OnDeleteSampleReportSubscription = {
  onDeleteSampleReport:  {
    __typename: "SampleReport",
    id: string,
    name: string | null,
    createdAt: string | null,
    rootsDescription: string | null,
    sampleReportDescription: string | null,
    coverImageUrl: string | null,
    documentResourceUrl: string | null,
  } | null,
};

export type OnCreateTransactionVoucherSubscription = {
  onCreateTransactionVoucher:  {
    __typename: "TransactionVoucher",
    id: string,
    transaction:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    voucher:  {
      __typename: "Voucher",
      id: string,
      code: string | null,
      discountPercentage: string | null,
      numberOfUses: string | null,
      expiryDate: string | null,
      archived: boolean | null,
      createdAt: string | null,
      transactions:  {
        __typename: "ModelTransactionVoucherConnection",
        items:  Array< {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateTransactionVoucherSubscription = {
  onUpdateTransactionVoucher:  {
    __typename: "TransactionVoucher",
    id: string,
    transaction:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    voucher:  {
      __typename: "Voucher",
      id: string,
      code: string | null,
      discountPercentage: string | null,
      numberOfUses: string | null,
      expiryDate: string | null,
      archived: boolean | null,
      createdAt: string | null,
      transactions:  {
        __typename: "ModelTransactionVoucherConnection",
        items:  Array< {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteTransactionVoucherSubscription = {
  onDeleteTransactionVoucher:  {
    __typename: "TransactionVoucher",
    id: string,
    transaction:  {
      __typename: "Transaction",
      id: string,
      createdAt: string | null,
      cost: string | null,
      voucher:  {
        __typename: "TransactionVoucher",
        id: string,
        transaction:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        voucher:  {
          __typename: "Voucher",
          id: string,
          code: string | null,
          discountPercentage: string | null,
          numberOfUses: string | null,
          expiryDate: string | null,
          archived: boolean | null,
          createdAt: string | null,
          transactions:  {
            __typename: "ModelTransactionVoucherConnection",
            items:  Array< {
              __typename: "TransactionVoucher",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      report:  {
        __typename: "Report",
        id: string,
        createdAt: string | null,
        name: string | null,
        description: string | null,
        publishedDate: string | null,
        price: string | null,
        documentLocation: string | null,
        maxDownloads: string | null,
        downloadable: boolean | null,
        status: ReportStatus | null,
        paidOrFree: boolean | null,
        reportDetails: string | null,
        coverImageUrl: string | null,
        documentResourceUrl: string | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        tags:  {
          __typename: "ModelReportTagConnection",
          items:  Array< {
            __typename: "ReportTag",
            id: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        categories:  {
          __typename: "ModelReportCategoryConnection",
          items:  Array< {
            __typename: "ReportCategory",
            id: string,
            category:  {
              __typename: "Category",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        region:  {
          __typename: "ModelReportRegionConnection",
          items:  Array< {
            __typename: "ReportRegion",
            id: string,
            region:  {
              __typename: "Region",
              id: string,
              name: string | null,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companys:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
      },
      company:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      },
    } | null,
    voucher:  {
      __typename: "Voucher",
      id: string,
      code: string | null,
      discountPercentage: string | null,
      numberOfUses: string | null,
      expiryDate: string | null,
      archived: boolean | null,
      createdAt: string | null,
      transactions:  {
        __typename: "ModelTransactionVoucherConnection",
        items:  Array< {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateCompanyCardSubscription = {
  onCreateCompanyCard:  {
    __typename: "CompanyCard",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    card:  {
      __typename: "Card",
      id: string,
      type: CardType | null,
      number: string | null,
      nameOnCard: string | null,
      expiryMonth: string | null,
      expiryYear: string | null,
      peachPaymentCardId: string | null,
      company:  {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateCompanyCardSubscription = {
  onUpdateCompanyCard:  {
    __typename: "CompanyCard",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    card:  {
      __typename: "Card",
      id: string,
      type: CardType | null,
      number: string | null,
      nameOnCard: string | null,
      expiryMonth: string | null,
      expiryYear: string | null,
      peachPaymentCardId: string | null,
      company:  {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteCompanyCardSubscription = {
  onDeleteCompanyCard:  {
    __typename: "CompanyCard",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    card:  {
      __typename: "Card",
      id: string,
      type: CardType | null,
      number: string | null,
      nameOnCard: string | null,
      expiryMonth: string | null,
      expiryYear: string | null,
      peachPaymentCardId: string | null,
      company:  {
        __typename: "CompanyCard",
        id: string,
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        card:  {
          __typename: "Card",
          id: string,
          type: CardType | null,
          number: string | null,
          nameOnCard: string | null,
          expiryMonth: string | null,
          expiryYear: string | null,
          peachPaymentCardId: string | null,
          company:  {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateCompanyInterestSubscription = {
  onCreateCompanyInterest:  {
    __typename: "CompanyInterest",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    interests:  {
      __typename: "Interest",
      id: string,
      name: string | null,
      createdAt: string | null,
      company:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateCompanyInterestSubscription = {
  onUpdateCompanyInterest:  {
    __typename: "CompanyInterest",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    interests:  {
      __typename: "Interest",
      id: string,
      name: string | null,
      createdAt: string | null,
      company:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteCompanyInterestSubscription = {
  onDeleteCompanyInterest:  {
    __typename: "CompanyInterest",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    interests:  {
      __typename: "Interest",
      id: string,
      name: string | null,
      createdAt: string | null,
      company:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateUserCompanySubscription = {
  onCreateUserCompany:  {
    __typename: "UserCompany",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateUserCompanySubscription = {
  onUpdateUserCompany:  {
    __typename: "UserCompany",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteUserCompanySubscription = {
  onDeleteUserCompany:  {
    __typename: "UserCompany",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      dateOfBirth: string | null,
      createdAt: string | null,
      name: string | null,
      surname: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      idNumber: string | null,
      physicalAddress: string | null,
      archived: boolean | null,
      verified: boolean | null,
      role: UserRole | null,
      status: UserStatus | null,
      jobTitle: string | null,
      company:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companyTeamLead:  {
        __typename: "Company",
        id: string,
        createdAt: string | null,
        name: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        address: string | null,
        industry: Industry | null,
        physicalAddress: string | null,
        numberOfEmployees: string | null,
        archived: boolean | null,
        interests:  {
          __typename: "ModelCompanyInterestConnection",
          items:  Array< {
            __typename: "CompanyInterest",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            interests:  {
              __typename: "Interest",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        area:  {
          __typename: "AreaType",
          id: string,
          name: string | null,
          createdAt: string | null,
          city:  {
            __typename: "CityType",
            id: string,
            name: string | null,
            province:  {
              __typename: "ProvinceType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            createdAt: string | null,
            areas:  {
              __typename: "ModelAreaTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          companies:  {
            __typename: "ModelCompanyConnection",
            items:  Array< {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelReportConnection",
            items:  Array< {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        teamLead:  {
          __typename: "User",
          id: string,
          dateOfBirth: string | null,
          createdAt: string | null,
          name: string | null,
          surname: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          idNumber: string | null,
          physicalAddress: string | null,
          archived: boolean | null,
          verified: boolean | null,
          role: UserRole | null,
          status: UserStatus | null,
          jobTitle: string | null,
          company:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companyTeamLead:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        teamMembers:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelCompanyReportConnection",
          items:  Array< {
            __typename: "CompanyReport",
            id: string,
            createdAt: string | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            } | null,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        transactions:  {
          __typename: "Transaction",
          id: string,
          createdAt: string | null,
          cost: string | null,
          voucher:  {
            __typename: "TransactionVoucher",
            id: string,
            transaction:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            voucher:  {
              __typename: "Voucher",
              id: string,
              code: string | null,
              discountPercentage: string | null,
              numberOfUses: string | null,
              expiryDate: string | null,
              archived: boolean | null,
              createdAt: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          },
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          },
        } | null,
        cards:  {
          __typename: "ModelCompanyCardConnection",
          items:  Array< {
            __typename: "CompanyCard",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            card:  {
              __typename: "Card",
              id: string,
              type: CardType | null,
              number: string | null,
              nameOnCard: string | null,
              expiryMonth: string | null,
              expiryYear: string | null,
              peachPaymentCardId: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateCompanyReportSubscription = {
  onCreateCompanyReport:  {
    __typename: "CompanyReport",
    id: string,
    createdAt: string | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateCompanyReportSubscription = {
  onUpdateCompanyReport:  {
    __typename: "CompanyReport",
    id: string,
    createdAt: string | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteCompanyReportSubscription = {
  onDeleteCompanyReport:  {
    __typename: "CompanyReport",
    id: string,
    createdAt: string | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
    company:  {
      __typename: "Company",
      id: string,
      createdAt: string | null,
      name: string | null,
      emailAddress: string | null,
      contactNumber: string | null,
      address: string | null,
      industry: Industry | null,
      physicalAddress: string | null,
      numberOfEmployees: string | null,
      archived: boolean | null,
      interests:  {
        __typename: "ModelCompanyInterestConnection",
        items:  Array< {
          __typename: "CompanyInterest",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          interests:  {
            __typename: "Interest",
            id: string,
            name: string | null,
            createdAt: string | null,
            company:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      teamLead:  {
        __typename: "User",
        id: string,
        dateOfBirth: string | null,
        createdAt: string | null,
        name: string | null,
        surname: string | null,
        emailAddress: string | null,
        contactNumber: string | null,
        idNumber: string | null,
        physicalAddress: string | null,
        archived: boolean | null,
        verified: boolean | null,
        role: UserRole | null,
        status: UserStatus | null,
        jobTitle: string | null,
        company:  {
          __typename: "ModelUserCompanyConnection",
          items:  Array< {
            __typename: "UserCompany",
            id: string,
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        companyTeamLead:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
      } | null,
      teamMembers:  {
        __typename: "ModelUserCompanyConnection",
        items:  Array< {
          __typename: "UserCompany",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          user:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      reports:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
      cards:  {
        __typename: "ModelCompanyCardConnection",
        items:  Array< {
          __typename: "CompanyCard",
          id: string,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
          card:  {
            __typename: "Card",
            id: string,
            type: CardType | null,
            number: string | null,
            nameOnCard: string | null,
            expiryMonth: string | null,
            expiryYear: string | null,
            peachPaymentCardId: string | null,
            company:  {
              __typename: "CompanyCard",
              id: string,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateReportTagSubscription = {
  onCreateReportTag:  {
    __typename: "ReportTag",
    id: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReportTagSubscription = {
  onUpdateReportTag:  {
    __typename: "ReportTag",
    id: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type OnDeleteReportTagSubscription = {
  onDeleteReportTag:  {
    __typename: "ReportTag",
    id: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type OnCreateReportCategorySubscription = {
  onCreateReportCategory:  {
    __typename: "ReportCategory",
    id: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReportCategorySubscription = {
  onUpdateReportCategory:  {
    __typename: "ReportCategory",
    id: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type OnDeleteReportCategorySubscription = {
  onDeleteReportCategory:  {
    __typename: "ReportCategory",
    id: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type OnCreateReportRegionSubscription = {
  onCreateReportRegion:  {
    __typename: "ReportRegion",
    id: string,
    region:  {
      __typename: "Region",
      id: string,
      name: string | null,
      reports:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReportRegionSubscription = {
  onUpdateReportRegion:  {
    __typename: "ReportRegion",
    id: string,
    region:  {
      __typename: "Region",
      id: string,
      name: string | null,
      reports:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};

export type OnDeleteReportRegionSubscription = {
  onDeleteReportRegion:  {
    __typename: "ReportRegion",
    id: string,
    region:  {
      __typename: "Region",
      id: string,
      name: string | null,
      reports:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    report:  {
      __typename: "Report",
      id: string,
      createdAt: string | null,
      name: string | null,
      description: string | null,
      publishedDate: string | null,
      price: string | null,
      documentLocation: string | null,
      maxDownloads: string | null,
      downloadable: boolean | null,
      status: ReportStatus | null,
      paidOrFree: boolean | null,
      reportDetails: string | null,
      coverImageUrl: string | null,
      documentResourceUrl: string | null,
      area:  {
        __typename: "AreaType",
        id: string,
        name: string | null,
        createdAt: string | null,
        city:  {
          __typename: "CityType",
          id: string,
          name: string | null,
          province:  {
            __typename: "ProvinceType",
            id: string,
            name: string | null,
            createdAt: string | null,
            cities:  {
              __typename: "ModelCityTypeConnection",
              nextToken: string | null,
            } | null,
          } | null,
          createdAt: string | null,
          areas:  {
            __typename: "ModelAreaTypeConnection",
            items:  Array< {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
        } | null,
        companies:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        reports:  {
          __typename: "ModelReportConnection",
          items:  Array< {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
      } | null,
      tags:  {
        __typename: "ModelReportTagConnection",
        items:  Array< {
          __typename: "ReportTag",
          id: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      categories:  {
        __typename: "ModelReportCategoryConnection",
        items:  Array< {
          __typename: "ReportCategory",
          id: string,
          category:  {
            __typename: "Category",
            id: string,
            name: string | null,
            createdAt: string | null,
            reports:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      region:  {
        __typename: "ModelReportRegionConnection",
        items:  Array< {
          __typename: "ReportRegion",
          id: string,
          region:  {
            __typename: "Region",
            id: string,
            name: string | null,
            reports:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
          } | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      companys:  {
        __typename: "ModelCompanyReportConnection",
        items:  Array< {
          __typename: "CompanyReport",
          id: string,
          createdAt: string | null,
          report:  {
            __typename: "Report",
            id: string,
            createdAt: string | null,
            name: string | null,
            description: string | null,
            publishedDate: string | null,
            price: string | null,
            documentLocation: string | null,
            maxDownloads: string | null,
            downloadable: boolean | null,
            status: ReportStatus | null,
            paidOrFree: boolean | null,
            reportDetails: string | null,
            coverImageUrl: string | null,
            documentResourceUrl: string | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            tags:  {
              __typename: "ModelReportTagConnection",
              nextToken: string | null,
            } | null,
            categories:  {
              __typename: "ModelReportCategoryConnection",
              nextToken: string | null,
            } | null,
            region:  {
              __typename: "ModelReportRegionConnection",
              nextToken: string | null,
            } | null,
            companys:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
          } | null,
          company:  {
            __typename: "Company",
            id: string,
            createdAt: string | null,
            name: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            address: string | null,
            industry: Industry | null,
            physicalAddress: string | null,
            numberOfEmployees: string | null,
            archived: boolean | null,
            interests:  {
              __typename: "ModelCompanyInterestConnection",
              nextToken: string | null,
            } | null,
            area:  {
              __typename: "AreaType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            teamLead:  {
              __typename: "User",
              id: string,
              dateOfBirth: string | null,
              createdAt: string | null,
              name: string | null,
              surname: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              idNumber: string | null,
              physicalAddress: string | null,
              archived: boolean | null,
              verified: boolean | null,
              role: UserRole | null,
              status: UserStatus | null,
              jobTitle: string | null,
            } | null,
            teamMembers:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelCompanyReportConnection",
              nextToken: string | null,
            } | null,
            transactions:  {
              __typename: "Transaction",
              id: string,
              createdAt: string | null,
              cost: string | null,
            } | null,
            cards:  {
              __typename: "ModelCompanyCardConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      transactions:  {
        __typename: "Transaction",
        id: string,
        createdAt: string | null,
        cost: string | null,
        voucher:  {
          __typename: "TransactionVoucher",
          id: string,
          transaction:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          voucher:  {
            __typename: "Voucher",
            id: string,
            code: string | null,
            discountPercentage: string | null,
            numberOfUses: string | null,
            expiryDate: string | null,
            archived: boolean | null,
            createdAt: string | null,
            transactions:  {
              __typename: "ModelTransactionVoucherConnection",
              nextToken: string | null,
            } | null,
          } | null,
        } | null,
        report:  {
          __typename: "Report",
          id: string,
          createdAt: string | null,
          name: string | null,
          description: string | null,
          publishedDate: string | null,
          price: string | null,
          documentLocation: string | null,
          maxDownloads: string | null,
          downloadable: boolean | null,
          status: ReportStatus | null,
          paidOrFree: boolean | null,
          reportDetails: string | null,
          coverImageUrl: string | null,
          documentResourceUrl: string | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          tags:  {
            __typename: "ModelReportTagConnection",
            items:  Array< {
              __typename: "ReportTag",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          categories:  {
            __typename: "ModelReportCategoryConnection",
            items:  Array< {
              __typename: "ReportCategory",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          region:  {
            __typename: "ModelReportRegionConnection",
            items:  Array< {
              __typename: "ReportRegion",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          companys:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
        },
        company:  {
          __typename: "Company",
          id: string,
          createdAt: string | null,
          name: string | null,
          emailAddress: string | null,
          contactNumber: string | null,
          address: string | null,
          industry: Industry | null,
          physicalAddress: string | null,
          numberOfEmployees: string | null,
          archived: boolean | null,
          interests:  {
            __typename: "ModelCompanyInterestConnection",
            items:  Array< {
              __typename: "CompanyInterest",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          area:  {
            __typename: "AreaType",
            id: string,
            name: string | null,
            createdAt: string | null,
            city:  {
              __typename: "CityType",
              id: string,
              name: string | null,
              createdAt: string | null,
            } | null,
            companies:  {
              __typename: "ModelCompanyConnection",
              nextToken: string | null,
            } | null,
            reports:  {
              __typename: "ModelReportConnection",
              nextToken: string | null,
            } | null,
          } | null,
          teamLead:  {
            __typename: "User",
            id: string,
            dateOfBirth: string | null,
            createdAt: string | null,
            name: string | null,
            surname: string | null,
            emailAddress: string | null,
            contactNumber: string | null,
            idNumber: string | null,
            physicalAddress: string | null,
            archived: boolean | null,
            verified: boolean | null,
            role: UserRole | null,
            status: UserStatus | null,
            jobTitle: string | null,
            company:  {
              __typename: "ModelUserCompanyConnection",
              nextToken: string | null,
            } | null,
            companyTeamLead:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            } | null,
          } | null,
          teamMembers:  {
            __typename: "ModelUserCompanyConnection",
            items:  Array< {
              __typename: "UserCompany",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
          reports:  {
            __typename: "ModelCompanyReportConnection",
            items:  Array< {
              __typename: "CompanyReport",
              id: string,
              createdAt: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          transactions:  {
            __typename: "Transaction",
            id: string,
            createdAt: string | null,
            cost: string | null,
            voucher:  {
              __typename: "TransactionVoucher",
              id: string,
            } | null,
            report:  {
              __typename: "Report",
              id: string,
              createdAt: string | null,
              name: string | null,
              description: string | null,
              publishedDate: string | null,
              price: string | null,
              documentLocation: string | null,
              maxDownloads: string | null,
              downloadable: boolean | null,
              status: ReportStatus | null,
              paidOrFree: boolean | null,
              reportDetails: string | null,
              coverImageUrl: string | null,
              documentResourceUrl: string | null,
            },
            company:  {
              __typename: "Company",
              id: string,
              createdAt: string | null,
              name: string | null,
              emailAddress: string | null,
              contactNumber: string | null,
              address: string | null,
              industry: Industry | null,
              physicalAddress: string | null,
              numberOfEmployees: string | null,
              archived: boolean | null,
            },
          } | null,
          cards:  {
            __typename: "ModelCompanyCardConnection",
            items:  Array< {
              __typename: "CompanyCard",
              id: string,
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
      } | null,
    } | null,
  } | null,
};
