import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withToastManager } from "react-toast-notifications";

/** GraphQL */
import { getUser } from "../../graphql/queries";

import { GetUserQuery, GetUserQueryVariables } from "../../API";

/** Presentation/UI */
import FullWidthContainer from "../../Components/Layouts/FullWidthContainer";
import Loader from "../../Components/Loader";
import { Colors } from "../../Themes";

/** Custom Types */
import {
  ToastNotificationType,
  UpdatePersonalProfileViews
} from "../../CustomTypes";

/** Local components */
import EditProfileForm from "./EditProfileForm";
import ChangePasswordForm from "./ChangePasswordForm";

type Props = {
  userId: string;
  toastManager: ToastNotificationType;
  closeModal(): void;
};

type State = { view: UpdatePersonalProfileViews };

class EditProfile extends React.Component<Props, State> {
  state: State = {
    view: "profileDetails"
  };
  // success notification
  toastNotification = (message: string) => {
    this.props.toastManager.add(message, {
      appearance: "success",
      autoDismiss: true
    });
  };

  // Update view
  updateView = (view: UpdatePersonalProfileViews): void => {
    this.setState({ view });
  };

  render() {
    const { userId, closeModal } = this.props;
    const { view } = this.state;
    return (
      <Query<GetUserQuery, GetUserQueryVariables>
        query={gql(getUser)}
        variables={{ id: userId }}
      >
        {({ loading, error, data }: any) => {
          if (loading) {
            return (
              <FullWidthContainer align="center">
                <Loader size={50} color={Colors.primary} />
              </FullWidthContainer>
            );
          }
          if (error) {
            return <div>There was a problem loading your user data</div>;
          }

          if (!data) {
            return <div>There was a problem loading your user data</div>;
          }

          const user = data.getUser;

          if (!user) {
            return <div>There was a problem loading your user data</div>;
          }

          if (view === "profileDetails") {
            return (
              <EditProfileForm
                user={user}
                updateView={this.updateView}
                notification={this.toastNotification}
                closeModal={closeModal}
              />
            );
          } else {
            return (
              <ChangePasswordForm
                updateView={this.updateView}
                notification={this.toastNotification}
                closeModal={closeModal}
              />
            );
          }
        }}
      </Query>
    );
  }
}

export default withToastManager(EditProfile);
