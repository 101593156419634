// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:8a67043f-2018-45b4-b71f-16136c53db1b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_5mVPL36D2",
    "aws_user_pools_web_client_id": "75mlcqmj7bam8e9dr7ic3ecv8n",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://kx65wmwe7javtca5xp45l4siyy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "roots-develop",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://roots-develop.s3-website-eu-west-1.amazonaws.com",
    "aws_user_files_s3_bucket": "rootsee3f62432e544888ba163df8d2e33f42-develop",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
