import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withToastManager } from "react-toast-notifications";

/** GraphQL */
import { getCompanyReports } from "../../graphql/custom-queries";

/** Presentation/UI */
import Table from "../../Components/Table";
import StyledButton from "../../Components/Styled/Button";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import PageLoader from "../../Components/PageLoader";
import {
  TableHeader,
  TableHeaderContainer
} from "../../Components/Styled/ListViewElements";
import Row from "../../Components/Styled/Row";

/** Themes */
import { Colors } from "../../Themes";

/** Custom Types */
import { ToastNotificationType } from "../../CustomTypes";
import { TableConsts } from "../../Utils/Consts";
import { getUserId, sortByCreatedAt } from "../../Utils/Helpers";
import { RouteComponentProps } from "react-router";
import { MdSearch } from "react-icons/md";

type Props = RouteComponentProps & {
  toastManager: ToastNotificationType;
};

type State = {
  importModal: boolean;
  searchFilter: boolean;
};

class PurchasedReports extends React.Component<Props> {
  state: State = {
    importModal: false,
    searchFilter: false
  };

  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance ? appearance : "success",
      autoDismiss: true
    });
  };

  // Format & show report purchased date
  formatDate = (date: string) => {
    const purchasedDate = new Date(date);
    const day = purchasedDate.getDate();
    const month = purchasedDate.getMonth() + 1;
    const year = purchasedDate.getFullYear();
    return day + "-" + month + "-" + year;
  };

  render() {
    const { searchFilter } = this.state;
    return (
      <BackendWrapper>
        <Query
          query={gql(getCompanyReports)}
          variables={{ id: getUserId(), limit: 50 }}
        >
          {({ loading, error, data }: any) => {
            if (loading) {
              return <PageLoader />;
            }

            if (error) {
              return <div>There was a problem loading your data</div>;
            }

            if (!data || !data.getUser.company.items[0].company.reports) {
              return <Row>There are no Reports at the moment</Row>;
            }

            return (
              <React.Fragment>
                <TableHeaderContainer>
                  <TableHeader>
                    <span>Purchased Reports</span>
                  </TableHeader>
                  <StyledButton
                    type="button"
                    label={<MdSearch size="1.3em" color={Colors.secondary} />}
                    width="auto"
                    margin="0px 10px"
                    onClick={this.toggleSearchFilter}
                    color={searchFilter ? Colors.background : Colors.coal}
                    background={
                      searchFilter ? Colors.default : Colors.background
                    }
                  />
                </TableHeaderContainer>
                <Table
                  data={sortByCreatedAt(
                    data.getUser.company.items[0].company.reports.items
                  )}
                  getTdProps={(state: any, rowInfo: { [key: string]: any }) => {
                    return {
                      onClick: () => {
                        if (rowInfo) {
                          this.props.history.push({
                            pathname: `/consumer/report/${
                              rowInfo.original.report.id
                            }`,
                            state: rowInfo.original
                          });
                        }
                      }
                    };
                  }}
                  columns={[
                    {
                      Header: "Report Name",
                      accessor: "report.name",
                      sortable: true,
                      filterable: searchFilter
                    },
                    {
                      id: "price",
                      Header: "Price",
                      accessor: (item: { [key: string]: any }) => {
                        return item.report.price
                          ? "R " + item.report.price
                          : "-";
                      },
                      sortable: true
                    },
                    {
                      id: "purchaseDate",
                      Header: "Purchased Date",
                      accessor: (date: { [key: string]: string }) => {
                        return this.formatDate(date.createdAt);
                      },
                      sortable: true,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={TableConsts.defaultRows}
                  showPaginationBottom={
                    data.getUser.company.items[0].company.reports.items.length >
                    TableConsts.defaultRows
                  }
                />
              </React.Fragment>
            );
          }}
        </Query>
        <Helmet title="View Purchased Reports" />
      </BackendWrapper>
    );
  }
}

export default withToastManager(PurchasedReports);
