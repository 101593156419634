import * as React from "react";

/** Presentation/UI */
import { Col, CardText, CardBody } from "reactstrap";
import Card from "../../Components/Styled/Card";
import Row from "../../Components/Styled/Row";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import { MdKeyboardArrowLeft } from "react-icons/md";
import StyledButton from "../../Components/Styled/Button";
import Link from "../../Components/Styled/Link";
import colors from "../../Themes/Colors";
import styled from "styled-components";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import PageLoader from "../../Components/PageLoader";
import { RouteComponentProps } from "react-router";
import ImageWrapper from "../ConsumerReports/ResourceWrapper";
import CardImg from "reactstrap/lib/CardImg";
import images from "../../Themes/Images";
import {
  getReportWithTrans,
  getUsersCompany
} from "../../graphql/custom-queries";
import { getUserId } from "../../Utils/Helpers";

interface IPathParams {
  id: string;
}

type Props = RouteComponentProps<IPathParams> & {
  computedMatch?: {
    params: {
      id: string;
    };
  };
  location?: { [key: string]: any }; // object with any amount and types of params
};

const Label = styled.label`
  padding: 2px;
  background: ${colors.primary};
  display: block;
`;

const returnFields = (report: any, props: any, showDownload: boolean) => {
  return (
    <Row>
      <Row style={{ minWidth: "100%" }}>
        <Col sm={12} md={12} lg={12}>
          {props.computedMatch &&
          props.computedMatch.params &&
          props.computedMatch.params.id ? (
            <Link
              to={{
                pathname: "/consumer/reports"
              }}
              color={colors.coal}
              label={
                <p style={{ fontSize: "17px" }}>
                  <MdKeyboardArrowLeft size="2em" /> Back
                </p>
              }
            />
          ) : (
            <Link
              to={{
                pathname: props.location.state.id
                  ? "/admin-reports/edit"
                  : "/admin-reports/add",
                state: props.location.state
              }}
              color={colors.coal}
              label={
                <p style={{ fontSize: "17px" }}>
                  <MdKeyboardArrowLeft size="2em" /> Back
                </p>
              }
            />
          )}
        </Col>
        <Col sm={12} md={4} lg={4}>
          <Card height={"100%"} padding={"0px"}>
            {props.location &&
            props.location.state &&
            props.location.state.id ? (
              <CardImg src={images.placeHolder} />
            ) : (
              <ImageWrapper resourceKey={report.coverImageUrl} />
            )}
          </Card>
        </Col>
        <Col sm={12} md={8} lg={8}>
          <Card>
            <CardBody>
              <h4>{report.name}</h4>
              <h5>R {report.price}</h5>
              <span>(incl. VAT)</span>
              <CardText>{report.description}</CardText>
              <Label />
              {props.computedMatch &&
              props.computedMatch.params &&
              props.computedMatch.params.id ? (
                showDownload ? (
                  <ImageWrapper
                    downloadPdf={true}
                    resourceKey={report.documentResourceUrl}
                  />
                ) : (
                  <Link
                    to={{
                      pathname: `/consumer/checkout/${report.id}`
                    }}
                    label={
                      <StyledButton
                        type="button"
                        label="Checkout"
                        width="100px"
                        color={colors.snow}
                        background={colors.default}
                        margin="5px"
                      />
                    }
                  />
                )
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row paddingTop={"20"} style={{ minWidth: "100%" }}>
        <Col sm={12} md={12} lg={12}>
          <Card>
            <CardBody>
              <p style={{ fontSize: "15px" }}>DETAILS</p>
              <CardText>{report.reportDetails}</CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Row>
  );
};

const Report = (props: Props) => {
  if (
    props.computedMatch &&
    props.computedMatch.params &&
    props.computedMatch.params.id
  ) {
    return (
      <BackendWrapper>
        <Query
          query={gql(getUsersCompany)}
          variables={{ limit: 5, id: getUserId() }}
        >
          {({ loading: userLoading, data: userData, error: userError }: any) => {
            if (userLoading) {
              return <PageLoader />;
            }
            if (!userLoading && userData) {
              const usersCompany = userData.getUser.company.items[0].company.id;
              return (
                <Query
                  query={gql(getReportWithTrans)}
                  variables={{
                    limit: 5,
                    id:
                      props && props.computedMatch
                        ? props.computedMatch.params.id
                        : ""
                  }}
                >
                  {({ loading, data, error }: any) => {
                    if (loading) {
                      return <PageLoader />;
                    }
                    if (!loading && data) {
                      let showDownload = false;
                      // after react scripts changes
                      // eslint-disable-next-line
                      data.getReport.companys.items.map((transaction: any) => {
                        if (transaction.company.id === usersCompany) {
                          showDownload = true;
                        }
                      });

                      return (
                        <React.Fragment>
                          {returnFields(data.getReport, props, showDownload)}
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <p>There has been a problem loading your report</p>
                      );
                    }
                  }}
                </Query>
              );
            }
            return;
          }}
        </Query>
      </BackendWrapper>
    );
  }
  if (props.location.state) {
    return (
      <BackendWrapper>
        {returnFields(props.location.state, props, false)}
      </BackendWrapper>
    );
  }
  return <p>There has been a problem loading your report</p>;
};

export default Report;
