import * as React from "react";
import styled from "styled-components";

// Themes
import { Colors } from "../../Themes";

interface IProps {
  children: React.ReactNode;
  background?: string;
  overlay?: boolean;
}

interface IWrapper {
  background?: string;
  overlay?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background: ${(props: IWrapper) =>
    props.background ? `url(${props.background})` : Colors.darkGrey};
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: 50% 50%;
  z-index: -1;
  padding: 0;
  margin: 0;
  flex-direction: column;
  &:before {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props: IWrapper) =>
      props.overlay ? Colors.overlayBlack : Colors.transparent};
    content: "";
    z-index: 0;
  }
`;

export default class PageWrapper extends React.Component<IProps> {
  render() {
    const { children, background, overlay } = this.props;
    return (
      <Wrapper background={background} overlay={overlay}>
        {children}
      </Wrapper>
    );
  }
}
