/** Query to list company details */
export const getCompaniesUsers = `query GetCompaniesUsers($id: ID!) {
    getCompany(id: $id) {
      id
      name
      emailAddress
      contactNumber
      address
      physicalAddress
      numberOfEmployees
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
          id
          name
          createdAt
          }
          createdAt
          areas {
          nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          user {
              id
              dateOfBirth
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
          }
        }
        nextToken
      }
    }
  }
  `;
export const getUsersCompany = `query GetUsersCompany(
  $id: ID!
  $limit: Int
  ) {
    getUser(id: $id) {
      id
      dateOfBirth
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company{
            id
            name
            emailAddress
            contactNumber
            address
            physicalAddress
            numberOfEmployees
            industry
            interests{
              items {
                id
                interests {
                  id
                  name
                }
              }
              nextToken
            }
            area {
              id
              name
              createdAt
              city {
                id
                name
                province {
                id
                name
                createdAt
                }
                createdAt
                areas {
                nextToken
                }
              }
            }
            teamMembers {
              items {
                id
                user {
                    id
                    dateOfBirth
                    name
                    surname
                    emailAddress
                    contactNumber
                    idNumber
                    physicalAddress
                    archived
                    verified
                    role
                    status
                    jobTitle
                }
              }
              nextToken
            }
            cards (limit: $limit) {
              items {
                id
                card {
                  id
                  expiryMonth
                  expiryYear
                  nameOnCard
                  number
                  peachPaymentCardId
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      
    }
  }
  `;

export const dashboardCount = `query Count(
    $companyFilter: ModelCompanyFilterInput
    $reportFilter: ModelReportFilterInput
    $transFilter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $companyFilter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
      }
      nextToken
    }
    listReports(filter: $reportFilter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        name
        price
        companys {
          items {
            id
          }
        }
      }
      nextToken
    }
    listTransactions(filter: $transFilter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
      }
      nextToken
    }
  }
  `;

export const listCategorysWithReports = `query GetCategorysList(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      reports {
        items {
          id
        }
        nextToken
      }
    }
    nextToken
  }
}
  `;

export const listTagsWithReports = `query GetTagsList($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
  listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      reports {
        items {
          id
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const listInterestsWithCompanys = `query GetInterestsList(
  $filter: ModelInterestFilterInput
  $limit: Int
  $nextToken: String
) {
  listInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      company {
        items {
          id
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const listReportsWithCategories = `query GetReportList(
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
          id
          name
          createdAt
          }
          createdAt
          areas {
          nextToken
          }
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
          }
        }
        nextToken
      }
      region {
        nextToken
      }
      companys {
        items {
          id
        }
        nextToken
      }
      transactions {
        id
      }
    }
    nextToken
  }
}
`;

export const listCompanyCards = `query GetCompanyCards(
  $filter: ModelCompanyCardFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      company {
        id
      }
      card {
        id
        nameOnCard
        number
        type
        expiryMonth
        expiryYear
        peachPaymentCardId
      }
    }
    nextToken
  }
}
`;

export const getCompanyReports = `query GetUserCompanyReports($id: ID!) {
  getUser(id: $id) {
    id
    company {
      items {
        id
        company {
          id
          cards {
            items {
              id
              card {
                id
                number
                peachPaymentCardId
              }
            }
          }
          reports {
            items {
              id
              createdAt
              report {
                id
                name
                price
              }
            }
          }
        }
      }
      nextToken
    }
  }
}`;

export const listCompanyReports = `query GetCompanyReports(
  $id: ID!
  $limit: Int
  ) {
    getUser(id: $id) {
      id
      company {
        items {
          company {
            reports(limit: $limit) {
              items {
                createdAt
                report {
                  id
                  name
                  price
                }
              }
              nextToken
            }
          }
        }
      }
    }
  }
`;

export const getReport = `query GetSingleReport($id: ID!) {
  getReport(id: $id) {
    id
    name
    price
    maxDownloads
    companys {
      items {
        id
        company {
          id
        }
      }
      nextToken
    }

  }
}
`;

export const getReportCategoriesTags = `query GetSingleReportCT($id: ID!) {
  getReport(id: $id) {
    id
    categories {
      items {
        id
        category {
          id
          name
        }
      }
      nextToken
    }
    tags {
      items {
        id
        tag {
          id
          name
        }
      }
      nextToken
    }
  }
}
`;

export const listSales = `query getSales(
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
  ) {
  listTransactions(filter: $filter limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      cost
      company {
        id
        name
        emailAddress
        contactNumber
        numberOfEmployees
        industry
        address
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
            id
            name
            createdAt
            }
            createdAt
            areas {
            nextToken
            }
          }
        }
      }
      report {
        id
        name
        price
      }
    }
    nextToken
  }
}`;

export const getReportWithTrans = `query GetReportTrans($id: ID!) {
  getReport(id: $id) {
    id
    createdAt
    name
    description
    publishedDate
    price
    documentLocation
    maxDownloads
    downloadable
    status
    paidOrFree
    reportDetails
    coverImageUrl
    documentResourceUrl
    area {
      id
      name
      createdAt
      city {
        id
        name
        province {
        id
        name
        createdAt
        }
        createdAt
        areas {
        nextToken
        }
      }
    }
    tags {
      nextToken
    }
    categories {
      nextToken
    }
    region {
      nextToken
    }
    companys {
      items {
        id
        company {
          id
        }
      }
    }
    transactions {
      id
    }
  }
}
`;

export const getCategoryReports = `query getOneCategory($id: ID!) {
  getCategory(id: $id) {
    id
    reports {
      items {
        id
        report {
          id
          name
          price
          description
          coverImageUrl
          status
        }
      }
    }
  }
}`;

export const listFeaturedReports = `query getFeatured(
  $filter: ModelReportFilterInput
  $limit: Int
  ) {
  listReports(filter: $filter, limit: $limit) {
    items {
      id
      name
      price
      description
      coverImageUrl
      status
      companys {
        items {
          id
        }
      }
    }
  }
}`;