import styled from "styled-components";

import { Colors } from "../../Themes";

type IStyledElements = {
  open: boolean;
};

export const PageWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

export const SideBarWrapper = styled.div`
  display: flex;
  width: ${(props: IStyledElements) => (props.open ? "250px" : "60px")};
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  z-index: 100;
  max-width: 100%;
  box-sizing: border-box;
  background: ${Colors.coal};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0 20px 0;
  z-index: 100;
  box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.09);
  & ul li span {
    display: ${(props: IStyledElements) => (props.open ? "inline" : "none")};
  }
`;

export const Spacer = styled.div`
  position: relative;
  width: ${(props: IStyledElements) => (props.open ? "250px" : "60px")};
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  display: flex;
  height: 100vh;
`;

export const Logo = styled.img`
  display: inline-block;
  max-width: 100%;
  height: auto;
  margin: ${(props: IStyledElements) =>
    props.open ? "0 20px 0 0" : "0 -15px 0 0"};
  width: 40%;
`;

export const LogoCont = styled.div`
  position: fixed;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props: IStyledElements) => (props.open ? "250px" : "60px")};
  z-index: 200;
  box-sizing: border-box;
  border-bottom: 1px solid ${Colors.transparentWhite};
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-property: width;
  & ${Logo} {
    visibility: ${(props: IStyledElements) =>
      props.open ? "visible" : "hidden"};
    display: ${(props: IStyledElements) => (props.open ? "flex" : "none")};
    opacity: ${(props: IStyledElements) => (props.open ? 1 : 0)};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-property: visibility, opacity;
  }
`;

export const LogoutButton = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: ${(props: IStyledElements) => (props.open ? "250px" : "60px")};
  background-color: ${Colors.transparent};
  border-top: 1px solid ${Colors.transparentWhite};
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &:hover {
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    background-color: ${Colors.primary};
    cursor: pointer;
  }
`;
