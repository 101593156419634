import * as React from "react";
import { Col, CardText, CardBody } from "reactstrap";
import Card from "../../Components/Styled/Card";
import Row from "../../Components/Styled/Row";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import { Query } from "react-apollo";
import { listSampleReports } from "../../graphql/queries";
import PageLoader from "../../Components/PageLoader";
import gql from "graphql-tag";
import { sortByCreatedAt } from "../../Utils/Helpers";
import ImageWrapper from "../ConsumerReports/ResourceWrapper";

const AboutRoots = () => {
  return (
    <Query query={gql(listSampleReports)} variables={{ limit: 1 }}>
      {({ loading, data }: any) => {
        if (loading) {
          return <PageLoader />;
        }
        if (
          !loading &&
          data &&
          data.listSampleReports &&
          data.listSampleReports.items &&
          data.listSampleReports.items[0]
        ) {
          const {
            coverImageUrl,
            documentResourceUrl,
            rootsDescription,
            sampleReportDescription
          } = sortByCreatedAt(data.listSampleReports.items)[0];
          return (
            <BackendWrapper>
              <Row>
                <Row style={{ minWidth: "100%" }}>
                  <Col sm={12} md={4} lg={4}>
                    <Card height={"100%"} padding={"0px"}>
                      <ImageWrapper resourceKey={coverImageUrl} />
                    </Card>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Card height={"100%"}>
                      <CardBody>
                        <h4>About Roots</h4>
                        <CardText>{rootsDescription}</CardText>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row paddingTop={"20"} style={{ minWidth: "100%" }}>
                  <Col sm={12} md={12} lg={12}>
                    <Card>
                      <CardBody>
                        <p style={{ fontSize: "15px" }}>Roots Report</p>
                        <CardText>{sampleReportDescription}</CardText>
                        <ImageWrapper
                          downloadPdf={true}
                          resourceKey={documentResourceUrl}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </BackendWrapper>
          );
        } else {
          return (
            <div>
              There has been an error loading the Sample Report. Please contact
              Roots.
            </div>
          );
        }
      }}
    </Query>
  );
};

export default AboutRoots;
