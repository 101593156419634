import * as React from "react";
import * as ReactDOM from "react-dom";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

import App from "./App";

import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";

// Error boundary fallback component
import FallbackComponent from "./Components/ErrorBoundaryFallback";

const bugsnagClient = bugsnag("17b8a09afee994ce11b01b13b8192d3b");
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin("react");

const Root: React.SFC<{}> = () => (
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <App />
  </ErrorBoundary>
);

ReactDOM.render(<Root />, document.getElementById("root") as HTMLElement);
registerServiceWorker();
