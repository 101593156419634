import * as React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { Colors } from "../../Themes";

const SimpleLineChart = (props: any) => (
  <ResponsiveContainer>
    <LineChart
      data={props.data}
      margin={{ top: 0, right: 0, left: -35, bottom: 0 }}
    >
      <XAxis dataKey="name" />
      <YAxis />
      <CartesianGrid
        strokeDasharray="solid"
        vertical={false}
        stroke={"#d6dbe6"}
      />
      <Tooltip />
      <Legend />
      <Line
        type="linear"
        dataKey="Clients"
        stroke={Colors.default}
        activeDot={{ r: 8 }}
      />
    </LineChart>
  </ResponsiveContainer>
);

export default SimpleLineChart;
