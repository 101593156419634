// tslint:disable
// this is an auto generated file. This will be overwritten

export const createTask = `mutation CreateTask($input: CreateTaskInput!) {
  createTask(input: $input) {
    id
    title
    description
    status
    assignedTo {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const updateTask = `mutation UpdateTask($input: UpdateTaskInput!) {
  updateTask(input: $input) {
    id
    title
    description
    status
    assignedTo {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const deleteTask = `mutation DeleteTask($input: DeleteTaskInput!) {
  deleteTask(input: $input) {
    id
    title
    description
    status
    assignedTo {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const createCompany = `mutation CreateCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    id
    createdAt
    name
    emailAddress
    contactNumber
    address
    industry
    physicalAddress
    numberOfEmployees
    archived
    interests {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        interests {
          id
          name
          createdAt
          company {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    area {
      id
      name
      createdAt
      city {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      companies {
        items {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
    }
    teamLead {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    teamMembers {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        user {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
    reports {
      items {
        id
        createdAt
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    transactions {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    cards {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        card {
          id
          type
          number
          nameOnCard
          expiryMonth
          expiryYear
          peachPaymentCardId
          company {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const updateCompany = `mutation UpdateCompany($input: UpdateCompanyInput!) {
  updateCompany(input: $input) {
    id
    createdAt
    name
    emailAddress
    contactNumber
    address
    industry
    physicalAddress
    numberOfEmployees
    archived
    interests {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        interests {
          id
          name
          createdAt
          company {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    area {
      id
      name
      createdAt
      city {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      companies {
        items {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
    }
    teamLead {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    teamMembers {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        user {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
    reports {
      items {
        id
        createdAt
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    transactions {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    cards {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        card {
          id
          type
          number
          nameOnCard
          expiryMonth
          expiryYear
          peachPaymentCardId
          company {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const deleteCompany = `mutation DeleteCompany($input: DeleteCompanyInput!) {
  deleteCompany(input: $input) {
    id
    createdAt
    name
    emailAddress
    contactNumber
    address
    industry
    physicalAddress
    numberOfEmployees
    archived
    interests {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        interests {
          id
          name
          createdAt
          company {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    area {
      id
      name
      createdAt
      city {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      companies {
        items {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
    }
    teamLead {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    teamMembers {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        user {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
    reports {
      items {
        id
        createdAt
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    transactions {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    cards {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        card {
          id
          type
          number
          nameOnCard
          expiryMonth
          expiryYear
          peachPaymentCardId
          company {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    dateOfBirth
    createdAt
    name
    surname
    emailAddress
    contactNumber
    idNumber
    physicalAddress
    archived
    verified
    role
    status
    jobTitle
    company {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        user {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
    companyTeamLead {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    dateOfBirth
    createdAt
    name
    surname
    emailAddress
    contactNumber
    idNumber
    physicalAddress
    archived
    verified
    role
    status
    jobTitle
    company {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        user {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
    companyTeamLead {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    dateOfBirth
    createdAt
    name
    surname
    emailAddress
    contactNumber
    idNumber
    physicalAddress
    archived
    verified
    role
    status
    jobTitle
    company {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        user {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
    companyTeamLead {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const createReport = `mutation CreateReport($input: CreateReportInput!) {
  createReport(input: $input) {
    id
    createdAt
    name
    description
    publishedDate
    price
    documentLocation
    maxDownloads
    downloadable
    status
    paidOrFree
    reportDetails
    coverImageUrl
    documentResourceUrl
    area {
      id
      name
      createdAt
      city {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      companies {
        items {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
    }
    tags {
      items {
        id
        tag {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    categories {
      items {
        id
        category {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    region {
      items {
        id
        region {
          id
          name
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    companys {
      items {
        id
        createdAt
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    transactions {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const updateReport = `mutation UpdateReport($input: UpdateReportInput!) {
  updateReport(input: $input) {
    id
    createdAt
    name
    description
    publishedDate
    price
    documentLocation
    maxDownloads
    downloadable
    status
    paidOrFree
    reportDetails
    coverImageUrl
    documentResourceUrl
    area {
      id
      name
      createdAt
      city {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      companies {
        items {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
    }
    tags {
      items {
        id
        tag {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    categories {
      items {
        id
        category {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    region {
      items {
        id
        region {
          id
          name
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    companys {
      items {
        id
        createdAt
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    transactions {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const deleteReport = `mutation DeleteReport($input: DeleteReportInput!) {
  deleteReport(input: $input) {
    id
    createdAt
    name
    description
    publishedDate
    price
    documentLocation
    maxDownloads
    downloadable
    status
    paidOrFree
    reportDetails
    coverImageUrl
    documentResourceUrl
    area {
      id
      name
      createdAt
      city {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      companies {
        items {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
    }
    tags {
      items {
        id
        tag {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    categories {
      items {
        id
        category {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    region {
      items {
        id
        region {
          id
          name
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
    companys {
      items {
        id
        createdAt
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
    transactions {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const createRegion = `mutation CreateRegion($input: CreateRegionInput!) {
  createRegion(input: $input) {
    id
    name
    reports {
      items {
        id
        region {
          id
          name
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const updateRegion = `mutation UpdateRegion($input: UpdateRegionInput!) {
  updateRegion(input: $input) {
    id
    name
    reports {
      items {
        id
        region {
          id
          name
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const deleteRegion = `mutation DeleteRegion($input: DeleteRegionInput!) {
  deleteRegion(input: $input) {
    id
    name
    reports {
      items {
        id
        region {
          id
          name
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const createTag = `mutation CreateTag($input: CreateTagInput!) {
  createTag(input: $input) {
    id
    name
    createdAt
    reports {
      items {
        id
        tag {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const updateTag = `mutation UpdateTag($input: UpdateTagInput!) {
  updateTag(input: $input) {
    id
    name
    createdAt
    reports {
      items {
        id
        tag {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const deleteTag = `mutation DeleteTag($input: DeleteTagInput!) {
  deleteTag(input: $input) {
    id
    name
    createdAt
    reports {
      items {
        id
        tag {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const createCategory = `mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(input: $input) {
    id
    name
    createdAt
    reports {
      items {
        id
        category {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const updateCategory = `mutation UpdateCategory($input: UpdateCategoryInput!) {
  updateCategory(input: $input) {
    id
    name
    createdAt
    reports {
      items {
        id
        category {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const deleteCategory = `mutation DeleteCategory($input: DeleteCategoryInput!) {
  deleteCategory(input: $input) {
    id
    name
    createdAt
    reports {
      items {
        id
        category {
          id
          name
          createdAt
          reports {
            items {
              id
            }
            nextToken
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const createVoucher = `mutation CreateVoucher($input: CreateVoucherInput!) {
  createVoucher(input: $input) {
    id
    code
    discountPercentage
    numberOfUses
    expiryDate
    archived
    createdAt
    transactions {
      items {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const updateVoucher = `mutation UpdateVoucher($input: UpdateVoucherInput!) {
  updateVoucher(input: $input) {
    id
    code
    discountPercentage
    numberOfUses
    expiryDate
    archived
    createdAt
    transactions {
      items {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const deleteVoucher = `mutation DeleteVoucher($input: DeleteVoucherInput!) {
  deleteVoucher(input: $input) {
    id
    code
    discountPercentage
    numberOfUses
    expiryDate
    archived
    createdAt
    transactions {
      items {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const createFaq = `mutation CreateFaq($input: CreateFaqInput!) {
  createFaq(input: $input) {
    id
    question
    answer
    category {
      id
      title
      faqs {
        items {
          id
          question
          answer
          category {
            id
            title
            faqs {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const updateFaq = `mutation UpdateFaq($input: UpdateFaqInput!) {
  updateFaq(input: $input) {
    id
    question
    answer
    category {
      id
      title
      faqs {
        items {
          id
          question
          answer
          category {
            id
            title
            faqs {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const deleteFaq = `mutation DeleteFaq($input: DeleteFaqInput!) {
  deleteFaq(input: $input) {
    id
    question
    answer
    category {
      id
      title
      faqs {
        items {
          id
          question
          answer
          category {
            id
            title
            faqs {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const createFaqType = `mutation CreateFaqType($input: CreateFaqTypeInput!) {
  createFaqType(input: $input) {
    id
    title
    faqs {
      items {
        id
        question
        answer
        category {
          id
          title
          faqs {
            items {
              id
              question
              answer
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const updateFaqType = `mutation UpdateFaqType($input: UpdateFaqTypeInput!) {
  updateFaqType(input: $input) {
    id
    title
    faqs {
      items {
        id
        question
        answer
        category {
          id
          title
          faqs {
            items {
              id
              question
              answer
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const deleteFaqType = `mutation DeleteFaqType($input: DeleteFaqTypeInput!) {
  deleteFaqType(input: $input) {
    id
    title
    faqs {
      items {
        id
        question
        answer
        category {
          id
          title
          faqs {
            items {
              id
              question
              answer
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const createInterest = `mutation CreateInterest($input: CreateInterestInput!) {
  createInterest(input: $input) {
    id
    name
    createdAt
    company {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        interests {
          id
          name
          createdAt
          company {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const updateInterest = `mutation UpdateInterest($input: UpdateInterestInput!) {
  updateInterest(input: $input) {
    id
    name
    createdAt
    company {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        interests {
          id
          name
          createdAt
          company {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const deleteInterest = `mutation DeleteInterest($input: DeleteInterestInput!) {
  deleteInterest(input: $input) {
    id
    name
    createdAt
    company {
      items {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        interests {
          id
          name
          createdAt
          company {
            items {
              id
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const createTransaction = `mutation CreateTransaction($input: CreateTransactionInput!) {
  createTransaction(input: $input) {
    id
    createdAt
    cost
    voucher {
      id
      transaction {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      voucher {
        id
        code
        discountPercentage
        numberOfUses
        expiryDate
        archived
        createdAt
        transactions {
          items {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          nextToken
        }
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const updateTransaction = `mutation UpdateTransaction($input: UpdateTransactionInput!) {
  updateTransaction(input: $input) {
    id
    createdAt
    cost
    voucher {
      id
      transaction {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      voucher {
        id
        code
        discountPercentage
        numberOfUses
        expiryDate
        archived
        createdAt
        transactions {
          items {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          nextToken
        }
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const deleteTransaction = `mutation DeleteTransaction($input: DeleteTransactionInput!) {
  deleteTransaction(input: $input) {
    id
    createdAt
    cost
    voucher {
      id
      transaction {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      voucher {
        id
        code
        discountPercentage
        numberOfUses
        expiryDate
        archived
        createdAt
        transactions {
          items {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          nextToken
        }
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const createCard = `mutation CreateCard($input: CreateCardInput!) {
  createCard(input: $input) {
    id
    type
    number
    nameOnCard
    expiryMonth
    expiryYear
    peachPaymentCardId
    company {
      id
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
      card {
        id
        type
        number
        nameOnCard
        expiryMonth
        expiryYear
        peachPaymentCardId
        company {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
      }
    }
  }
}
`;
export const updateCard = `mutation UpdateCard($input: UpdateCardInput!) {
  updateCard(input: $input) {
    id
    type
    number
    nameOnCard
    expiryMonth
    expiryYear
    peachPaymentCardId
    company {
      id
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
      card {
        id
        type
        number
        nameOnCard
        expiryMonth
        expiryYear
        peachPaymentCardId
        company {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
      }
    }
  }
}
`;
export const deleteCard = `mutation DeleteCard($input: DeleteCardInput!) {
  deleteCard(input: $input) {
    id
    type
    number
    nameOnCard
    expiryMonth
    expiryYear
    peachPaymentCardId
    company {
      id
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
      card {
        id
        type
        number
        nameOnCard
        expiryMonth
        expiryYear
        peachPaymentCardId
        company {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
      }
    }
  }
}
`;
export const createProvinceType = `mutation CreateProvinceType($input: CreateProvinceTypeInput!) {
  createProvinceType(input: $input) {
    id
    name
    createdAt
    cities {
      items {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const updateProvinceType = `mutation UpdateProvinceType($input: UpdateProvinceTypeInput!) {
  updateProvinceType(input: $input) {
    id
    name
    createdAt
    cities {
      items {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const deleteProvinceType = `mutation DeleteProvinceType($input: DeleteProvinceTypeInput!) {
  deleteProvinceType(input: $input) {
    id
    name
    createdAt
    cities {
      items {
        id
        name
        province {
          id
          name
          createdAt
          cities {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        createdAt
        areas {
          items {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const createCityType = `mutation CreateCityType($input: CreateCityTypeInput!) {
  createCityType(input: $input) {
    id
    name
    province {
      id
      name
      createdAt
      cities {
        items {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
    createdAt
    areas {
      items {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const updateCityType = `mutation UpdateCityType($input: UpdateCityTypeInput!) {
  updateCityType(input: $input) {
    id
    name
    province {
      id
      name
      createdAt
      cities {
        items {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
    createdAt
    areas {
      items {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const deleteCityType = `mutation DeleteCityType($input: DeleteCityTypeInput!) {
  deleteCityType(input: $input) {
    id
    name
    province {
      id
      name
      createdAt
      cities {
        items {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
    createdAt
    areas {
      items {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const createAreaType = `mutation CreateAreaType($input: CreateAreaTypeInput!) {
  createAreaType(input: $input) {
    id
    name
    createdAt
    city {
      id
      name
      province {
        id
        name
        createdAt
        cities {
          items {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          nextToken
        }
      }
      createdAt
      areas {
        items {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        nextToken
      }
    }
    companies {
      items {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
      nextToken
    }
    reports {
      items {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const updateAreaType = `mutation UpdateAreaType($input: UpdateAreaTypeInput!) {
  updateAreaType(input: $input) {
    id
    name
    createdAt
    city {
      id
      name
      province {
        id
        name
        createdAt
        cities {
          items {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          nextToken
        }
      }
      createdAt
      areas {
        items {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        nextToken
      }
    }
    companies {
      items {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
      nextToken
    }
    reports {
      items {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const deleteAreaType = `mutation DeleteAreaType($input: DeleteAreaTypeInput!) {
  deleteAreaType(input: $input) {
    id
    name
    createdAt
    city {
      id
      name
      province {
        id
        name
        createdAt
        cities {
          items {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          nextToken
        }
      }
      createdAt
      areas {
        items {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        nextToken
      }
    }
    companies {
      items {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
      nextToken
    }
    reports {
      items {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      nextToken
    }
  }
}
`;
export const createSampleReport = `mutation CreateSampleReport($input: CreateSampleReportInput!) {
  createSampleReport(input: $input) {
    id
    name
    createdAt
    rootsDescription
    sampleReportDescription
    coverImageUrl
    documentResourceUrl
  }
}
`;
export const updateSampleReport = `mutation UpdateSampleReport($input: UpdateSampleReportInput!) {
  updateSampleReport(input: $input) {
    id
    name
    createdAt
    rootsDescription
    sampleReportDescription
    coverImageUrl
    documentResourceUrl
  }
}
`;
export const deleteSampleReport = `mutation DeleteSampleReport($input: DeleteSampleReportInput!) {
  deleteSampleReport(input: $input) {
    id
    name
    createdAt
    rootsDescription
    sampleReportDescription
    coverImageUrl
    documentResourceUrl
  }
}
`;
export const createTransactionVoucher = `mutation CreateTransactionVoucher($input: CreateTransactionVoucherInput!) {
  createTransactionVoucher(input: $input) {
    id
    transaction {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    voucher {
      id
      code
      discountPercentage
      numberOfUses
      expiryDate
      archived
      createdAt
      transactions {
        items {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const updateTransactionVoucher = `mutation UpdateTransactionVoucher($input: UpdateTransactionVoucherInput!) {
  updateTransactionVoucher(input: $input) {
    id
    transaction {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    voucher {
      id
      code
      discountPercentage
      numberOfUses
      expiryDate
      archived
      createdAt
      transactions {
        items {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const deleteTransactionVoucher = `mutation DeleteTransactionVoucher($input: DeleteTransactionVoucherInput!) {
  deleteTransactionVoucher(input: $input) {
    id
    transaction {
      id
      createdAt
      cost
      voucher {
        id
        transaction {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        voucher {
          id
          code
          discountPercentage
          numberOfUses
          expiryDate
          archived
          createdAt
          transactions {
            items {
              id
            }
            nextToken
          }
        }
      }
      report {
        id
        createdAt
        name
        description
        publishedDate
        price
        documentLocation
        maxDownloads
        downloadable
        status
        paidOrFree
        reportDetails
        coverImageUrl
        documentResourceUrl
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        categories {
          items {
            id
            category {
              id
              name
              createdAt
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        region {
          items {
            id
            region {
              id
              name
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
          }
          nextToken
        }
        companys {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
      }
      company {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
    voucher {
      id
      code
      discountPercentage
      numberOfUses
      expiryDate
      archived
      createdAt
      transactions {
        items {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const createCompanyCard = `mutation CreateCompanyCard($input: CreateCompanyCardInput!) {
  createCompanyCard(input: $input) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    card {
      id
      type
      number
      nameOnCard
      expiryMonth
      expiryYear
      peachPaymentCardId
      company {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        card {
          id
          type
          number
          nameOnCard
          expiryMonth
          expiryYear
          peachPaymentCardId
          company {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
        }
      }
    }
  }
}
`;
export const updateCompanyCard = `mutation UpdateCompanyCard($input: UpdateCompanyCardInput!) {
  updateCompanyCard(input: $input) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    card {
      id
      type
      number
      nameOnCard
      expiryMonth
      expiryYear
      peachPaymentCardId
      company {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        card {
          id
          type
          number
          nameOnCard
          expiryMonth
          expiryYear
          peachPaymentCardId
          company {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
        }
      }
    }
  }
}
`;
export const deleteCompanyCard = `mutation DeleteCompanyCard($input: DeleteCompanyCardInput!) {
  deleteCompanyCard(input: $input) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    card {
      id
      type
      number
      nameOnCard
      expiryMonth
      expiryYear
      peachPaymentCardId
      company {
        id
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
        card {
          id
          type
          number
          nameOnCard
          expiryMonth
          expiryYear
          peachPaymentCardId
          company {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
        }
      }
    }
  }
}
`;
export const createCompanyInterest = `mutation CreateCompanyInterest($input: CreateCompanyInterestInput!) {
  createCompanyInterest(input: $input) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    interests {
      id
      name
      createdAt
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const updateCompanyInterest = `mutation UpdateCompanyInterest($input: UpdateCompanyInterestInput!) {
  updateCompanyInterest(input: $input) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    interests {
      id
      name
      createdAt
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const deleteCompanyInterest = `mutation DeleteCompanyInterest($input: DeleteCompanyInterestInput!) {
  deleteCompanyInterest(input: $input) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    interests {
      id
      name
      createdAt
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const createUserCompany = `mutation CreateUserCompany($input: CreateUserCompanyInput!) {
  createUserCompany(input: $input) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    user {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const updateUserCompany = `mutation UpdateUserCompany($input: UpdateUserCompanyInput!) {
  updateUserCompany(input: $input) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    user {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const deleteUserCompany = `mutation DeleteUserCompany($input: DeleteUserCompanyInput!) {
  deleteUserCompany(input: $input) {
    id
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
    user {
      id
      dateOfBirth
      createdAt
      name
      surname
      emailAddress
      contactNumber
      idNumber
      physicalAddress
      archived
      verified
      role
      status
      jobTitle
      company {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      companyTeamLead {
        id
        createdAt
        name
        emailAddress
        contactNumber
        address
        industry
        physicalAddress
        numberOfEmployees
        archived
        interests {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            interests {
              id
              name
              createdAt
            }
          }
          nextToken
        }
        area {
          id
          name
          createdAt
          city {
            id
            name
            province {
              id
              name
              createdAt
            }
            createdAt
            areas {
              nextToken
            }
          }
          companies {
            items {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            nextToken
          }
        }
        teamLead {
          id
          dateOfBirth
          createdAt
          name
          surname
          emailAddress
          contactNumber
          idNumber
          physicalAddress
          archived
          verified
          role
          status
          jobTitle
          company {
            items {
              id
            }
            nextToken
          }
          companyTeamLead {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        teamMembers {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          nextToken
        }
        transactions {
          id
          createdAt
          cost
          voucher {
            id
            transaction {
              id
              createdAt
              cost
            }
            voucher {
              id
              code
              discountPercentage
              numberOfUses
              expiryDate
              archived
              createdAt
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        cards {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            card {
              id
              type
              number
              nameOnCard
              expiryMonth
              expiryYear
              peachPaymentCardId
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const createCompanyReport = `mutation CreateCompanyReport($input: CreateCompanyReportInput!) {
  createCompanyReport(input: $input) {
    id
    createdAt
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const updateCompanyReport = `mutation UpdateCompanyReport($input: UpdateCompanyReportInput!) {
  updateCompanyReport(input: $input) {
    id
    createdAt
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const deleteCompanyReport = `mutation DeleteCompanyReport($input: DeleteCompanyReportInput!) {
  deleteCompanyReport(input: $input) {
    id
    createdAt
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
    company {
      id
      createdAt
      name
      emailAddress
      contactNumber
      address
      industry
      physicalAddress
      numberOfEmployees
      archived
      interests {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          interests {
            id
            name
            createdAt
            company {
              nextToken
            }
          }
        }
        nextToken
      }
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      teamLead {
        id
        dateOfBirth
        createdAt
        name
        surname
        emailAddress
        contactNumber
        idNumber
        physicalAddress
        archived
        verified
        role
        status
        jobTitle
        company {
          items {
            id
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
            user {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
          }
          nextToken
        }
        companyTeamLead {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      teamMembers {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          user {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        nextToken
      }
      reports {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
      cards {
        items {
          id
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          card {
            id
            type
            number
            nameOnCard
            expiryMonth
            expiryYear
            peachPaymentCardId
            company {
              id
            }
          }
        }
        nextToken
      }
    }
  }
}
`;
export const createReportTag = `mutation CreateReportTag($input: CreateReportTagInput!) {
  createReportTag(input: $input) {
    id
    tag {
      id
      name
      createdAt
      reports {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
export const updateReportTag = `mutation UpdateReportTag($input: UpdateReportTagInput!) {
  updateReportTag(input: $input) {
    id
    tag {
      id
      name
      createdAt
      reports {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
export const deleteReportTag = `mutation DeleteReportTag($input: DeleteReportTagInput!) {
  deleteReportTag(input: $input) {
    id
    tag {
      id
      name
      createdAt
      reports {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
export const createReportCategory = `mutation CreateReportCategory($input: CreateReportCategoryInput!) {
  createReportCategory(input: $input) {
    id
    category {
      id
      name
      createdAt
      reports {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
export const updateReportCategory = `mutation UpdateReportCategory($input: UpdateReportCategoryInput!) {
  updateReportCategory(input: $input) {
    id
    category {
      id
      name
      createdAt
      reports {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
export const deleteReportCategory = `mutation DeleteReportCategory($input: DeleteReportCategoryInput!) {
  deleteReportCategory(input: $input) {
    id
    category {
      id
      name
      createdAt
      reports {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
export const createReportRegion = `mutation CreateReportRegion($input: CreateReportRegionInput!) {
  createReportRegion(input: $input) {
    id
    region {
      id
      name
      reports {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
export const updateReportRegion = `mutation UpdateReportRegion($input: UpdateReportRegionInput!) {
  updateReportRegion(input: $input) {
    id
    region {
      id
      name
      reports {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
export const deleteReportRegion = `mutation DeleteReportRegion($input: DeleteReportRegionInput!) {
  deleteReportRegion(input: $input) {
    id
    region {
      id
      name
      reports {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
    }
    report {
      id
      createdAt
      name
      description
      publishedDate
      price
      documentLocation
      maxDownloads
      downloadable
      status
      paidOrFree
      reportDetails
      coverImageUrl
      documentResourceUrl
      area {
        id
        name
        createdAt
        city {
          id
          name
          province {
            id
            name
            createdAt
            cities {
              nextToken
            }
          }
          createdAt
          areas {
            items {
              id
              name
              createdAt
            }
            nextToken
          }
        }
        companies {
          items {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
          nextToken
        }
        reports {
          items {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          nextToken
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      categories {
        items {
          id
          category {
            id
            name
            createdAt
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      region {
        items {
          id
          region {
            id
            name
            reports {
              nextToken
            }
          }
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
        }
        nextToken
      }
      companys {
        items {
          id
          createdAt
          report {
            id
            createdAt
            name
            description
            publishedDate
            price
            documentLocation
            maxDownloads
            downloadable
            status
            paidOrFree
            reportDetails
            coverImageUrl
            documentResourceUrl
            area {
              id
              name
              createdAt
            }
            tags {
              nextToken
            }
            categories {
              nextToken
            }
            region {
              nextToken
            }
            companys {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
          }
          company {
            id
            createdAt
            name
            emailAddress
            contactNumber
            address
            industry
            physicalAddress
            numberOfEmployees
            archived
            interests {
              nextToken
            }
            area {
              id
              name
              createdAt
            }
            teamLead {
              id
              dateOfBirth
              createdAt
              name
              surname
              emailAddress
              contactNumber
              idNumber
              physicalAddress
              archived
              verified
              role
              status
              jobTitle
            }
            teamMembers {
              nextToken
            }
            reports {
              nextToken
            }
            transactions {
              id
              createdAt
              cost
            }
            cards {
              nextToken
            }
          }
        }
        nextToken
      }
      transactions {
        id
        createdAt
        cost
        voucher {
          id
          transaction {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          voucher {
            id
            code
            discountPercentage
            numberOfUses
            expiryDate
            archived
            createdAt
            transactions {
              nextToken
            }
          }
        }
        report {
          id
          createdAt
          name
          description
          publishedDate
          price
          documentLocation
          maxDownloads
          downloadable
          status
          paidOrFree
          reportDetails
          coverImageUrl
          documentResourceUrl
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          tags {
            items {
              id
            }
            nextToken
          }
          categories {
            items {
              id
            }
            nextToken
          }
          region {
            items {
              id
            }
            nextToken
          }
          companys {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
        }
        company {
          id
          createdAt
          name
          emailAddress
          contactNumber
          address
          industry
          physicalAddress
          numberOfEmployees
          archived
          interests {
            items {
              id
            }
            nextToken
          }
          area {
            id
            name
            createdAt
            city {
              id
              name
              createdAt
            }
            companies {
              nextToken
            }
            reports {
              nextToken
            }
          }
          teamLead {
            id
            dateOfBirth
            createdAt
            name
            surname
            emailAddress
            contactNumber
            idNumber
            physicalAddress
            archived
            verified
            role
            status
            jobTitle
            company {
              nextToken
            }
            companyTeamLead {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          teamMembers {
            items {
              id
            }
            nextToken
          }
          reports {
            items {
              id
              createdAt
            }
            nextToken
          }
          transactions {
            id
            createdAt
            cost
            voucher {
              id
            }
            report {
              id
              createdAt
              name
              description
              publishedDate
              price
              documentLocation
              maxDownloads
              downloadable
              status
              paidOrFree
              reportDetails
              coverImageUrl
              documentResourceUrl
            }
            company {
              id
              createdAt
              name
              emailAddress
              contactNumber
              address
              industry
              physicalAddress
              numberOfEmployees
              archived
            }
          }
          cards {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
}
`;
