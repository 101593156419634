import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withToastManager } from "react-toast-notifications";

/** GraphQL */
import {
  listCategorysWithReports,
  listTagsWithReports,
  listInterestsWithCompanys
} from "../../graphql/custom-queries";
/** Presentation/UI */
import Table from "../../Components/Table";
import StyledButton from "../../Components/Styled/Button";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import PageLoader from "../../Components/PageLoader";
import {
  TableHeader,
  TableHeaderContainer
} from "../../Components/Styled/ListViewElements";
import GlobalModalContainer from "../../Components/Modal";
import { Input } from "reactstrap";

/** Themes */
import { Colors } from "../../Themes";

/** Custom Types */
import { ToastNotificationType } from "../../CustomTypes";
import { formatTitle, sortByCreatedAt } from "../../Utils/Helpers";
import AddEdit from "./Add";
import { TableConsts } from "../../Utils/Consts";

type Props = {
  toastManager: ToastNotificationType;
  location: { [key: string]: any }; // object with any amount and types of params
};

type State = {
  importModal: boolean;
  selected: any;
  containerType: string;
  modal: boolean;
  searchData: string;
};

class Management extends React.Component<Props> {
  state: State = {
    importModal: false,
    selected: null,
    containerType: this.props.location.pathname,
    modal: false,
    searchData: ""
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance ? appearance : "success",
      autoDismiss: true
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };
  /** Open modal */
  openModal = (data?: any): void => {
    this.setState({
      modal: true,
      selected: data
    });
  };

  categoryManagement = () => {
    const { searchData } = this.state;
    return (
      <Query
        query={gql(listCategorysWithReports)}
        variables={{ limit: TableConsts.limit }}
      >
        {({ loading, error, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (error) {
            return <div>There was a problem loading your data</div>;
          }

          if (!data || !data.listCategorys) {
            return <div>There are no categories at the moment</div>;
          }
          let filteredData = data.listCategorys.items;
          filteredData = filteredData.filter((row: any) => {
            return row.name.toLowerCase().includes(searchData.toLowerCase());
          });

          return (
            <React.Fragment>
              <Table
                data={sortByCreatedAt(filteredData)}
                getTdProps={(
                  state: any,
                  rowInfo: { [key: string]: string }
                ) => {
                  return {
                    onClick: (e: any) => {
                      if (rowInfo) {
                        this.openModal(rowInfo.original);
                      }
                    }
                  };
                }}
                columns={[
                  {
                    Header: "Name",
                    accessor: "name",
                    sortable: true
                  },
                  {
                    id: "numberOfReports",
                    Header: "Number of reports",
                    accessor: (category: any) => {
                      return category.reports.items.length;
                    },
                    sortable: true
                  }
                ]}
                defaultPageSize={TableConsts.defaultRows}
                showPaginationBottom={
                  data.listCategorys.items.length > TableConsts.defaultRows
                }
              />
            </React.Fragment>
          );
        }}
      </Query>
    );
  };

  tagManagement = () => {
    const { searchData } = this.state;
    return (
      <Query
        query={gql(listTagsWithReports)}
        variables={{ limit: TableConsts.limit }}
      >
        {({ loading, error, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (error) {
            return <div>There was a problem loading your data</div>;
          }

          if (!data || !data.listTags) {
            return <div>There are no Tags at the moment</div>;
          }

          let filteredData = data.listTags.items;
          filteredData = filteredData.filter((row: any) => {
            return row.name.toLowerCase().includes(searchData.toLowerCase());
          });

          return (
            <Table
              data={sortByCreatedAt(filteredData)}
              getTdProps={(state: any, rowInfo: { [key: string]: string }) => {
                return {
                  onClick: (e: any) => {
                    if (rowInfo) {
                      this.openModal(rowInfo.original);
                    }
                  }
                };
              }}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                  sortable: true
                },
                {
                  id: "numberOfReports",
                  Header: "Number of reports",
                  accessor: (tag: any) => {
                    return tag.reports.items.length;
                  },
                  sortable: true
                }
              ]}
              defaultPageSize={TableConsts.defaultRows}
              showPaginationBottom={
                data.listTags.items.length > TableConsts.defaultRows
              }
            />
          );
        }}
      </Query>
    );
  };

  interestManagement = () => {
    const { searchData } = this.state;
    return (
      <Query
        query={gql(listInterestsWithCompanys)}
        variables={{ limit: TableConsts.limit }}
      >
        {({ loading, error, data }: any) => {
          if (loading) {
            return <PageLoader />;
          }
          if (error) {
            return <div>There was a problem loading your data</div>;
          }

          if (!data || !data.listInterests) {
            return <div>There are no Interests at the moment</div>;
          }

          let filteredData = data.listInterests.items;
          filteredData = filteredData.filter((row: any) => {
            return row.name.toLowerCase().includes(searchData.toLowerCase());
          });

          return (
            <Table
              data={sortByCreatedAt(filteredData)}
              getTdProps={(state: any, rowInfo: { [key: string]: string }) => {
                return {
                  onClick: (e: any) => {
                    if (rowInfo) {
                      this.openModal(rowInfo.original);
                    }
                  }
                };
              }}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                  sortable: true
                },
                {
                  id: "numberOfReports",
                  Header: "Number of companies",
                  accessor: (interest: any) => {
                    return interest.company.items.length;
                  },
                  sortable: true
                }
              ]}
              defaultPageSize={TableConsts.defaultRows}
              showPaginationBottom={
                data.listInterests.items.length > TableConsts.defaultRows
              }
            />
          );
        }}
      </Query>
    );
  };

  render() {
    const { containerType, selected, modal, searchData } = this.state;
    return (
      <BackendWrapper>
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title={`Add ${
            containerType === "/interest-management"
              ? "interest"
              : containerType === "/tags-management"
              ? "tag"
              : "category"
          }`}
          modalDisplay={
            <AddEdit
              notification={this.toastNotification}
              closeModal={this.closeModal}
              data={selected}
              containerType={
                containerType === "/interest-management"
                  ? "interest"
                  : containerType === "/tags-management"
                  ? "tag"
                  : "category"
              }
            />
          }
          modal={modal}
        />
        <TableHeaderContainer>
          <TableHeader>
            <span>{formatTitle(containerType)}</span>
          </TableHeader>
          <StyledButton
            type="button"
            label={`Add ${
              containerType === "/interest-management"
                ? "interest"
                : containerType === "/tags-management"
                ? "tag"
                : "category"
            }`}
            width="250px"
            margin="0px 10px"
            onClick={() => this.openModal()}
            color={Colors.coal}
            background={Colors.background}
          />
          <Input
            type="text"
            name="name"
            style={{ height: "40px", width: "300px" }}
            value={searchData}
            id="name"
            placeholder="Search"
            onChange={e => this.setState({ searchData: e.target.value })}
          />
        </TableHeaderContainer>
        {containerType === "/interest-management"
          ? this.interestManagement()
          : containerType === "/tags-management"
          ? this.tagManagement()
          : this.categoryManagement()}
        <Helmet title={formatTitle(containerType) || undefined} />
      </BackendWrapper>
    );
  }
}

export default withToastManager(Management);
