import * as React from "react";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import AppSyncConfig from "./aws-exports";
import { ApolloProvider } from "react-apollo";
import Amplify, { Auth } from "aws-amplify";
import { Rehydrated } from "aws-appsync-react";
import { ToastProvider } from "react-toast-notifications";
import "whatwg-fetch";

// Context Provider
import ContextProvider from "./Components/ContextProvider";

// Root Container
import RootContainer from "./Containers/RootContainer";

// Amplify/AppSync config
Amplify.configure(AppSyncConfig);

const client: any = new AWSAppSyncClient(
  {
    url: AppSyncConfig.aws_appsync_graphqlEndpoint,
    region: AppSyncConfig.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken()
    }
  },
  {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all"
      },
      query: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all"
      },
      mutate: {
        errorPolicy: "all"
      }
    }
  }
);

const App: React.SFC<{}> = () => {
  return (
    <ContextProvider>
      <ApolloProvider client={client}>
        <Rehydrated>
          <ToastProvider placement="bottom-center">
            <RootContainer />
          </ToastProvider>
        </Rehydrated>
      </ApolloProvider>
    </ContextProvider>
  );
};

export default App;
