import * as React from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { MdMoreVert } from "react-icons/md";

import { UpdateUserInput } from "../../API";

/**  Presentation UI */
import GlobalModalContainer from "../../Components/Modal";

/**  Context API */
import { AppContextConsumer } from "../ContextProvider";

/** Local components */
import EditProfile from "./EditProfile";

/** Styled elements */
import {
  AvatarContainer,
  AvatarContainerItem,
  Wrapper
} from "./StyledElements";

type Props = RouteComponentProps & {
  user: UpdateUserInput;
};

type State = {
  dropdownOpen: boolean;
  modal: boolean;
  avatarUrl?: object | string;
};

class HeaderWrapper extends React.Component<Props, State> {
  state: State = {
    dropdownOpen: false,
    modal: false
  };

  // Handle sign out
  handleLogout = (): void => {
    window.localStorage.clear();
    const { history } = this.props;
    history.push("/sign-in");
  };

  handleFAQ = (): void => {
    this.props.history.push("/help");
  };

  toggle = (): void => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  // Close modal
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  // Open modal
  openModal = (): void => {
    this.setState({
      modal: true,
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  /** Check if the link/path is the active one */
  checkIfActive = (path: string): boolean => {
    const { location } = this.props;
    return (
      path === location.pathname ||
      this.checkParentPath(path, location.pathname)
    );
  };

  /** Check the "parent" path of a particular sub-route (i.e. parent/sub-route)  */
  checkParentPath = (path: string, locationPathname: string): boolean => {
    const pathLinkArray = path.split("/").filter(pathPart => pathPart !== "");
    const locationPathnameLinkArray = locationPathname
      .split("/")
      .filter(pathPart => pathPart !== "");

    return pathLinkArray[0] === locationPathnameLinkArray[0];
  };

  /** Return username */
  returnUsername = (
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null
  ): string => {
    if (firstName || lastName) {
      return `${firstName} ${lastName}`;
    }

    return email || "";
  };

  render() {
    const { dropdownOpen, modal } = this.state;
    const { user } = this.props;

    return (
      <AppContextConsumer>
        {({ drawerOpen }) => {
          const sideBarOpen = drawerOpen;
          return (
            <Wrapper open={sideBarOpen}>
              <GlobalModalContainer
                toggleModal={this.closeModal}
                title="Personal Profile"
                modalDisplay={
                  <EditProfile userId={user.id} closeModal={this.closeModal} />
                }
                modal={modal}
              />
              <AvatarContainer>
                <AvatarContainerItem>
                  {this.returnUsername(
                    user.emailAddress,
                    user.name,
                    user.surname
                  )}
                </AvatarContainerItem>
                <AvatarContainerItem>
                  <Dropdown
                    className="avatar-dropdown"
                    isOpen={dropdownOpen}
                    toggle={this.toggle}
                  >
                    <DropdownToggle
                      tag="span"
                      onClick={this.toggle}
                      data-toggle="dropdown"
                      aria-expanded={dropdownOpen}
                    >
                      <MdMoreVert size="1.3em" style={{ cursor: "pointer" }} />
                    </DropdownToggle>
                    <DropdownMenu>
                      <div
                        onClick={() => this.openModal()}
                        className="avatar-dropdown-menu-item"
                      >
                        Personal Profile
                      </div>
                      <div
                        className="avatar-dropdown-menu-item"
                        onClick={this.handleFAQ}
                      >
                        Help
                      </div>
                      <div
                        className="avatar-dropdown-menu-item"
                        onClick={this.handleLogout}
                      >
                        Log Out
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </AvatarContainerItem>
              </AvatarContainer>
            </Wrapper>
          );
        }}
      </AppContextConsumer>
    );
  }
}

export default withRouter(HeaderWrapper);
