import * as React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Row, Col, Button, Form, FormGroup, Input } from "reactstrap";
// GraphQL
import { updateUser } from "../../graphql/mutations";
import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserRole
} from "../../API";

// Presentation/UI
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import { apiRequest } from "../../Utils/API";
import { COGNITO_EDIT_USER } from "../../Utils/LambdaEndpoints";
import { HTTP_METHODS } from "../../Utils/Consts";
import AppSyncConfig from "../../aws-exports";
import RequiredField from "../../Components/Styled/RequiredField";

type State = {
  firstName: string;
  surname: string;
  idNumber: string;
  jobTitle: string;
  contactNumber: string;
  loading: boolean;
  error: string | null;
};

type Props = {
  userId: string;
  updateView(view: string): void;
};

class UserDetailsForm extends React.Component<Props, State> {
  state: State = {
    firstName: "",
    surname: "",
    idNumber: "",
    jobTitle: "",
    contactNumber: "",
    loading: false,
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  // Handle change in input fields
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    const validateNumInput = /^[0-9\b]+$/;

    switch (name) {
      case "firstName":
        this.setState({ firstName: value });
        break;
      case "surname":
        this.setState({ surname: value });
        break;
      case "idNumber":
        if (value === "" || validateNumInput.test(value)) {
          this.setState({ idNumber: value });
        }
        break;
      case "jobTitle":
        this.setState({ jobTitle: value });
        break;
      case "contactNumber":
        if (value === "" || validateNumInput.test(value)) {
          this.setState({ contactNumber: value });
        }
        break;
      default:
        break;
    }
  };

  // Validation
  validateForm = (): boolean => {
    const {
      firstName,
      surname,
      idNumber,
      jobTitle,
      contactNumber
    } = this.state;
    // Check for undefined or empty input fields
    if (!firstName || !surname || !idNumber || !jobTitle || !contactNumber) {
      this.setError("Please complete the form.");
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const {
      firstName,
      surname,
      idNumber,
      jobTitle,
      contactNumber
    } = this.state;

    const { updateView, userId } = this.props;

    return (
      <Mutation<UpdateUserMutation, UpdateUserMutationVariables>
        mutation={gql(updateUser)}
      >
        {updateUserMutation => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateForm()) {
                updateUserMutation({
                  variables: {
                    input: {
                      id: userId,
                      name: firstName,
                      surname,
                      idNumber,
                      jobTitle,
                      contactNumber,
                      archived: false,
                      role: UserRole.CONSUMER_ADMIN
                    }
                  }
                })
                  .then((updateUserRes: any) => {
                    const bodyParams = {
                      userPoolId: AppSyncConfig.aws_user_pools_id,
                      email: updateUserRes.data.updateUser.emailAddress,
                      groupName: updateUserRes.data.updateUser.role
                    };
                    apiRequest(
                      COGNITO_EDIT_USER,
                      HTTP_METHODS.POST,
                      bodyParams
                    ).catch(error => {
                      this.setError(error.message);
                      return;
                    });
                    // change view to enter company details
                    updateView("companySetup");
                  })
                  .catch((updateUserErr: any) => {
                    this.setError(updateUserErr.message);
                  });
              }
            }}
          >
            <Row>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <RequiredField for="firstName" value="Name"/>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={firstName}
                    placeholder="First Name"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <RequiredField for="surname" value="Surname"/>
                  <Input
                    type="text"
                    name="surname"
                    id="surname"
                    value={surname}
                    placeholder="Last name"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <RequiredField for="idNumber" value="ID Number"/>
                  <Input
                    maxLength={13}
                    minLength={13}
                    type="text"
                    name="idNumber"
                    id="idNumber"
                    value={idNumber}
                    placeholder="ID Number"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <RequiredField for="jobTitle" value="Job Title"/>
                  <Input
                    type="text"
                    name="jobTitle"
                    id="jobTitle"
                    value={jobTitle}
                    placeholder="Job Title"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <RequiredField for="contactNumber" value="Contact Number"/>
                  <Input
                    maxLength={10}
                    minLength={10}
                    type="text"
                    name="contactNumber"
                    id="contactNumber"
                    value={contactNumber}
                    placeholder="Contact Number"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <br />
            <Button>
              <span>Next</span>
            </Button>
            {this.state.error && (
              <ErrorMessage errorMessage={this.state.error} />
            )}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default UserDetailsForm;
