import styled from "styled-components";

import { Colors } from "../../Themes";

type IStyledElements = {
  open: boolean;
};

export const Wrapper = styled.div`
  top: 0;
  right: 0;
  z-index: 1;
  height: 70px;
  display: flex;
  position: fixed;
  padding: 0 40px;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.snow};
  box-shadow: 5px 1px 5px 1px rgba(0, 0, 0, 0.05);
  left: ${(props: IStyledElements) => (props.open ? "250px" : "60px")};
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  & a {
    padding: 25px 30px 20px 30px;
    border-bottom: 4px solid ${Colors.snow};
    color: ${Colors.grey};
    font-weight: 100;
    font-size: 1.2em;
  }
  & svg {
    position: relative;
    top: -2px;
  }
`;

export const AvatarContainer = styled.div`
  position: absolute;
  right: 20px;
`;

export const AvatarContainerItem = styled.div`
  height: 70px;
  line-height: 70px;
  display: inline-block;
  margin-left: 15px;
  font-size: 1.15em;

  & .dropdown-menu {
    font-size: 12px !important;
    color: #7b8389 !important;
    border: none !important;
    border-radius: 3px;
    box-shadow: 0px 3px 14px -2px rgba(176, 176, 176, 1);
  }

  & .avatar-dropdown-menu-item {
    padding: 5px;
    width: calc(100% - 20px);
    margin: 0 auto;
    cursor: pointer;
    height: auto !important;
    line-height: normal !important;
  }

  & .dropdown-menu-item-border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.035) !important;
  }

  & .avatar-dropdown .dropdown-menu.show {
    top: 26px !important;
  }
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  top: 15px;
  border-radius: 50%;
  background: ${Colors.snow};
  box-shadow: rgba(190, 229, 226, 0.38) 0px 0px 0px 5px;
  border: 2px solid ${Colors.snow};
  background-size: 50%;
  background-position: center center !important;
  background-repeat: no-repeat !important;
`;
