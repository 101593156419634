import * as React from "react";

// Presentation/UI
import FullWidthContainer from "../../Components/Layouts/FullWidthContainer";
import LottieWrapper from "../../Components/Anim/LottieWrapper";
import Link from "../../Components/Styled/Link";
import StyledButton from "../../Components/Styled/Button";

// Themes
import { Colors } from "../../Themes";

class Success extends React.Component<{}> {
  render() {
    return (
      <FullWidthContainer>
        <LottieWrapper
          loop={false}
          width={320}
          height={260}
          anim={require("../../LottieFiles/success2.json")}
        />
        <br />
        <br />
        <p className="center-align">
          Initial setup complete!
          <br />
          <br />
          <Link
            to="/dashboard"
            label={
              <StyledButton
                type="button"
                label="Take me in"
                width="120px"
                color={Colors.snow}
                background={Colors.default}
              />
            }
            color={Colors.darkGrey}
          />
        </p>
      </FullWidthContainer>
    );
  }
}

export default Success;
