import * as React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { deleteCard, deleteCompanyCard } from "../../graphql/mutations";
import {
  DeleteCardMutation,
  DeleteCardMutationVariables,
  DeleteCompanyCardMutation,
  DeleteCompanyCardMutationVariables
} from "../../API";

import { deletePeachCard } from "../../PeachPayments/DeleteCard";

import { Label, Form } from "reactstrap";
import StyledButton from "../../Components/Styled/Button";

/** Custom Types */
import { Error } from "../../CustomTypes";
import { Colors } from "../../Themes";
import Loader from "../../Components/Loader";

type Props = {
  cardData: any;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  loading: boolean;
  error: Error;
  modal: boolean;
  cardData: any;
};

class DeleteCardModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { cardData } = props;

    this.state = {
      loading: false,
      error: null,
      modal: false,
      cardData
    };
  }

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  deleteCardFromPeach = (
    deleteCardMutation: any,
    deleteCompanyCardMutation: any
  ) => {
    this.setState({ loading: true });
    const { cardData } = this.state;
    return deletePeachCard(cardData.card.peachPaymentCardId)
      .then(() => {
        // After successully deleting the card from peach
        return this.deleteCardFromDB(
          deleteCardMutation,
          deleteCompanyCardMutation
        );
      })
      .catch(() => {
        this.setState({ loading: false });
        this.setError("There has been an error, please try again");
        return false;
      });
  };

  deleteCardFromDB = async (
    // after react scripts changes
    // eslint-disable-next-line
    deleteCardMutation: ({ }) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    deleteCompanyCardMutation: ({ }) => Promise<any>
  ) => {
    const { closeModal, notification } = this.props;
    const { cardData } = this.state;
    return deleteCompanyCardMutation({
      variables: {
        input: {
          id: cardData.id
        }
      }
    })
      .then(() => {
        deleteCardMutation({
          variables: {
            input: {
              id: cardData.card.id
            }
          }
        })
          .then(() => {
            this.setState({ loading: false });
            closeModal();
            notification("Card has been successfully deleted");
          })
          .catch(deleteLinkError => {
            this.setState({ loading: false });
            this.setError(deleteLinkError.message);
          });
      })
      .catch(deleteCardError => {
        this.setState({ loading: false });
        this.setError(deleteCardError.message);
      });
  };

  render() {
    return (
      <Mutation<DeleteCompanyCardMutation, DeleteCompanyCardMutationVariables>
        mutation={gql(deleteCompanyCard)}
      >
        {deleteCompanyCardMutation => (
          <Mutation<DeleteCardMutation, DeleteCardMutationVariables>
            mutation={gql(deleteCard)}
          >
            {deleteCardMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  this.deleteCardFromPeach(
                    deleteCardMutation,
                    deleteCompanyCardMutation
                  );
                }}
              >
                <Label> Are you sure you want to delete this card ?</Label>
                <br />
                <StyledButton
                  type="submit"
                  label={this.state.loading ? <Loader /> : "Delete Card"}
                  width="100px"
                  color={Colors.snow}
                  background={Colors.error}
                />
              </Form>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export default DeleteCardModal;
