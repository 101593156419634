import * as React from "react";
import { NavLink } from "react-router-dom";
import { Collapse, NavItem, Tooltip } from "reactstrap";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

// Context API
import { AppContextConsumer } from "../ContextProvider";

// Utils
import { getArrayFromObject } from "../../Utils/Helpers";

// Themes
import { Colors } from "../../Themes";

type Props = RouteComponentProps & {
  navId: string;
  to: string;
  title: string;
  icon: React.ReactNode;
  subMenu?: Array<{ to: string; title: string }>;
};

class SideBarNavItem extends React.Component<
  Props,
  { tooltipOpen: boolean; expanded: boolean }
> {
  state = {
    expanded: false,
    tooltipOpen: false
  };

  componentWillMount() {
    const {
      subMenu,
      location: { pathname }
    } = this.props;

    if (getArrayFromObject(subMenu).some(path => path.to === pathname)) {
      // we are in the this route so we open submenu
      this.setState({ expanded: true });
    }
  }

  checkIfActive = (path: string): boolean => {
    const {
      subMenu,
      location: { pathname }
    } = this.props;

    /**
     * Check if this nav item has a sub menu, and if it does, check
     * if the link is active
     */
    if (subMenu) {
      return getArrayFromObject(subMenu).some(
        subMenuPath => subMenuPath.to === pathname
      );
    }

    // eslint-disable-next-line
    return path === location.pathname;
  };

  // Toggle tooltip
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  // Toggle sub menu
  toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  // Return sub menu
  renderSubMenu = (
    subMenu: Array<{ to: string; title: string }>
  ): React.ReactNode => {
    return subMenu.map((navItem: any) => {
      return (
        <NavItem key={navItem.to} style={{ marginLeft: "35px" }}>
          <NavLink to={navItem.to}>
            <span>- {navItem.title}</span>
          </NavLink>
        </NavItem>
      );
    });
  };

  render() {
    const { navId, to, title, icon, subMenu } = this.props;
    const { expanded } = this.state;

    return (
      <AppContextConsumer>
        {({ drawerOpen }) => {
          const sideBarOpen = drawerOpen;

          return (
            <React.Fragment>
              <NavItem id={"Tooltip-" + navId} onClick={this.toggleExpanded}>
                <NavLink
                  to={to}
                  style={
                    this.checkIfActive(to) ? { color: Colors.primary } : {}
                  }
                >
                  {icon}
                  &nbsp;&nbsp;&nbsp;&nbsp;<span>{title}</span>
                </NavLink>
                {!sideBarOpen && (
                  <Tooltip
                    placement="right"
                    className="nav-tooltip"
                    isOpen={this.state.tooltipOpen}
                    target={"Tooltip-" + navId}
                    toggle={this.toggle}
                  >
                    {title}
                  </Tooltip>
                )}
              </NavItem>
              <Collapse isOpen={expanded} style={{ width: "100%" }}>
                {subMenu && this.renderSubMenu(subMenu)}
              </Collapse>
            </React.Fragment>
          );
        }}
      </AppContextConsumer>
    );
  }
}

export default withRouter(SideBarNavItem);
