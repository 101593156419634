import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withToastManager } from "react-toast-notifications";

/** GraphQL */
import { listUsers } from "../../graphql/queries";
import { getCompaniesUsers, getUsersCompany } from "../../graphql/custom-queries";
import { UpdateUserInput, UserRole, UpdateCompanyInput } from "../../API";
/** Presentation/UI */
import Table from "../../Components/Table";
import StyledButton from "../../Components/Styled/Button";
import BackendWrapper from "../../Components/Layouts/BackendWrapper";
import PageLoader from "../../Components/PageLoader";
import {
  TableHeader,
  TableHeaderContainer
} from "../../Components/Styled/ListViewElements";
import { MdSearch } from "react-icons/md";
import { ToastNotificationType } from "../../CustomTypes";
import GlobalModalContainer from "../../Components/Modal";
import AddAdminUser from "./AddAdminUser";

/** Themes */
import { Colors } from "../../Themes";

/** Utils */
import {
  formatString,
  getUserId,
  getUserRole,
  cleanArray,
  sortByCreatedAt
} from "../../Utils/Helpers";
import { TableConsts } from "../../Utils/Consts";

/** Custom Types */
type Props = {
  toastManager: ToastNotificationType;
  companyData?: UpdateCompanyInput;
  location: { [key: string]: any }; // object with any amount and types of params
};

type State = {
  modal: boolean;
  importModal: boolean;
  searchFilter: boolean;
  user: UpdateUserInput;
  archivedFilter: boolean;
  selected: any;
  userData: UpdateUserInput | null;
  companyData: UpdateCompanyInput;
  containerType: string;
  modalTitle: string;
};

class Team extends React.Component<Props> {
  state: State = {
    modal: false,
    importModal: false,
    searchFilter: false,
    user: { id: "", emailAddress: "" },
    archivedFilter: false,
    selected: null,
    userData: null,
    companyData: this.props.location.params
      ? this.props.location.params.companyData
      : null,
    containerType: this.props.location.pathname,
    modalTitle: "Add User"
  };

  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance ? appearance : "success",
      autoDismiss: true
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  /** Open modal */
  openModal = (modalTitle: string, userData?: {} | null): void => {
    this.setState({
      modal: true,
      modalTitle,
      userData
    });
  };

  toggleArchivedFilter = (): void => {
    this.setState({ archivedFilter: !this.state.archivedFilter });
  };

  getQuery = () => {
    const { archivedFilter } = this.state;
    switch (this.state.containerType) {
      case "/team":
        return {
          filter: {
            or: [
              { role: { eq: UserRole.CONSUMER_ADMIN } },
              { role: { eq: UserRole.SUPER_USER } },
              { role: { eq: UserRole.USER } }
            ],
            archived: { eq: archivedFilter }
          }
        };
      case "/admin/team":
        return {
          filter: {
            or: [
              { role: { eq: UserRole.ROOTS_SUPER_ADMIN } },
              { role: { eq: UserRole.ROOTS_ADMIN } }
            ],
            archived: { eq: archivedFilter }
          }
        };
      default:
        // no users returned
        return {
          filter: {
            or: [
              { role: { ne: UserRole.ROOTS_SUPER_ADMIN } },
              { role: { ne: UserRole.ROOTS_ADMIN } },
              { role: { ne: UserRole.CONSUMER_ADMIN } },
              { role: { ne: UserRole.SUPER_USER } },
              { role: { ne: UserRole.USER } }
            ],
            archived: { eq: archivedFilter }
          }
        };
    }
  };

  returnFields = (
    data: { [key: string]: string }[],
    title: string,
    companyId?: string
  ) => {
    const {
      searchFilter,
      modal,
      archivedFilter,
      userData,
      companyData,
      modalTitle,
      containerType
    } = this.state;
    return (
      <React.Fragment>
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title={modalTitle}
          modalDisplay={
            <AddAdminUser
              notification={this.toastNotification}
              closeModal={this.closeModal}
              userData={userData}
              containerType={containerType}
              companyId={containerType === "/consumer/team" ? companyId : null}
              archivedFilter={archivedFilter}
            />
          }
          modal={modal}
        />
        <TableHeaderContainer>
          <TableHeader>
            <span>{title}</span>
          </TableHeader>
          {!companyData &&
            (containerType !== "/admin/team" &&
              getUserRole() === UserRole.CONSUMER_ADMIN) ? (
              <StyledButton
                type="button"
                label="Add Member"
                width="120px"
                onClick={() => this.openModal(`Add User`)}
                color={Colors.coal}
                background={Colors.background}
              />
            ) : null}

          {containerType === "/admin/team" ? (
            <StyledButton
              type="button"
              label="Add Admin User"
              width="120px"
              onClick={() => this.openModal(`Add Admin User`)}
              color={Colors.coal}
              background={Colors.background}
            />
          ) : null}
          <StyledButton
            type="button"
            label={<MdSearch size="1.3em" color={Colors.secondary} />}
            width="auto"
            margin="0px 10px"
            onClick={this.toggleSearchFilter}
            color={searchFilter ? Colors.background : Colors.coal}
            background={searchFilter ? Colors.default : Colors.background}
          />
          <StyledButton
            type="button"
            label={archivedFilter ? "Show Active" : "Show Archived"}
            width="120px"
            onClick={this.toggleArchivedFilter}
            color={archivedFilter ? Colors.background : Colors.coal}
            background={archivedFilter ? Colors.default : Colors.background}
          />
        </TableHeaderContainer>
        <Table
          getTdProps={(state: any, rowInfo: { [key: string]: string }) => {
            return {
              onClick: (e: any) => {
                if (rowInfo) {
                  this.openModal(
                    `Edit ${
                    containerType === "/admin/team" ? "Admin" : ""
                    } User`,
                    rowInfo.original
                  );
                }
              }
            };
          }}
          data={sortByCreatedAt(cleanArray(data))}
          columns={[
            {
              Header: "First Name",
              accessor: "name",
              sortable: true,
              filterable: searchFilter
            },
            {
              Header: "Last Name",
              accessor: "surname",
              sortable: true,
              filterable: searchFilter
            },
            {
              Header: "Email Address",
              accessor: "emailAddress",
              sortable: true,
              filterable: searchFilter
            },
            {
              Header: "ID Number",
              accessor: "idNumber",
              sortable: true,
              filterable: false
            },
            {
              id: "permission",
              Header: "Permission",
              accessor: (user: UpdateUserInput) => {
                return user.role ? formatString(user.role, true) : "-";
              },
              sortable: true,
              filterable: false
            }
          ]}
          defaultPageSize={TableConsts.defaultRows}
          showPaginationBottom={
            cleanArray(data).length > TableConsts.defaultRows
          }
        />
      </React.Fragment>
    );
  };

  render() {
    switch (this.state.containerType) {
      case "/companies/team":
        if (this.state.companyData) {
          return (
            <BackendWrapper>
              <Query
                query={gql(getCompaniesUsers)}
                variables={{ id: this.state.companyData.id }}
              >
                {({ loading, error, data }: any) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (error) {
                    return <div>There was a problem loading your data</div>;
                  }
                  if (
                    !data ||
                    !data.getCompany ||
                    !data.getCompany.teamMembers ||
                    !data.getCompany.teamMembers.items
                  ) {
                    return <div>There was a problem loading your data</div>;
                  }
                  return this.returnFields(
                    // after react scripts changes
                    // eslint-disable-next-line
                    data.getCompany.teamMembers.items.map((item: any) => {
                      if (item.user.archived === this.state.archivedFilter) {
                        return item.user;
                      }
                    }),
                    formatString(
                      this.state.companyData && this.state.companyData.name
                        ? this.state.companyData.name
                        : "company"
                    ) + " team members"
                  );
                }}
              </Query>
              <Helmet title="Manage Team" />
            </BackendWrapper>
          );
        } else {
          return (
            <BackendWrapper>
              <Query query={gql(listUsers)} variables={this.getQuery()}>
                {({ loading, error, data }: any) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (error) {
                    return <div>There was a problem loading your data</div>;
                  }

                  if (!data || !data.listUsers) {
                    return <div>There was a problem loading your data</div>;
                  }

                  return this.returnFields(
                    data.listUsers.items,
                    "Manage Users"
                  );
                }}
              </Query>
              <Helmet title="Manage Team" />
            </BackendWrapper>
          );
        }
      case "/consumer/team":
        return (
          <BackendWrapper>
            <Query query={gql(getUsersCompany)} variables={{ id: getUserId() }}>
              {({ loading, error, data }: any) => {
                if (loading) {
                  return <PageLoader />;
                }
                if (error) {
                  return <div>There was a problem loading your data</div>;
                }
                if (
                  !data ||
                  !data.getUser ||
                  !data.getUser.company.items ||
                  !data.getUser.company.items[0].company
                ) {
                  return <div>There was a problem loading your data</div>;
                }
                return this.returnFields(
                  data.getUser.company.items[0].company.teamMembers.items.map(
                    // after react scripts changes
                    // eslint-disable-next-line
                    (item: any) => {
                      if (item.user.archived === this.state.archivedFilter) {
                        return item.user;
                      }
                    }
                  ),
                  formatString(
                    data.getUser.company.items[0].company
                      ? data.getUser.company.items[0].company.name
                      : "company"
                  ) + " team members",
                  data.getUser.company.items[0].company.id
                );
              }}
            </Query>
            <Helmet title="Manage Team" />
          </BackendWrapper>
        );
      default:
        return (
          <BackendWrapper>
            <Query query={gql(listUsers)} variables={this.getQuery()}>
              {({ loading, error, data }: any) => {
                if (loading) {
                  return <PageLoader />;
                }
                if (error) {
                  return <div>There was a problem loading your data</div>;
                }

                if (!data || !data.listUsers) {
                  return <div>There was a problem loading your data</div>;
                }

                return this.returnFields(data.listUsers.items, "Manage Users");
              }}
            </Query>
            <Helmet title="Manage Team" />
          </BackendWrapper>
        );
    }
  }
}

export default withToastManager(Team);
