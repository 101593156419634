import * as React from "react";
import ReactTable, { SubComponentFunction } from "react-table";

/** Import CSS */
import "react-table/react-table.css";
import "./index.css";

/** Utils */
import { filterCaseInsensitive } from "../../Utils/Helpers";

type TableProps = {
  data: any;
  columns: Array<{
    id?: string;
    Header?: string;
    accessor: any;
    sortable?: boolean;
    filterable?: boolean;
    width?: number;
    Cell?: any;
  }>;
  defaultFilterMethod?: any;
  defaultPageSize?: number;
  showPaginationTop?: boolean;
  showPaginationBottom?: boolean;
  showPagination?: boolean;
  SubComponent?: SubComponentFunction;
  getTdProps?: any;
  defaultSortBy?: any;
};

const Table: React.SFC<TableProps> = props => {
  const defaultFilterMethod = props.defaultFilterMethod
    ? props.defaultFilterMethod
    : filterCaseInsensitive;
  return (
    <ReactTable
      data={props.data}
      columns={props.columns}
      defaultPageSize={props.defaultPageSize}
      showPaginationTop={props.showPaginationTop}
      showPaginationBottom={props.showPaginationBottom}
      defaultFilterMethod={defaultFilterMethod}
      SubComponent={props.SubComponent}
      getTdProps={props.getTdProps}
      defaultSorted={props.defaultSortBy}
    />
  );
};

export default Table;
