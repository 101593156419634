import * as React from "react";
import styled from "styled-components";

// Themes
import { Colors } from "../../Themes";

type Props = {
  children: React.ReactNode;
  height?: string;
};

const Wrapper = styled.div`
  position: fixed;
  background: ${Colors.background};
  align-items: center;
  justify-content: center;
  width: 100%
  height: ${(props: { height?: string }) =>
    props.height ? `${props.height}` : "auto"};
  background-size: cover;
  background-position: 50% 50%;
  z-index: 200;
  padding: 0;
  margin: 0;
  flex-direction: column;
`;

const PageLoaderWrapper: React.FC<Props> = props => {
  return <Wrapper height={props.height}>{props.children}</Wrapper>;
};

export default PageLoaderWrapper;
