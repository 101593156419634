import * as React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Row, Col, Label, Form, FormGroup } from "reactstrap";

/** GraphQL */
import { deleteVoucher, updateVoucher } from "../../graphql/mutations";

import {
  DeleteVoucherMutation,
  DeleteVoucherMutationVariables,
  UpdateVoucherMutation,
  UpdateVoucherMutationVariables
} from "../../API";

/** Presentation/UI */
import Loader from "../../Components/Loader";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import StyledButton from "../../Components/Styled/Button";

/** Custom Types */
import { Error } from "../../CustomTypes";

/** Themes */
import { Colors } from "../../Themes";
import { listVouchers } from "../../graphql/queries";
import { TableConsts } from "../../Utils/Consts";

type Props = {
  voucherData: any;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  loading: boolean;
  error: Error;
  archived: boolean;
};

class AddVoucher extends React.Component<Props, State> {
  state: State = {
    loading: false,
    error: null,
    archived: this.props.voucherData.archived
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };
  deleteStatus = (data: any) => {
    if (data.items && data.items.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  // Delete Voucher only if voucher has not been used yet
  deleteVoucher = () => {
    const { voucherData, closeModal, notification } = this.props;
    const { loading } = this.state;
    return (
      <Mutation<DeleteVoucherMutation, DeleteVoucherMutationVariables>
        mutation={gql(deleteVoucher)}
      >
        {deleteVoucherMutation => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              this.setState({ loading: true });
              deleteVoucherMutation({
                variables: {
                  input: {
                    id: voucherData.id
                  }
                },
                refetchQueries: [
                  {
                    query: gql(listVouchers),
                    variables: { limit: TableConsts.limit }
                  }
                ]
              })
                .then(() => {
                  this.setState({ loading: false });
                  closeModal();
                  notification("Voucher Deleted");
                })
                .catch((err: any) => {
                  this.setState({ loading: false });
                  this.setError(err.message);
                });
            }}
          >
            <FormGroup>
              <StyledButton
                type="submit"
                label={!loading ? "Delete Voucher" : <Loader />}
                color={Colors.snow}
                background={Colors.error}
                disabled={this.deleteStatus(voucherData.transactions)}
              />
            </FormGroup>
          </Form>
        )}
      </Mutation>
    );
  };
  toggleArchived = () => {
    this.setState({ archived: !this.state.archived });
  };
  // Archive Voucher only if it has been used
  archiveVoucher = () => {
    const { voucherData, closeModal, notification } = this.props;
    const { loading, archived } = this.state;
    return (
      <Mutation<UpdateVoucherMutation, UpdateVoucherMutationVariables>
        mutation={gql(updateVoucher)}
      >
        {updateVoucherMutation => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              this.setState({ loading: true });
              updateVoucherMutation({
                variables: {
                  input: {
                    id: voucherData.id,
                    archived
                  }
                },
                refetchQueries: [
                  {
                    query: gql(listVouchers),
                    variables: { limit: TableConsts.limit }
                  }
                ]
              })
                .then(() => {
                  this.setState({ loading: false });
                  closeModal();
                  notification("Voucher Updated");
                })
                .catch((err: any) => {
                  this.setState({ loading: false });
                  this.setError(err.message);
                });
            }}
          >
            <React.Fragment>
              <FormGroup>
                <StyledButton
                  type="submit"
                  label={
                    !loading ? (
                      `${archived ? "Unarchive" : "Archive"}`
                    ) : (
                      <Loader />
                    )
                  }
                  color={Colors.snow}
                  background={Colors.error}
                  onClick={() => this.toggleArchived()}
                />
              </FormGroup>
            </React.Fragment>
          </Form>
        )}
      </Mutation>
    );
  };

  render() {
    const { error } = this.state;
    const { voucherData } = this.props;
    return (
      <React.Fragment>
        <Row>
          <h3>{voucherData.code}</h3>
        </Row>
        <Row>
          <Label>If a voucher has been used before you may not delete it</Label>
          <Col xs={12} md={6} lg={6}>
            {this.deleteVoucher()}
          </Col>
          <Col xs={12} md={6} lg={6}>
            {this.archiveVoucher()}
          </Col>
          {error && <ErrorMessage errorMessage={error} />}
        </Row>
      </React.Fragment>
    );
  }
}

export default AddVoucher;
