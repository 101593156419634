import * as React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { createCard, createCompanyCard } from "../../graphql/mutations";
import {
  CreateCardMutation,
  CreateCardMutationVariables,
  CreateCompanyCardMutation,
  CreateCompanyCardMutationVariables,
  CardType
} from "../../API";

import { saveNewCard } from "../../PeachPayments/SaveNewCard";

import { Label, Input, Form, FormGroup } from "reactstrap";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import StyledButton from "../../Components/Styled/Button";
import { Col, Row } from "reactstrap";
import Loader from "../../Components/Loader";

/** Custom Types */
import { Error } from "../../CustomTypes";
import { Colors } from "../../Themes";
import Select from "react-select";
import { formatDropdown } from "../../Utils/Helpers";

type Props = {
  companyId: string;
  closeModal(): void;
  notification(message: string): void;
};

type SelectCardType = {
  value: CardType;
  label: string;
};

type State = {
  loading: boolean;
  error: Error;
  companyId: string;
  nameOnCard: string;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cardCVV: string;
  peachPaymentCardId: string;
  cardType: SelectCardType;
};

class AddCardModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { companyId } = props;
    this.state = {
      loading: false,
      error: null,
      companyId,
      nameOnCard: "",
      cardNumber: "",
      expiryMonth: "",
      expiryYear: "",
      cardCVV: "",
      peachPaymentCardId: "",
      cardType: { value: CardType.VISA, label: "" }
    };
  }

  selectCompanyCardType = (cardType: SelectCardType): void => {
    this.setState({ cardType });
  };

  // Validation
  validateForm = (): boolean => {
    const {
      nameOnCard,
      cardNumber,
      expiryMonth,
      expiryYear,
      cardCVV
    } = this.state;

    // Check for undefined or empty input fields
    if (!nameOnCard || !cardNumber || !expiryMonth || !expiryYear || !cardCVV) {
      this.setError("Please complete the form.");
      return false;
    }

    // Check to make sure the card number is 16 digits long
    if (cardNumber.length !== 16) {
      this.setError("Card Number should be 16 digits long");
      return false;
    }
    // Check to make sure CVV number is 3 digits long
    if (cardCVV.length !== 3) {
      this.setError("CVV number should be 3 digits long");
      return false;
    }

    return true;
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  // format the constants into dropdowns for list of card types
  listOfCardTypes = (consts: object) => {
    return Object.keys(consts).map((item: string) => {
      return formatDropdown(item);
    });
  };

  /* Function called onsubmitting the add card form
   * It saves the card to peach and expects a registrationId so we can save the card to roots db
   * @createCardMutation: Mutation for storing a card
   * @createCompanyCardMutation: Mutation for creating the relationship between card & company
   * */
  saveCardToPeach = (
    createCardMutation: any,
    createCompanyCardMutation: any
  ) => {
    const {
      cardNumber,
      nameOnCard,
      expiryMonth,
      expiryYear,
      cardCVV,
      cardType
    } = this.state;
    return (
      // Peach Function for saving a card to their DB
      saveNewCard(
        cardType.value,
        cardNumber,
        nameOnCard,
        expiryMonth,
        expiryYear,
        cardCVV
      )
        .then((res: any) => {
          // On successfully saving the card get the registrationId
          this.setState({ peachPaymentCardId: res.data.registrationId });

          /* After card has been successfully saved on peach save card to roots db
           * @createCardMutation: Mutation for storing a card
           * @createCompanyCardMutation: Mutation for creating the relationship between card & company
           * */
          return this.saveCardToDB(
            createCardMutation,
            createCompanyCardMutation
          );
        })
        .catch(() => {
          this.setState({ loading: false });
          this.setError(
            "There has been an error, please verify your card details"
          );
          return false;
        })
    );
  };

  /* This function is called after a card has been successfully saved to peach and we have a registrationId
   * it then saves the card info and the registrationId to roots db
   * @createCardMutation: Mutation for storing a card
   * @createCompanyCardMutation: Mutation for creating the relationship between card & company
   * */
  saveCardToDB = async (
    // after react scripts changes
    // eslint-disable-next-line
    createCardMutation: ({ }) => Promise<any>,
    // after react scripts changes
    // eslint-disable-next-line
    createCompanyCardMutation: ({ }) => Promise<any>
  ) => {
    const { closeModal, notification } = this.props;
    const {
      cardNumber,
      nameOnCard,
      expiryMonth,
      expiryYear,
      cardType,
      peachPaymentCardId
    } = this.state;
    return createCardMutation({
      variables: {
        input: {
          // Card details to be stored on roots db including peach payment registrationId as peachPaymentCardId
          number: cardNumber.slice(12, 16),
          nameOnCard,
          expiryMonth,
          expiryYear,
          type: cardType.value,
          peachPaymentCardId
        }
      }
    })
      .then((res: any) => {
        createCompanyCardMutation({
          variables: {
            input: {
              // card id & company id to create the relationship
              companyCardCardId: res.data.createCard.id,
              companyCardCompanyId: this.state.companyId
            }
          }
        })
          .then(() => {
            closeModal();
            notification("Card has been successfully saved");
          })
          .catch((linkCardError: any) => {
            this.setState({ loading: false });
            this.setError(linkCardError.message);
          });
      })
      .catch((createCardErr: any) => {
        this.setState({ loading: false });
        this.setError(createCardErr.message);
      });
  };

  render() {
    const {
      error,
      cardNumber,
      nameOnCard,
      expiryMonth,
      expiryYear,
      cardType,
      cardCVV
    } = this.state;
    return (
      <Mutation<CreateCardMutation, CreateCardMutationVariables>
        mutation={gql(createCard)}
      >
        {createCardMutation => (
          <Mutation<
            CreateCompanyCardMutation,
            CreateCompanyCardMutationVariables
          >
            mutation={gql(createCompanyCard)}
          >
            {createCompanyCardMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  if (this.validateForm()) {
                    this.setState({ loading: true });
                    this.saveCardToPeach(
                      createCardMutation,
                      createCompanyCardMutation
                    );
                  }
                }}
              >
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <FormGroup>
                      <Label>Card Type</Label>
                      <Select
                        options={this.listOfCardTypes(CardType)}

                        // @ts-ignore after react scripts changes.
                        onChange={this.selectCompanyCardType}
                        value={cardType}
                        className="select=styling"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Name on Card</Label>
                      <Input
                        type="text"
                        name="nameOnCard"
                        id="nameOnCard"
                        value={nameOnCard}
                        placeholder="Name on card"
                        onChange={e =>
                          this.setState({ nameOnCard: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Card Number</Label>
                      <Input
                        type="text"
                        name="cardNumber"
                        id="cardNumber"
                        value={cardNumber}
                        placeholder="Card Number"
                        maxLength={16}
                        onChange={e =>
                          this.setState({ cardNumber: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Expiry Month</Label>
                      <Input
                        type="text"
                        name="expiryMonth"
                        id="expiryMonth"
                        value={expiryMonth}
                        placeholder="03"
                        maxLength={2}
                        onChange={e =>
                          this.setState({ expiryMonth: e.target.value })
                        }
                      />
                      <Label>Expiry Year</Label>
                      <Input
                        type="text"
                        name="expiryYear"
                        id="expiryYear"
                        value={expiryYear}
                        placeholder="20"
                        maxLength={4}
                        pattern="^[0-9]{4}$"
                        onChange={e =>
                          this.setState({ expiryYear: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>CVV</Label>
                      <Input
                        type="text"
                        name="cardCVV"
                        id="cardCVV"
                        value={cardCVV}
                        placeholder="123"
                        maxLength={3}
                        onChange={e =>
                          this.setState({ cardCVV: e.target.value })
                        }
                      />
                    </FormGroup>
                    <StyledButton
                      type="submit"
                      label={this.state.loading ? <Loader /> : "Add Card"}
                      width="100px"
                      color={Colors.snow}
                      background={Colors.default}
                    />
                  </Col>
                  {error && <ErrorMessage errorMessage={error} />}
                </Row>
              </Form>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export default AddCardModal;
