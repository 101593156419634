import jwtDecode from "jwt-decode";

import { AUTH_USER_TOKEN_KEY } from "./LocalStorage";

import { Storage } from "aws-amplify";

/** Custom Types */
import { UserRoleType } from "../CustomTypes";
import { USER_ROLES } from "./Consts";
import { UserRole, Industry } from "../API";

/**
 * Has a valid test.
 * 
 * @param token This is a JWT
 */
export const validateToken = (token: any): boolean => {
  if (!token) {
    return false;
  }
  try {
    // @ts-ignore after react scripts changes
    const decodedJwt: any = jwtDecode(token);
    return decodedJwt.exp >= Date.now() / 1000;
  } catch (e) {
    return false;
  }
};


/** 
 * Has a valid test
 * Return user id from JWT 
 * 
 */
export const getUserId = (jwt?: string): string => {
  let token: string | undefined | null = jwt
  if (!jwt) {
    token = window.localStorage.getItem(AUTH_USER_TOKEN_KEY);
  }
  if (token) {
    // @ts-ignore after react scripts changes
    const decodedJwt: any = jwtDecode(token);
    return decodedJwt.sub;
  }
  return "";
};

/** 
 * Has a valid test
 * Return user role from JWT 
 */
export const getUserRole = (jwt?: string): string => {
  let token: string | undefined | null = jwt
  if (!jwt) {
    token = window.localStorage.getItem(AUTH_USER_TOKEN_KEY);
  }
  if (token) {
    // @ts-ignore after react scripts changes
    const decodedJwt: any = jwtDecode(token);
    return decodedJwt["cognito:groups"][0];
  }
  return "";
};

/** 
 * Has a valid test
 * Convert text to a selector
 */
export const convertToSelector = (text: string): string => {
  return text.toLowerCase().replace(" ", "-");
};

/** Get array from object */
export const getArrayFromObject = (objectToConvert: any) => {
  const arrayFromObject = [];

  for (const item in objectToConvert) {
    if (Object.keys(objectToConvert[item]).length > 0) {
      arrayFromObject.push({
        ...objectToConvert[item]
      });
    }
  }

  return arrayFromObject;
};

/**
 * Filter regardless of letter casing. Used by react table filter
 */
export const filterCaseInsensitive = (filter: any, row: any) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true;
};

/** 
 * Don't know how to test AWS calls
 * Display avatar image
 */
export const returnUserAvatar = async (
  avatarKey: string
): Promise<object | string> => {
  const result = await Storage.get(avatarKey).catch(err => {
    return "";
  });
  return result;
};

/** 
 * Has a valid test
 * Return user roles
 */
export const returnUserRole = (role: UserRoleType): string => {
  switch (role) {
    case USER_ROLES.rootsSuperAdmin:
      return "Roots Super Admin";
    case USER_ROLES.rootsAdmin:
      return "Roots Admin";
    case USER_ROLES.consumerAdmin:
      return "Consumer Admin";
    case USER_ROLES.superUser:
      return "Super User";
    case USER_ROLES.user:
      return "User";
    default:
      return "Non-User";
  }
};

/** 
 * Has a valid test
 * formats a string 'LIKE_THIS' to something 'Like this'
 */
export const formatString = (
  toFix: string | UserRole | Industry | null,
  allFirstCaps: boolean = false
) => {
  try {
    let toFixTemp = "";
    if (toFix) {
      toFixTemp = toFix;
    }
    if (allFirstCaps) {
      let sentence: string = "";
      const temp: string[] = toFixTemp
        .toLowerCase()
        .replace(/_/g, " ")
        .split(" ");
      temp.forEach(
        word =>
          (sentence +=
            word.charAt(0).toUpperCase() + word.substr(1).toLowerCase() + " ")
      );

      return sentence;
    }
    return (
      toFixTemp.charAt(0).toUpperCase() +
      toFixTemp
        .substr(1)
        .toLowerCase()
        .replace(/_/g, " ")
    );
  } catch (error) {
    return toFix;
  }
};

/** 
 * Has a valid test
 * formats a string 'LIKE_THIS' to an object { value: "LIKE_THIS", label: "Like this" }
 */
export const formatDropdown = (
  enumName: string | UserRole | null | undefined | any
) => {
  try {
    if (!enumName) {
      return { value: "", label: "" };
    }
    return { value: enumName, label: formatString(enumName || "") };
  } catch (error) {
    return { value: "", label: "" };
  }
};

/** 
 * Has a valid test
 * formats a string 'like-this' to an a string 'Like This'
 */
export const formatTitle = (toFix: string = "") => {
  try {
    let sentence: string = "";
    const temp: string[] = toFix
      .toLowerCase()
      .replace(/-/g, " ")
      .replace(/\//g, "")
      .split(" ");
    temp.forEach(
      word =>
        (sentence +=
          word.charAt(0).toUpperCase() + word.substr(1).toLowerCase() + " ")
    );

    return formatString(sentence, true);
  } catch (error) {
    return "";
  }
};

/**
 * Has a valid test
 * This function removes any undefined items from an array.
 * @param toClean
 */
export const cleanArray = (toClean: any[]) => {
  return toClean.filter(el => {
    return el != null;
  });
};

/**
 * This function sorts an array of objects by the "createdAt" field,
 * and returns an array sorted in descending order.
 * @param toSort
 */
export const sortByCreatedAt = (toSort: any[]) => {
  return toSort.sort((a: any, b: any) => (a.createdAt > b.createdAt) ? 1 : -1).reverse()
};

/**
 * Has a valid test
 * This function generates a random 11 digit alpha numeric string
 */
export const generateRandomNumber = () => {
  return Math.random()
    .toString(36)
    .substr(2)
};

/**
 * Don't know how to test AWS calls.
 * Return image from AWS storage.
 * @param imageKey
 */
export const returnImageFromStorage = async (
  imageKey: string
): Promise<string> => {
  const result = await Storage.get(imageKey).catch(err => {
    return '';
  });
  return typeof result === 'string' ? result : '';
};