import axios from "axios";
import { TEST_HOST, AUTHORIZATION, ENTITY_ID_RECURRING } from "./Constants";

// Make payment through peach
export async function makePayment(
  cardId: string,
  amount: number
): Promise<object> {
  try {
    return axios({
      method: "post",
      headers: {
        Authorization: AUTHORIZATION
      },
      url: `/v1/registrations/${cardId}/payments`,
      baseURL: TEST_HOST,
      data:
        "entityId=" +
        ENTITY_ID_RECURRING +
        "&paymentType=DB" +
        "&currency=ZAR" +
        `&amount=${Number(amount).toFixed(2)}`
    });
  } catch (e) {
    return { error: e.message };
  }
}
