import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

/** GraphQL */
import { getUser } from "../../graphql/queries";

import { GetUserQuery, GetUserQueryVariables } from "../../API";

/**  Presentation UI */
import PageLoaderWrapper from "../../Components/Layouts/PageLoaderWrapper";
import PageLoader from "../../Components/PageLoader";

/** Local components */
import HeaderWrapper from "./HeaderWrapper";

/** Utils */
import { getUserId } from "../../Utils/Helpers";

type Props = {
  userId: string;
};

class Header extends React.Component<Props> {
  render() {
    return (
      <Query<GetUserQuery, GetUserQueryVariables>
        query={gql(getUser)}
        variables={{ id: this.props.userId || getUserId() }}
        fetchPolicy="cache-first"
      >
        {({ loading, error, data }: any) => {
          if (loading) {
            return (
              <PageLoaderWrapper height="70px">
                <PageLoader height="70px" />
              </PageLoaderWrapper>
            );
          }
          if (error) {
            return <div>There was a problem loading your user data</div>;
          }

          if (!data) {
            return <div>There was a problem loading your user data</div>;
          }

          const user = data.getUser;

          if (!user) {
            return <div>There was a problem loading your user data</div>;
          }
          return <HeaderWrapper user={user} />;
        }}
      </Query>
    );
  }
}

export default Header;
