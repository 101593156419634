import * as React from "react";
import * as moment from "moment";
import * as validator from "validator";
import { AUTH_USER_TOKEN_KEY } from "../../Utils/LocalStorage";
import { UserRole } from "../../API";
import AppSyncConfig from "../../aws-exports";
import { HTTP_METHODS } from "../../Utils/Consts";
import { COGNITO_EDIT_USER } from "../../Utils/LambdaEndpoints";
import { apiRequest } from "../../Utils/API";

import { TiMail, TiLockClosedOutline } from "react-icons/ti";
import { MdCopyright } from "react-icons/md";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

import styled from "styled-components";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import { Auth } from "aws-amplify";

// Presentation/UI
import FullWidthContainer from "../../Components/Layouts/FullWidthContainer";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import TermsAndConditions from "../../Components/TermsAndConditions";
import LottieWrapper from "../../Components/Anim/LottieWrapper";
import GlobalModalContainer from "../../Components/Modal";
import Loader from "../../Components/Loader";
import Span from "../../Components/Styled/Span";
import Link from "../../Components/Styled/Link";

// Local components
import ConfirmationCodeForm from "./ConfirmationCodeForm";

// Custom Types
import { ConfirmationCodeError, SignUpViews, Error } from "../../CustomTypes";

// Themes
import { Colors } from "../../Themes";

type IProps = {
  createUserMutation({
    variables: {
      // after react scripts changes
      // eslint-disable-next-line
      input: { }
    }
  }): void;
};

type IState = {
  email: string;
  password: string;
  error: Error;
  confirmationCodeError: ConfirmationCodeError | null;
  loading: boolean;
  showPassword: boolean;
  showOptionToResendCode: boolean;
  modal: boolean;
  termsModal: boolean;
  view: SignUpViews;
};

const SignUpContainer = styled.div`
  width: 100%;
  & input {
    background: transparent !important;
  }
  & .input-group {
    background: rgba(214, 219, 230, 0.36) !important;
    border-radius: 0.25rem;
  }
  & a {
    color: ${Colors.primary};
    text-decoration: none;
  }
`;

export default class LoginForm extends React.Component<IProps, IState> {
  state: IState = {
    view: "form",
    email: "",
    password: "",
    loading: false,
    showPassword: false,
    showOptionToResendCode: false,
    modal: false,
    termsModal: false,
    error: null,
    confirmationCodeError: null
  };

  // Sign up user
  signUp = () => {
    const { email, password } = this.state;
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email
      }
    })
      .then(() => {
        this.setState({ loading: false });
        this.openModal();
      })
      .catch(err => {
        this.setState({ loading: false });
        this.setError(err.message);
        if (
          err.code === "UsernameExistsException" ||
          err.name === "UsernameExistsException"
        ) {
          this.setState({ showOptionToResendCode: true });
        }
      });
  };

  // Verify user email account and create user
  confirmSignUp = (confirmationCode: string) => {
    const { email, password } = this.state;
    const { createUserMutation } = this.props;
    Auth.confirmSignUp(email, confirmationCode)
      .then(data => {
        if (data === "SUCCESS") {
          const bodyParams = {
            userPoolId: AppSyncConfig.aws_user_pools_id,
            email,
            groupName: UserRole.CONSUMER_ADMIN
          };
          apiRequest(COGNITO_EDIT_USER, HTTP_METHODS.POST, bodyParams)
            .then(() => {
              Auth.signIn(email, password).then(user => {
                // store user's session
                window.localStorage.setItem(
                  AUTH_USER_TOKEN_KEY,
                  user.signInUserSession.accessToken.jwtToken
                );
                const {
                  sub,
                  username
                } = user.signInUserSession.accessToken.payload;
                createUserMutation({
                  variables: {
                    input: {
                      id: sub,
                      emailAddress: username
                    }
                  }
                });
                this.closeModal();
                this.setState({ view: "companySetup" });
              });
            })
            .catch(err => {
              this.setState({ confirmationCodeError: err });
            });
        }
      })
      .catch(err => {
        this.setState({ confirmationCodeError: err });
      });
  };

  // Resend verification code
  resendConfirmSignUp = (email: string) => {
    Auth.resendSignUp(email)
      .then(() => {
        this.openModal();
      })
      .catch(err => {
        this.setError(err.message);
      });
  };

  // Close modal
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  // Open modal
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  /** Open modal with terms and conditions */
  openTermsAndConditionsModal = (): void => {
    this.setState({
      termsModal: true
    });
  };

  /** Close modal with terms and conditions */
  closeTermsAndConditionsModal = (): void => {
    this.setState({
      termsModal: false
    });
  };

  // Handle change
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    switch (name) {
      case "email":
        this.setState({ email: value.toLowerCase() });
        break;
      case "password":
        this.setState({ password: value });
        break;
      default:
        break;
    }
  };

  validateForm = (): boolean => {
    const { email, password } = this.state;
    // Check for undefined or empty input fields
    if (!email || !password) {
      this.setError("Please enter a valid email and password.");
      return false;
    }

    // Validate email
    if (!validator.isEmail(email)) {
      this.setError("Please enter a valid email address.");
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  // Toggle password visibility
  togglePasswordVisibility = (): void => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  // Render eye icon
  renderEyeIcon = () => {
    const { showPassword } = this.state;

    if (showPassword) {
      return (
        <IoIosEye
          size="1.5em"
          style={{ marginRight: "10px", marginTop: "10px" }}
        />
      );
    }

    return (
      <IoIosEyeOff
        size="1.5em"
        style={{ marginRight: "10px", marginTop: "10px" }}
      />
    );
  };

  // Return Loader
  renderLoader = () => {
    return (
      <FullWidthContainer align="center">
        <Loader size={150} color={Colors.primary} />
        <br />
        <br />
        <p className="center-align">Please wait...</p>
      </FullWidthContainer>
    );
  };

  // Render view
  renderView = () => {
    const { email, password, view, showPassword, loading } = this.state;
    const fieldType = showPassword ? "text" : "password";

    if (view === "companySetup") {
      return (
        <FullWidthContainer>
          <LottieWrapper
            loop={false}
            width={280}
            height={220}
            anim={require("../../LottieFiles/success1.json")}
          />
          <br />
          <br />
          <p className="center-align">
            Your account has been verified.
            <br />
            <br />
            <Link
              to="/company-setup"
              label={<Button>Continue to company setup</Button>}
              color={Colors.primary}
            />
          </p>
        </FullWidthContainer>
      );
    }

    return (
      <Form
        onSubmit={e => {
          e.preventDefault();
          if (this.validateForm()) {
            this.setState({ loading: true });
            this.signUp();
          }
        }}
      >
        <FormGroup>
          <Label for="userEmail">Email Address</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <TiMail size="1.5em" />
            </InputGroupAddon>
            <Input
              type="email"
              name="email"
              value={email}
              id="userEmail"
              placeholder=""
              onChange={this.handleChange}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="userPassword">Password</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <TiLockClosedOutline size="1.5em" />
            </InputGroupAddon>
            <Input
              type={fieldType}
              name="password"
              value={password}
              id="userPassword"
              placeholder=""
              onChange={this.handleChange}
            />
            <InputGroupAddon
              onClick={this.togglePasswordVisibility}
              addonType="append"
            >
              {this.renderEyeIcon()}
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        <br />
        <FullWidthContainer align="center">
          By signing up, you are agreeing to our{" "}
          <Span
            onClick={this.openTermsAndConditionsModal}
            text="Terms &amp; Conditions"
            pointer={true}
            color={Colors.primary}
          />
        </FullWidthContainer>
        <br />
        <br />

        {this.state.showOptionToResendCode ? (
          <Button
            type="button"
            onClick={() => this.resendConfirmSignUp(this.state.email)}
          >
            {!loading ? <span>Resend Verification Code</span> : <Loader />}
          </Button>
        ) : (
            <Button disabled={loading}>
              {!loading ? "Sign Up" : <Loader />}
            </Button>
          )}
        <br />
        <br />
        <br />
        <FullWidthContainer align="center">
          <Span
            text="Already have an account?"
            style={{ marginRight: "10px" }}
          />
          {"  "}
          <Link to="/sign-in" label="Sign In" color={Colors.primary} />
        </FullWidthContainer>
      </Form>
    );
  };

  render() {
    const { modal, termsModal, confirmationCodeError, error } = this.state;
    return (
      <SignUpContainer>
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title="Please Check Your Email"
          modalDisplay={
            <ConfirmationCodeForm
              confirmSignUp={this.confirmSignUp}
              confirmationCodeError={confirmationCodeError}
            />
          }
          modal={modal}
        />
        <TermsAndConditions
          modal={termsModal}
          closeModal={this.closeTermsAndConditionsModal}
        />
        {this.renderView()}
        {error && <ErrorMessage errorMessage={error} />}
        <br />
        <FullWidthContainer align="center">
          <Span text="ROOTS" />{" "}
          <MdCopyright
            size="1em"
            style={{ position: "relative", top: "-2px" }}
          />{" "}
          {/*
          // @ts-ignore after react scripts changes.*/}
          {moment().format("YYYY")}
        </FullWidthContainer>
      </SignUpContainer>
    );
  }
}
