import * as React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Row, Col, Form, Label, FormGroup, Input } from "reactstrap";

/** GraphQL */
import {
  createTag,
  createCategory,
  updateTag,
  updateCategory,
  updateInterest,
  createInterest,
  deleteCategory,
  deleteTag,
  deleteInterest
} from "../../graphql/mutations";

import {
  CreateTagMutation,
  CreateTagMutationVariables,
  CreateInterestMutation,
  CreateInterestMutationVariables,
  CreateCategoryMutation,
  CreateCategoryMutationVariables,
  UpdateTagMutation,
  UpdateInterestMutation,
  UpdateTagMutationVariables,
  UpdateInterestMutationVariables,
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables,
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables,
  DeleteTagMutation,
  DeleteTagMutationVariables,
  DeleteInterestMutation,
  DeleteInterestMutationVariables
} from "../../API";

/** Presentation/UI */
import Loader from "../../Components/Loader";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import StyledButton from "../../Components/Styled/Button";

/** Custom Types */
import { Error } from "../../CustomTypes";

/** Themes */
import { Colors } from "../../Themes";
import { TableConsts } from "../../Utils/Consts";
import {
  listCategorysWithReports,
  listTagsWithReports,
  listInterestsWithCompanys
} from "../../graphql/custom-queries";

type Props = {
  data?: any; // UpdateTagInput | UpdateCategoryInput | null;
  containerType: string;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  loading: boolean;
  error: Error;
  name: string;
  selectedId: string;
};

class AddEdit extends React.Component<Props, State> {
  state: State = {
    loading: false,
    error: null,
    name: this.props.data && this.props.data.name ? this.props.data.name : "",
    selectedId: this.props.data && this.props.data.id ? this.props.data.id : ""
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Create a new tag */
  // after react scripts changes
  // eslint-disable-next-line
  createTag = async (createTagMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { name } = this.state;

    this.setState({ loading: true });
    // create the tag in dynamodb
    if (name !== "") {
      createTagMutation({
        variables: {
          input: {
            name
          }
        },
        refetchQueries: [
          {
            query: gql(listTagsWithReports),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Added tag, ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };
  /** edit a tag */
  // after react scripts changes
  // eslint-disable-next-line
  editTag = async (updateTagMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { selectedId, name } = this.state;

    this.setState({ loading: true });
    // update the tag in dynamodb
    if (name !== "") {
      updateTagMutation({
        variables: {
          input: {
            id: selectedId,
            name
          }
        },
        refetchQueries: [
          {
            query: gql(listTagsWithReports),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Updated tag, ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  /** Create a new category */
  // after react scripts changes
  // eslint-disable-next-line
  createCategory = async (createCategoryMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { name } = this.state;

    this.setState({ loading: true });
    // create the tag in dynamodb
    if (name !== "") {
      createCategoryMutation({
        variables: {
          input: {
            name
          }
        },
        refetchQueries: [
          {
            query: gql(listCategorysWithReports),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Added category ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  /** Create a new category */
  // after react scripts changes
  // eslint-disable-next-line
  editCategory = async (editCategoryMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { selectedId, name } = this.state;

    this.setState({ loading: true });
    // create the tag in dynamodb
    if (name !== "") {
      editCategoryMutation({
        variables: {
          input: {
            id: selectedId,
            name
          }
        },
        refetchQueries: [
          {
            query: gql(listCategorysWithReports),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Edited category ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  /** Create a new category */
  // after react scripts changes
  // eslint-disable-next-line
  createInterest = async (createInterestMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { name } = this.state;

    this.setState({ loading: true });
    // create the tag in dynamodb
    if (name !== "") {
      createInterestMutation({
        variables: {
          input: {
            name
          }
        },
        refetchQueries: [
          {
            query: gql(listInterestsWithCompanys),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Added Interest ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  /** Create a new category */
  // after react scripts changes
  // eslint-disable-next-line
  editInterest = async (editInterestMutation: ({ }) => Promise<any>) => {
    const { closeModal, notification } = this.props;
    const { selectedId, name } = this.state;

    this.setState({ loading: true });
    // create the tag in dynamodb
    if (name !== "") {
      editInterestMutation({
        variables: {
          input: {
            id: selectedId,
            name
          }
        },
        refetchQueries: [
          {
            query: gql(listInterestsWithCompanys),
            variables: { limit: TableConsts.limit }
          }
        ]
      })
        .then(() => {
          this.setState({ loading: false });
          closeModal();
          notification(`Edited Interest ${this.state.name}`);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    } else {
      this.setState({ loading: false });
      this.setError("Name cannot be blank");
    }
  };

  returnDelete = () => {
    const { loading } = this.state;
    switch (this.props.containerType) {
      case "tag":
        if (
          this.props.data &&
          this.props.data.reports &&
          this.props.data.reports.items.length > 0
        ) {
          return null;
        }
        return (
          <Mutation<DeleteTagMutation, DeleteTagMutationVariables>
            mutation={gql(deleteTag)}
          >
            {deleteTagMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  deleteTagMutation({
                    variables: {
                      input: {
                        id: this.state.selectedId
                      }
                    },
                    refetchQueries: [
                      {
                        query: gql(listTagsWithReports),
                        variables: {
                          limit: TableConsts.limit
                        }
                      }
                    ]
                  })
                    .then(() => {
                      this.setState({ loading: false });
                      this.props.closeModal();
                      this.props.notification(`Deleted tag ${this.state.name}`);
                    })
                    .catch((err: any) => {
                      this.setState({ loading: false });
                      this.setError(err.message);
                    });
                }}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <StyledButton
                        type="submit"
                        label={!loading ? "Delete" : <Loader />}
                        color={Colors.coal}
                        background={Colors.error}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}
          </Mutation>
        );
      case "interest":
        if (
          this.props.data &&
          this.props.data.company &&
          this.props.data.company.items.length > 0
        ) {
          return null;
        }
        return (
          <Mutation<DeleteInterestMutation, DeleteInterestMutationVariables>
            mutation={gql(deleteInterest)}
          >
            {deleteInterestMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  deleteInterestMutation({
                    variables: {
                      input: {
                        id: this.state.selectedId
                      }
                    },
                    refetchQueries: [
                      {
                        query: gql(listInterestsWithCompanys),
                        variables: {
                          limit: TableConsts.limit
                        }
                      }
                    ]
                  })
                    .then(() => {
                      this.setState({ loading: false });
                      this.props.closeModal();
                      this.props.notification(
                        `Deleted interest ${this.state.name}`
                      );
                    })
                    .catch((err: any) => {
                      this.setState({ loading: false });
                      this.setError(err.message);
                    });
                }}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <StyledButton
                        type="submit"
                        label={!loading ? "Delete" : <Loader />}
                        color={Colors.coal}
                        background={Colors.error}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}
          </Mutation>
        );
      case "category":
        if (
          this.props.data &&
          this.props.data.reports &&
          this.props.data.reports.items.length > 0
        ) {
          return null;
        }
        return (
          <Mutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>
            mutation={gql(deleteCategory)}
          >
            {deleteCategoryMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  deleteCategoryMutation({
                    variables: {
                      input: {
                        id: this.state.selectedId
                      }
                    },
                    refetchQueries: [
                      {
                        query: gql(listCategorysWithReports),
                        variables: {
                          limit: TableConsts.limit
                        }
                      }
                    ]
                  })
                    .then(() => {
                      this.setState({ loading: false });
                      this.props.closeModal();
                      this.props.notification(
                        `Deleted category ${this.state.name}`
                      );
                    })
                    .catch((err: any) => {
                      this.setState({ loading: false });
                      this.setError(err.message);
                    });
                }}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <StyledButton
                        type="submit"
                        label={!loading ? "Delete" : <Loader />}
                        color={Colors.coal}
                        background={Colors.error}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}
          </Mutation>
        );
      default:
        return null;
    }
  };

  returnFormFields = () => {
    const { error, loading, name, selectedId } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                value={name}
                id="name"
                placeholder="Name"
                onChange={e => this.setState({ name: e.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <StyledButton
                type="submit"
                label={
                  !loading ? selectedId !== "" ? "Save" : "Add" : <Loader />
                }
                color={Colors.coal}
                background={Colors.background}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        {selectedId !== "" ? this.returnDelete() : null}
        {error && <ErrorMessage errorMessage={error} />}
      </React.Fragment>
    );
  };

  render() {
    switch (this.props.containerType) {
      case "tag":
        if (this.state.selectedId !== "") {
          return (
            <Mutation<UpdateTagMutation, UpdateTagMutationVariables>
              mutation={gql(updateTag)}
            >
              {updateTagMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.editTag(updateTagMutation);
                  }}
                >
                  {this.returnFormFields()}
                </Form>
              )}
            </Mutation>
          );
        } else {
          return (
            <Mutation<CreateTagMutation, CreateTagMutationVariables>
              mutation={gql(createTag)}
            >
              {createTagMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.createTag(createTagMutation);
                  }}
                >
                  {this.returnFormFields()}
                </Form>
              )}
            </Mutation>
          );
        }
      case "category":
        if (this.state.selectedId !== "") {
          return (
            <Mutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>
              mutation={gql(updateCategory)}
            >
              {updateCategoryMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.editCategory(updateCategoryMutation);
                  }}
                >
                  {this.returnFormFields()}
                </Form>
              )}
            </Mutation>
          );
        } else {
          return (
            <Mutation<CreateCategoryMutation, CreateCategoryMutationVariables>
              mutation={gql(createCategory)}
            >
              {createCategoryMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.createCategory(createCategoryMutation);
                  }}
                >
                  {this.returnFormFields()}
                </Form>
              )}
            </Mutation>
          );
        }
      case "interest":
        if (this.state.selectedId !== "") {
          return (
            <Mutation<UpdateInterestMutation, UpdateInterestMutationVariables>
              mutation={gql(updateInterest)}
            >
              {updateInterestMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.editInterest(updateInterestMutation);
                  }}
                >
                  {this.returnFormFields()}
                </Form>
              )}
            </Mutation>
          );
        } else {
          return (
            <Mutation<CreateInterestMutation, CreateInterestMutationVariables>
              mutation={gql(createInterest)}
            >
              {createInterestMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.createInterest(createInterestMutation);
                  }}
                >
                  {this.returnFormFields()}
                </Form>
              )}
            </Mutation>
          );
        }
      default:
        return <div>There was an error loading your data.</div>;
    }
  }
}

export default AddEdit;
