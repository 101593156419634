import * as React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Row, Col, Form, Label, FormGroup, Input } from "reactstrap";

/** GraphQL */
import { createVoucher } from "../../graphql/mutations";

import {
  CreateVoucherMutation,
  CreateVoucherMutationVariables
} from "../../API";

/** Presentation/UI */
import Loader from "../../Components/Loader";
import ErrorMessage from "../../Components/Styled/ErrorMessage";
import StyledButton from "../../Components/Styled/Button";

/** Custom Types */
import { Error } from "../../CustomTypes";

/** Themes */
import { Colors } from "../../Themes";
import { listVouchers } from "../../graphql/queries";
import { TableConsts } from "../../Utils/Consts";

type Props = {
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  loading: boolean;
  error: Error;
  discountPercentage: string;
  numberOfUses: string;
  expiryDate: string;
};

class AddVoucher extends React.Component<Props, State> {
  state: State = {
    loading: false,
    error: null,
    discountPercentage: "",
    numberOfUses: "",
    expiryDate: ""
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  // Validate the voucher form
  validateForm = (): boolean => {
    const { discountPercentage, numberOfUses, expiryDate } = this.state;

    if (!discountPercentage || !numberOfUses || !expiryDate) {
      this.setError("Please complete all fields");
      this.setState({ loading: false });
      return false;
    }

    return true;
  };

  render() {
    const { closeModal, notification } = this.props;
    const {
      error,
      loading,
      discountPercentage,
      numberOfUses,
      expiryDate
    } = this.state;
    const re = /^[0-9\b]+$/;
    return (
      <Mutation<CreateVoucherMutation, CreateVoucherMutationVariables>
        mutation={gql(createVoucher)}
      >
        {createVoucherMutation => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              this.setState({ loading: true });
              if (this.validateForm()) {
                createVoucherMutation({
                  variables: {
                    input: {
                      code: Math.random()
                        .toString(36)
                        .substr(2)
                        .toUpperCase(),
                      discountPercentage,
                      numberOfUses,
                      archived: false,
                      expiryDate
                    }
                  },
                  refetchQueries: [
                    {
                      query: gql(listVouchers),
                      variables: { limit: TableConsts.limit }
                    }
                  ]
                })
                  .then(() => {
                    this.setState({ loading: false });
                    closeModal();
                    notification("Voucher Saved");
                  })
                  .catch((err: any) => {
                    this.setState({ loading: false });
                    this.setError(err.message);
                  });
              }
            }}
          >
            <React.Fragment>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <FormGroup>
                    <Label for="discountPercentage">Discount Percentage</Label>
                    <Input
                      type="text"
                      name="discountPercentage"
                      value={discountPercentage}
                      id="discountPercentage"
                      placeholder="Discout Percentage"
                      onChange={e => {
                        if (e.target.value === "" || re.test(e.target.value)) {
                          this.setState({ discountPercentage: e.target.value });
                        }
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="numberOfUses">Number Of Uses</Label>
                    <Input
                      type="text"
                      name="numberOfUses"
                      value={numberOfUses}
                      id="numberOfUses"
                      placeholder="Number Of Uses"
                      onChange={e => {
                        if (e.target.value === "" || re.test(e.target.value)) {
                          this.setState({ numberOfUses: e.target.value });
                        }
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="expiryDate">Expiry Date</Label>
                    <Input
                      type="date"
                      name="expiryDate"
                      value={expiryDate}
                      id="expiryDate"
                      placeholder="Expiry Date"
                      onChange={e =>
                        this.setState({ expiryDate: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <FormGroup>
                    <StyledButton
                      type="submit"
                      label={!loading ? "Generate Voucher" : <Loader />}
                      color={Colors.coal}
                      background={Colors.background}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              {error && <ErrorMessage errorMessage={error} />}
            </React.Fragment>
          </Form>
        )}
      </Mutation>
    );
  }
}

export default AddVoucher;
