const images = {
  defaultAvatar: require("../Assets/Images/default-avatar.svg"),
  rootsLogo: require("../Assets/Images/logo.png"),
  rootsLogoIcon: require("../Assets/Images/logo.png"),
  landingBackground: require("../Assets/Images/bg1.jpg"),
  placeHolder: require("../Assets/Images/finance_insurance.jpeg"),
  pdfIcon: require("../Assets/Images/pdfIcon.png")
};

export default images;
